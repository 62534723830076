import React, { useState } from "react";
import { useSelector } from "react-redux";
import GoogleMap from "@client.components/GoogleMap_DashBoard";
import { makeDashboardFilter } from "@client.features/main/dashboard";
import DashboardMapFilter from "./DashboardMapFilter";
import DeviceStatuses from "@client.enums/deviceStatuses";
import DeviceTypes from "@client.enums/deviceTypes";
import { IDevice, IHeatmap, IZone } from "@client.features/main/type";

const DevicesOverview = ({
  deviceMap,
  deviceLocation,
  deviceHeatmap
}: {
  deviceMap: IDevice[];
  deviceLocation: IZone[];
  deviceHeatmap: IHeatmap[];
}) => {
  // console.log("test deviceLocation", deviceLocation);
  const dashboardFilter = useSelector(makeDashboardFilter);
  const [recenterMapTimestamp, setRecenterMapTimestamp] = useState<number>();
  const [selectedClusterMarkers, setSelectedClusterMarkers] = useState<
    IDevice[]
  >([]);

  const typeStatistic = {
    dynamicQty: 0,
    staticQty: 0,
    unknownQty: 0
  };

  const statusStatistic = {
    onlineQty: 0,
    offlineQty: 0
  };
  console.log("test deviceMap", deviceMap);
  const markerPositions = deviceMap
    ? deviceMap
        .filter((item) => {
          console.log("From DevicesOverview Item", item);
          let statusMatch = false;
          let typeMatch = false;

          if (item.isOnline) {
            statusStatistic.onlineQty++;
          } else {
            statusStatistic.offlineQty++;
          }

          switch (item.deviceType) {
            case DeviceTypes.Unknown:
              typeStatistic.unknownQty++;
              break;
            case DeviceTypes.Static:
              typeStatistic.staticQty++;
              break;
            case DeviceTypes.Dynamic:
              typeStatistic.dynamicQty++;
              break;
          }

          if (dashboardFilter.isOnline && item.isOnline) {
            statusMatch = true;
          }

          if (dashboardFilter.isOffline && !item.isOnline) {
            statusMatch = true;
          }

          if (
            dashboardFilter.isDynamic &&
            DeviceTypes.Dynamic === item.deviceType
          ) {
            typeMatch = true;
          }

          if (
            dashboardFilter.isUnknown &&
            DeviceTypes.Unknown === item.deviceType
          ) {
            typeMatch = true;
          }

          if (
            dashboardFilter.isStatic &&
            DeviceTypes.Static === item.deviceType
          ) {
            typeMatch = true;
          }

          return statusMatch && typeMatch;
        })
        .map((device) => ({
          lat: device.latitude,
          lng: device.longitude,
          deviceId: device.Id,
          status: device.isOnline
            ? DeviceStatuses.Online
            : DeviceStatuses.Offline
        }))
    : [];
  console.log("test markerPositions", markerPositions);
  return (
    <>
      <div className="devices-map">
        <GoogleMap
          className="dashboard-google-map"
          options={{
            mapTypeControl: false,
            disableDefaultUI: true,
            zoomControl: true
          }}
          // zoom={deviceMap.length>1?12:null}
          zoom={12}
          markers={markerPositions}
          polymarks={dashboardFilter.enableLocations ? deviceLocation : []}
          heatmapData={dashboardFilter.enableHeatmap ? deviceHeatmap : []}
          recenterMapTimestamp={recenterMapTimestamp}
          onClusterClick={(markersData) => {
            const deviceIds: string[] = markersData.map((d) => d.deviceId);
            setSelectedClusterMarkers(
              deviceMap.filter((d) => deviceIds.includes(d.Id))
            );
          }}
          onMarkerClick={(marker) => {
            setSelectedClusterMarkers(
              deviceMap.filter((d) => d.Id === marker.deviceId)
            );
          }}
        >
          <DashboardMapFilter
            onRecenterClick={() =>
              setRecenterMapTimestamp(new Date().getTime())
            }
            typeStatistic={typeStatistic}
            statusStatistic={statusStatistic}
            selectedDeviceInfos={selectedClusterMarkers}
          />
        </GoogleMap>
      </div>
    </>
  );
};

export default DevicesOverview;
