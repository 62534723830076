import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";
import ServiceProvider from "@client.services/provider";
import { IPlan } from "./types";

const initialState = {
  items: [],
  selectedPlan: { plan: null as IPlan | null, error: null, loading: false },
  error: null,
  loading: false,
};

export const getAllPlansAsync = createAsyncThunk("plans/getAll", async () => {
  return await ServiceProvider.Plan.getAll();
});

////////////////////////////////////////
export const getPlanByIdAsync = createAsyncThunk(
  "plans/getById",
  async (id: string, thunkAPI) => {
    thunkAPI.dispatch(setSelectedPlanLoading(true));
    try {
      const response = await ServiceProvider.Plan.getPlanById(id);
      return response;
    } finally {
      thunkAPI.dispatch(setSelectedPlanLoading(false));
    }
  }
);
//////////////////////////////////////

export const createPlanAsync = createAsyncThunk(
  "plans/create",
  async (fields: IPlan, thunkAPI) => {
    try {
      const response = await ServiceProvider.Plan.create(fields);
      const data = response?.data?.createPlan; // Extract `createPlan` from the GraphQL response
      if (!data) throw new Error("Invalid response structure");
      return data; // Return the relevant data
    } catch (error:any) {
      return thunkAPI.rejectWithValue(error.message || "Failed to create plan");
    }
  }
);


export const createPlanAsync2 = createAsyncThunk(
  "plans/create",
  async (model: IPlan) => {
    return ServiceProvider.Plan.create(model);
  }
);

export const updatePlanAsync = createAsyncThunk(
  "plans/update",
  async (plan: IPlan, thunkAPI) => {
    try {
      const response = await ServiceProvider.Plan.update(plan); // Use the updated single-parameter style
      const data = response?.data?.updatePlan; // Extract `updatePlan` from the GraphQL response
      if (!data) throw new Error("Invalid response structure");
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message || "Failed to update plan");
    }
  }
);


export const updatePlanAsync2 = createAsyncThunk(
  "plans/update",
  async (model: IPlan) => {
    return ServiceProvider.Plan.update2(model);
  }
);

export const deletePlanAsync = createAsyncThunk(
  "plans/delete",
  async (id: string) => {
    return ServiceProvider.Plan.delete(id);
  }
);

export const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    clearPlansError: (state) => {
      state.error = null;
    },
    ////////////////////////////////
    setSelectedPlanLoading: (state, action) => {
      state.selectedPlan.loading = get(action, "payload", false);
    },
    ////////////////////////////////
  },
  extraReducers: (builder) => {
    builder
      ////////////////////////////////
      .addCase(getAllPlansAsync.fulfilled, (state, action) => {
        const data = get(action, "payload.data.plans", {
          count: 0,
          results: [],
        });
        state.items = data.results;
        state.error = getGraphqlResponseError(action);
        state.loading = false;
      })
      // .addCase(getAllPlansAsync.fulfilled, (state, action) => {
      //   state.items = get(action, "payload.data.plans.results", []);
      //   state.error = getGraphqlResponseError(action);
      //   state.loading = false;
      // })
      //////////////////////////////
      .addCase(getAllPlansAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPlansAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
        state.loading = false;
      })



      .addCase(createPlanAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPlanAsync.fulfilled, (state:any, action:any) => {
        state.loading = false;
        state.error = null;
        // Add the new plan to the `items` array
        if (action.payload?.plan) {
          state.items.push(action.payload.plan);
        }
      })
      .addCase(createPlanAsync.rejected, (state:any, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to create plan";
      })


      // .addCase(createPlanAsync.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(createPlanAsync.fulfilled, (state, action) => {
      //   state.error = getGraphqlResponseError(action);
      // })
      // .addCase(createPlanAsync.rejected, (state, action) => {
      //   state.error = getResponseError(action);
      // })


      ////////////////////////////////

      .addCase(updatePlanAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePlanAsync.fulfilled, (state:any, action) => {
        const updatedPlan = action.payload?.plan;
        if (updatedPlan) {
          state.items = state.items.map((item) =>
            item.Id === updatedPlan.Id ? updatedPlan : item
          );
        }
        state.error = getGraphqlResponseError(action);
        state.loading = false;
      })
      .addCase(updatePlanAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
        state.loading = false;
      })

      // .addCase(updatePlanAsync.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(updatePlanAsync.fulfilled, (state, action) => {
      //   state.error = getGraphqlResponseError(action);
      //   state.loading = false;
      // })
      // .addCase(updatePlanAsync.rejected, (state, action) => {
      //   state.error = getResponseError(action);
      //   state.loading = false;
      // })
      ////////////////////////////////
      .addCase(deletePlanAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePlanAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
        state.loading = false;
      })
      .addCase(deletePlanAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
        state.loading = false;
      })
      ////////////////////////////////



      .addCase(getPlanByIdAsync.fulfilled, (state, action) => {
        const planData: any = get(action, "payload.data.plan", null);
      
        if (planData) {
          console.log("From getPlanByIdAsync.fulfilled", planData);
          state.selectedPlan.plan = {
            ...planData,
            features: Array.isArray(planData.featuresAllowed)
              ? planData.featuresAllowed
                  .flatMap((feature) =>
                    typeof feature === "string" ? feature.split(",") : feature
                  ) // Split strings with commas into separate features
                  .map((feature) => feature.trim() || "Unknown") // Remove extra spaces and handle empty features
              : [],
          };
        } else {
          state.selectedPlan.plan = null;
        }
      
        state.selectedPlan.error = getGraphqlResponseError(action);
      })
      
    .addCase(getPlanByIdAsync.rejected, (state, action) => {
        state.selectedPlan.plan = null;
        state.selectedPlan.error = getResponseError(action);
    });



    //   .addCase(getPlanByIdAsync.fulfilled, (state, action) => {
    //     const planData: any = get(action, "payload.data.plan", null);
    //     // console.log("planData", planData);
    
    //     if (planData) {
    //         state.selectedPlan.plan = {
    //             ...planData,
    //             features: Array.isArray(planData.features)
    //                 ? planData.features.map((feature) => feature || "Unknown")
    //                 : [],
    //         };
    //     } else {
    //         state.selectedPlan.plan = null;
    //     }
    
    //     state.selectedPlan.error = getGraphqlResponseError(action);
    // })
    

    //   .addCase(getPlanByIdAsync.rejected, (state, action) => {
    //     state.selectedPlan.plan = null;
    //     state.selectedPlan.error = getResponseError(action);
    //   });








    // .addCase(getPlanByIdAsync.fulfilled, (state, action) => {
    //   const planData:any = get(action, "payload.data.plan", null);

    //   if (planData) {
    //     state.selectedPlan.plan = {
    //       ...planData,
    //       features: planData.features.name.split(", "),
    //     };
    //     console.log("planData",  state.selectedPlan.plan);
    //   } else {
    //     state.selectedPlan.plan = null;
    //   }

    //   state.selectedPlan.error = getGraphqlResponseError(action);
    // })
    // .addCase(getPlanByIdAsync.rejected, (state, action) => {
    //   state.selectedPlan.plan = null;
    //   state.selectedPlan.error = getResponseError(action);
    // });
    ////////////////////////////////
  },
});

////////////////////////////////////////
export const { clearPlansError, setSelectedPlanLoading } = plansSlice.actions;
////////////////////////////////////////
export const makeSelectedPlan = (state) =>
  state.plans.selectedPlan.plan || null;
export const makeSelectedPlanLoading = (state) =>
  state.plans.selectedPlan.loading;
////////////////////////////////////////
export const makePlansError = (state) => state.plans.error;
export const makePlans = (state) => state.plans.items;
export const makePlansLoading = (state) => state.plans.loading;

export default plansSlice.reducer;



























// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { get } from "lodash";
// import { getGraphqlResponseError, getResponseError } from "@client.utils/error";
// import ServiceProvider from "@client.services/provider";
// import { IPlan } from "./types";

// const initialState = {
//   items: [],
//   selectedPlan: { plan: null as IPlan | null, error: null, loading: false },
//   error: null,
//   loading: false,
// };

// export const getAllPlansAsync = createAsyncThunk("plans/getAll", async () => {
//   return await ServiceProvider.Plan.getAll();
// });

// ////////////////////////////////////////
// export const getPlanByIdAsync = createAsyncThunk(
//   "plans/getById",
//   async (id: string, thunkAPI) => {
//     thunkAPI.dispatch(setSelectedPlanLoading(true));
//     try {
//       const response = await ServiceProvider.Plan.getPlanById(id);
//       return response;
//     } finally {
//       thunkAPI.dispatch(setSelectedPlanLoading(false));
//     }
//   }
// );
// //////////////////////////////////////

// export const createPlanAsync = createAsyncThunk(
//   "plans/create",
//   async (model: IPlan) => {
//     return ServiceProvider.Plan.create(model);
//   }
// );

// export const updatePlanAsync = createAsyncThunk(
//   "plans/update",
//   async (model: IPlan) => {
//     return ServiceProvider.Plan.update(model);
//   }
// );

// export const deletePlanAsync = createAsyncThunk(
//   "plans/delete",
//   async (id: string) => {
//     return ServiceProvider.Plan.delete(id);
//   }
// );

// export const plansSlice = createSlice({
//   name: "plans",
//   initialState,
//   reducers: {
//     clearPlansError: (state) => {
//       state.error = null;
//     },

//     ////////////////////////////////
//     setSelectedPlanLoading: (state, action) => {
//       state.selectedPlan.loading = get(action, "payload", false);
//     },
//     ////////////////////////////////

//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(getAllPlansAsync.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(getAllPlansAsync.fulfilled, (state, action) => {
//         state.items = get(action, "payload.data.plans.results", []);
//         state.error = getGraphqlResponseError(action);
//         state.loading = false;
//       })
//       .addCase(getAllPlansAsync.rejected, (state, action) => {
//         state.error = getResponseError(action);
//         state.loading = false;
//       })
//       .addCase(createPlanAsync.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(createPlanAsync.fulfilled, (state, action) => {
//         state.error = getGraphqlResponseError(action);
//       })
//       .addCase(createPlanAsync.rejected, (state, action) => {
//         state.error = getResponseError(action);
//       })
//       .addCase(updatePlanAsync.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(updatePlanAsync.fulfilled, (state, action) => {
//         state.error = getGraphqlResponseError(action);
//         state.loading = false;
//       })
//       .addCase(updatePlanAsync.rejected, (state, action) => {
//         state.error = getResponseError(action);
//         state.loading = false;
//       })
//       .addCase(deletePlanAsync.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(deletePlanAsync.fulfilled, (state, action) => {
//         state.error = getGraphqlResponseError(action);
//         state.loading = false;
//       })
//       .addCase(deletePlanAsync.rejected, (state, action) => {
//         state.error = getResponseError(action);
//         state.loading = false;
//       })

//       ////////////////////////////////
//       .addCase(getPlanByIdAsync.fulfilled, (state, action) => {
//         state.selectedPlan.plan = get(action, "payload.plan", null);
//         state.selectedPlan.error = getGraphqlResponseError(action);
//       })
//       .addCase(getPlanByIdAsync.rejected, (state, action) => {
//         state.selectedPlan.plan = null;
//         state.selectedPlan.error = getResponseError(action);
//       });
//       ////////////////////////////////
//   },
// });

// ////////////////////////////////////////
// export const { clearPlansError,setSelectedPlanLoading  } = plansSlice.actions;
// ////////////////////////////////////////
// export const makePlansError = (state) => state.plans.error;
// export const makePlans = (state) => state.plans.items;
// export const makePlansLoading = (state) => state.plans.loading;

// export default plansSlice.reducer;
