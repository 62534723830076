import React from "react";
import { useTranslation } from "react-i18next";
import * as Popover from "@radix-ui/react-popover";
import Icon from "@client.core.components/Icon";
import { VoidFunc } from "types";
import "./GridManagementButtons.scss";
import IconRenderer  from "@client.components/FA/IconRenderer ";



interface GridManagementButtonsProps {
  item: {
    Id: string;
  };
  onView?: VoidFunc<string>;
  onEdit: VoidFunc<any>;
  onDelete: VoidFunc<string>;
}

const GridManagementButtons: React.FC<GridManagementButtonsProps> = ({
  item,
  onView,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className="grid-management-buttons"
      title={t("pages.Locations.GridManagementButtons.managementButtons")}
    >
      <Popover.Root>
        <Popover.Trigger asChild>
          <button className="management-button">
            {/* <Icon name="vertical-dots" /> */}
            <IconRenderer iconName="BsThreeDotsVertical" size={22} />
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content className="mb-popover-content mb-popover-locations">
            {onView && (
              <div onClick={() => onView(item.Id)}>
                {t("pages.Locations.GridManagementButtons.View")}
              </div>
            )}
            <div onClick={() => onEdit(item)}>
              {t("pages.Locations.GridManagementButtons.update")}
            </div>
            <div onClick={() => onDelete(item.Id)}>
              {t("pages.Locations.GridManagementButtons.delete")}
            </div>
            <Popover.Arrow className="popover-arrow" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default GridManagementButtons;
