import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import "./FullCampaignSummery.scss";
import Button from "@client.core.components/Button";
import Zones from "@client.components/Zones";
import LocationTypes from "@client.enums/locationTypes";
import { getLocationByIdAsync } from "@client.pages/Zones/reducer";
import {
  setActiveStep,
  makeActiveStep,
  setOkPressed,
  makeOkPressed,
  makeIsCompleted,
  setIsCompleted,
  resetActiveStep,
  getCampaignTargetAsync,
  makeTargets,
  setSummaryTriggered,
  makeSummaryTriggered,
} from "../../reducers/Campaign";
import { useTranslation } from "react-i18next";
import Title from "@client.components/Title";
import moment from "moment";
import config from "@client.config";

const FullCampaignSummery = ({ model }) => {
  const [locationNames, setLocationNames] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const targets = useSelector(makeTargets);
  const [targetName, setTargetName] = useState("");
  const activeStep = useSelector(makeActiveStep);
  const okPressed = useSelector(makeOkPressed);
  const isCompleted = useSelector(makeIsCompleted);
  const summaryTriggered = useSelector(makeSummaryTriggered);

  console.log("From FullCampaignSummery.js: activeStep", activeStep);
  console.log("From FullCampaignSummery.js: okPressed", okPressed);
  console.log("From FullCampaignSummery.js: isCompleted", isCompleted);
  console.log("From FullCampaignSummery.js: activeStep", activeStep);


  useEffect(() => {
      dispatch(setSummaryTriggered(true));
  }, [dispatch, summaryTriggered]);
  useEffect(() => {
    dispatch(getCampaignTargetAsync());
  }, [dispatch]);

  useEffect(() => {
    if (targets && targets.length > 0) {
      const foundTarget = targets.find(
        (t) => parseInt(t.id) === parseInt(model.target)
      );
      setTargetName(foundTarget?.name || "Unknown Target");
    }
  }, [targets, model.target]);

  const onEdit = () => {
    // dispatch(resetActiveStep());
    // dispatch(setIsCompleted(false));
    // dispatch(setOkPressed(false));
    const initialStep = 0;
    dispatch(setIsCompleted(false));
    dispatch(setOkPressed(false));
    dispatch(setActiveStep(initialStep));
    // dispatch(setActiveStep(0));
  };

  if (model?.geofenceZoneIds?.length > 0) {
    useEffect(() => {
      const fetchLocationNames = async () => {
        if (Array.isArray(model.geofenceZoneIds)) {
          // Check if geofenceZoneIds is defined and is an array
          const names = await Promise.all(
            model.geofenceZoneIds.map(async (id) => {
              const result = await dispatch(getLocationByIdAsync(id));
              const revertedZone = {
                ...result.payload?.data?.zone,
                polygon: JSON.parse(result.payload?.data?.zone?.polygon),
              };
              return revertedZone || "Unknown"; // Adjust based on actual response structure
            })
          );
          setLocationNames(names);
          console.log("From FullCampaignSummery.js: names", names[0]);
        } else {
          setLocationNames([]);
        }
      };

      fetchLocationNames();
    }, [dispatch, model.geofenceZoneIds]);
  }

  const { campaignName, budgetTotal, budgetType, startDate, endDate } = model;

  return (
    <div className="full-campaign-summery">
      {/* Top Section */}

      {/* Edit Button */}
      {/* <Button text="Edit" className="edit-button" onClick={onEdit} /> */}

      <Title text="pages.CreateCampaign.fullSummary.title">
        <div className="management-buttons">
          <Button
            text={t("pages.CreateCampaign.fullSummary.button.edit")}
            onClick={onEdit}
          />
        </div>
      </Title>
      <div className="summary-top">
        {/* General Information */}
        <div className="general-info">
          <h3>General Information</h3>
          <p>
            <strong>Campaign Name:</strong> {campaignName}
          </p>
          <p>
            <strong>Target:</strong> {targetName}
          </p>
          <p>
            <strong>Start Date:</strong>{" "}
            {moment(startDate).format(config.dateTimeSecondsFormatReversed)}
          </p>
          <p>
            <strong>End Date:</strong>{" "}
            {moment(endDate).format(config.dateTimeSecondsFormatReversed)}
          </p>
          {/* <p> */}
          {/* <strong>Media:</strong> {mediaId} */}
          {/* </p> */}
        </div>

        {/* Budget Information */}
        <div className="budget-info">
          <h3>Budget</h3>
          <p>
            <strong>Amount:</strong> {budgetTotal}
          </p>
          <p>
            <strong>Available Slots:</strong> 0
          </p>
          {/* <p>
            <strong>Type:</strong> {budgetType}
          </p> */}
        </div>
      </div>

      {/* Bottom Section */}
      <div className="summary-bottom">
        <h3>Zone Details</h3>
        {locationNames ? (
          <Zones
            selectedZone={locationNames[0]}
            // zones={[zone || locationNames]}
          />
        ) : (
          <p>No zone details available</p>
        )}
      </div>
    </div>
  );
};

FullCampaignSummery.propTypes = {
  model: PropTypes.shape({
    campaignName: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    // mediaId: PropTypes.string.isRequired,
    budgetTotal: PropTypes.number.isRequired,
    budgetType: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    locationType: PropTypes.string.isRequired,
    geofenceZoneIds: PropTypes.arrayOf(PropTypes.object),
    zone: PropTypes.object,
  }).isRequired,
};

export default FullCampaignSummery;

// import React, { useEffect, useRef } from "react";
// import PropTypes from "prop-types";
// import { useDispatch, useSelector } from "react-redux";
// import SvgIcon from "@client.core.components/SvgIcon";
// import CampaignModel from "@client.models/campaign";
// import {
//   setActiveStep,
//   makeActiveStep,
//   setOkPressed,
//   makeOkPressed,
//   makeIsCompleted,
//   setIsCompleted,
//   resetActiveStep
// } from "../../reducers/Campaign";
// import Button from "@client.core.components/Button";
// import { useTranslation } from "react-i18next";

// // import { resetSelectedDraft } from "@client.pages/Advertiser/reducer";
// import "./FullCampaignSummery.scss";

// const FullCampaignSummery = () => {
//   const { t } = useTranslation();
//   const dispatch = useDispatch();
//   const activeStep = useSelector(makeActiveStep);
//   const okPressed = useSelector(makeOkPressed);
//   const isCompleted = useSelector(makeIsCompleted);
//   // console.log("From FullCampaignSummery.js: activeStep", activeStep);
//   console.log("From FullCampaignSummery.js: okPressed", okPressed);
//   console.log("From FullCampaignSummery.js: isCompleted", isCompleted);
//   console.log("From FullCampaignSummery.js: activeStep", activeStep);
//   // Set initial active step based on the campaign model
//   //   useEffect(() => {
//   //     const campaign = new CampaignModel(model);
//   //     const initialStep = campaign.getActiveStep();
//   //     dispatch(setActiveStep(initialStep));
//   //   }, []);

//   const onEdit = () => {
//     dispatch(resetActiveStep());
//     dispatch(setIsCompleted(false));
//     dispatch(setOkPressed(false));

//     // dispatch(setActiveStep(0));
//     }

//   return (
//     <>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           backgroundColor: "blue",
//           padding: "20px",
//           borderRadius: "10px",
//           boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
//         }}
//       >
//         hi
//       </div>
//       <div className="management-buttons">
//         <Button
//           text={t("pages.CreateCampaign.fullSummary.button.edit")}
//           onClick={onEdit}
//         />

//       </div>
//     </>
//   );
// };

// FullCampaignSummery.propTypes = {
//   steps: PropTypes.array.isRequired,
//   model: PropTypes.object.isRequired,
//   setModel: PropTypes.func.isRequired,
// };

// export default FullCampaignSummery;
