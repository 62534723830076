// original

import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Title from "@client.components/Title";
import CampaignOverview from "@client.components/CampaignOverview";
import Button from "@client.core.components/Button";
import Pages from "@client.enums/pages";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import NoDataPlaceholder from "@client.core.components/NoDataPlaceholder";
import Input from "@client.core.components/Input";
import Dropdown from "@client.core.components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdvertiserCampaignsAsync,
  makeSelectedCampaigns,
  setCampaignFilter,
  makeCampaignFilter,
} from "@client.pages/Advertiser/reducer";
import CampaignStatuses from "@client.enums/campaignStatuses";
import DataTablePagination from "@client.components/Pagination/Pagination";
import AdvertiserDropdown from "@client.components/AdvertiserDropdown";
import CampaignModel from "@client.models/campaign";
import CustomDateRangePicker from "@client.components/CustomDateRangePicker";
import CampaignsInnerTabs, {
  CampaignsTabsEnum,
} from "./CampaignsInnerTabs";
import { resetSelectedCampaign } from '../../reducers/Campaign';

const ITEMS_PER_PAGE = 10;

const CampaignsList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedCampaigns = useSelector(makeSelectedCampaigns);
  console.log("selectedCampaignssss", selectedCampaigns);
  const filterNew = useSelector(makeCampaignFilter);

  // Separate pagination states for each tab
  const [currentPage, setCurrentPage] = useState({
    all: 1,
    archived: 1,
    drafts: 1,
  });

  const [selectedTab, setSelectedTab] = useState(
    CampaignsTabsEnum.AllTheRest
  );
  const [status, setStatus] = useState(CampaignStatuses.All);
  const [filter, setFilter] = useState({
    query: "",
    startDate: "",
    endDate: "",
  });

  const [model, setModel] = useState(
    new CampaignModel({
      advertiserId: null,
      name: "",
      startDate: "",
      endDate: "",
      budget: 0,
      status: CampaignStatuses.All,
      type: "static",
      locations: [],
    })
  );

  const statuses = [
    { value: CampaignStatuses.All, label: "All" },
    { value: CampaignStatuses.Active, label: t("pages.Campaign.status.active") },
    { value: CampaignStatuses.Queued, label: t("pages.Campaign.status.queued") },
    { value: CampaignStatuses.Paused, label: t("pages.Campaign.status.paused") },
    { value: CampaignStatuses.PendingApproval, label: t("pages.Campaign.status.pending") },
    { value: CampaignStatuses.Ended, label: t("pages.Campaign.status.ended") },
  ];

  useEffect(() => {
    const offset = (currentPage[selectedTab] - 1) * ITEMS_PER_PAGE;

    let selectedStatus = status;
    if (selectedTab === CampaignsTabsEnum.Archived) {
      selectedStatus = CampaignStatuses.Archive;
    } else if (selectedTab === CampaignsTabsEnum.Drafts) {
      selectedStatus = CampaignStatuses.Draft;
    }

    dispatch(
      getAdvertiserCampaignsAsync({
        status: selectedStatus === CampaignStatuses.All ? null : selectedStatus,
        advertiserId: model.advertiserId,
        startDate: filter.startDate,
        endDate: filter.endDate,
        search: filter.query,
        limit: ITEMS_PER_PAGE,
        offset,
      })
    );
  }, [
    dispatch,
    currentPage,
    status,
    model.advertiserId,
    filter,
    selectedTab,
  ]);

  const handleTabChange = (newTab) => {
    setSelectedTab(newTab);
    // Reset current page to 1 for the new tab
    setCurrentPage((prevState) => ({
      ...prevState,
      [newTab]: 1,
    }));
  };

  const handlePageChange = (page) => {
    setCurrentPage((prevState) => ({
      ...prevState,
      [selectedTab]: page,
    }));
  };

  const handleStatusChange = (value) => {
    const parsedStatus =
      value === CampaignStatuses.All ? CampaignStatuses.All : parseInt(value);
    setStatus(parsedStatus);
    dispatch(setCampaignFilter({ ...filterNew, status: parsedStatus }));
  };

  const changeModel = useCallback(
    (value, field) => {
      setModel(
        new CampaignModel(
          { ...model, [field]: value },
          model.validationMsgs || {}
        )
      );
    },
    [model]
  );

  const handleCreateCampaignClick = async () => {
    await dispatch(resetSelectedCampaign())
    navigate(Pages.CreateCampaign)
  }

  return (
    <div className="campaigns page">
      <Title text={t("components.NavigationBar.campaigns")}>
        <div className="management-buttons">
          <Button
            icon="plus"
            text={t("components.NavigationBar.createCampaign")}
            onClick={handleCreateCampaignClick}
            className="button-create"
          />
        </div>
      </Title>

      <div className="filters-container">
        <Input
          label="Search"
          value={filter.query}
          onChange={(e) => setFilter({ ...filter, query: e })}
        />
        <CustomDateRangePicker
          value={[filter.startDate, filter.endDate]}
          label={t("pages.Campaigns.dateRange")}
          onChange={(range) =>
            setFilter({ ...filter, startDate: range[0], endDate: range[1] })
          }
        />
        {selectedTab === CampaignsTabsEnum.AllTheRest && (
          <Dropdown
            items={statuses}
            defaultValue={status}
            label={t("pages.Campaigns.status")}
            valueKey="value"
            labelKey="label"
            onSelect={handleStatusChange}
          />
        )}
        <AdvertiserDropdown
          value={model.advertiserId}
          label="Advertisers"
          onSelect={(option) => changeModel(option, "advertiserId")}
        />
      </div>

      <CampaignsInnerTabs
        value={selectedTab}
        onSelect={handleTabChange}
      />

      <div className="campaign-list">
        {selectedCampaigns.campaigns && selectedCampaigns.campaigns.length > 0 ? (
          selectedCampaigns.campaigns.map((campaign) => (
            // <CampaignOverview key={campaign.Id} campaign={campaign} />
            <CampaignOverview
              key={campaign.Id}
              campaign={campaign}
              currentFilters={filter}
              onStatusChange={handleStatusChange} // Pass callback
            />
          ))
        ) : (
          <NoDataPlaceholder description={t("pages.Campaign.status.noArchived")} />
        )}
      </div>
      <div className="pagination">
        <DataTablePagination
          total={selectedCampaigns.count}
          itemsPerPage={ITEMS_PER_PAGE}
          onChange={handlePageChange}
          currentPage={currentPage[selectedTab]} // Use the current page for the active tab
        />
      </div>
    </div>
  );
};

CampaignsList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default CampaignsList;












//different Tabs
// import React, { useEffect, useState, useCallback } from "react";
// import PropTypes from "prop-types";
// import Title from "@client.components/Title";
// import CampaignOverview from "@client.components/CampaignOverview";
// import Button from "@client.core.components.Button";
// import Pages from "@client.enums/pages";
// import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
// import NoDataPlaceholder from "@client.core.components/NoDataPlaceholder";
// import Input from "@client.core.components/Input";
// import Dropdown from "@client.core.components/Dropdown";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getAdvertiserCampaignsAsync,
//   makeSelectedCampaigns,
//   setCampaignFilter,
//   makeCampaignFilter,
// } from "@client.pages/Advertiser/reducer";
// import CampaignStatuses from "@client.enums/campaignStatuses";
// import DataTablePagination from "@client.components/Pagination/Pagination";
// import AdvertiserDropdown from "@client.components/AdvertiserDropdown";
// import CampaignModel from "@client.models/campaign";
// import CustomDateRangePicker from "@client.components/CustomDateRangePicker";
// import ArchivedCampaignInnerTabs, {
//   CampaignsTabsEnum,
// } from "./ArchivedCampaignInnerTabs";
// import AllTheRestTab from "./AllTheRestTab";
// import ArchivedCampaignsTab from "./ArchivedCampaignsTab";
// import DraftsTab from "./DraftsTab";
// import "./ArchivedCampaignsOverview.scss";

// const ITEMS_PER_PAGE = 10;

// const ArchivedCampaignsOverview = () => {
//   const { t } = useTranslation();
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const selectedCampaigns = useSelector(makeSelectedCampaigns);
//   const filterNew = useSelector(makeCampaignFilter);

//   const [currentPage, setCurrentPage] = useState({
//     all: 1,
//     archived: 1,
//     drafts: 1,
//   });

//   const [selectedTab, setSelectedTab] = useState(
//     CampaignsTabsEnum.AllTheRest
//   );
//   const [status, setStatus] = useState(CampaignStatuses.All);
//   const [filter, setFilter] = useState({
//     query: "",
//     startDate: "",
//     endDate: "",
//   });

//   const [model, setModel] = useState(
//     new CampaignModel({
//       advertiserId: null,
//       name: "",
//       startDate: "",
//       endDate: "",
//       budget: 0,
//       status: CampaignStatuses.All,
//       type: "static",
//       locations: [],
//     })
//   );

//   const statuses = [
//     { value: CampaignStatuses.All, label: "All" },
//     { value: CampaignStatuses.Active, label: t("pages.Campaign.status.active") },
//     { value: CampaignStatuses.Queued, label: t("pages.Campaign.status.queued") },
//     { value: CampaignStatuses.Paused, label: t("pages.Campaign.status.paused") },
//     { value: CampaignStatuses.PendingApproval, label: t("pages.Campaign.status.pending") },
//     { value: CampaignStatuses.Ended, label: t("pages.Campaign.status.ended") },
//   ];

//   useEffect(() => {
//     const offset = (currentPage[selectedTab] - 1) * ITEMS_PER_PAGE;

//     let selectedStatus = status;
//     if (selectedTab === CampaignsTabsEnum.Archived) {
//       selectedStatus = CampaignStatuses.Archive;
//     } else if (selectedTab === CampaignsTabsEnum.Drafts) {
//       selectedStatus = CampaignStatuses.Draft;
//     }

//     dispatch(
//       getAdvertiserCampaignsAsync({
//         status: selectedStatus === CampaignStatuses.All ? null : selectedStatus,
//         advertiserId: model.advertiserId,
//         startDate: filter.startDate,
//         endDate: filter.endDate,
//         search: filter.query,
//         limit: ITEMS_PER_PAGE,
//         offset,
//       })
//     );
//   }, [dispatch, currentPage, status, model.advertiserId, filter, selectedTab]);

//   const handleTabChange = (newTab) => {
//     setSelectedTab(newTab);
//     setCurrentPage((prevState) => ({
//       ...prevState,
//       [newTab]: 1,
//     }));
//   };

//   const handlePageChange = (page) => {
//     setCurrentPage((prevState) => ({
//       ...prevState,
//       [selectedTab]: page,
//     }));
//   };

//   const handleStatusChange = (value) => {
//     const parsedStatus =
//       value === CampaignStatuses.All ? CampaignStatuses.All : parseInt(value);
//     setStatus(parsedStatus);
//     dispatch(setCampaignFilter({ ...filterNew, status: parsedStatus }));
//   };

//   const changeModel = useCallback(
//     (value, field) => {
//       setModel(
//         new CampaignModel(
//           { ...model, [field]: value },
//           model.validationMsgs || {}
//         )
//       );
//     },
//     [model]
//   );

//   const renderTabContent = () => {
//     switch (selectedTab) {
//       case CampaignsTabsEnum.Archived:
//         return <ArchivedCampaignsTab />;
//       case CampaignsTabsEnum.Drafts:
//         return <DraftsTab />;
//       default:
//         return <AllTheRestTab />;
//     }
//   };

//   return (
//     <div className="archived-campaigns page">
//       <Title text={t("components.NavigationBar.campaigns")}>
//         <div className="management-buttons">
//           <Button
//             icon="plus"
//             text={t("components.NavigationBar.createCampaign")}
//             onClick={() => navigate(Pages.CreateCampaign)}
//             className="button-create"
//           />
//         </div>
//       </Title>

//       <div className="search">
//         <Input
//           label="Search"
//           value={filter.query}
//           onChange={(e) => setFilter({ ...filter, query: e })}
//         />
//         <CustomDateRangePicker
//           value={[filter.startDate, filter.endDate]}
//           label={t("pages.Campaigns.dateRange")}
//           onChange={(range) =>
//             setFilter({ ...filter, startDate: range[0], endDate: range[1] })
//           }
//         />
//         {selectedTab === CampaignsTabsEnum.AllTheRest && (
//           <Dropdown
//             items={statuses}
//             defaultValue={status}
//             label={t("pages.Campaigns.status")}
//             valueKey="value"
//             labelKey="label"
//             onSelect={handleStatusChange}
//           />
//         )}
//         <AdvertiserDropdown
//           value={model.advertiserId}
//           label="Advertisers"
//           onSelect={(option) => changeModel(option, "advertiserId")}
//         />
//       </div>

//       <ArchivedCampaignInnerTabs
//         value={selectedTab}
//         onSelect={handleTabChange}
//       />

//       {renderTabContent()}

//       <div className="pagination">
//         <DataTablePagination
//           total={selectedCampaigns.count}
//           itemsPerPage={ITEMS_PER_PAGE}
//           onChange={handlePageChange}
//           currentPage={currentPage[selectedTab]}
//         />
//       </div>
//     </div>
//   );
// };

// ArchivedCampaignsOverview.propTypes = {
//   data: PropTypes.array.isRequired,
// };

// export default ArchivedCampaignsOverview;






















//8 in a page
// import React, { useEffect, useState, useCallback } from "react";
// import PropTypes from "prop-types";
// import Title from "@client.components/Title";
// import CampaignOverview from "@client.components/CampaignOverview";
// import Button from "@client.core.components/Button";
// import Pages from "@client.enums/pages";
// import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
// import NoDataPlaceholder from "@client.core.components/NoDataPlaceholder";
// import Input from "@client.core.components/Input";
// import Dropdown from "@client.core.components/Dropdown";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getAdvertiserCampaignsAsync,
//   makeSelectedCampaigns,
//   setCampaignFilter,
//   makeCampaignFilter,
// } from "@client.pages/Advertiser/reducer";
// import CampaignStatuses from "@client.enums/campaignStatuses";
// import DataTablePagination from "@client.components/Pagination/Pagination";
// import AdvertiserDropdown from "@client.components/AdvertiserDropdown";
// import CampaignModel from "@client.models/campaign";
// import CustomDateRangePicker from "@client.components/CustomDateRangePicker";
// import ArchivedCampaignInnerTabs, {
//   CampaignsTabsEnum,
// } from "./ArchivedCampaignInnerTabs";
// import "./ArchivedCampaignsOverview.scss";

// const ITEMS_PER_PAGE = 10;

// const ArchivedCampaignsOverview = () => {
//   const { t } = useTranslation();
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const selectedCampaigns = useSelector(makeSelectedCampaigns);
//   const filterNew = useSelector(makeCampaignFilter);

//   // Separate pagination states for each tab
//   const [currentPage, setCurrentPage] = useState({
//     all: 1,
//     archived: 1,
//     drafts: 1,
//   });

//   const [selectedTab, setSelectedTab] = useState(
//     CampaignsTabsEnum.AllTheRest
//   );
//   const [status, setStatus] = useState(CampaignStatuses.All);
//   const [filter, setFilter] = useState({
//     query: "",
//     startDate: "",
//     endDate: "",
//   });

//   const [model, setModel] = useState(
//     new CampaignModel({
//       advertiserId: null,
//       name: "",
//       startDate: "",
//       endDate: "",
//       budget: 0,
//       status: CampaignStatuses.All,
//       type: "static",
//       locations: [],
//     })
//   );

//   const statuses = [
//     { value: CampaignStatuses.All, label: "All" },
//     { value: CampaignStatuses.Active, label: t("pages.Campaign.status.active") },
//     { value: CampaignStatuses.Queued, label: t("pages.Campaign.status.queued") },
//     { value: CampaignStatuses.Paused, label: t("pages.Campaign.status.paused") },
//     { value: CampaignStatuses.PendingApproval, label: t("pages.Campaign.status.pending") },
//     { value: CampaignStatuses.Ended, label: t("pages.Campaign.status.ended") },
//   ];

//   useEffect(() => {
//     const offset =
//       (currentPage[selectedTab] - 1) * ITEMS_PER_PAGE;

//     let selectedStatus = status;
//     if (selectedTab === CampaignsTabsEnum.Archived) {
//       selectedStatus = CampaignStatuses.Archive;
//     } else if (selectedTab === CampaignsTabsEnum.Drafts) {
//       selectedStatus = CampaignStatuses.Draft;
//     }

//     dispatch(
//       getAdvertiserCampaignsAsync({
//         status: selectedStatus === CampaignStatuses.All ? null : selectedStatus,
//         advertiserId: model.advertiserId,
//         startDate: filter.startDate,
//         endDate: filter.endDate,
//         search: filter.query,
//         limit: ITEMS_PER_PAGE,
//         offset,
//       })
//     );
//   }, [
//     dispatch,
//     currentPage,
//     status,
//     model.advertiserId,
//     filter,
//     selectedTab,
//   ]);

//   const filteredAndOrderedCampaigns = selectedCampaigns.campaigns
//     ?.filter((campaign) => {
//       if (selectedTab === CampaignsTabsEnum.AllTheRest) {
//         return (
//           campaign.status !== CampaignStatuses.Archive &&
//           campaign.status !== CampaignStatuses.Draft
//         );
//       }
//       return true; // Show all campaigns for other tabs
//     })
//     .sort((a, b) => {
//       const statusOrder = {
//         [CampaignStatuses.Active]: 1,
//         [CampaignStatuses.Queued]: 2,
//         [CampaignStatuses.PendingApproval]: 3,
//         [CampaignStatuses.Paused]: 4,
//         [CampaignStatuses.Ended]: 5,
//       };
//       return statusOrder[a.status] - statusOrder[b.status];
//     });

//   const handleTabChange = (newTab) => {
//     setSelectedTab(newTab);
//     // Reset current page to 1 for the new tab
//     setCurrentPage((prevState) => ({
//       ...prevState,
//       [newTab]: 1,
//     }));
//   };

//   const handlePageChange = (page) => {
//     setCurrentPage((prevState) => ({
//       ...prevState,
//       [selectedTab]: page,
//     }));
//   };

//   const handleStatusChange = (value) => {
//     const parsedStatus =
//       value === CampaignStatuses.All ? CampaignStatuses.All : parseInt(value);
//     setStatus(parsedStatus);
//     dispatch(setCampaignFilter({ ...filterNew, status: parsedStatus }));
//   };

//   const changeModel = useCallback(
//     (value, field) => {
//       setModel(
//         new CampaignModel(
//           { ...model, [field]: value },
//           model.validationMsgs || {}
//         )
//       );
//     },
//     [model]
//   );

//   return (
//     <div className="archived-campaigns page">
//       <Title text={t("components.NavigationBar.campaigns")}>
//         <div className="management-buttons">
//           <Button
//             icon="plus"
//             text={t("components.NavigationBar.createCampaign")}
//             onClick={() => navigate(Pages.CreateCampaign)}
//             className="button-create"
//           />
//         </div>
//       </Title>

//       <div className="search">
//         <Input
//           label="Search"
//           value={filter.query}
//           onChange={(e) => setFilter({ ...filter, query: e })}
//         />
//         <CustomDateRangePicker
//           value={[filter.startDate, filter.endDate]}
//           label={t("pages.Campaigns.dateRange")}
//           onChange={(range) =>
//             setFilter({ ...filter, startDate: range[0], endDate: range[1] })
//           }
//         />
//         {selectedTab === CampaignsTabsEnum.AllTheRest && (
//           <Dropdown
//             items={statuses}
//             defaultValue={status}
//             label={t("pages.Campaigns.status")}
//             valueKey="value"
//             labelKey="label"
//             onSelect={handleStatusChange}
//           />
//         )}
//         <AdvertiserDropdown
//           value={model.advertiserId}
//           label="Advertisers"
//           onSelect={(option) => changeModel(option, "advertiserId")}
//         />
//       </div>

//       <ArchivedCampaignInnerTabs
//         value={selectedTab}
//         onSelect={handleTabChange}
//       />

//       <div className="campaign-list">
//         {filteredAndOrderedCampaigns && filteredAndOrderedCampaigns.length > 0 ? (
//           filteredAndOrderedCampaigns.map((campaign) => (
//             <CampaignOverview key={campaign.Id} campaign={campaign} />
//           ))
//         ) : (
//           <NoDataPlaceholder description={t("pages.Campaign.status.noArchived")} />
//         )}
//       </div>
//       <div className="pagination">
//         <DataTablePagination
//           total={selectedCampaigns.count}
//           itemsPerPage={ITEMS_PER_PAGE}
//           onChange={handlePageChange}
//           currentPage={currentPage[selectedTab]} // Use the current page for the active tab
//         />
//       </div>
//     </div>
//   );
// };

// ArchivedCampaignsOverview.propTypes = {
//   data: PropTypes.array.isRequired,
// };

// export default ArchivedCampaignsOverview;




















// import React, { useEffect, useState, useCallback } from "react";
// import PropTypes from "prop-types";
// import Title from "@client.components/Title";
// import CampaignOverview from "@client.components/CampaignOverview";
// import Button from "@client.core.components/Button";
// import Pages from "@client.enums/pages";
// import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
// import NoDataPlaceholder from "@client.core.components/NoDataPlaceholder";
// import Input from "@client.core.components/Input";
// import Dropdown from "@client.core.components/Dropdown";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getAdvertiserCampaignsAsync,
//   makeSelectedCampaigns,
//   setCampaignFilter,
//   makeCampaignFilter,
// } from "@client.pages/Advertiser/reducer";
// import CampaignStatuses from "@client.enums/campaignStatuses";
// import DataTablePagination from "@client.components/Pagination/Pagination";
// import AdvertiserDropdown from "@client.components/AdvertiserDropdown";
// import CampaignModel from "@client.models/campaign";
// import CustomDateRangePicker from "@client.components/CustomDateRangePicker";
// import ArchivedCampaignInnerTabs, {
//   CampaignsTabsEnum,
// } from "./ArchivedCampaignInnerTabs";
// import "./ArchivedCampaignsOverview.scss";

// const ITEMS_PER_PAGE = 10;

// const ArchivedCampaignsOverview = () => {
//   const { t } = useTranslation();
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const selectedCampaigns = useSelector(makeSelectedCampaigns);
//   const filterNew = useSelector(makeCampaignFilter);

//   const [selectedTab, setSelectedTab] = useState(
//     CampaignsTabsEnum.AllTheRest
//   );
//   const [status, setStatus] = useState(CampaignStatuses.All);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [filter, setFilter] = useState({
//     query: "",
//     startDate: "",
//     endDate: "",
//   });

//   const [model, setModel] = useState(
//     new CampaignModel({
//       advertiserId: null,
//       name: "",
//       startDate: "",
//       endDate: "",
//       budget: 0,
//       status: CampaignStatuses.All,
//       type: "static",
//       locations: [],
//     })
//   );

//   const statuses = [
//     { value: CampaignStatuses.All, label: "All" },
//     // { value: CampaignStatuses.Archive, label: t("pages.Campaign.status.archived") },
//     { value: CampaignStatuses.Active, label: t("pages.Campaign.status.active") },
//     { value: CampaignStatuses.Queued, label: t("pages.Campaign.status.queued") },
//     { value: CampaignStatuses.Paused, label: t("pages.Campaign.status.paused") },
//     { value: CampaignStatuses.PendingApproval, label: t("pages.Campaign.status.pending") },
//     // { value: CampaignStatuses.Draft, label: t("pages.Campaign.status.draft") },
//     { value: CampaignStatuses.Ended, label: t("pages.Campaign.status.ended") },
//   ];

//   useEffect(() => {
//     const offset = (currentPage - 1) * ITEMS_PER_PAGE;

//     let selectedStatus = status;
//     if (selectedTab === CampaignsTabsEnum.Archived) {
//       selectedStatus = CampaignStatuses.Archive;
//     } else if (selectedTab === CampaignsTabsEnum.Drafts) {
//       selectedStatus = CampaignStatuses.Draft;
//     }

//     dispatch(
//       getAdvertiserCampaignsAsync({
//         status: selectedStatus === CampaignStatuses.All ? null : selectedStatus,
//         advertiserId: model.advertiserId,
//         startDate: filter.startDate,
//         endDate: filter.endDate,
//         search: filter.query,
//         limit: ITEMS_PER_PAGE,
//         offset,
//       })
//     );
//   }, [dispatch, currentPage, status, model.advertiserId, filter, selectedTab]);

//   const statusOrder = {
//     [CampaignStatuses.Active]: 1,
//     [CampaignStatuses.Queued]: 2,
//     [CampaignStatuses.PendingApproval]: 3,
//     [CampaignStatuses.Paused]: 4,
//     [CampaignStatuses.Ended]: 5,
//   };
  
//   const filteredAndOrderedCampaigns =
//     selectedTab === CampaignsTabsEnum.AllTheRest
//       ? selectedCampaigns.campaigns
//           ?.filter(
//             (campaign) =>
//               campaign.status !== CampaignStatuses.Archive &&
//               campaign.status !== CampaignStatuses.Draft
//           )
//           .sort((a, b) => statusOrder[a.status] - statusOrder[b.status])
//       : selectedCampaigns.campaigns;
  

//   const handleStatusChange = (value) => {
//     const parsedStatus = value === CampaignStatuses.All ? CampaignStatuses.All : parseInt(value);
//     setStatus(parsedStatus);
//     dispatch(setCampaignFilter({ ...filterNew, status: parsedStatus }));
//   };

//   const changeModel = useCallback(
//     (value, field) => {
//       setModel(
//         new CampaignModel({ ...model, [field]: value }, model.validationMsgs || {})
//       );
//     },
//     [model]
//   );

//   return (
//     <div className="archived-campaigns page">
//       <Title text={t("components.NavigationBar.campaigns")}>
//         <div className="management-buttons">
//           <Button
//             icon="plus"
//             text={t("components.NavigationBar.createCampaign")}
//             onClick={() => navigate(Pages.CreateCampaign)}
//             className="button-create"
//           />
//         </div>
//       </Title>

//       <div className="search">
//         <Input
//           label="Search"
//           value={filter.query}
//           onChange={(e) => setFilter({ ...filter, query: e })}
//         />
//         <CustomDateRangePicker
//           value={[filter.startDate, filter.endDate]}
//           label={t("pages.Campaigns.dateRange")}
//           onChange={(range) =>
//             setFilter({ ...filter, startDate: range[0], endDate: range[1] })
//           }
//         />
//         {selectedTab === CampaignsTabsEnum.AllTheRest &&
//         <Dropdown
//           items={statuses}
//           defaultValue={status}
//           label={t("pages.Campaigns.status")}
//           valueKey="value"
//           labelKey="label"
//           onSelect={handleStatusChange}
//         />}
//         <AdvertiserDropdown
//           value={model.advertiserId}
//           label="Advertisers"
//           onSelect={(option) => changeModel(option, "advertiserId")}
//         />
//       </div>

//       <ArchivedCampaignInnerTabs value={selectedTab} onSelect={setSelectedTab} />

//       <div className="campaign-list">
//         {filteredAndOrderedCampaigns && filteredAndOrderedCampaigns.length > 0 ? (
//           filteredAndOrderedCampaigns.map((campaign) => (
//             <CampaignOverview key={campaign.Id} campaign={campaign} />
//           ))
//         ) : (
//           <NoDataPlaceholder description={t("pages.Campaign.status.noArchived")} />
//         )}
//       </div>
//       <div className="pagination">
//         <DataTablePagination
//           total={selectedCampaigns.count}
//           itemsPerPage={ITEMS_PER_PAGE}
//           onChange={setCurrentPage}
//         />
//       </div>
//     </div>
//   );
// };

// ArchivedCampaignsOverview.propTypes = {
//   data: PropTypes.array.isRequired,
// };

// export default ArchivedCampaignsOverview;








