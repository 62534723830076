import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ServiceProvider from "@client.services/provider";
import { get } from "lodash";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";

const DEFAULT_INVOICES = { results: [], loading: false, count: 0 };
const DEFAULT_SELECTED_INVOICE = { invoice: null, loading: false };

const initialState = {
  invoices: DEFAULT_INVOICES,
  selected: DEFAULT_SELECTED_INVOICE,
  error: null,
  loading: false,
  filter: {
    accountLookupId: "",
    startDate: "",
    endDate: "",
    limit: 10,
    offset: 0
  }
};

export const getAllInvoicesAsync = createAsyncThunk(
  "invoices/getAll",
  async (_query, thunkAPI) => {
    thunkAPI.dispatch(setAllInvoicesLoading(true));
    try {
      const state = thunkAPI.getState();
      const filter = makeInvoiceFilter(state);

      const response = await ServiceProvider.Invoice.getAll();
      return response;
    } finally {
      thunkAPI.dispatch(setAllInvoicesLoading(false));
    }
  }
);

export const getInvoiceByIdAsync = createAsyncThunk(
  "invoices/getById",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(setSelectedLoading(true));
    try {
      const response = await ServiceProvider.Invoice.getByInvoiceId(id);
      return response;
    } finally {
      thunkAPI.dispatch(setSelectedLoading(false));
    }
  }
);

export const createInvoiceAsync = createAsyncThunk(
  "invoices/create",
  async (invoice, thunkAPI) => {
    const resp = await ServiceProvider.Invoice.create(invoice);

    if (!resp.errors) {
      thunkAPI.dispatch(getAllInvoicesAsync(10, 0));
    }
    return resp;
  }
);

export const updateInvoiceAsync = createAsyncThunk(
  "invoices/update",
  async (invoice, thunkAPI) => {
    const resp = await ServiceProvider.Invoice.update(invoice);
    if (!resp.errors) {
      thunkAPI.dispatch(getAllInvoicesAsync());
    }
    return resp;
  }
);

export const deleteInvoiceAsync = createAsyncThunk(
  "invoices/delete",
  async (invoiceId, thunkAPI) => {
    const resp = await ServiceProvider.Invoice.delete(invoiceId);

    if (!resp.errors) {
      thunkAPI.dispatch(getAllInvoicesAsync());
    }
    return resp;
  }
);

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = get(action, "payload", state.filter);
    },
    setAllInvoicesLoading: (state, action) => {
      state.invoices.loading = get(action, "payload", false);
    },
    setSelectedLoading: (state, action) => {
      state.selected.loading = get(action, "payload", false);
    },
    clearInvoiceError: (state) => {
      state.error = null;
    },
    setFilter: (state, action) => {
      state.filter = get(action, "payload", false);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllInvoicesAsync.fulfilled, (state, action) => {
        const response = get(
          action,
          "payload.data.invoices",
          DEFAULT_INVOICES.results
        );
        state.invoices.results = response.results;
        state.invoices.count = response.count;
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAllInvoicesAsync.rejected, (state, action) => {
        state.invoices = {
          ...DEFAULT_INVOICES
        };
        state.error = action.payload;
      })
      .addCase(getInvoiceByIdAsync.fulfilled, (state, action) => {
        state.selected.invoice = get(action, "payload.data.invoice", null);
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getInvoiceByIdAsync.rejected, (state, action) => {
        state.selected = {
          ...DEFAULT_SELECTED_INVOICE
        };
        state.error = getResponseError(action);
      })
      .addCase(createInvoiceAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(createInvoiceAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(updateInvoiceAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(updateInvoiceAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(deleteInvoiceAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(deleteInvoiceAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      });
  }
});

export const {
  setAllInvoicesLoading,
  setSelectedLoading,
  clearInvoiceError,
  setLoading,
  setFilter
} = invoiceSlice.actions;

export const makeInvoices = (state) => state.invoice.invoices;
export const makeInvoiceFilter = (state) => state.invoice.filter;
export const makeInvoiceInfo = (state) => state.invoice.selected;
export const makeInvoiceError = (state) => state.invoice.error;
export const makeInvoiceLoading = (state) => state.invoice.loading;

export default invoiceSlice.reducer;
