import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";

const DEFAULT_SUBSCRIPTIONS = { results: [], loading: false, count: 0 };
const DEFAULT_SELECTED_SUBSCRIPTION = { subscription: null, loading: false };

const initialState = {
  subscriptions: { results: [], loading: false, count: 0 },
  selected: { subscription: null, loading: false },
  error: null,
  loading: false,
  filter: {
    accountLookupId: "",
    startDate: "",
    endDate: "",
    limit: 10,
    offset: 0
  }
};

// Thunks
export const getAllSubscriptionsAsync = createAsyncThunk(
  "subscriptions/getAll",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setSubscriptionsLoading(true));
    try {
      const state = thunkAPI.getState();
      const filter = makeSubscriptionFilter(state);
      const response = await ServiceProvider.Subscription.getAllSubscriptions();
      console.log("From getAllSubscriptionsAsync", response);
      return response;
    } finally {
      thunkAPI.dispatch(setSubscriptionsLoading(false));
    }
  }
);

export const getSubscriptionByIdAsync = createAsyncThunk(
  "subscriptions/getById",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(setSelectedLoading(true));
    try {
      const response = await ServiceProvider.Subscription.getSubscription(id);
      return response;
    } finally {
      thunkAPI.dispatch(setSelectedLoading(false));
    }
  }
);

export const createSubscriptionAsync = createAsyncThunk(
  "subscriptions/create",
  async (fields, thunkAPI) => {
    const response = await ServiceProvider.Subscription.createSubscription(
      fields
    );
    if (!response.errors) {
      thunkAPI.dispatch(getAllSubscriptionsAsync());
    }
    return response;
  }
);

export const updateSubscriptionAsync = createAsyncThunk(
  "subscriptions/update",
  async ({ id, fields }, thunkAPI) => {
    const response = await ServiceProvider.Subscription.updateSubscription(
      id,
      fields
    );
    if (!response.errors) {
      thunkAPI.dispatch(getAllSubscriptionsAsync());
    }
    return response;
  }
);

export const deleteSubscriptionAsync = createAsyncThunk(
  "subscriptions/delete",
  async (id, thunkAPI) => {
    const response = await ServiceProvider.Subscription.deleteSubscription(id);
    if (!response.errors) {
      thunkAPI.dispatch(getAllSubscriptionsAsync());
    }
    return response;
  }
);

// Slice
export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setSubscriptionsLoading: (state, action) => {
      state.subscriptions.loading = get(action, "payload", false);
    },
    setSelectedLoading: (state, action) => {
      state.selected.loading = get(action, "payload", false);
    },
    clearSubscriptionError: (state) => {
      state.error = null;
    },
    setFilter: (state, action) => {
      state.filter = get(action, "payload", false);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSubscriptionsAsync.fulfilled, (state, action) => {
        const response = get(
          action,
          "payload.data.subscriptions",
          DEFAULT_SUBSCRIPTIONS
        );
        state.subscriptions.results = response.results;
        state.subscriptions.count = response.count;
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAllSubscriptionsAsync.rejected, (state, action) => {
        console.error("Error in getAllSubscriptionsAsync:", action.payload);
        state.subscriptions = {
          ...DEFAULT_SUBSCRIPTIONS
        };
        state.error = getResponseError(action);
      })
      .addCase(getSubscriptionByIdAsync.fulfilled, (state, action) => {
        state.selected.subscription = get(
          action,
          "payload.data.subscription",
          null
        );
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getSubscriptionByIdAsync.rejected, (state, action) => {
        state.selected = {
          ...DEFAULT_SELECTED_SUBSCRIPTION
        };
        state.error = getResponseError(action);
      })
      .addCase(createSubscriptionAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(createSubscriptionAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(updateSubscriptionAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(updateSubscriptionAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      })
      .addCase(deleteSubscriptionAsync.fulfilled, (state, action) => {
        state.error = getGraphqlResponseError(action);
      })
      .addCase(deleteSubscriptionAsync.rejected, (state, action) => {
        state.error = getResponseError(action);
      });
  }
});

// Actions
export const {
  setSubscriptionsLoading,
  setSelectedLoading,
  clearSubscriptionError,
  setFilter
} = subscriptionsSlice.actions;

// Selectors
export const makeSubscriptions = (state) => state.subscriptions.subscriptions;
export const makeSelectedSubscription = (state) => state.subscriptions.selected;
export const makeSubscriptionsError = (state) => state.subscriptions.error;
export const makeSubscriptionFilter = (state) => state.subscriptions.filter;

export default subscriptionsSlice.reducer;
















// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { get } from "lodash";
// import ServiceProvider from "@client.services/provider";
// import { getGraphqlResponseError, getResponseError } from "@client.utils/error";

// const DEFAULT_SUBSCRIPTIONS = { results: [], loading: false, count: 0 };
// const DEFAULT_SELECTED_SUBSCRIPTION = { subscription: null, loading: false };

// const initialState = {
//   subscriptions: { results: [], loading: false, count: 0 },
//   selected: { subscription: null, loading: false },
//   error: null,
//   loading: false,
// };

// // Thunks
// export const getAllSubscriptionsAsync = createAsyncThunk(
//   "subscriptions/getAll",
//   async (_, thunkAPI) => {
//     thunkAPI.dispatch(setSubscriptionsLoading(true));
//     try {
//       const response = await ServiceProvider.Subscription.getAllSubscriptions();
//       console.log("From getAllSubscriptionsAsync", response);
//       return response;
//     } finally {
//       thunkAPI.dispatch(setSubscriptionsLoading(false));
//     }
//   }
// );

// export const getSubscriptionByIdAsync = createAsyncThunk(
//   "subscriptions/getById",
//   async (id, thunkAPI) => {
//     thunkAPI.dispatch(setSelectedLoading(true));
//     try {
//       const response = await ServiceProvider.Subscription.getSubscriptionById(id);
//       return response;
//     } finally {
//       thunkAPI.dispatch(setSelectedLoading(false));
//     }
//   }
// );

// export const createSubscriptionAsync = createAsyncThunk(
//   "subscriptions/create",
//   async (fields, thunkAPI) => {
//     const response = await ServiceProvider.Subscription.createSubscription(fields);
//     if (!response.errors) {
//       thunkAPI.dispatch(getAllSubscriptionsAsync());
//     }
//     return response;
//   }
// );

// export const updateSubscriptionAsync = createAsyncThunk(
//   "subscriptions/update",
//   async ({ id, fields }, thunkAPI) => {
//     const response = await ServiceProvider.Subscription.updateSubscription(id, fields);
//     if (!response.errors) {
//       thunkAPI.dispatch(getAllSubscriptionsAsync());
//     }
//     return response;
//   }
// );

// export const deleteSubscriptionAsync = createAsyncThunk(
//   "subscriptions/delete",
//   async (id, thunkAPI) => {
//     const response = await ServiceProvider.Subscription.deleteSubscription(id);
//     if (!response.errors) {
//       thunkAPI.dispatch(getAllSubscriptionsAsync());
//     }
//     return response;
//   }
// );

// // Slice
// export const subscriptionsSlice = createSlice({
//   name: "subscriptions",
//   initialState,
//   reducers: {
//     setSubscriptionsLoading: (state, action) => {
//       state.subscriptions.loading = get(action, "payload", false);
//     },
//     setSelectedLoading: (state, action) => {
//       state.selected.loading = get(action, "payload", false);
//     },
//     clearSubscriptionError: (state) => {
//       state.error = null;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(getAllSubscriptionsAsync.fulfilled, (state, action) => {
//         const response = get(
//           action,
//           "payload.data.subscriptions",
//           DEFAULT_SUBSCRIPTIONS
//         );
//         state.subscriptions.results = response.results;
//         state.subscriptions.count = response.count;
//         state.error = getGraphqlResponseError(action);
//       })
//       .addCase(getAllSubscriptionsAsync.rejected, (state, action) => {
//         console.error("Error in getAllSubscriptionsAsync:", action.payload);
//         state.subscriptions = {
//           ...DEFAULT_SUBSCRIPTIONS,
//         };
//         state.error = getResponseError(action);
//       })      
//       .addCase(getSubscriptionByIdAsync.fulfilled, (state, action) => {
//         state.selected.subscription = get(action, "payload.data.subscription", null);
//         state.error = getGraphqlResponseError(action);
//       })
//       .addCase(getSubscriptionByIdAsync.rejected, (state, action) => {
//         state.selected = {
//           ...DEFAULT_SELECTED_SUBSCRIPTION,
//         };
//         state.error = getResponseError(action);
//       })
//       .addCase(createSubscriptionAsync.fulfilled, (state, action) => {
//         state.error = getGraphqlResponseError(action);
//       })
//       .addCase(createSubscriptionAsync.rejected, (state, action) => {
//         state.error = getResponseError(action);
//       })
//       .addCase(updateSubscriptionAsync.fulfilled, (state, action) => {
//         state.error = getGraphqlResponseError(action);
//       })
//       .addCase(updateSubscriptionAsync.rejected, (state, action) => {
//         state.error = getResponseError(action);
//       })
//       .addCase(deleteSubscriptionAsync.fulfilled, (state, action) => {
//         state.error = getGraphqlResponseError(action);
//       })
//       .addCase(deleteSubscriptionAsync.rejected, (state, action) => {
//         state.error = getResponseError(action);
//       });
//   },
// });

// // Actions
// export const { setSubscriptionsLoading, setSelectedLoading, clearSubscriptionError } =
//   subscriptionsSlice.actions;

// // Selectors
// export const makeSubscriptions = (state) => state.subscriptions.subscriptions;
// export const makeSelectedSubscription = (state) => state.subscriptions.selected;
// export const makeSubscriptionsError = (state) => state.subscriptions.error;

// export default subscriptionsSlice.reducer;
