import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import "./ToolTipInfo.scss";
import { useEffect } from "react";

export const ToolTipInfo = ({ className, info, toggleInfo, isVisible }) => {
  console.log("From ToolTipInfo", isVisible);
  const { t } = useTranslation();
  useEffect(() => {
    let timer;
    if (isVisible) {
      // Set a timeout to close the tooltip after 5 seconds
      timer = setTimeout(() => {
        toggleInfo();
      }, 5000);
    }

    // Cleanup the timer when the component unmounts or `isVisible` changes
    return () => clearTimeout(timer);
  }, [isVisible, toggleInfo]);

  return (
    <div
      className={classNames(className, {
        "tooltip-visible": isVisible,
      })}
    >
      <span
      data-tooltip={info}
      data-tooltip-conf="top"
      className="tooltip"
    ></span>
    </div>
  );
};

ToolTipInfo.defaultProps = {};

ToolTipInfo.propTypes = {
  className: PropTypes.string,
  info: PropTypes.string,
  toggleInfo: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

// import React from "react";
// import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";
// import classNames from "classnames";
// import "./ToolTipInfo.scss";

// export const ToolTipInfo = ({ className, info, toggleInfo }) => {
//   const { t } = useTranslation();

//   return (
//     <div className={classNames("info-block", className)}>
//       <div className="info-box">
//         <button className="close" onClick={toggleInfo}>
//           ×
//         </button>
//       </div>
//       {/* <div className="info-body"> */}
//       <span data-tooltip="Top">
//         {info}
//       </span>
//       {/* </div> */}
//     </div>
//   );
// };

// ToolTipInfo.defaultProps = {};

// ToolTipInfo.propTypes = {
//   className: PropTypes.string,
//   info: PropTypes.string,
//   toggleInfo: PropTypes.func.isRequired
// };
