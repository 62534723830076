import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "@client.components/DataTable";
import { getColumns } from "./consts";
import { useTranslation } from "react-i18next";

import Button from "@client.core.components/Button";
import SystemLogForm from "./SystemLogForm";
import Input from "@client.core.components/Input";
import Title from "@client.components/Title";
import DateTimeRangePicker from "@client.components/DateTimeRangePicker/DateTimeRangePicker";
import ServiceProvider from "@client.services/provider";
import { MODAL_EVENT } from "@client.components/Modal";
import { getGraphqlResponseError } from "@client.utils/error";
import {
  createSystemLogAsync,
  getAllSystemLogsAsync,
  makeSystemLogs
} from "./reducer";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import moment from "moment";
import config from "@client.config";
import "./SystemLogs.scss";

const ITEMS_PER_PAGE = 10;

const SystemLogsPage = () => {
  const { t } = useTranslation();
  const systemLogs = useSelector(makeSystemLogs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const gridRef = useRef(null);
  const [filter, setFilter] = useState({
    query: "",
    startDate: "",
    endDate: ""
  });

  useEffect(() => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;
    dispatch(
      getAllSystemLogsAsync({
        ...filter,
        offset,
        limit: ITEMS_PER_PAGE,
        search: filter.query,
        searchOn: "description,user_full_name",
        startDate: filter.startDate,
        endDate: filter.endDate
      })
    );
  }, [filter, currentPage, dispatch]);

  const onRowClick = useCallback(
    (page, entityId) => {
      switch (page) {
        case "Device":
          navigate(`${Pages.Devices}/${entityId}`);
          break;
        case "User":
          navigate(`${Pages.ProfileGeneralEditUser}/${entityId}`);
          break;
        case "Accounts":
          navigate(`${Pages.Advertiser}/${entityId}`);
          break;
        default:
          console.warn("Unknown page type");
          break;
      }
    },
    [navigate]
  );

  const createTxt = t("systemLogs.title.create");
  return (
    <div className="system-logs page">
      <Title text="components.NavigationBar.SystemLogs">
        <Button
          icon="plus"
          className="button-create"
          text={createTxt}
          onClick={() => {
            ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
              show: true,
              withBodyRef: true,
              title: createTxt,
              component: <SystemLogForm />,
              buttonSettings: {
                text: t("components.Advertisers.create"),
                onClick: async (component) => {
                  const isValid = component.validate();
                  const logData = component.logData || {};
                  // console.log("logData From Sl", logData);
                  return isValid
                    ? dispatch(
                        createSystemLogAsync({
                          description: logData.description,
                          type: logData.type,
                          entity: logData.entity,
                          entityId:
                            logData.entityId 
                        })
                      ).then((resp) => {
                        const error = getGraphqlResponseError(resp);
                        if (!error) {
                          ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
                            show: false
                          });
                        }
                      })
                    : Promise.resolve();
                }
              }
            });
          }}
        />
      </Title>
      <div className="search">
        <Input
          label="Search"
          value={filter.query}
          onChange={(e) => {
            setFilter({
              ...filter,
              query: e
            });
          }}
        />
        <DateTimeRangePicker
          dateFrom={filter.startDate}
          dateTo={filter.endDate}
          showTimeSelect={false}
          captionStart="Start Date"
          captionEnd="End Date"
          onChange={(startDate, endDate) => {
            setFilter({
              ...filter,
              startDate: startDate
                ? moment(startDate).format(config.dateTimeFormat)
                : "",
              endDate: endDate
                ? moment(endDate).format(config.dateTimeFormat)
                : ""
            });
          }}
        />
      </div>
      <div className="content">
        <DataTable
          ref={gridRef}
          columns={getColumns(t, onRowClick)}
          data={systemLogs.results}
          isLoading={systemLogs.loading}
          pagination={{
            total: systemLogs.count,
            itemsPerPage: ITEMS_PER_PAGE,
            currentPage,
            onChange: setCurrentPage
          }}
        />
      </div>
    </div>
  );
};

export default SystemLogsPage;
