const enum SubscriptionsType {
    Active = 0,
    Cancelled = 1,
    PastDue = 2,
    Expired = 3,
    Trial = 4,
  }
  
  export default SubscriptionsType;
  
  