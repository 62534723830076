import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "@client.core.components/Button";
//import "./DeleteDeviceConfirmation.scss";

const DeleteSubConfirmation = ({ onDelete, onCancel }) => {
  const { t } = useTranslation();
  return (
    <div className="delete-subscription-confirmation">
      <span>{t("components.DeletesubscriptionConfirmation.confirmation")}</span>
      <div className="management-buttons">
        <Button
          text={t("components.DeletesubscriptionConfirmation.delete")}
          onClick={onDelete}
        />
        <Button
          text={t("components.DeletesubscriptionConfirmation.cancel")}
          onClick={onCancel}
          className="alternative"
        />
      </div>
    </div>
  );
};

DeleteSubConfirmation.propTypes = {
  onDelete: PropTypes.func,
  onCancel: PropTypes.func
};

export default DeleteSubConfirmation;