import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "@client.store";
import { makeProfile } from "@client.pages/Login/reducer";
import { 
  getAllAdvertisersAsync, 
  getAdvertiserCampaignsAsync, 
  makeAdvertisers, 
  makeSelectedCampaigns,
} from "@client.pages/Advertiser/reducer";
import {
  fetchPlatformDashboardAsync,
  makePlatformDashboard,
} from "@client.features/main/dashboard";

import CircleIcon from "@client.components/CircleIcon";
import TopBoardOverview from "@client.components/TopBoardOverview";
import CampaignOverview from "@client.components/CampaignOverview";

import "./AdvertiserDashboard.scss";

interface IProfile {
  Id: string,
  accountId: string,
  avatarUrl: string,
  currency: string,
  emailAddress: string,
  firstName: string,
  lastName: string,
  name: string,
  phoneNumber: string,
  username: string,
}

interface ICampaign {
  Id: string
}

interface ICampaignsEntity {
  campaigns: ICampaign[],
  count: number,
  loading: boolean,
}

interface IAdvertiser {
  Id: string,
  companyLogoMediaId: string,
  companyName: string,
  companyRegistrationNumber: string,
  companyUrl: string,
  companyVatNumber: string,
  entity: number,
}

const AdvertiserDashboard = () => {
  const dispatch = useDispatch<AppDispatch>();

  const profile: IProfile = useSelector(makeProfile);
  const advertisers: IAdvertiser[] = useSelector(makeAdvertisers);
  const selectedCampaigns: ICampaignsEntity = useSelector(makeSelectedCampaigns);
  const platformDashboard = useSelector(makePlatformDashboard);

  const advertiser = advertisers.find((advertiser) => advertiser.Id === profile.accountId)

  useEffect(() => {
    dispatch(getAllAdvertisersAsync());
  }, [profile]);

  useEffect(() => {
    dispatch(fetchPlatformDashboardAsync());
  }, [dispatch]);

  useEffect(() => {
    if (profile.accountId) {
      dispatch(getAdvertiserCampaignsAsync({ advertiserId: profile.accountId }));
    }
  }, [profile]);

  return (
    <div className='advertiser-dashboard'>
      {advertiser?.companyName && (
        <div className='account-name'>
          <CircleIcon name={advertiser.companyName} index={0} />
          {advertiser.companyName}
        </div>
      )}
      <TopBoardOverview
        className="top-board-dashboard-overview"
        activeCampaigns={platformDashboard.activeCampaigns}
        adPlays={platformDashboard.dailyAdPlays}
        adViews={platformDashboard.dailyAdViews}
        budgetSpent={platformDashboard.dailyBudgetSpent}
      />
      <div className='campaigns-title'>
        My Campaigns
      </div>
      {selectedCampaigns.campaigns.map((campaign) => (
        <CampaignOverview
          key={campaign.Id}
          campaign={campaign}
        />
      ))}
    </div>
  );
};

export default AdvertiserDashboard;
