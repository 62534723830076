import React from "react";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import SvgIcon from "@client.core.components/SvgIcon";
import { useTranslation } from "react-i18next";
import Button from "@client.core.components/Button";
import { NavigationSection } from "./NavigationSection";
import { useSelector } from "react-redux";
import "./NavigationBar.scss";
import CustomerType from "@client.enums/customerType";
import { makeProfileAccount } from "@client.pages/Login/reducer";
import { resetSelectedCampaign } from "../../reducers/Campaign";
import { RiDashboard2Fill } from "react-icons/ri";
import { MdOutlineImportantDevices } from "react-icons/md";
import { FaMapLocationDot } from "react-icons/fa6";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FcAdvertising } from "react-icons/fc";
import { RiAccountCircleFill } from "react-icons/ri";
import { FaSitemap } from "react-icons/fa6";
import { MdOutlineDataThresholding } from "react-icons/md";
import { BsClipboard2Data } from "react-icons/bs";
import { FaFileInvoice } from "react-icons/fa";
import { TbLogs } from "react-icons/tb";
import { useDispatch } from "react-redux";
export const NavigationBar = () => {
  const profileAccount = useSelector(makeProfileAccount);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Platform Sidebar Sections
  const platformSections = [
    {
      title: t("components.NavigationBar.dashboard"),
      items: [
        {
          // icon: "dashboard",
          // icon: <AiTwotoneDashboard />,
          icon: <RiDashboard2Fill />,
          url: Pages.Dashboard,
          name: t("components.NavigationBar.overview"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.deviceOperations"),
      items: [
        {
          // icon: "device",
          icon: <MdOutlineImportantDevices />,
          url: Pages.Devices,
          name: t("components.NavigationBar.devices"),
        },
        {
          // icon: "locations",
          icon: <FaMapLocationDot />,
          url: Pages.Zones,
          name: t("components.NavigationBar.locations"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.campaignManagement"),
      items: [
        {
          // icon: "circle-check",
          icon: <IoMdCheckmarkCircleOutline />,
          url: Pages.CampaignApprovals,
          name: t("components.NavigationBar.campaignApprovals"),
        },
        {
          // icon: "active-campaigns",
          icon: (
            <FcAdvertising
              style={{
                filter: "grayscale(100%)",
                // width: "50px",
                // height: "50px"
              }}
            />
          ),
          url: Pages.Campaigns,
          name: t("components.NavigationBar.campaigns"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.userAndAccountManagement"),
      items: [
        {
          icon: <FaSitemap />,
          url: Pages.Accounts,
          name: t("components.NavigationBar.accounts"),
        },
        {
          // icon: "profile",
          icon: <RiAccountCircleFill />,
          url: Pages.Users,
          name: t("components.NavigationBar.Users"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.billingAndSubscription"),
      items: [
        {
          // icon: "billing",
          icon: <BsClipboard2Data />,
          url: Pages.Plans,
          name: t("components.NavigationBar.plans"),
        },
        {
          icon: "billing",

          url: Pages.Subscriptions,
          name: t("components.NavigationBar.subscriptions"),
        },
        {
          // icon: "billing",
          icon: <FaFileInvoice />,
          url: Pages.Invoices,
          name: t("components.NavigationBar.invoices"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.logsAndActivity"),
      items: [
        {
          // icon: "locations",
          icon: <TbLogs />,
          url: Pages.UserLogs,
          name: t("components.NavigationBar.userLogs"),
        },
        {
          // icon: "data-analyzer",
          icon: <MdOutlineDataThresholding />,
          url: Pages.SystemLogs,
          name: t("components.NavigationBar.systemLogs"),
        },
      ],
    },
  ];

  // Advertiser Sidebar Sections
  const advertiserSections = [
    {
      title: t("components.NavigationBar.dashboard"),
      items: [
        {
          icon: "dashboard",
          url: Pages.Dashboard,
          name: t("components.NavigationBar.overview"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.campaignManagement"),
      items: [
        {
          icon: "active-campaigns",
          url: Pages.Campaigns,
          name: t("components.NavigationBar.myCampaigns"),
        },
        {
          icon: "media",
          url: Pages.MediaGallery,
          name: t("components.NavigationBar.mediaGallery"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.userManagement"),
      items: [
        {
          icon: "profile",
          url: Pages.Users,
          name: t("components.NavigationBar.myUsers"),
        },
        {
          icon: "activity",
          url: Pages.UserActivity,
          name: t("components.NavigationBar.userActivity"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.analyticsAndReporting"),
      items: [
        {
          icon: "reports",
          url: Pages.Reports,
          name: t("components.NavigationBar.reports"),
        },
      ],
    },
    {
      title: t("components.NavigationBar.helpAndSupport"),
      items: [
        {
          icon: "faqs",
          url: Pages.FAQs,
          name: t("components.NavigationBar.faqs"),
        },
        {
          icon: "tickets",
          url: Pages.SupportTickets,
          name: t("components.NavigationBar.supportTickets"),
        },
        {
          icon: "contact-support",
          url: Pages.ContactSupport,
          name: t("components.NavigationBar.contactSupport"),
        },
      ],
    },
  ];

  return (
    <div className="navigation-bar" data-testid="NavigationBar">
      <div className="nav-top-bar">
        <div className="logo-container">
          <SvgIcon
            name="logo"
            className="logo"
            onClick={() => {
              navigate(Pages.Dashboard);
            }}
          />
        </div>
        <Button
          icon="plus"
          text={t("components.NavigationBar.createCampaign")}
          onClick={() => {
            dispatch(resetSelectedCampaign());
            navigate(Pages.CreateCampaign);
          }}
        />
      </div>

      {/* Platform Sidebar */}
      {profileAccount && profileAccount.entity === CustomerType.Platform && (
        // <div className="nav-main-content">
        <div className="nav-main-content">
          {platformSections.map((section) => (
            <NavigationSection
              key={section.title}
              title={section.title}
              items={section.items}
            />
          ))}
        </div>
      )}

      {/* Advertiser Sidebar */}
      {profileAccount && profileAccount.entity === CustomerType.Advertiser && (
        <div className="nav-main-content">
          {advertiserSections.map((section) => (
            <NavigationSection
              key={section.title}
              title={section.title}
              items={section.items}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default NavigationBar;
