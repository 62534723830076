import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { makeProfile } from "@client.pages/Login/reducer";
import moment from "moment";
import config from "@client.config";
import classNames from "classnames";
import CampaignStatuses from "@client.enums/campaignStatuses";
import CampaignActions from "./CampaignActions";
import MediaFile from "@client.components/MediaFile";
import "./CampaignOverview.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateAdvertiserCampaignStatusAsync } from "@client.pages/Advertiser/reducer";
import Pages from "@client.enums/pages";
import { useState,useEffect } from "react";

export const CampaignOverview = ({
  campaign,
  currentFilters,
  onStatusChange,
}) => {
  // console.log("From CampaignOverview.js: ", campaign);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const profile = useSelector(makeProfile);

  const currentDate = new Date();
  const startDate = new Date(campaign.startDate);
  const endDate = new Date(campaign.endDate);

  const views = campaign.totalViews || 0;
  const spent = campaign.totalSpent || 0;
  const pricePerView = views === 0 || spent === 0 ? 0 : spent / views;

  const isStartDateFuture = startDate > currentDate;
  const isEndDatePast = endDate < currentDate;
  const isEndedOver24HoursAgo = moment(endDate).add(1, "days") < currentDate;
  const [newStatus, setNewStatus] = useState(campaign.status);


  // const example = {
  //   Id: "675aee5781124fe1462e55e7",
  //   advertiserId: "674f80e9237fcbe58e376b65",
  //   budgetDaily: 0,
  //   campaignName: "My Pending Campign new2s",
  //   createdAt: "2024-12-01 14:08:23.807000",
  //   creatorAccountId: "67422450f2b4d693d530980a",
  //   creatorUserId: "67422450f2b4d693d530980c",
  //   endDate: "2024-12-11 19:29:57.666000",
  //   geofenceZoneIds: ["Zone1", "Zone2"],
  //   isActive: true,
  //   isDraft: false,
  //   mediaId:
  //     "https://core-ams-01.kaligon.com:60000/api/download/67459d650858c06705d3e5f0",
  //   paymentId: "",
  //   startDate: "2024-11-29 16:58:57.666000",
  //   status: 1,
  //   target: 1,
  //   updatedAt: "2024-12-12 14:26:59.965000",
  // };

  // const endDateExample = new Date(example.endDate);

  // const isEndedOver24HoursAgoExample = moment(endDateExample).add(1, "days") < currentDate;

  // console.log("isEndedOver24HoursAgoExample", isEndedOver24HoursAgoExample);
  
//  console.log("isEndedOver24HoursAgoExample", isEndedOver24HoursAgo);
 
const updateStatus = useCallback(
  (status) => {
    if (status !== campaign.status || campaign.isDraft) {
      // Prepare updated campaign object
      const updatedCampaign = {
        ...campaign,
        status,
        isDraft: false, // Ensure isDraft is set to false
      };

      // Dispatch the status update
      dispatch(
        updateAdvertiserCampaignStatusAsync({
          campaign: updatedCampaign,
          filters: currentFilters,
        })
      );

      // Update local state
      setNewStatus(status);

      // Trigger any external callback
      if (onStatusChange) {
        onStatusChange(updatedCampaign);
      }
    }
  },
  [campaign, currentFilters, dispatch, onStatusChange]
);


useEffect(() => {
  if (isEndDatePast) {
    if (isEndedOver24HoursAgo && newStatus !== CampaignStatuses.Archive) {
      // Transition to "Archived"
      setNewStatus((prevStatus) => {
        if (prevStatus !== CampaignStatuses.Archive) {
          updateStatus(CampaignStatuses.Archive);
        }
        return CampaignStatuses.Archive;
      });
    } else if (!isEndedOver24HoursAgo && newStatus !== CampaignStatuses.Ended) {
      // Transition to "Ended"
      setNewStatus((prevStatus) => {
        if (prevStatus !== CampaignStatuses.Ended) {
          updateStatus(CampaignStatuses.Ended);
        }
        return CampaignStatuses.Ended;
      });
    }
  } else if (campaign.isDraft && newStatus !== CampaignStatuses.Queued) {
    // Handle draft campaigns transitioning to "Queued"
    updateStatus(CampaignStatuses.Queued);
  }
}, [
  isEndDatePast,
  isEndedOver24HoursAgo,
  newStatus,
  campaign.isDraft,
  updateStatus,
]);

  const determineStatus = () => {

    if (isEndDatePast) {
      if (isEndedOver24HoursAgo) {
        return { term: "pages.Campaign.status.archived", class: "archived" };
      }
      return { term: "pages.Campaign.status.ended", class: "ended" };
    }
    
    // if (campaign.isDraft) {
    //   return { term: "pages.Campaign.status.draft", class: "draft" };
    // }

    switch (newStatus) {
      case CampaignStatuses.Paused:
        return { term: "pages.Campaign.status.paused", class: "paused" };
      case CampaignStatuses.Archive:
        return { term: "pages.Campaign.status.archived", class: "archived" };
      case CampaignStatuses.Queued:
        return { term: "pages.Campaign.status.queued", class: "queued" };
      case CampaignStatuses.PendingApproval:
        return { term: "pages.Campaign.status.pending", class: "pending" };
      case CampaignStatuses.Draft:
        return { term: "pages.Campaign.status.draft", class: "draft" };
      default:
        if (isStartDateFuture)
          return { term: "pages.Campaign.status.queued", class: "queued" };
        return { term: "pages.Campaign.status.live", class: "live" };
    }
  };

  // console.log("campaign", campaign);

  const status = determineStatus();

  return (
    <div className="campaign-overview">
      <div
        className="preview"
        onClick={() => {
          navigate(
            campaign.status === CampaignStatuses.Draft
              ? `${Pages.CreateCampaign}/${campaign.Id}`
              : `${Pages.Campaign}/${campaign.Id}`
          );
        }}
      >
        <MediaFile
          src={campaign.mediaId || ""}
          showThumbnail
          isNotFound={!campaign.mediaId}
        />
      </div>
      <div className="status">
        <span className={classNames("badge", status.class)}>
          {t(status.term)}
        </span>
        <span className="time">
          <span>
            {isEndDatePast
              ? t("components.CampaignOverview.ended")
              : t("components.CampaignOverview.ending")}
          </span>
          <span>{moment(campaign.endDate).format(config.dateTimeFormat)}</span>
        </span>
      </div>
      <div className="name">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span>{campaign.campaignName}</span>
          <CampaignActions
            campaignId={campaign.Id}
            campaign={campaign}
            currentFilters={currentFilters}
            onStatusChange={onStatusChange} // Pass callback
          />
        </div>
      </div>
      <div className="statistic">
        <div className="section-payment">
          <div className="price">{views.toLocaleString()}</div>
          <div className="description">
            {t("components.CampaignOverview.plays")}
          </div>
        </div>
        <div className="section-payment">
          <div className="price">
            {profile.currency} {pricePerView.toLocaleString()}
          </div>
          <div className="description">
            {t("components.CampaignOverview.perPlay")}
          </div>
        </div>
        <div className="section-payment no-border">
          <div className="price">
            {profile.currency} {spent.toLocaleString()}
          </div>
          <div className="description">
            {t("components.CampaignOverview.spent")}
          </div>
        </div>
      </div>
    </div>
  );
};

CampaignOverview.propTypes = {
  campaign: PropTypes.shape({
    Id: PropTypes.string.isRequired,
    campaignName: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    mediaId: PropTypes.string,
    totalViews: PropTypes.number,
    totalSpent: PropTypes.number,
    currentFilters: PropTypes.array,
    isDraft: PropTypes.boolean,
  }).isRequired,
  currentFilters: PropTypes.array,
  onStatusChange: PropTypes.func,
};
