import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import GoogleMap from "@client.components/GoogleMap";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceByIdAsync, makeDeviceInfo } from "../../reducers/Device";
import Loader from "@client.core.components/Loader";
import config from "@client.config";
import SvgIcon from "@client.core.components/SvgIcon";
import Icon from "@client.core.components/Icon";
import ServiceProvider from "@client.services/provider";
import { MODAL_EVENT } from "@client.components/Modal";
import DeleteDeviceConfirmation from "./DeleteDeviceConfirmation";
import DeviceStatuses from "@client.enums/deviceStatuses";
import "./DeviceDetails.scss";
import {
  makeSelectedDeviceTelemetry,
  fetchDeviceTelemetryAsync,
} from "../../reducers/Device";
const DeviceDetails = ({ id, showMap, onView, onDelete }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedDevice = useSelector(makeDeviceInfo);

  const telemerty = useSelector(makeSelectedDeviceTelemetry);
  // console.log(
  //   "From CampaignPage.jssssssssssssssssssssssssssssssssssssssssssssss ",
  //   telemerty
  // );

  useEffect(() => {
    dispatch(fetchDeviceTelemetryAsync(id))
    // .then((response) => {console.log("DeviceDetails -> response", response);});
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getDeviceByIdAsync(id));
  }, [dispatch, id]);

  if (selectedDevice.loading) {
    return (
      <div className="device-details device-details-loading">
        <Loader loading={true} />
      </div>
    );
  }

  const renderInfo = (field, value) => {
    return (
      <div className="card">
        <h2 className="card-title">{t(field)}</h2>
        <p className="card-description">{value}</p>
      </div>
    );
  };

  const { device } = selectedDevice;
  // console.log("DeviceDetails -> device", device);
  if (device === null) {
    return null;
  }
  const { lastTelemetry } = device;
  // console.log("DeviceDetails -> lastTelemetry", lastTelemetry);
  // console.log("DeviceDetails -> lastTelemetry", lastTelemetry?.sensors?.batteryA);
  const renderMap = () => {
    if (!showMap) {
      return null;
    }
    return lastTelemetry ? (
      <GoogleMap
        width="100%"
        height="30vh"
        markers={[
          {
            // lng: lastTelemetry.longitude?lastTelemetry.longitude:0.2, 
            // lat: lastTelemetry.latitude?lastTelemetry.latitude:0.1,
            lat: lastTelemetry?.sensors?.gnss?.latitude || 1.1,
            lng: lastTelemetry?.sensors?.gnss?.longitude || 1.1,
            // lat: device.latitude ||1.1,
            // lng: device.longitude||1.1,
          },
        ]}
      />
    ) : (
      <span className="no-telemerty-found">
        <SvgIcon name="no-data" />
        {t("pages.Devices.noTelemetryFound")}
      </span>
    );
  };

  return (
    <div className="device-details">
      <div className="device-details-title">
        {t("pages.Devices.deviceDetails")}
        <div className="management-butotns">
          <div className="btn" onClick={onView}>
            <Icon name="eye" />
          </div>
          <div className="btn">
            <Icon
              name="delete"
              onClick={() => {
                ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
                  show: true,
                  title: t("components.DeleteDeviceConfirmation.title"),
                  component: (
                    <DeleteDeviceConfirmation
                      onDelete={onDelete}
                      onCancel={() =>
                        ServiceProvider.EventEmitter.emit(MODAL_EVENT, {})
                      }
                    />
                  ),
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="device-info-grid">
        {renderInfo("pages.Devices.Details.id", device.Id)}
        {renderInfo("pages.Devices.Details.name", device.deviceName)}
        {renderInfo(
          "pages.Devices.Details.swVersion",
          device.softwareVersion || "Version not found"
        )}
        {renderInfo(
          "pages.Devices.Details.hwVersion",
          device.hardwareVersion || "Version not found"
        )}
        {renderInfo("pages.Devices.Details.macId", device.machineId)}
        {renderInfo(
          "pages.Devices.Details.timeZoneCode",
          device.timezoneCode || "UTC +8"
        )}
        {renderInfo(
          "pages.Devices.Details.status",
          t(
            device.isOnline
            === true
              ? "pages.Devices.status.online"
              : "pages.Devices.status.offline"
          )
        )}
        {renderInfo(
          "pages.Devices.Details.update",
          moment(device.updateAt).format(config.dateTimeFormat)
        )}
      </div>
      {lastTelemetry && (
        <div className="device-details-title">
          {t("pages.Devices.lastTelemetry")}
        </div>
      )}
      {lastTelemetry && (
        <div className="device-info-grid">
          {renderInfo(
            "pages.Devices.LastTelemetry.latitude",
            device.latitude || 0.3
          )}
          {renderInfo(
            "pages.Devices.LastTelemetry.longitude",
            device.longitude || 0.3
          )}
          {/* {renderInfo(
            "pages.Devices.LastTelemetry.lteReception",
            lastTelemetry.signal
          )}
          {renderInfo(
            "pages.Devices.LastTelemetry.boardTemperature",
            lastTelemetry.sensors.cpuTemperature + "C°"
          )} */}

          {renderInfo(
            "pages.Devices.LastTelemetry.upTime",
            lastTelemetry?.uptime|| 0.1
          )}
          {renderInfo(
            "pages.Devices.LastTelemetry.screenTemperature",
            (lastTelemetry?.sensors?.screen?.temperature||0.1) + "C°"
          )}
          {renderInfo(
            "pages.Devices.LastTelemetry.batteryA",
            `${lastTelemetry?.sensors?.batteryA?.percentage||0.1}%`
          )}
          {renderInfo(
            "pages.Devices.LastTelemetry.batteryB",
            `${lastTelemetry?.sensors?.batteryB?.percentage||0.1}%`
          )}
        </div>
      )}
      {renderMap()}
    </div>
  );
};

DeviceDetails.defaultProps = {
  showMap: true,
};

DeviceDetails.propTypes = {
  id: PropTypes.any,
  showMap: PropTypes.bool,
  onView: PropTypes.func,
  onDelete: PropTypes.func,
};

export default DeviceDetails;
