import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as Popover from "@radix-ui/react-popover";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import Icon from "@client.core.components/Icon";
import IconRenderer  from "@client.components/FA/IconRenderer ";

import { useDispatch } from "react-redux";
import { updateAdvertiserCampaignStatusAsync } from "@client.pages/Advertiser/reducer";
import CampaignStatuses from "@client.enums/campaignStatuses";

const CampaignActions = ({ campaignId, campaign, currentFilters, onStatusChange }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const archivedOrQueued = useCallback(() => {
    const newStatus =
      campaign.status === CampaignStatuses.Archive
        ? CampaignStatuses.Queued
        : CampaignStatuses.Archive;
    const updatedCampaign = { ...campaign, status: newStatus };

    dispatch(
      updateAdvertiserCampaignStatusAsync({
        campaign: updatedCampaign,
        filters: currentFilters,
      })
    ).then(() => {
      if (onStatusChange) onStatusChange(); // Notify parent of status change
    });
  }, [campaign, dispatch, currentFilters, onStatusChange]);

  const pausedOrQueued = useCallback(() => {
    const newStatus =
      campaign.status === CampaignStatuses.Paused
        ? CampaignStatuses.Queued
        : CampaignStatuses.Paused;
    const updatedCampaign = { ...campaign, status: newStatus };

    dispatch(
      updateAdvertiserCampaignStatusAsync({
        campaign: updatedCampaign,
        filters: currentFilters,
      })
    ).then(() => {
      if (onStatusChange) onStatusChange(); // Notify parent of status change
    });
  }, [campaign, dispatch, currentFilters, onStatusChange]);

  const actions = [
    {
      name: t("components.CampaignActions.open"),
      // icon: "open",
      icon: "IoMdEye",
      onClick: () => {
        navigate(`${Pages.Campaign}/${campaignId}`);
      },
    },
    {
      name: campaign.status === CampaignStatuses.Paused ? t("components.CampaignActions.queue") : t("components.CampaignActions.pause"),
      icon:
        campaign.status === CampaignStatuses.Paused
        // ? "eye-cross"
          ? "IoMdEyeOff"
          // : "paused-campaigns",
          : "FaRegPauseCircle",
      onClick: pausedOrQueued,
    },
    {
      name:
        campaign.status === CampaignStatuses.Archive ? t("components.CampaignActions.queue") : t("components.CampaignActions.archive"),
      icon:
        campaign.status === CampaignStatuses.Archive
        // ? "eye-cross"
          ? "IoMdEyeOff"
          // : "archived-campaigns",
          : "FaArchive",
      onClick: archivedOrQueued,
    },
  ];

  return (
    <div className="campaign-actions-control">
      <Popover.Root>
        <Popover.Trigger asChild>
          <button className="popover-button">
            {/* <Icon name="vertical-dots" /> */}
            <IconRenderer iconName="BsThreeDotsVertical" size={22} />

          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="nm-popover-content"
            sideOffset={10}
            alignOffset={110}
          >
            <div className="campaign-actions">
              {actions.map((action) => (
                <div
                  className="action"
                  key={action.name}
                  onClick={action.onClick}
                >
                  {/* <Icon name={action.icon} /> */}
                  <IconRenderer iconName={action.icon} style={{marginRight: 5}}
                   />
                  {t(action.name)}
                </div>
              ))}
            </div>
            <Popover.Arrow className="popover-arrow" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

CampaignActions.propTypes = {
  campaignId: PropTypes.string.isRequired,
  campaign: PropTypes.object.isRequired,
  currentFilters: PropTypes.object,
  onStatusChange: PropTypes.func, // Add prop type for callback
};

export default React.memo(CampaignActions);























// import React, { useCallback } from "react";
// import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";
// import * as Popover from "@radix-ui/react-popover";
// import { useNavigate } from "react-router-dom";
// import Pages from "@client.enums/pages";
// import Icon from "@client.core.components/Icon";
// import { useDispatch } from "react-redux";
// import { updateAdvertiserCampaignAsync } from "@client.pages/Advertiser/reducer";
// import CampaignStatuses from "@client.enums/campaignStatuses";

// const CampaignActions = ({ campaignId, campaign, currentFilters }) => {
//   const { t } = useTranslation();
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const archivedOrQueued = useCallback(() => {
//     const newStatus =
//       campaign.status === CampaignStatuses.Archive
//         ? CampaignStatuses.Queued
//         : CampaignStatuses.Archive;
//     const updatedCampaign = { ...campaign, status: newStatus };

//     // Pass the updated campaign and current filters to ensure the list reloads correctly
//     dispatch(
//       updateAdvertiserCampaignAsync({
//         campaign: updatedCampaign,
//         filters: currentFilters,
//       })
//     );
//   }, [campaign, dispatch, currentFilters, updateAdvertiserCampaignAsync]);

//   const pausedOrQueued = useCallback(() => {
//     const newStatus =
//       campaign.status === CampaignStatuses.Paused
//         ? CampaignStatuses.Queued
//         : CampaignStatuses.Paused;
//     const updatedCampaign = { ...campaign, status: newStatus };

//     dispatch(
//       updateAdvertiserCampaignAsync({
//         campaign: updatedCampaign,
//         filters: currentFilters,
//       })
//     );
//   }, [campaign, dispatch, currentFilters, updateAdvertiserCampaignAsync]);

//   const actions = [
//     {
//       name: t("components.CampaignActions.open"),
//       icon: "open",
//       onClick: () => {
//         navigate(`${Pages.Campaign}/${campaignId}`);
//       },
//     },
//     {
//       // name: "components.CampaignActions.pause",
//       // icon: "paused-campaigns",
//       name: campaign.status === CampaignStatuses.Paused ? t("components.CampaignActions.queue") : t("components.CampaignActions.pause"),
//       icon:
//         campaign.status === CampaignStatuses.Paused
//           ? "eye-cross"
//           : "paused-campaigns",
//       onClick: pausedOrQueued,
//     },
//     {
//       name:
//         campaign.status === CampaignStatuses.Archive ? t("components.CampaignActions.queue") : t("components.CampaignActions.archive"),
//       icon:
//         campaign.status === CampaignStatuses.Archive
//           ? "eye-cross"
//           : "archived-campaigns",
//       // name: "components.CampaignActions.archive",
//       // icon: "archived-campaigns",
//       onClick: archivedOrQueued,
//     },
//   ];
//   return (
//     <div className="campaign-actions-control">
//       <Popover.Root>
//         <Popover.Trigger asChild>
//           <button className="popover-button">
//             <Icon name="vertical-dots" />
//           </button>
//         </Popover.Trigger>
//         <Popover.Portal>
//           <Popover.Content
//             className="nm-popover-content"
//             sideOffset={10}
//             alignOffset={110}
//           >
//             <div className="campaign-actions">
//               {actions.map((action) => (
//                 <div
//                   className="action"
//                   key={action.name}
//                   onClick={action.onClick}
//                 >
//                   <Icon name={action.icon} />
//                   {t(action.name)}
//                 </div>
//               ))}
//             </div>
//             <Popover.Arrow className="popover-arrow" />
//           </Popover.Content>
//         </Popover.Portal>
//       </Popover.Root>
//     </div>
//   );
// };

// CampaignActions.propTypes = {
//   advertiserId: PropTypes.string,
//   campaignId: PropTypes.string,
//   campaign: PropTypes.object,
//   currentFilters: PropTypes.object,
// };

// export default React.memo(CampaignActions);
