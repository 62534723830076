import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "@client.components/DateRangePicker";
import DataTable from "@client.components/DataTable";
import Dropdown from "@client.core.components/Dropdown";
import { getColumns } from "./consts";
import { useTranslation } from "react-i18next";
import {
  createDeviceAsync,
  deleteDeviceAsync,
  getAllDevicesAsync,
  makeDevices,
  makeDevicesFilter,
  setFilter,
  updateDeviceAsync,
  deviceRegistrationsJudgementAsync,
} from "../../reducers/Device";
import Button from "@client.core.components/Button";
import ServiceProvider from "@client.services/provider";
import { MODAL_EVENT } from "@client.components/Modal";
import DeviceManagement from "./DeviceManagement";
import DeviceDetails from "./DeviceDetails";
import SvgIcon from "@client.core.components/SvgIcon";
import { useCallback } from "react";
import useToast from "@client.hooks/useToast";
import { useNavigate } from "react-router-dom";
import Pages from "@client.enums/pages";
import Input from "@client.core.components/Input";
import Title from "@client.components/Title";
import DeviceStatuses from "@client.enums/deviceStatuses";
import "./Devices.scss";
import CustomDateRangePicker from "@client.components/CustomDateRangePicker";
import DeviceApproval from "./DeviceApproval";
import ModalDeviceApprovalCustom from "../../components/ModalDeviceApprovalCustom/ModalDeviceApprovalCustom";
// import IconRenderer  from "../../components/FA/IconRenderer ";
// import { AiTwotoneDashboard } from "react-icons/ai";


// import "../../components/Modal_Device_Approval/Modal_Device_Approval.scss";
const ITEMS_PER_PAGE = 10;

const DevicesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showToastAfterRequest } = useToast();
  const dispatch = useDispatch();

  const data = useSelector(makeDevices);
  const filter = useSelector(makeDevicesFilter);

  const gridRef = useRef(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isApprovalModalVisible, setApprovalModalVisible] = useState(false);
  // Ref for DeviceApproval to access selected devices
  const deviceApprovalRef = useRef(null);

  useEffect(() => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;
    handleFilterChange({
      offset,
      limit: ITEMS_PER_PAGE,
    });
  }, [currentPage]);

  useEffect(() => {
    dispatch(
      getAllDevicesAsync({
        limit: ITEMS_PER_PAGE,
        offset: (currentPage - 1) * ITEMS_PER_PAGE,
        search: filter.query,
        searchOn: "device_name",
        startDate: filter.startDate,
        endDate: filter.endDate,
      })
    );
  }, [dispatch, currentPage, filter]);

  const onSelectionChanged = (d) => {
    switch (d.colDef.field) {
      case "Name":
      case "index":
        navigate(`${Pages.Devices}/${d.data.Id}`);
        break;
      case undefined:
        break;
      default: {
        const row = gridRef.current.api.getSelectedRows()[0];
        setSelectedRow(row);
        break;
      }
    }
  };

  const createTxt = t("pages.Devices.createDevice");
  const updateTxt = t("pages.Devices.updateDevice");

  const onDeviceManagement = useCallback(
    (device) => {
      ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
        show: true,
        withBodyRef: true,
        title: device.Id ? updateTxt : createTxt,
        component: <DeviceManagement device={device} />,
        buttonSettings: {
          text: t(device.Id ? "pages.Devices.update" : "pages.Devices.create"),
          onClick: async (component) => {
            const isValid = component.validate();
            return isValid
              ? dispatch(
                  component.managementItem.Id
                    ? updateDeviceAsync(component.managementItem)
                    : createDeviceAsync(component.managementItem)
                ).then((resp) => {
                  showToastAfterRequest(
                    resp,
                    "pages.Devices.deviceCreated"
                  ).then(() => {
                    ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
                      show: false,
                    });
                  });
                })
              : Promise.resolve();
          },
        },
      });
    },
    [createTxt, dispatch, showToastAfterRequest, t]
  );


  const onDeviceApproval = useCallback(() => {
    setApprovalModalVisible(true);
  }, []);



  const confirmApproval = useCallback(() => {
    if (deviceApprovalRef.current) {
      const selectedDevices = deviceApprovalRef.current.selectedDevices();
      console.log("Selected Devices:", selectedDevices);
  
      Promise.all(
        selectedDevices.map((device) =>
          dispatch(
            deviceRegistrationsJudgementAsync({
              id: device.Id,
              status: 1, // Approve
            })
          ).unwrap()
        )
      )
        .then(() => {
          console.log("All devices approved successfully");
          // Fetch updated list of devices
          dispatch(
            fetchDeviceRegistrationsAsync({
              limit: ITEMS_PER_PAGE,
              offset: (currentPage - 1) * ITEMS_PER_PAGE,
              search: filter.query,
              searchOn: "device_name",
              startDate: filter.startDate,
              endDate: filter.endDate,
            })
          );
        })
        .catch((err) => console.error("Error approving devices:", err))
        .finally(() => setApprovalModalVisible(false));
    }
  }, [dispatch, currentPage, filter]);
  



  const rejectApprovalModal = useCallback(() => {
    if (deviceApprovalRef.current) {
      const selectedDevices = deviceApprovalRef.current.selectedDevices();
      console.log("Selected Devices:", selectedDevices);
  
      Promise.all(
        selectedDevices.map((device) =>
          dispatch(
            deviceRegistrationsJudgementAsync({
              id: device.Id,
              status: 2, // Reject
            })
          ).unwrap()
        )
      )
        .then(() => {
          console.log("All devices rejected successfully");
          // Fetch updated list of devices
          dispatch(
            fetchDeviceRegistrationsAsync({
              limit: ITEMS_PER_PAGE,
              offset: (currentPage - 1) * ITEMS_PER_PAGE,
              search: filter.query,
              searchOn: "device_name",
              startDate: filter.startDate,
              endDate: filter.endDate,
            })
          );
        })
        .catch((err) => console.error("Error rejecting devices:", err))
        .finally(() => setApprovalModalVisible(false));
    }
  }, [dispatch, currentPage, filter]);


  const closeApprovalModal = useCallback(() => {
    setApprovalModalVisible(false);
  }, []);
  


  const statuses = [
    {
      value: DeviceStatuses.All,
      label: t("pages.Devices.status.all"),
    },
    {
      value: DeviceStatuses.Online,
      label: t("pages.Devices.status.online"),
    },
    {
      value: DeviceStatuses.Offline,
      label: t("pages.Devices.status.offline"),
    },
  ];

  const onEdit = useCallback(
    (rowData) => {
      onDeviceManagement(rowData);
    },
    [onDeviceManagement]
  );

  const onView = useCallback((id) => {
    navigate(`${Pages.Devices}/${id}`);
  }, []);

  const onDelete = useCallback(
    (deviceId) => {
      dispatch(deleteDeviceAsync(deviceId)).then((resp) => {
        showToastAfterRequest(resp, "pages.Devices.deviceDeleted");
        setSelectedRow(null);
        ServiceProvider.EventEmitter.emit(MODAL_EVENT, {});
      });
    },
    [dispatch, showToastAfterRequest]
  );

  const handleFilterChange = (request) => {
    dispatch(
      setFilter({
        ...filter,
        ...request,
      })
    );
  };

  return (
    <div className="devices page">
      <div className="content">
        <div className="content-grid">
          {/* <AiTwotoneDashboard/> */}
          {/* <IconRenderer iconName="FaBeer" size={40} color="gold" />
          <IconRenderer iconName="AiTwotoneDashboard" size={40} color="blue" />
          <IconRenderer iconName="MdHMobiledata" size={40} color="black" /> */}
          <Title text="components.NavigationBar.devices">
            <div className="management-buttons">
              {/* <Button
                icon="plus"
                text={createTxt}
                className="button-create"
                onClick={() => onDeviceManagement({})}
              /> */}

              <Button
                text={t("pages.Devices.deviceApproval")}
                className="button-create"
                onClick={onDeviceApproval}
              />
              {/* <div>
                <button onClick={handleJudgement}>Submit Judgement</button>
              </div> */}
              {isApprovalModalVisible && (
                <ModalDeviceApprovalCustom
                title={t("pages.Devices.deviceApproval")}
                onReject={rejectApprovalModal}
                Close={rejectApprovalModal}
                onConfirm={confirmApproval}
                // status={selectedRow?.status} // Pass the status of the selected row
              >
                  <DeviceApproval ref={deviceApprovalRef} Close={
                    closeApprovalModal
                  } />
                </ModalDeviceApprovalCustom>
              )}
            </div>
          </Title>
          
          <div className="search">
            <Input
              label="Search"
              value={filter.query}
              onChange={(v) => {
                handleFilterChange({
                  query: v,
                });
              }}
            />

            <CustomDateRangePicker
              dateFrom={
                filter.startDate === "" ? undefined : new Date(filter.startDate)
              }
              dateTo={
                filter.endDate === "" ? undefined : new Date(filter.endDate)
              }
              label={t("pages.Devices.dateRange")}
              onChange={(v) => {
                handleFilterChange({
                  startDate: v[0] === null ? "" : v[0].toISOString(),
                  endDate: v[1] === null ? "" : v[1].toISOString(),
                });
              }}
            />

            <Dropdown
              items={statuses}
              defaultValue={filter.isOnline}
              label={t("pages.Devices.status")}
              valueKey="value"
              labelKey="label"
              onSelect={(v) => {
                handleFilterChange({
                  isOnline: v,
                });
              }}
            />
          </div>
          <DataTable
            ref={gridRef}
            columns={getColumns(t, onView, onEdit, onDelete)}
            data={data.results}
            isLoading={data.loading}
            onSelectionChanged={onSelectionChanged}
            pagination={{
              total: data.count,
              itemsPerPage: ITEMS_PER_PAGE,
              onChange: setCurrentPage,
            }}
          />
        </div>
        {selectedRow ? (
          selectedRow.Id && (
            <DeviceDetails
              id={selectedRow.Id}
              onView={() => onView(selectedRow.Id)}
              onDelete={() => onDelete(selectedRow.Id)}
            />
          )
        ) : (
          <div className="no-selection">
            <SvgIcon name="no-address" />
            <span>{t("pages.Devices.noDeviceSelected")}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DevicesPage;
