import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validate } from "validate.js";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import Title from "@client.components/Title";
import ProfileTabs from "@client.components/ProfileTabs";
import Password from "@client.components/Password";
import { useTranslation } from "react-i18next";
import ChangePasswordModel from "@client.models/changePassword";
import Button from "@client.core.components/Button";
import { validateModel } from "@client.utils/form";
import { changeUserPasswordAsync } from "../../reducers/Profile";
import Pages from "@client.enums/pages";
import { makeProfile } from "@client.pages/Login/reducer";
import useToast from "@client.hooks/useToast";
import "./ProfileChangePassword.scss";
import useStableParams from "@client.hooks/useStableParams";

const ProfileChangePasswordPage = () => {
  const { t } = useTranslation();
  const [model, setModel] = useState({});
  const profile = useSelector(makeProfile);
  const { showToastAfterRequest } = useToast();
  const [validationRules, setValidationRules] = useState([false, false, false]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profileId } = useStableParams();

  useEffect(() => {
    setModel(
      new ChangePasswordModel(
        {},
        {
          oldPassword: t("pages.ProfileChangePassword.validation.oldPassword"),
          newPassword: t("pages.ProfileChangePassword.validation.password"),
          passwordNotEqual: t(
            "pages.ProfileChangePassword.validation.passwordNotEqual"
          ),
        }
      )
    );
  }, [t]);

  const changeModel = useCallback(
    (v, field) => {
      setModel(
        new ChangePasswordModel({ ...model, [field]: v }, model.validationMsgs)
      );
    },
    [model]
  );

  const handlePasswordChange = (newPassword) => {
    const lengthValid = validate(
      { password: newPassword },
      {
        password: {
          length: {
            minimum: ChangePasswordModel.passwordMinLength,
            maximum: ChangePasswordModel.passwordMaxLength,
          },
        },
      }
    );

    const upperCaseValid = validate(
      { password: newPassword },
      {
        password: { format: ChangePasswordModel.passwordUppercase },
      }
    );

    const numberValid = validate(
      { password: newPassword },
      { password: { format: ChangePasswordModel.passwordNumber } }
    );

    setValidationRules([
      lengthValid === undefined,
      upperCaseValid === undefined,
      numberValid === undefined,
    ]);

    changeModel(newPassword, "newPassword");
  };

  return (
    <div className="profile-change-password-page page">
      <Title text="pages.ProfileGeneral.myAccount" />
      <ProfileTabs profileId={profileId} />
      <div className="profile-change-password-content">
        <Password
          value={model.oldPassword}
          placeholder={t("pages.ProfileChangePasswordPage.oldPassword")}
          onChange={(v) => changeModel(v, "oldPassword")}
        />
        <Password
          value={model.newPassword}
          placeholder={t("pages.ProfileChangePasswordPage.newPassword")}
          onChange={handlePasswordChange}
        />
        <Password
          value={model.confirmNewPassword}
          placeholder={t("pages.ProfileChangePasswordPage.confirmNewPassword")}
          onChange={(v) => changeModel(v, "confirmNewPassword")}
        />
        <div className="password-must">
          {t("pages.ProfileChangePasswordPage.passwordMust")}
        </div>
        <ul className="rules">
          <li className={classNames({ valid: validationRules[0] })}>
            {t("pages.ProfileChangePasswordPage.rules.passwordLength")}
          </li>
          <li className={classNames({ valid: validationRules[1] })}>
            {t("pages.ProfileChangePasswordPage.rules.passwordUpperCase")}
          </li>
          <li className={classNames({ valid: validationRules[2] })}>
            {t("pages.ProfileChangePasswordPage.rules.passwordNumber")}
          </li>
        </ul>
        <Button
          text={t("pages.ProfileChangePasswordPage.save")}
          onClick={async () => {
            const isValid = validateModel(model);
            if (isValid) {
              const resp = await dispatch(
                changeUserPasswordAsync({
                  userId: profile.lookupId,
                  password: model.newPassword,
                })
              );
              showToastAfterRequest(
                resp,
                "pages.ProfileChangePasswordPage.passwordChanged"
              ).then(() => {
                navigate(Pages.Logout);
              });
            }
          }}
        />
      </div>
    </div>
  );
};

export default ProfileChangePasswordPage;
