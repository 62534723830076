import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Card.scss";

const Card = ({ details = false, title, children, className }) => {
  return (
    <div className={classNames("details-card", className)}>
      {!details && (
        <>
          <div className="title">{title}</div>
          <div></div>
        </>
      )}

      {details && <div className="title">{title}</div>}
      {children}
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  details: PropTypes.object,
};

export default Card;
