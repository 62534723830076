import GridManagementButtons from "@client.components/GridManagementButtons";
import config from "@client.config";
import moment from "moment";
import React from "react";
import DeviceTypes from "@client.enums/deviceTypes";

export const getColumns = (t, onView, onEdit, onDelete) => [
  {
    field: "index",
    tooltipField: "index",
    headerName: "#",
    valueGetter: (params) => params.node.childIndex + 1,
    cellRenderer: (params) => (
      <div className="invoice-name clickable-cell">{params.value}</div>
    ),
    maxWidth: 80
  },

  {
    field: "Name",
    cellRenderer: (params) => (
      <div className="invoice-name clickable-cell">
        {params.data.subscriptionId}
      </div>
    ),
    tooltipField: "InvoiceName",
    headerName: t("pages.Invoices.columns.name")
    // minWidth: 250,
  },
  {
    field: "Invocie Date",
    cellRenderer: (params) => (
      <div className="invoice-name clickable-cell">
        {params.data.invoiceDate}
      </div>
    ),
    tooltipField: "InvoiceDate",
    headerName: t("pages.Invoices.columns.date")
    // minWidth: 250,
  },
  // {
  //   field: "type",
  //   headerName: t("pages.Invoices.columns.date"),
  //   cellClass: "data-table-cell",
  //   cellRenderer: (data) => {
  //     let label = "pages.Invoices.na";
  //     if (data.data.invoiceDate) {
  //       label =
  //         data.data.invoiceDate === DeviceTypes.Dynamic
  //           ? "pages.Invoices.type.dynamic"
  //           : "pages.Invoices.type.static";
  //     }
  //     return t(label);
  //   }
  // },
  {
    field: "status",
    headerName: t("pages.Invoices.columns.invoiceStatus"),
    cellClass: "data-table-cell cell-status",
    cellRenderer: (data) => {
      const statusClassName = data.data.status ? "Unpaid" : "Paid";
      const status = t(
        data.data.status
          ? "pages.Invoices.status.unpaid"
          : "pages.Invoices.status.paid"
      );
      return <span className={`status ${statusClassName}`}>{status}</span>;
    }
  },
  {
    field: "amount",
    cellRenderer: (params) => (
      <div className="invoice-name clickable-cell">{params.data.amount}</div>
    ),
    tooltipField: "InvoiceAmount",
    headerName: t("pages.Invoices.columns.amount")
    // minWidth: 250,
  },

  // {
  //   field: "amount",
  //   headerName: t("pages.Invoices.columns.amount"),
  //   cellClass: "data-table-cell",
  //   cellRenderer: (data) => {
  //     return data.data && data.data.amount
  //       ? moment(data.data.createdAt).format(config.dateTimeFormat)
  //       : t("pages.Devices.na");
  //   }
  // },
  // {
  //   field: "vpnConfiguration",
  //   headerName: t("pages.Devices.columns.vpnConfiguration"),
  //   cellClass: "data-table-cell",
  //   cellRenderer: (data) => {
  //     return data.data.vpnConfiguration
  //       ? data.data.vpnConfiguration
  //       : t("pages.Devices.na");
  //   },
  // },
  // {
  //   field: "country",
  //   headerName: t("pages.Devices.columns.country"),
  //   cellClass: "data-table-cell",
  //   cellRenderer: (data) => {
  //     return data.data.country ? data.data.country : t("pages.Devices.na");
  //   },
  // },
  {
    colId: "actions",
    headerName: "",
    cellRenderer: ({ data }) => (
      <>
        <GridManagementButtons
          item={data}
          onView={onView}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </>
    ),
    maxWidth: 70
  }
];
