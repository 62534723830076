import React from "react";

import { useSelector } from "react-redux";
import { makeProfileAccount } from "@client.pages/Login/reducer";
import CustomerType from "@client.enums/customerType";
import PlatformDashboard from "./PlatformDashboard";
import AdvertiserDashboard from "./AdvertiserDashboard";

interface IProfileAccount {
  entity: number,
  accountName: string | null,
}

const DashboardPage = () => {
  const profileAccount: IProfileAccount = useSelector(makeProfileAccount);

  return (
    <div className="page">
      {profileAccount.entity === CustomerType.Advertiser ? (
        <AdvertiserDashboard />
      ) : (
        <PlatformDashboard />
      )}
    </div>
  );
};

export default DashboardPage;
