import React, { useState, useCallback, useEffect, useRef } from "react";
import { get } from "lodash";
import classNames from "classnames";
import Title from "@client.components/Title";
import ProfileTabs from "@client.components/ProfileTabs";
import Uploader from "@client.components/Uploader";
import Input from "@client.core.components/Input";
import ProfileModel from "@client.models/profile";
import BaseUserModel from "@client.models/baseUser";
import { useTranslation } from "react-i18next";
import Button from "@client.core.components/Button";
import { useDispatch, useSelector } from "react-redux";
import { makeProfileLoading } from "./reducer";
import Loader from "@client.core.components/Loader";
import { validateModel } from "@client.utils/form";
import { getProfileAsync, makeProfile } from "@client.pages/Login/reducer";
import UserAsset from "@client.models/userAsset";
import {
  getUserByIdAsync,
  makeSelectedUser,
  updateUserAsync,
  uploadUserAssetAsync
} from "@client.pages/Users/reducer";

import useToast from "@client.hooks/useToast";
import "./ProfileGeneralEditUser.scss";
import useStableParams from "@client.hooks/useStableParams";
import moment from "moment";
import config from "@client.config";
import RoleDropdown from "@client.components/RoleDropdown";

const ProfileGeneralEditUserPage = () => {
  const { t } = useTranslation();
  const { showToastAfterRequest } = useToast();
  const dispatch = useDispatch();
  const { profileId } = useStableParams();
  const isUploading = useRef(false);

  const isProfileLoading = profileId && useSelector(makeProfileLoading);
  const profile = useSelector(makeProfile);
  const selectedUser = useSelector(makeSelectedUser);
  ///////////
  // useEffect(() => {
  //   dispatch(
  //     updateUserAsync({
  //       id: profileId, // User ID For Example
  //       fields: {
  //         avatarUrl: "",
  //         emailAddress: "updated@example2.com",
  //         firstName: "Updateds",
  //         lastName: "Name",
  //         jobTitle: "Engineer",
  //         password: "NewPassword123!",
  //         phoneNumber: "1234567890",
  //         role: 1,
  //       },
  //     })
  //   );
  // }, [dispatch]);
  /////////////////////////

  useEffect(() => {
    if (profileId) {
      dispatch(getUserByIdAsync(profileId));
      // .then((resp) => {
      //   console.log("From ProfileGeneralEditUser.js: ", resp);
      // });
    }
  }, [dispatch, profileId]);

  const [model, setModel] = useState(() => {
    let profileModel = {};

    const result = new BaseUserModel(
      { ...profileModel },
      {
        firstName: t("pages.ProfileGeneral.validation.firstName"),
        lastName: t("pages.ProfileGeneral.validation.lastName"),
        emailAddress: t("pages.ProfileGeneral.validation.emailAddress"),
        invalidEmail: t("pages.ProfileGeneral.validation.invalidEmail"),
        phoneNumber: t("pages.profileGeneral.validation.phoneNumber"),
        companyName: t("pages.ProfileGeneral.validation.companyName"),
        currencyCode: t("pages.ProfileGeneral.validation.currencyCode"),
        currencySymbol: t("pages.ProfileGeneral.validation.currencySymbol")
      }
    );

    console.log("From ProfileGeneralEditUser.js: result", result);
    return result;
  });

  const changeModel = useCallback(
    (v, field) => {
      setModel(
        new BaseUserModel({ ...model, [field]: v }, model.validationMsgs)
      );
      console.log("From ProfileGeneralEditUser.js: modelssss", model);
    },
    [model]
  );

  useEffect(() => {
    if (profileId && selectedUser) {
      setModel(
        new BaseUserModel(
          {
            ...selectedUser,
            createdAt: selectedUser.createdAt,
            lastLoginTime: selectedUser.lastLoginTime
          },
          model.validationMsgs
        )
      );
      console.log("From ProfileGeneralEditUser.js: model", model);
    }
  }, [dispatch, selectedUser, profileId]);

  const saveUser = useCallback(
    async (successMessage) => {
      if (model) {
        const isValid = validateModel(model);
        if (isValid) {
          const resp = await dispatch(
            updateUserAsync({ id: profileId, fields: model.fields })
          );
          showToastAfterRequest(
            resp,
            t(successMessage || "pages.ProfileGeneral.successSave")
          );
        }
      }
    },
    [dispatch, model, profileId, showToastAfterRequest, t]
  );

  const changeAvatar = useCallback(
    (file) => {
      if (isUploading.current) return; // Prevent multiple simultaneous uploads
      isUploading.current = true;

      const fileModel = new UserAsset({
        userLookupId: profile?.Id,
        file: file
      });

      dispatch(uploadUserAssetAsync(fileModel)).then((resp) => {
        const data = get(resp, "payload", {});
        if (data.external_id) {
          setModel(
            (prevModel) =>
              new BaseUserModel(
                {
                  ...prevModel,
                  avatarUrl: `${config.downloadUrl}/${data.external_id}`
                },
                prevModel.validationMsgs
              )
          );
          setTimeout(() => {
            saveUser("pages.ProfileGeneral.successPhotoUpload");
            isUploading.current = false;
          }, 100);
        } else {
          isUploading.current = false;
        }
      });
    },
    [dispatch, profile?.Id, saveUser]
  );

  const renderContent = () => (
    //Photo And Switch
    <div className="content">
      <div className="uploader-content">
        <Uploader placeholderUrl={model.avatarUrl} onChange={changeAvatar} />
        <div className="toggle-container">
          <label htmlFor="visibleProfie">
            {t("pages.ProfileGeneral.visible")}
          </label>
          <div className="toggle-switch">
            <input type="checkbox" id="visibleProfie" checked={false} />
            <label htmlFor="profileVisisble" className="toggle-label"></label>
          </div>
        </div>
      </div>

      <div className="user-settings-content">
        <Input
          value={model.firstName}
          onChange={(v) => changeModel(v, "firstName")}
          label={t("components.Advertisers.firstName")}
        />
        <Input
          value={model.lastName}
          onChange={(v) => changeModel(v, "lastName")}
          label={t("components.Advertisers.lastName")}
        />
        <Input
          value={model.emailAddress}
          onChange={(v) => changeModel(v, "emailAddress")}
          label={t("components.Advertisers.emailAddress")}
        />
        <Input
          value={model.phoneNumber}
          onChange={(v) => changeModel(v, "phoneNumber")}
          label={t("components.Advertisers.phoneNumber")}
        />
        <RoleDropdown
          label="Role"
          value={model.role}
          onSelect={(v) => {
            console.log("From ProfileGeneralEditUser.js: v", v);
            changeModel(v, "role");
          }}
        />

        {model.createdAt !== "" && (
          <Input
            value={moment(model.createdAt).format(config.dateTimeFormat)}
            label={t("components.Advertisers.created")}
            readOnly={true}
          />
        )}
        {model.lastLoginTime !== "" && (
          <Input
            value={moment(model.lastLoginTime).format(config.dateTimeFormat)}
            label={t("components.Advertisers.lastLoggedIn")}
            readOnly={true}
          />
        )}

        <div>
          <Button
            text={t("pages.ProfileGeneral.save")}
            onClick={async () => {
              const isValid = validateModel(model);
              if (isValid) {
                await saveUser("pages.ProfileGeneral.successSave");
              }
            }}
          />
        </div>
      </div>
    </div>
  );
  const isLoading = isProfileLoading;
  return (
    <div className="profile-general page">
      <Title text="pages.ProfileGeneral.myAccount" />
      <ProfileTabs profileId={profileId} />
      <div className={classNames({ "content-loading": isLoading })}>
        {isLoading ? <Loader loading={true} /> : renderContent()}
      </div>
    </div>
  );
};

export default ProfileGeneralEditUserPage;

{
  /*const saveUser = async () => {
    if (model) {
      const isValid = validateModel(model);
      if (isValid) {
        const resp = await dispatch(
          updateUserAsync({ id: profileId, fields: model.fields })
        );
        showToastAfterRequest(resp, t("pages.ProfileGeneral.successSave"));
      }
    }
  };*/
}
