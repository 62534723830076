import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import CampaignInfoSidebar from "./CampaignInfoSidebar/CampaignInfoSidebar";
import Loader from "@client.core.components/Loader";
import useStableParams from "@client.hooks/useStableParams";
import Countdown from "@client.components/Countdown";
import PendingMessage from "@client.components/PendingMessage"; // Import the component
// import { getCampaignByIdAsync, makeSelectedCampaign } from "./reducer";
import { getCampaignByIdAsync, makeSelectedCampaign, getCampaignDetailsAsync, makeSelectedCampaignDetails } from "../../reducers/Campaign";
import CampaignInnerTabs, { AdvertiserTabsEnum } from "./CampaignInnerTabs/CampaignInnerTabs";
import Overview from "./CampaignInnerTabs/Overview/Overview";
import Insights from "./CampaignInnerTabs/Insights/Insights";
import CampaignStatuses from "@client.enums/campaignStatuses";
import ArchivedMessage from "../../components/Messages/ArchivedMessage";
import PausedMessage from "../../components/Messages/PausedMessage";
import EndedMessage from "../../components/Messages/EndedMessage";

const CampaignPage = () => {
  const context = useOutletContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedCampaign = useSelector(makeSelectedCampaign);
  const campaignDetails = useSelector(makeSelectedCampaignDetails);
  // console.log("From CampaignPage.jssssssssssssssssssssssssssssssssssssssssssssss ", campaignDetails);
  const { campaign } = selectedCampaign;
  const { advertiserId, id } = useStableParams();
  const { t } = useTranslation();

  const [endDate, setEndDate] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [selectedTab, setSelectedTab] = useState(AdvertiserTabsEnum.Statistics); // Default tab
  const currentDate = new Date();

  const explanationBox = () => {
    if (
      campaign.status === CampaignStatuses.Archive ||
      campaign.status === CampaignStatuses.Paused ||
      campaign.status === CampaignStatuses.Ended
    ) {
      let MessageComponent;
      switch (campaign.status) {
        case CampaignStatuses.Archive:
          MessageComponent = ArchivedMessage;
          break;
        case CampaignStatuses.Paused:
          MessageComponent = PausedMessage;
          break;
        case CampaignStatuses.Ended:
          MessageComponent = EndedMessage;
          break;
        default:
          return null;
      }
  
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f0f0f0", // Light gray background
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
            width: "40%", // Adjust width as per requirement
            // margin: "0 auto", // Centers the container horizontally
            marginTop: "0",
            marginBottom: "30px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <MessageComponent campaign={campaign} />
        </div>
      );
    }
    return null;
  };
  
  
  useEffect(() => {
    dispatch(getCampaignDetailsAsync(id));
    if (!campaign || campaign.Id !== id) {
      dispatch(getCampaignByIdAsync(id));
    }
    const campaignStart = new Date(campaign?.startDate);
    const campaignEnd = new Date(campaign?.endDate);
    setEndDate(campaignEnd < currentDate);
    setStartDate(currentDate >= campaignStart);
  }, [dispatch, campaign, id]);

  useEffect(() => {
    context.setShowAdvertiser(true);

    return () => {
      context.setShowAdvertiser(false);
      context.setNavigationLink(null);
    };
  }, [context]);

  if (selectedCampaign.loading) {
    return (
      <div className="campaign page-loading">
        <Loader loading={true} />
      </div>
    );
  }

  if (!campaign) {
    return (
      <div className="campaign not-found">
        {t("pages.Campaign.campaignNotFound")}
      </div>
    );
  }

  const renderTabContent = () => {
    if (selectedTab === AdvertiserTabsEnum.Statistics) {
      return (
        <>
          {/* { console.log("From CampaignPage.js: ", campaign)} */}
          {campaign.status === CampaignStatuses.PendingApproval && (
            <div className="pending-message-container">
              <PendingMessage campaign={campaign} />
            </div>
          )}
          {campaign &&
            !startDate &&
            !endDate &&
            campaign.status === CampaignStatuses.Queued && (
              <div className="countdown-container">
                <Countdown campaign={campaign} />
              </div>
            )}
          {explanationBox()}

          {(campaign.status === CampaignStatuses.Paused
            || campaign.status === CampaignStatuses.Active
            || campaign.status === CampaignStatuses.Archive
            || campaign.status === CampaignStatuses.Paused
            || campaign.status === CampaignStatuses.Ended
          ) 
          
          && (
            <Overview campaign={campaign} campaignId={id} />
          )}
        </>
      );
    }

    if (selectedTab === AdvertiserTabsEnum.Insights) {
      return <Insights campaign={campaign} />;
    }

    return null;
  };

  return (
    <div className="campaign">
      <div className="campaign-container">
          <CampaignInfoSidebar model={campaign} estimation={null}  infoData={campaignDetails}  />
        <div className="campaign-content">
          <div className="tabs-container">
            <CampaignInnerTabs value={selectedTab} onSelect={setSelectedTab}/>
          </div>
          <div className="tab-content">{renderTabContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default CampaignPage;
