import React from "react";
import CustomZoneManagement from "../../components/Zones/components/CustomZoneManagement";
import ZonesTree from "../../components/Zones/components/ZonesTree";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  makeOkPressed,
  makeIsCompleted,
} from "../../reducers/Campaign";


const Zones = ({
  zones,
  selectedZone,
  onZoneSelect,
  isEditable,
  allowDrawing,
  isPricePerViewEditable,
}) => {
    const dispatch = useDispatch();
    const okPressed = useSelector(makeOkPressed);
    const isCompleted = useSelector(makeIsCompleted);

  return (
    <>
      {!okPressed&&!isCompleted&&<ZonesTree
        zones={zones || []}
        isLoading={zones.loading}
        isEditable={isEditable}
        selectedZone={selectedZone}
        onZoneSelect={onZoneSelect}
      />}
      
      <CustomZoneManagement
        selectedZone={selectedZone}
        allowDrawing={allowDrawing}
        isPricePerViewEditable={isPricePerViewEditable}
        onReset={() => onZoneSelect(null)}
      />
    </>
  );
};

Zones.propTypes = {
  zones: PropTypes.array,
  selectedZone: PropTypes.object,
  onZoneSelect: PropTypes.func,
  isEditable: PropTypes.bool,
  allowDrawing: PropTypes.bool,
  isPricePerViewEditable: PropTypes.bool,
};

export default Zones;
