import React, { useCallback, forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Input from "@client.core.components/Input";
import { useTranslation } from "react-i18next";
import { validateModel } from "@client.utils/form";
import InvoiceModel from "@client.models/invoice";
//import "./DeviceManagement.scss";
import InvoiceTypeDropDown from "@client.components/InvoiceTypeDropDown/InvoiceTypeDropDown";
import "./InvoiceForm.scss";

const InvoiceForm = forwardRef(({ invoice }, ref) => {
  console.log("invoices object", invoice);
  const { t } = useTranslation();
  const [model, setModel] = useState(new InvoiceModel());

  useEffect(() => {
    setModel(
      new InvoiceModel(
        { ...invoice },
        {
          subscriptionId: t("pages.Invoices.validation.subscriptionId"),
          invoiceDate: t("pages.Invoices.validation.invoiceDate"),
          dueDate: t("pages.Invoices.validation.dueDate"),
          amount: t("pages.Invoices.validation.amount"),
          status: t("pages.Invoices.validation.status")
        }
      )
    );
  }, [t, invoice]);

  // Expose the model and validation to parent components
  React.useImperativeHandle(ref, () => ({
    managementItem: model,
    validate: () => {
      const isValid = validateModel(model, model.validationRules);
      return isValid;
    }
  }));

  // Update model fields as user inputs data
  const changeModel = useCallback(
    (v, field) => {
      setModel(
        new InvoiceModel({ ...model, [field]: v }, model.validationMsgs)
      );
    },
    [model]
  );

  return (
    <div className="create-invoice" ref={ref}>
      <Input
        value={model.subscriptionId}
        onChange={(v) => changeModel(v, "subscriptionId")}
        label={t("pages.Invoices.columns.subscriptionId")}
      />
      <Input
        value={model.invoiceDate}
        onChange={(v) => changeModel(v, "invoiceDate")}
        label={t("pages.Invoices.columns.invoiceDate")}
      />
      <Input
        value={model.dueDate}
        onChange={(v) => changeModel(v, "dueDate")}
        label={t("pages.Invoices.columns.dueDate")}
      />
      <Input
        value={model.amount}
        onChange={(v) => changeModel(parseFloat(v), "amount")}
        label={t("pages.Invoices.columns.amount")}
      />
      <InvoiceTypeDropDown
        value={model.status}
        onSelect={(v) => changeModel(v, "status")}
        label={t("pages.Invoices.columns.status")}
      />
    </div>
  );
});

InvoiceForm.displayName = "InvoiceForm";

InvoiceForm.defaultProps = {
  invoice: {}
};

InvoiceForm.propTypes = {
  invoice: PropTypes.object
};

export default InvoiceForm;
