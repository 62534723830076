import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { AuthProvider } from "@client.hooks/useAuth";
import { getStore } from "./store";
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={getStore()}>
    <BrowserRouter>
      <AuthProvider>
        <Routes />
        <div id="root-portal"></div>
      </AuthProvider>
    </BrowserRouter>
  </Provider>
);
