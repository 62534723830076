import React, { useCallback, forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Input from "@client.core.components/Input";
import { useTranslation } from "react-i18next";
import { validateModel } from "@client.utils/form";
import SubscriptionModel from "@client.models/subscriptionModel";
//import "./DeviceManagement.scss";
import SubsTypeDropDown from "@client.components/SubsTypeDropDown/SubsTypeDropDown";
import "./SubsForm.scss";

const SubsForm = forwardRef(({ sub }, ref) => {
  //console.log("invoices object", invoice);
  const { t } = useTranslation();
  const [model, setModel] = useState(new SubscriptionModel()); //

  useEffect(() => {
    setModel(
      new SubscriptionModel(
        { ...sub },
        {
          planId: t("pages.subscription.validation.subscriptionId"),
          startDate: t("pages.subscription.validation.invoiceDate"),
          endDate: t("pages.subscription.validation.dueDate"),
          status: t("pages.subscription.validation.amount"),
          userId: t("pages.subscription.validation.status"),
          updatedAt: t("pages.subscription.validation.updatedAt"),
          isActive: t("pages.subscription.validation.isActive"),
          createdAt: t("pages.subscription.validation.createdAt")
        }
      )
    );
  }, [t, sub]);

  // Expose the model and validation to parent components
  React.useImperativeHandle(ref, () => ({
    managementItem: model,
    validate: () => {
      const isValid = validateModel(model, model.validationRules);
      return isValid;
    }
  }));

  // Update model fields as user inputs data
  const changeModel = useCallback(
    (v, field) => {
      setModel(
        new SubscriptionModel({ ...model, [field]: v }, model.validationMsgs)
      );
    },
    [model]
  );

  return (
    <div className="create-subscription" ref={ref}>
      <Input
        value={model.planId}
        onChange={(v) => changeModel(v, "planId")}
        label={t("pages.subscription.columns.planId")}
      />
      <Input
        value={model.startDate}
        onChange={(v) => changeModel(v, "startDate")}
        label={t("pages.subscription.columns.startDate")}
      />
      <Input
        value={model.endDate}
        onChange={(v) => changeModel(v, "endDate")}
        label={t("pages.subscription.columns.endDate")}
      />
      <Input
        value={model.userId}
        onChange={(v) => changeModel(parseFloat(v), "userId")}
        label={t("pages.subscription.columns.userId")}
      />
      <Input
        value={model.updatedAt}
        onChange={(v) => changeModel(parseFloat(v), "updatedAt")}
        label={t("pages.subscription.columns.updatedAt")}
      />
      <Input
        value={model.isActive}
        onChange={(v) => changeModel(parseFloat(v), "isActive")}
        label={t("pages.subscription.columns.isActive")}
      />
      <Input
        value={model.createdAt}
        onChange={(v) => changeModel(parseFloat(v), "createdAt")}
        label={t("pages.subscription.columns.createdAt")}
      />
      <SubsTypeDropDown
        value={model.status}
        onSelect={(v) => changeModel(v, "status")}
        label={t("pages.subscription.columns.status")}
      />
    </div>
  );
});

SubsForm.displayName = "SubscriptionsForm";

SubsForm.defaultProps = {
  sub: {}
};

SubsForm.propTypes = {
  sub: PropTypes.object
};

export default SubsForm;