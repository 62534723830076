import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import NoDataPlaceholder from "@client.core.components/NoDataPlaceholder";
import TimelineItem from "./TimelineItem";
import MediaFile from "@client.components/MediaFile";

const Viewer = ({ data, onSelect }) => {
  const [selectedFrameIds, setSelectedFrameIds] = useState([]);
  const [itemsToShow, setItemsToShow] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  // Filter items to show
  useEffect(() => {
    const newItems = data.map((d) => ({
      ...d,
      // afterMediaUrl: d.afterMediaUrl || "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4",
      afterMediaUrl: d.campaignMediaUrl || "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4",
      // afterMediaUrl: d.afterMediaUrl || "https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4",
      // afterMediaUrl: "https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4" || "https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4",
      // afterMediaUrl:
      //   "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4" ||
      //   "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4"
        
    }));
    // console.log("From Viewer new item", newItems);
    setItemsToShow(newItems);
  }, [data]);

  // Update selected items
  useEffect(() => {
    const selectedItems = itemsToShow.filter((d) =>
      selectedFrameIds.includes(d.Id)
    );
    onSelect(selectedItems);
  }, [selectedFrameIds, itemsToShow, onSelect]);

  const getMediaFileSource = () => {
    const file = itemsToShow.find(
      (d) => selectedFrameIds[currentVideoIndex] === d.Id
    );
    return file?.campaignMediaUrl || "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4";
    // return file?.afterMediaUrl || "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4";
    // return file?.afterMediaUrl || "https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4";
    // return "https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4"|| "https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4";
    // return (
    //   "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4" ||
    //   "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4"
    // );
  };

  const handleItemClick = (id) => {
    setSelectedFrameIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((frameId) => frameId !== id)
        : [...prevSelected, id]
    );
    setCurrentVideoIndex(0);
  };

  const handleVideoEnded = () => {
    if (currentVideoIndex < selectedFrameIds.length - 1) {
      setCurrentVideoIndex((prevIndex) => prevIndex + 1);
    } else {
      setCurrentVideoIndex(0);
    }
  };

  return (
    <div className="data-analyzer-viewer">
      <div className="data-analyzer-viewer-block">
        {data.length === 0 && (
          <NoDataPlaceholder description="pages.DataAnalyzer.capturesNoData" />
        )}
        {data.length > 0 && selectedFrameIds.length === 0 && (
          <NoDataPlaceholder description="pages.DataAnalyzer.selectFrame" />
        )}
        {selectedFrameIds.length > 0 && (
          <div className="video-container">
            <MediaFile
              loop={false}
              src={getMediaFileSource()}
              // src={
                // "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4"
              // }
              controls
              onEnded={handleVideoEnded}
            />
          </div>
        )}
      </div>
      {itemsToShow.length > 0 && (
        <div className="timeline-container">
          <div className="timeline-slider">
            {itemsToShow.map((item) => (
              <TimelineItem
                item={item}
                key={item.Id}
                className={
                  selectedFrameIds.includes(item.Id) ? "selected" : null
                }
                onClick={
                  item.status === 5 // Restrict clicks to "Processed" items only
                    ? () => handleItemClick(item.Id)
                    : null
                }
                disabled={item.status !== 5}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

Viewer.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.string.isRequired,
      afterMediaUrl: PropTypes.string,
      status: PropTypes.number.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Viewer;

// import PropTypes from "prop-types";
// import React, { useEffect, useState } from "react";
// import NoDataPlaceholder from "@client.core.components/NoDataPlaceholder";
// import TimelineItem from "./TimelineItem";
// import MediaFile from "@client.components/MediaFile";

// const Viewer = ({ data, onSelect }) => {
//   const [selectedFrameIds, setSelectedFrameIds] = useState([]);
//   const [itemsToShow, setItemsToShow] = useState([]);
//   const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

//   // Filter items to show based on valid `afterMediaUrl`
//   useEffect(() => {
//     const newItems = data.map((d) => ({
//       ...d,
//       afterMediaUrl: d.afterMediaUrl || "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4",
//       // afterMediaUrl: d.afterMediaUrl || "https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4",
//     }));
//     setItemsToShow(newItems);
//   }, [data]);

//   // Update selected items
//   useEffect(() => {
//     const selectedItems = itemsToShow.filter((d) =>
//       selectedFrameIds.includes(d.Id)
//     );
//     onSelect(selectedItems);
//   }, [selectedFrameIds, itemsToShow, onSelect]);

//   const getMediaFileSource = () => {
//     const file = itemsToShow.find((d) => selectedFrameIds[currentVideoIndex] === d.Id);
//     // return file?.afterMediaUrl || "https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4";
//     return file?.afterMediaUrl || "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4";
//   };

//   const handleItemClick = (id) => {

//     setSelectedFrameIds((prevSelected) =>
//       prevSelected.includes(id)
//         ? prevSelected.filter((frameId) => frameId !== id)
//         : [...prevSelected, id]
//     );
//     setCurrentVideoIndex(0);
//   };

//   const handleVideoEnded = () => {
//     if (currentVideoIndex < selectedFrameIds.length - 1) {
//       setCurrentVideoIndex((prevIndex) => prevIndex + 1);
//     } else {
//       setCurrentVideoIndex(0);
//     }
//   };

//   return (
//     <div className="data-analyzer-viewer">
//       <div className="data-analyzer-viewer-block">
//         {data.length === 0 && (
//           <NoDataPlaceholder description="pages.DataAnalyzer.capturesNoData" />
//         )}
//         {data.length > 0 && selectedFrameIds.length === 0 && (
//           <NoDataPlaceholder description="pages.DataAnalyzer.selectFrame" />
//         )}
//         {selectedFrameIds.length > 0 && (
//           <div className="video-container">
//             <MediaFile
//               loop={false}
//               src={getMediaFileSource()}
//               controls
//               onEnded={handleVideoEnded}
//             />
//           </div>
//         )}
//       </div>
//       {itemsToShow.length > 0 && (
//         <div className="timeline-container">
//           <div className="timeline-slider">
//             {itemsToShow.map((item) => (
//               <TimelineItem
//                 item={item}
//                 key={item.Id}
//                 className={
//                   selectedFrameIds.includes(item.Id) ? "selected" : null
//                 }
//                 onClick={
//                   item.status === 5 // Allow click only if status is "Processed"
//                     ? () => {
//                       console.log("From Viewer item Id", item);
//                       handleItemClick(item.Id)}
//                     : null
//                 }
//                 disabled={item.status !== 5}
//               />
//             ))}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// Viewer.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       Id: PropTypes.string.isRequired,
//       afterMediaUrl: PropTypes.string,
//       status: PropTypes.number.isRequired, // Added status as a required prop
//       startDate: PropTypes.string.isRequired,
//       endDate: PropTypes.string.isRequired,
//     })
//   ).isRequired,
//   onSelect: PropTypes.func.isRequired,
// };

// export default Viewer;

// import PropTypes from "prop-types";
// import React, { useEffect, useState } from "react";
// import NoDataPlaceholder from "@client.core.components/NoDataPlaceholder";
// import TimelineItem from "./TimelineItem";
// import MediaFile from "@client.components/MediaFile";

// const Viewer = ({ data, onSelect }) => {
//   const [selectedFrameIds, setSelectedFrameIds] = useState([]);
//   const [itemsToShow, setItemsToShow] = useState([]);
//   const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

//   // Filter items to show based on `processed`, `unprocessed`, or `afterMediaUrl`
//   useEffect(() => {
//     const newItems = data.filter((d) => d.afterMediaUrl===""?"https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4" : d.afterMediaUrl);
//     // const newItems = data.filter((d) => d.afterMediaUrl===""?"https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4" : d.afterMediaUrl);
//     // console.log("From Viewer", data);
//     setItemsToShow(newItems);
//   }, [data]);

//   // Update selected items
//   useEffect(() => {
//     const selectedItems = itemsToShow.filter((d) =>
//       selectedFrameIds.includes(d.Id)
//     );
//     onSelect(selectedItems);
//   }, [selectedFrameIds, itemsToShow, onSelect]);

//   const getMediaFileSource = () => {
//     const file = itemsToShow.find((d) => selectedFrameIds[currentVideoIndex] === d.Id);
//     console.log("From Viewer file", file);
//     return file.afterMediaUrl==="" ? "https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4":file.afterMediaUrl;
//     // return file.afterMediaUrl==="" ? "https://core-ams-01.kaligon.com:60000/api/download/674f587e87fc1969cc2057a4":file.afterMediaUrl;
//   };

//   const handleItemClick = (id) => {
//     setSelectedFrameIds((prevSelected) =>
//       prevSelected.includes(id)
//         ? prevSelected.filter((frameId) => frameId !== id)
//         : [...prevSelected, id]
//     );
//     setCurrentVideoIndex(0);
//   };

//   const handleVideoEnded = () => {
//     if (currentVideoIndex < selectedFrameIds.length - 1) {
//       setCurrentVideoIndex((prevIndex) => prevIndex + 1);
//     } else {
//       setCurrentVideoIndex(0);
//     }
//   };

//   return (
//     <div className="data-analyzer-viewer">
//       <div className="data-analyzer-viewer-block">
//         {data.length === 0 && (
//           <NoDataPlaceholder description="pages.DataAnalyzer.capturesNoData" />
//         )}
//         {data.length > 0 && selectedFrameIds.length === 0 && (
//           <NoDataPlaceholder description="pages.DataAnalyzer.selectFrame" />
//         )}
//         {selectedFrameIds.length > 0 && (
//           <div className="video-container">
//             <MediaFile
//               loop={false}
//               src={getMediaFileSource()}
//               controls
//               onEnded={handleVideoEnded}
//             />
//           </div>
//         )}
//       </div>
//       {itemsToShow.length > 0 && (
//         <div className="timeline-container">
//           <div className="timeline-slider">
//             {itemsToShow.map((item) => (
//               <TimelineItem
//                 item={item}
//                 key={item.Id}
//                 className={
//                   selectedFrameIds.includes(item.Id) ? "selected" : null
//                 }
//                 onClick={() => {
//                   console.log("From Viewer itemmmmmmmmmmmmmmmmmmmmmmmmmmm", item);
//                   handleItemClick(item.Id)}}
//               />
//             ))}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// Viewer.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       Id: PropTypes.string.isRequired,
//       afterMediaUrl: PropTypes.string,
//       processed: PropTypes.string,
//       unprocessed: PropTypes.string,
//       startDate: PropTypes.string.isRequired,
//       endDate: PropTypes.string.isRequired,
//     })
//   ).isRequired,
//   onSelect: PropTypes.func.isRequired,
// };

// export default Viewer;

// import PropTypes from "prop-types";
// import DeviceCaptureStatuses from "@client.enums/deviceCaptureStatuses";
// import React, { useEffect, useState } from "react";
// import NoDataPlaceholder from "@client.core.components/NoDataPlaceholder";
// import TimelineItem from "./TimelineItem";
// import MediaFile from "@client.components/MediaFile";
// import Dropdown from "@client.core.components/Dropdown";

// const Viewer = ({ data, onSelect }) => {
//   const [status, setStatus] = useState(DeviceCaptureStatuses.Processed);
//   const [selectedFrameIds, setSelectedFrameIds] = useState([]);
//   const [itemsToShow, setItemsToShow] = useState([]);
//   const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

//   useEffect(() => {
//     const selectedItems = itemsToShow.filter((d) =>
//       selectedFrameIds.includes(d.lookupId)
//     );
//     onSelect(selectedItems);
//   }, [selectedFrameIds, itemsToShow]);

//   useEffect(() => {
//     const newItems = data.filter((d) => {
//       if (status === DeviceCaptureStatuses.Processed && d.processed) {
//         return true;
//       } else if (status === DeviceCaptureStatuses.Processed && d.unprocessed) {
//         return true;
//       }

//       return false;
//     });
//     setItemsToShow(newItems);
//   }, [status, data]);

//   const statusOptions = [
//     {
//       id: DeviceCaptureStatuses.NotProcessed,
//       name: "Not Processed",
//     },
//     {
//       id: DeviceCaptureStatuses.Processed,
//       name: "Processed",
//     },
//   ];

//   const getMediaFileSource = () => {
//     const file = data.find(
//       (d) => selectedFrameIds[currentVideoIndex] === d.lookupId
//     );
//     console.log("From Viewer file", file);
//     return file ? file.processed || file.unprocessed : null;
//   };

//   const handleItemClick = (id) => {
//     setSelectedFrameIds((prevSelected) =>
//       prevSelected.includes(id)
//         ? prevSelected.filter((frameId) => frameId !== id)
//         : [...prevSelected, id]
//     );
//     console.log("From Viewer selectedFrameIds", selectedFrameIds);
//     setCurrentVideoIndex(0);
//   };

//   const handleVideoEnded = () => {
//     if (currentVideoIndex < selectedFrameIds.length - 1) {
//       const nextVideoId = currentVideoIndex + 1;
//       setCurrentVideoIndex(nextVideoId);
//     } else {
//       setCurrentVideoIndex(0);
//     }
//   };

//   return (
//     <div className="data-analyzer-viewer">
//       <div className="data-analyzer-viewer-block">
//         {data.length > 0 && (
//           <Dropdown
//             items={statusOptions}
//             valueKey="id"
//             labelKey="name"
//             label={"Status"}
//             defaultValue={status}
//             onPrepareValue={(v) => parseInt(v)}
//             onSelect={(s) => {
//               setSelectedFrameIds([]);
//               setStatus(s);
//             }}
//           />
//         )}

//         {data.length === 0 && (
//           <NoDataPlaceholder description="pages.DataAnalyzer.capturesNoData" />
//         )}
//         {data.length > 0 && selectedFrameIds.length === 0 && (
//           <NoDataPlaceholder description="pages.DataAnalyzer.selectFrame" />
//         )}
//         {selectedFrameIds.length > 0 && (
//           <div className="video-container">
//             <MediaFile
//               loop={false}
//               src={getMediaFileSource()}
//               controls
//               onEnded={handleVideoEnded}
//             />
//           </div>
//         )}
//       </div>
//       {itemsToShow.length > 0 && (
//         <div className="timeline-container">
//           <div className="timeline-slider">
//             {itemsToShow.map((item) => (
//               <TimelineItem
//                 item={item}
//                 key={item.lookupId}
//                 className={
//                   selectedFrameIds.includes(item.lookupId) ? "selected" : null
//                 }
//                 onClick={() => handleItemClick(item.lookupId)}
//               />
//             ))}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// Viewer.propTypes = {
//   data: PropTypes.array,
//   onSelect: PropTypes.func,
// };

// export default Viewer;
