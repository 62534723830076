import React, { useEffect } from "react";
import Title from "@client.components/Title";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllLocationsAsync,
  makeSelectedZone,
  makeZones,
  setSelectedZone,
} from "./reducer";
import "./Zones.scss";
// import Zones from "@client.components/Zones";
import Zones from "../../components/Zones";

const ZonesPage = () => {
  const selectedZone = useSelector(makeSelectedZone);
  const zones = useSelector(makeZones);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllLocationsAsync());
  }, []);

  return (
    <div className="zones-page">
      <Title text="components.NavigationBar.locations" />
      <div className="content">
        <Zones
          selectedZone={selectedZone}
          zones={zones}
          isEditable={true}
          allowDrawing={true}
          isPricePerViewEditable={true}
          onZoneSelect={(zone) => dispatch(setSelectedZone(zone))}
        />
      </div>
    </div>
  );
};

export default ZonesPage;
