import BaseModel from "./base";
import {get} from "lodash"
class ProfileModel extends BaseModel {
  constructor(user = {}, msg) {
    super(msg);

    this.firstName = get(user, "firstName", "");
    this.lastName = get(user, "lastName", "");
    this.emailAddress = get(user, "emailAddress", "");
    this.phoneNumber = get(user, "phoneNumber", "");
    this.avatarUrl = get(user, "avatarUrl", "");

    this.validationMsgs = msg;
    this.validationRules = {
      firstName: {
        presence: { allowEmpty: false, message: msg.firstName },
      },
      lastName: {
        presence: { allowEmpty: false, message: msg.lastName },
      },
      emailAddress: {
        presence: { allowEmpty: false, message: msg.emailAddress },
        email: { message: msg.invalidEmail },
      },
      phoneNumber: {
        presence: { allowEmpty: false, message: msg.phoneNumber },
      },
    };
  }

  get fields() {
    return {
      emailAddress: this.emailAddress,
      lastName: this.lastName,
      firstName: this.firstName,
      avatarUrl: this.avatarUrl,
      phoneNumber: this.phoneNumber,
    };
  }
}
export default ProfileModel