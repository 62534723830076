import React, { useEffect, useState, useRef } from "react";
import AccountTabs, { AccountTabsEnum } from "./AccountTabs";
import { useTranslation } from "react-i18next";
import NoDataPlaceholder from "@client.core.components/NoDataPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectedAccount,
  setSelectedAccount,
  getAllAccountsAsync,
  makeAccountHierarchy,
} from "./reducer";
import {
  getAllAdvertisersAsync,
  makeAdvertisers,
  makeAdvertisersLoading,
  setAdvertiserFilters,
} from "@client.pages/Advertiser/reducer";
import Loader from "@client.core.components/Loader";
import DetailsTab from "./tabs/Details";
import "./Accounts.scss";
import DevicesTab from "./tabs/Devices";
import UsersTab from "./tabs/Users";
import WalletTab from "./tabs/Wallet";
import Button from "@client.core.components/Button";
import ModalAccountsApprovalCustom from "../../components/ModalAccountsApprovalCustom/ModalAccountsApprovalCustom";
import CircleIcon from "@client.components/CircleIcon";
import CreateAdvertiser from "./CreateAdvertiser";
import { getGraphqlResponseError } from "@client.utils/error";
import DataTablePagination from "@client.components/Pagination/Pagination";
import Input from "@client.core.components/Input";
import CustomDateRangePicker from "@client.components/CustomDateRangePicker";



const AccountsPage = () => {
  const [selectedTab, setSelectedTab] = useState(AccountTabsEnum.Details);
  const [isModalVisible, setModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;
  const modalRef = useRef(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const advertisers = useSelector(makeAdvertisers);
  // const isLoading = useSelector(makeAdvertisersLoading);
  const selectedAccount = useSelector(makeSelectedAccount);
  // const accounts = useSelector(makeAccountHierarchy);
 
 
  


  const [filters, setFilter] = useState({
    query: "",
    startDate: "",
    endDate: "",
    searchOn: "",
  });

  useEffect(() => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;
    dispatch(
      getAllAccountsAsync({
        limit: ITEMS_PER_PAGE,
        offset,
      })
    );
  }, [dispatch, currentPage]);

  useEffect(() => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;
    dispatch(
      getAllAdvertisersAsync({
        limit: ITEMS_PER_PAGE,
        offset,
        search: filters.query,
        searchOn: "company_name,first_name,last_name",
        startDate: filters.startDate,
        endDate: filters.endDate,
      })
    );
  }, [dispatch, currentPage, filters]);

  const handleFilterChange = (newFilters) => {
    setFilter({
      ...filters,
      ...newFilters,
    });
    dispatch(setAdvertiserFilters(newFilters));
  };

  const handleAccountSelect = (acc) => {
    dispatch(setSelectedAccount(acc));
  };

  const renderContent = () => {
    if (!selectedAccount) {
      return <NoDataPlaceholder description={t("pages.Accounts.noData")} />;
    }
    switch (selectedTab) {
      case AccountTabsEnum.Details:
        return <DetailsTab />;
      case AccountTabsEnum.Wallet:
        return <WalletTab />;
      case AccountTabsEnum.Devices:
        return <DevicesTab />;
      case AccountTabsEnum.Users:
        return <UsersTab />;
      default:
        return null;
    }
  };

  return (
    <div className="accounts page">
      <div className="page-title">
        <span className="title">{t("pages.AccountsPage.title")}</span>
      </div>

      <div className="accounts-content">
        <div className="accounts-hierarchy">
          <div className="search">
            <Input
              label="Search"
              value={filters.query}
              onChange={(e) => handleFilterChange({ query: e })}
            />
       
            <CustomDateRangePicker
              dateFrom={
                filters.startDate === "" ? undefined : new Date(filters.startDate)
              }
              dateTo={
                filters.endDate === "" ? undefined : new Date(filters.endDate)
              }
              label={t("pages.Devices.dateRange")}
              onChange={(v) => {
                handleFilterChange({
                  startDate: v[0] === null ? "" : v[0].toISOString(),
                  endDate: v[1] === null ? "" : v[1].toISOString(),
                });
              }}
            />
          </div>

          <Button
            icon="plus"
            className="create-account"
            text={t("components.Advertisers.createAccount")}
            onClick={() => setModalVisible(true)}
          />
          <div className="accounts-list">
            {advertisers
              .slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                currentPage * ITEMS_PER_PAGE
              )
              .map((account, index) => (
                <div
                  key={account.Id}
                  className={`account-item ${
                    selectedAccount?.Id === account.Id
                      ? "account-item--selected"
                      : ""
                  }`}
                  onClick={() => handleAccountSelect(account)}
                >
                  <CircleIcon name={account.companyName} index={index} />
                  {account.companyName}
                </div>
              ))}
          </div>
          <div className="pagination">
            <DataTablePagination
              total={advertisers.length}
              // total={accounts.hierarchy.length}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onChange={setCurrentPage}
            />
          </div>
        </div>
        <div className="tab-content">
          {selectedAccount && <AccountTabs onSelect={setSelectedTab} />}
          {renderContent()}</div>
      </div>

      {isModalVisible && (
        <ModalAccountsApprovalCustom
          title={t("components.Advertisers.createAccount")}
          Close={() => setModalVisible(false)}
          onSave={() => {
            const isValid = modalRef.current?.validate();
            if (isValid) {
              dispatch(
                createAdvertiserAsync(modalRef.current.managementItem)
              ).then((resp) => {
                const error = getGraphqlResponseError(resp);
                if (!error) {
                  setModalVisible(false);
                  dispatch(getAllAdvertisersAsync());
                }
              });
            }
          }}
        >
          <CreateAdvertiser ref={modalRef} />
        </ModalAccountsApprovalCustom>
      )}
    </div>
  );
};

export default AccountsPage;





