import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "@client.core.components/Loader";
import Icon from "@client.core.components/Icon";
import ServiceProvider from "@client.services/provider";
import { MODAL_EVENT } from "@client.components/Modal";
import DeleteInvoiceConfirmation from "./DeleteInvoiceConfirmation";
import { makeInvoiceInfo, getInvoiceByIdAsync } from "./reducer";
import "./InvoiceDetails.scss";

const InvoiceDetails = ({ id, onView, onDelete }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedInvoice = useSelector(makeInvoiceInfo);

  useEffect(() => {
    dispatch(getInvoiceByIdAsync(id));
  }, [dispatch, id]);

  if (selectedInvoice.loading) {
    return (
      <div className="invoice-details invoice-details-loading">
        <Loader loading={true} />
      </div>
    );
  }

  const renderInfo = (field, value) => {
    return (
      <div className="card">
        <h2 className="card-title">{t(field)}</h2>
        <p className="card-description">{value}</p>
      </div>
    );
  };

  const { invoice } = selectedInvoice;
  // console.log("InvoiceDetails -> invoice", invoice);
  if (invoice === null) {
    return null;
  }

  return (
    <div className="invoice-details">
      <div className="invoice-details-title">
        {t("pages.Invoice.invoiceDetails")}
        <div className="management-butotns">
          <div className="btn" onClick={onView}>
            <Icon name="eye" />
          </div>
          <div className="btn">
            <Icon
              name="delete"
              onClick={() => {
                ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
                  show: true,
                  title: t("components.DeleteInvoiceConfirmation.title"),
                  component: (
                    <DeleteInvoiceConfirmation
                      onDelete={onDelete}
                      onCancel={() =>
                        ServiceProvider.EventEmitter.emit(MODAL_EVENT, {})
                      }
                    />
                  )
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="invoice-info-grid">
        {renderInfo("pages.Invoices.Details.id", invoice.Id)}
        {renderInfo(
          "pages.Invoices.Details.subsctiptionId",
          invoice.deviceName
        )}
        {renderInfo(
          "pages.Invoices.Details.date",
          invoice.invoiceDate || "Date Was Not Found"
        )}
        {renderInfo(
          "pages.Invoices.Details.duedate",
          invoice.dueDate || "Due Date Was Not Found"
        )}
        {renderInfo("pages.Invoices.Details.amount", invoice.amount)}
        {renderInfo(
          "pages.Invoices.Details.status",
          invoice.status || "Status Unavailable"
        )}
      </div>
    </div>
  );
};

InvoiceDetails.defaultProps = {
  showMap: true
};

InvoiceDetails.propTypes = {
  id: PropTypes.any,
  showMap: PropTypes.bool,
  onView: PropTypes.func,
  onDelete: PropTypes.func
};

export default InvoiceDetails;
