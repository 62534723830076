
export const renderEndDate = (endDate, currentTime) => {
    const endDateObject = new Date(endDate);

    if (!endDateObject) {
      return false;
    }

    const diffInMs = endDateObject - currentTime;

    const daysAgo = Math.floor((currentTime - endDateObject) / (1000 * 60 * 60 * 24));

    if (diffInMs <= 0) {
      return `Ended ${daysAgo} days ago`
    }

    const daysLeft = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    if (daysLeft >= 1) {
      return `Ends in ${daysLeft} days`;
    }

    const hours = Math.floor((diffInMs / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diffInMs / (1000 * 60)) % 60);
    const seconds = Math.floor((diffInMs / 1000) % 60);

    const format = (unit) => String(unit).padStart(2, "0");

    return `Ends in ${format(hours)}:${format(minutes)}:${format(seconds)}`;
  }