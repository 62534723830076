import React, { useCallback, forwardRef } from "react";
import Input from "@client.core.components/Input";
import AdvertiserModel from "@client.models/advertiser";
import { useTranslation } from "react-i18next";
import { validateModel } from "@client.utils/form";
import Password from "@client.components/Password";
import RoleDropdown from "@client.components/RoleDropdown";
import AccountTypeDropdown from "@client.components/AccountTypeDropdown";
import "./CreateAdvertiser.scss";
const CreateAdvertiser = forwardRef((_, ref) => {
  const { t } = useTranslation();
  const [model, setModel] = React.useState(
    new AdvertiserModel(
      {},
      {
        firstName: t("components.Advertisers.validation.firstName"),
        lastName: t("components.Advertisers.validation.lastName"),
        emailAddress: t("components.Advertisers.validation.emailAddress"),
        role: t("components.Advertisers.validation.role"),
        password: t("components.Advertisers.validation.password"),
        companyName: t("components.Advertisers.validation.accountName"),
        companyRegistrationNumber: t(
          "components.Advertisers.validation.companyNumber"
        ),
        companyVatNumber: t("components.Advertisers.validation.vatNumber"),
      }
    )
  );

  React.useImperativeHandle(ref, () => ({
    managementItem: model,
    validate: () => validateModel(model, model.validationRules),
  }));

  const changeModel = useCallback(
    (v, field) => {
      setModel(
        new AdvertiserModel({ ...model, [field]: v }, model.validationMsgs)
      );
    },
    [model]
  );

  return (
    <div className="create-advertiser-modal" ref={ref}>
      <div className="form-layout">
        {/* Left Section */}
        <div className="form-left" style={{ gap: "1rem" }}>
          <Input
            value={model.companyName}
            onChange={(v) => changeModel(v, "companyName")}
            label={t("pages.Accounts.tabs.Details.companyName")}
          />
          {/* <Input
            value={model.companyRegistrationNumber}
            onChange={(v) => changeModel(v, "companyRegistrationNumber")}
            label={t("pages.Accounts.tabs.Details.companyNumber")}
          /> */}
          <Input
            value={model.companyRegistrationNumber}
            onChange={(v) => changeModel(v, "companyRegistrationNumber")}
            label={t("pages.Accounts.tabs.Details.companyNumber")}
          />
          <Input
            value={model.companyVatNumber}
            onChange={(v) => changeModel(v, "companyVatNumber")}
            label={t("pages.Accounts.tabs.Details.vatNumber")}
          />
          <AccountTypeDropdown
            value={model.entity}
            // label={t("components.Advertisers.customerType")}
            label={t("components.Advertisers.accountType")}
            onSelect={(val) => changeModel(val, "entity")}
          />
        </div>

        {/* Right Section */}
        <div className="form-right">
          <div className="form-row">
            <Input
              value={model.firstName}
              onChange={(v) => changeModel(v, "firstName")}
              label={t("components.Advertisers.firstName")}
            />
            <Input
              value={model.lastName}
              onChange={(v) => changeModel(v, "lastName")}
              label={t("components.Advertisers.lastName")}
            />
          </div>
          <div className="form-row">
            <Input
              value={model.emailAddress}
              onChange={(v) => changeModel(v, "emailAddress")}
              label={t("components.Advertisers.emailAddress")}
            />
          </div>
          <div className="form-row">
            <Input
              value={model.phoneNumber}
              onChange={(v) => changeModel(v, "phoneNumber")}
              label={t("components.Advertisers.phoneNumber")}
            />
          </div>
          <div className="form-row">
            <Password
              id="pswrd"
              value={model.password}
              label={t("components.Advertisers.password")}
              onChange={(v) => changeModel(v, "password")}
            />
          </div>
          {/* <RoleDropdown
            value={model.role}
            label={t("components.Advertisers.role")}
            onSelect={(val) => changeModel(val, "role")}
          /> */}
        </div>
      </div>
    </div>
  );
});

CreateAdvertiser.displayName = "CreateAdvertiser";

export default CreateAdvertiser;

//original code

// // C:\Users\Admin\Desktop\WORK2\work\AdPort\Frontend\src\pages\Accounts\CreateAdvertiser.js
// import React, { useCallback, forwardRef } from "react";
// import Input from "@client.core.components/Input";
// import AdvertiserModel from "@client.models/advertiser";
// import { useTranslation } from "react-i18next";
// import { validateModel } from "@client.utils/form";
// import Password from "@client.components/Password";
// import RoleDropdown from "@client.components/RoleDropdown";
// import AccountTypeDropdown from "@client.components/AccountTypeDropdown";

// const CreateAdvertiser = forwardRef((_, ref) => {
//   const { t } = useTranslation();
//   const [model, setModel] = React.useState(
//     new AdvertiserModel(
//       {},
//       {
//         firstName: t("components.Advertisers.validation.firstName"),
//         lastName: t("components.Advertisers.validation.lastName"),
//         emailAddress: t("components.Advertisers.validation.emailAddress"),
//         role: t("components.Advertisers.validation.role"),
//         password: t("components.Advertisers.validation.password"),
//         companyName: t("components.Advertisers.validation.accountName"),
//         companyRegistrationNumber: t("components.Advertisers.validation.companyNumber"),
//         companyVatNumber: t("components.Advertisers.validation.vatNumber"),
//       }
//     )
//   );

//   React.useImperativeHandle(ref, () => ({
//     managementItem: model,
//     validate: () => {
//       const isValid = validateModel(model, model.validationRules);
//       return isValid;
//     },
//   }));

//   const changeModel = useCallback(
//     (v, field) => {
//       setModel(
//         new AdvertiserModel({ ...model, [field]: v }, model.validationMsgs)
//       );
//     },
//     [model]
//   );

//   return (
//     <div className="create-advertiser-modal" ref={ref}>
//       {/* Account Fields */}
//       <Input
//         value={model.companyName}
//         onChange={(v) => changeModel(v, "companyName")}
//         label={t("pages.Accounts.tabs.Details.accountName")}
//       />
//       <Input
//         value={model.companyRegistrationNumber}
//         onChange={(v) => changeModel(v, "companyRegistrationNumber")}
//         label={t("pages.Accounts.tabs.Details.companyNumber")}
//       />
//       <Input
//         value={model.companyVatNumber}
//         onChange={(v) => changeModel(v, "companyVatNumber")}
//         label={t("pages.Accounts.tabs.Details.vatNumber")}
//       />

//       {/* User Fields */}
//       <Input
//         value={model.firstName}
//         onChange={(v) => changeModel(v, "firstName")}
//         label={t("components.Advertisers.firstName")}
//       />
//       <Input
//         value={model.lastName}
//         onChange={(v) => changeModel(v, "lastName")}
//         label={t("components.Advertisers.lastName")}
//       />
//       <Input
//         value={model.emailAddress}
//         onChange={(v) => changeModel(v, "emailAddress")}
//         label={t("components.Advertisers.emailAddress")}
//       />
//       <RoleDropdown
//         value={model.role}
//         label={t("components.Advertisers.role")}
//         onSelect={(val) => changeModel(val, "role")}
//       />
//       <AccountTypeDropdown
//         value={model.entity}
//         label={t("components.Advertisers.customerType")}
//         onSelect={(val) => changeModel(val, "entity")}
//       />
//       <Password
//         id="pswrd"
//         value={model.password}
//         label={t("components.Advertisers.password")}
//         onChange={(v) => changeModel(v, "password")}
//       />
//     </div>
//   );
// });

// CreateAdvertiser.displayName = "CreateAdvertiser";
// export default CreateAdvertiser;

// import React, { useCallback, forwardRef } from "react";
// import Input from "@client.core.components/Input";
// import AdvertiserModel from "@client.models/advertiser";
// import { useTranslation } from "react-i18next";
// import { validateModel } from "@client.utils/form";
// import Password from "@client.components/Password";
// import RoleDropdown from "@client.components/RoleDropdown";

// const CreateAdvertiser = forwardRef((_, ref) => {
//   const { t } = useTranslation();
//   const [model, setModel] = React.useState(
//     new AdvertiserModel(
//       {},
//       {
//         firstName: t("components.Advertisers.validation.firstName"),
//         lastName: t("components.Advertisers.validation.lastName"),
//         emailAddress: t("components.Advertisers.validation.emailAddress"),
//         role: t("components.Advertisers.validation.role"),
//         password: t("components.Advertisers.validation.password"),
//         invalidEmail: t("components.Advertisers.validation.invalidEmail"),
//       }
//     )
//   );

//   React.useImperativeHandle(ref, () => ({
//     managementItem: model,
//     validate: () => {
//       const isValid = validateModel(model, model.validationRules);
//       return isValid;
//     },
//   }));

//   const changeModel = useCallback(
//     (v, field) => {
//       setModel(
//         new AdvertiserModel({ ...model, [field]: v }, model.validationMsgs)
//       );
//     },
//     [model]
//   );

//   return (
//     <div className="create-advertiser-modal" ref={ref}>
//       <Input
//         value={model.firstName}
//         onChange={(v) => changeModel(v, "firstName")}
//         label={t("components.Advertisers.firstName")}
//       />
//       <Input
//         value={model.lastName}
//         onChange={(v) => changeModel(v, "lastName")}
//         label={t("components.Advertisers.lastName")}
//       />
//       <Input
//         value={model.emailAddress}
//         onChange={(v) => changeModel(v, "emailAddress")}
//         label={t("components.Advertisers.emailAddress")}
//       />
//       <RoleDropdown
//         value={model.role}
//         label={t("components.Advertisers.role")}
//         onSelect={(val) => {
//           changeModel(val, "role");
//         }}
//       />
//       <Password
//         id="pswrd"
//         value={model.password}
//         label={t("components.Advertisers.password")}
//         onChange={(v) => {
//           changeModel(v, "password");
//         }}
//       />
//     </div>
//   );
// });

// CreateAdvertiser.displayName = "CreateAdvertiser";

// export default CreateAdvertiser;
