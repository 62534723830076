import React from "react";
import moment from "moment";
import LogActions from "@client.enums/logActions";
import LogType from "@client.enums/logType";
import config from "@client.config";

export const getColumns = (t, onRowClick) => [
  {
    field: "createdAt",
    headerName: t("Created At"),
    cellRenderer: (params) => {
      return params.value
        ? moment(params.value).format(config.dateTimeFormat)
        : "N/A";
    }
  },
  {
    field: "entityId",
    headerName: t("systemLogs.Related"),
    cellRenderer: (params) => {
      const entityPage = (() => {
        switch (params.data.entity) {
          case LogType.Device:
            return t("systemLogs.goToPage.Device");
          case LogType.User:
            return t("systemLogs.goToPage.User");
          case LogType.Accounts:
            return t("systemLogs.goToPage.Accounts");
          default:
            return t("N/A");
        }
      })();
      return (
        <a href="" onClick={() => onRowClick(entityPage, params.value)}>
          {params.value || "N/A"}
        </a>
      );
    }
  },
  {
    field: "userFullName",
    headerName: t("Full Name")
  },
  {
    field: "type",
    headerName: t("Action"),
    valueGetter: (params) => {
      switch (params.data.type) {
        case LogActions.CREATE:
          return t("systemLogs.type.CREATE");
        case LogActions.UPDATE:
          return t("systemLogs.type.UPDATE");
        case LogActions.DELETE:
          return t("systemLogs.type.DELETE");
        case LogActions.LOGIN:
          return t("systemLogs.type.LOGIN");
        default:
          return t("N/A");
      }
    }
  },
  {
    field: "description",
    headerName: t("Description")
  },
  {
    field: "ipAddress",
    headerName: t("IP Address")
  }
];
