import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSubscriptions,
  getAllSubscriptionsAsync,
  makeSubscriptionFilter,
  createSubscriptionAsync,
  updateSubscriptionAsync,
  deleteSubscriptionAsync,
  setFilter
} from "./reducer";
import Pages from "@client.enums/pages";
import { MODAL_EVENT } from "@client.components/Modal";
import DataTable from "@client.components/DataTable";
import { useNavigate } from "react-router-dom";
import Title from "@client.components/Title";
import Input from "@client.core.components/Input";
import Dropdown from "@client.core.components/Dropdown";
import Button from "@client.core.components/Button";
import SubscriptionDetails from "./SubscriptionDetails";
import SvgIcon from "@client.core.components/SvgIcon";
import { useTranslation } from "react-i18next";
import "./Subscriptions.scss";
import CustomDateRangePicker from "@client.components/CustomDateRangePicker";
import SubscriptionsType from "@client.enums/subsType";
import SubsForm from "@client.components/SubsForm/SubsForm";
import useToast from "@client.hooks/useToast";
import { getColumns } from "./constants";
import ServiceProvider from "@client.services/provider";
const ITEMS_PER_PAGE = 10;

const Subscriptions = () => {
  const dispatch = useDispatch();
  const subs = useSelector(makeSubscriptions);
  console.log("test reducer", subs);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const gridRef = useRef(null);
  const { showToastAfterRequest } = useToast();
  const updateTxt = t("pages.subscription.updateInvoice");
  const createTxt = t("pages.subscription.title.create");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState(null);
  const filter = useSelector(makeSubscriptionFilter);

  const onSelectionChanged = (d) => {
    console.log("onSelectionChanged", d);
    switch (d.colDef.field) {
      case "Name":
      case "index":
        navigate(`${Pages.Plans}/${d.data.Id}`);
        break;
      case undefined:
        break;
      default: {
        const row = gridRef.current.api.getSelectedRows()[0];
        console.log("Selected Row:", row);
        setSelectedRow(row);
        break;
      }
    }
  };

  const subStatuses = [
    {
      value: SubscriptionsType.Active,
      label: t("pages.subscription.columns.Active")
    },
    {
      value: SubscriptionsType.cancelled,
      label: t("pages.subscription.columns.Cancelled")
    },
    {
      value: SubscriptionsType.PastDue,
      label: t("pages.subscription.columns.PastDue")
    },
    {
      value: SubscriptionsType.Expired,
      label: t("pages.subscription.columns.Expired")
    },
    {
      value: SubscriptionsType.Trial,
      label: t("pages.subscription.columns.Trial")
    }
  ];

  const handleFilterChange = (request) => {
    dispatch(
      setFilter({
        ...filter,
        ...request
      })
    );
  };

  useEffect(() => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;
    dispatch(
      getAllSubscriptionsAsync({
        ...filter,
        offset,
        limit: ITEMS_PER_PAGE,
        search: filter.query,
        searchOn: "description,user_full_name",
        startDate: filter.startDate,
        endDate: filter.endDate
      })
    );
  }, [filter, currentPage, dispatch]);

  const onSubsManagement = useCallback(
    (sub) => {
      //console.log("invoices", invoice);
      ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
        show: true,
        withBodyRef: true,
        title: sub.Id ? updateTxt : createTxt,
        component: <SubsForm sub={sub} />,
        buttonSettings: {
          text: t(
            sub.Id ? "pages.subscription.update" : "pages.subscription.create"
          ), // complete in i18n
          onClick: async (component) => {
            const isValid = component.validate();
            console.log("subscription to create:", component.managementItem);

            return isValid
              ? dispatch(
                  component.managementItem.Id
                    ? updateSubscriptionAsync(component.managementItem)
                    : createSubscriptionAsync(component.managementItem)
                ).then((resp) => {
                  showToastAfterRequest(
                    resp,
                    "pages.subscription.subscriptionCreated"
                  ).then(() => {
                    ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
                      show: false
                    });
                  });
                })
              : Promise.resolve();
          }
        }
      });
    },
    [createTxt, dispatch, showToastAfterRequest, t]
  );

  const onEdit = useCallback(
    (rowData) => {
      onSubsManagement(rowData);
    },
    [onSubsManagement]
  );

  const onView = useCallback((id) => {
    navigate(`${Pages.Plans}`); //add -> /${id} when there will be Id's for plans
  }, []);

  const onDelete = useCallback(
    (subscriptionId) => {
      dispatch(deleteSubscriptionAsync(subscriptionId)).then((resp) => {
        showToastAfterRequest(resp, "pages.subscription.subscriptionDeleted");
        setSelectedRow(null);
        ServiceProvider.EventEmitter.emit(MODAL_EVENT, {});
      });
    },
    [dispatch, showToastAfterRequest]
  );

  return (
    <div className="subscriptions page">
      <div className="content">
        <div className="content-grid">
          <Title text="components.NavigationBar.subscriptions">
            <div className="management-buttons">
              <Button
                icon="plus"
                text={createTxt}
                className="button-create"
                onClick={() => onSubsManagement({})}
              />
            </div>
          </Title>
          <div className="search">
            <Input
              label="Search"
              value={filter.query}
              onChange={(v) => {
                handleFilterChange({
                  query: v
                });
              }}
            />

            <CustomDateRangePicker
              dateFrom={
                filter.startDate === "" ? undefined : new Date(filter.startDate)
              }
              dateTo={
                filter.endDate === "" ? undefined : new Date(filter.endDate)
              }
              label={t("pages.subscription.dateRange")}
              onChange={(v) => {
                handleFilterChange({
                  startDate: v[0] === null ? "" : v[0].toISOString(),
                  endDate: v[1] === null ? "" : v[1].toISOString()
                });
              }}
            />

            <Dropdown
              items={subStatuses}
              defaultValue={null}
              label={t("pages.subscription.status")}
              valueKey="value"
              labelKey="label"
              onSelect={(v) => {
                handleFilterChange({
                  isOnline: v
                });
              }}
            />
          </div>
          <DataTable
            ref={gridRef}
            columns={getColumns(t, onView, onEdit, onDelete)}
            data={subs.results}
            isLoading={subs.loading}
            onSelectionChanged={onSelectionChanged}
            pagination={{
              total: subs.count,
              itemsPerPage: ITEMS_PER_PAGE,
              onChange: setCurrentPage
            }}
          />
        </div>
        {selectedRow ? (
          selectedRow.Id && (
            <SubscriptionDetails
              id={selectedRow.Id}
              onView={() => onView(selectedRow.Id)}
              onDelete={() => onDelete(selectedRow.Id)}
            />
          )
        ) : (
          <div className="no-selection">
            <SvgIcon name="no-address" />
            <span>{t("pages.subscription.nosubscriptionSelected")}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Subscriptions;















// import React, { useCallback, useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { makeSubscriptions, getAllSubscriptionsAsync } from "./reducer";
// import DataTable from "@client.components/DataTable";
// import Button from "@client.core.components/Button";
// import { SUBSCRIPTION_COLUMNS } from "./constants";
// import SubscriptionDetails from "./SubscriptionDetails";
// import SvgIcon from "@client.core.components/SvgIcon";
// import { useTranslation } from "react-i18next";
// import "./Subscriptions.scss";

// const Subscriptions = () => {
//   const dispatch = useDispatch();
//   const subscriptions = useSelector(makeSubscriptions);
//   const [selectedRowId, setSelectedRowId] = useState(null);
//   const { t } = useTranslation();

//   useEffect(() => {
//     dispatch(getAllSubscriptionsAsync());
//   }, [dispatch]);

//   const onRowClick = useCallback((rowData) => {
//     setSelectedRowId(rowData?.data?.Id || null);
//   }, []);

//   return (
//     <div className="subscriptions-page page">
//       <div className="subscriptions-content">
//         <div className="subscriptions-list">
//           <DataTable
//             columns={SUBSCRIPTION_COLUMNS}
//             data={subscriptions.results}
//             isLoading={subscriptions.loading}
//             onSelectionChanged={onRowClick}
//           />
//         </div>
//         <div className="subscriptions-details">
//           {selectedRowId ?<SubscriptionDetails subscriptionId={selectedRowId} />: (
//             <div className="no-selection">
//             <SvgIcon name="no-address" />
//             <span>{t("pages.Devices.noDeviceSelected")}</span>
//           </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Subscriptions;













// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getAllSubscriptionsAsync, makeSubscriptions } from "./reducer"; // Adjust the path if necessary

// const Subscriptions = () => {
//   const dispatch = useDispatch();
//   const subscriptions = useSelector(makeSubscriptions);

//   useEffect(() => {
//     // Dispatch the action to get all subscriptions
//     dispatch(getAllSubscriptionsAsync());
//   }, [dispatch]);

//   return (
//     <div>
//       <h1>Subscriptions</h1>
//       {subscriptions.loading && <p>Loading...</p>}
//       {subscriptions.results.length > 0 ? (
//         <ul>
//           {subscriptions.results.map((subscription) => (
//             <li key={subscription.Id}>
//               Plan ID: {subscription.planId} | Start Date: {subscription.startDate} | End Date: {subscription.endDate} | Status: {subscription.status}
//             </li>
//           ))}
//         </ul>
//       ) : (
//         !subscriptions.loading && <p>No subscriptions found.</p>
//       )}
//     </div>
//   );
// };

// export default Subscriptions;
