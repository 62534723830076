import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MapFilter from "@client.components/MapFilter/MapFilter";
import SearchContent from "@client.components/MapFilter/SearchContent";
import LogsAlertsDataTable from "@client.components/MapFilter/LogsAlertsDataTable";
import {
  makeDashboardFilter,
  setDashboardFilter,
  setLoading
} from "@client.features/main/dashboard";
import ToggleSwitch from "@client.core.components/ToggleSwitch";
import DevicesInfo from "@client.components/MapFilter/DevicesInfo";

enum PopoverType {
  None = 0,
  SearchById = 1,
  LogsAndAlerts = 2,
  DevicesInfo = 3,
}

const DashboardMapFilter = ({
  onRecenterClick,
  statusStatistic,
  typeStatistic,
  selectedDeviceInfos,
}) => {
  const dispatch = useDispatch();
  const [popoverType, setPopoverType] = useState(PopoverType.None);
  const dashboardFilter = useSelector(makeDashboardFilter);
  // console.log("dashboardFilter", dashboardFilter);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedDeviceInfos.length > 0) {
      setPopoverType(PopoverType.DevicesInfo);
    }
  }, [selectedDeviceInfos]);

  
  const handleMapLocationsClick = () =>
    dispatch(
      setDashboardFilter({
        ...dashboardFilter,
        enableLocations: !dashboardFilter.enableLocations,
      })
    );

  const handleHeamapClick = () => {
    dispatch(
      setDashboardFilter({
        ...dashboardFilter,
        enableHeatmap: !dashboardFilter.enableHeatmap,
      })
    );
  };
  const mapFilterItems = [
    {
      // icon: "search",
      icon: "CiSearch",
      title: "components.Dashboard.MapFilter.Search",
      onClick: () =>
        setPopoverType(
          popoverType === PopoverType.SearchById
            ? PopoverType.None
            : PopoverType.SearchById
        ),
      enabled: popoverType === PopoverType.SearchById,
    },
    {
      // icon: "recenter",
      icon: "AiOutlineAim",
      title: "components.Dashboard.MapFilter.RecenterMap",
      onClick: onRecenterClick,
      enabled: false,
    },
    {
      // icon: "pointer",
      icon: "IoIosPin",
      title: "components.Dashboard.MapFilter.ShowLocations",
      onClick: handleMapLocationsClick,
      enabled: dashboardFilter.enableLocations,
    },
    {
      // icon: "satellite",
      icon: "FaSatellite",
      title: "components.Dashboard.MapFilter.ShowHeatmap",
      onClick: handleHeamapClick,
      enabled: dashboardFilter.enableHeatmap,
    },
    {
      // icon: "archived-campaigns",
      icon: "FaArchive",
      title: "components.Dashboard.MapFilter.LogsAlerts",
      onClick: () =>
        setPopoverType(
          popoverType === PopoverType.LogsAndAlerts
            ? PopoverType.None
            : PopoverType.LogsAndAlerts
        ),
      enabled: popoverType === PopoverType.LogsAndAlerts,
    },
    {
      // icon: "setting",
      icon: "IoSettings",
      title: "components.Dashboard.MapFilter.AdportOptions",
      component: (
        <ul className="map-filter-list">
          {/* <li>
            {t("components.Dashboard.MapFilter.unknown", {
              val: typeStatistic.unknownQty,
            })}
            <ToggleSwitch
              value={dashboardFilter.isUnknown}
              onChange={(v) =>
                dispatch(
                  setDashboardFilter({
                    ...dashboardFilter,
                    isUnknown: v,
                  })
                )
              }
            />
          </li> */}
          <li>
            {t("components.Dashboard.MapFilter.dynamic", {
              val: typeStatistic.dynamicQty,
            })}
            <ToggleSwitch
              value={dashboardFilter.isDynamic}
              onChange={(v) =>
                dispatch(
                  setDashboardFilter({
                    ...dashboardFilter,
                    isDynamic: v,
                  })
                )
              }
            />
          </li>
          <li>
            {t("components.Dashboard.MapFilter.static", {
              val: typeStatistic.staticQty,
            })}
            <ToggleSwitch
              value={dashboardFilter.isStatic}
              onChange={(v) =>
                dispatch(
                  setDashboardFilter({
                    ...dashboardFilter,
                    isStatic: v,
                  })
                )
              }
            />
          </li>
        </ul>
      ),
    },
    {
      // icon: "wifi",
      icon: "FaWifi",
      title: "components.Dashboard.MapFilter.Status",
      component: (
        <ul className="map-filter-list">
          <li>
            <div className="status-label">
              <span className="status-online"></span>
              {t("components.Dashboard.MapFilter.online", {
                val: statusStatistic.onlineQty,
              })}
            </div>
            <ToggleSwitch
              value={dashboardFilter.isOnline}
              onChange={(v) =>
                dispatch(
                  setDashboardFilter({
                    ...dashboardFilter,
                    isOnline: v,
                  })
                )
              }
            />
          </li>
          <li>
            <div className="status-label">
              <span className="status-offline"></span>
              {t("components.Dashboard.MapFilter.offline", {
                val: statusStatistic.offlineQty,
              })}
            </div>

            <ToggleSwitch
              value={dashboardFilter.isOffline}
              onChange={(v) =>
                dispatch(
                  setDashboardFilter({
                    ...dashboardFilter,
                    isOffline: v,
                  })
                )
              }
            />
          </li>
        </ul>
      ),
    },
  ];

  return (
    <>
      <MapFilter items={mapFilterItems} />
      {popoverType === PopoverType.SearchById && <SearchContent />}
      {popoverType === PopoverType.LogsAndAlerts && <LogsAlertsDataTable />}
      {popoverType === PopoverType.DevicesInfo && (
        <DevicesInfo
          devices={selectedDeviceInfos.sort((a, b) => b.status - a.status)}
          onClose={() => setPopoverType(PopoverType.None)}
        />
      )}
    </>
  );
};
export default DashboardMapFilter;
