import React, { useCallback, forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Input from "@client.core.components/Input";
import { useTranslation } from "react-i18next";
import { validateModel } from "@client.utils/form";
import DeviceModel from "@client.models/device";
import "./DeviceManagement.scss";
import DeviceTypeDropdown from "@client.components/DeviceTypeDropdown";

const DeviceManagement = forwardRef(({ device }, ref) => {
  const { t } = useTranslation();
  const [model, setModel] = useState(new DeviceModel());

  useEffect(() => {
    setModel(
      new DeviceModel(
        { ...device },
        {
          name: t("pages.Devices.validation.name"),
          machineId: t("pages.Devices.validation.machineId"),
          deviceType: t("pages.Devices.validation.deviceType"),
        }
      )
    );
  }, [t, device]);

  // Expose the model and validation to parent components
  React.useImperativeHandle(ref, () => ({
    managementItem: model,
    validate: () => {
      const isValid = validateModel(model, model.validationRules);
      return isValid;
    },
  }));

  // Update model fields as user inputs data
  const changeModel = useCallback(
    (v, field) => {
      setModel(new DeviceModel({ ...model, [field]: v }, model.validationMsgs));
    },
    [model]
  );

  return (
    <div className="create-device" ref={ref}>
      <Input
        value={model.deviceName}
        onChange={(v) => changeModel(v, "deviceName")}
        label={t("pages.Devices.columns.name")}
      />
      <DeviceTypeDropdown
        value={model.deviceType}
        onSelect={(v) => changeModel(v, "deviceType")}
        label={t("pages.Devices.columns.type")}
      />
      <Input
        value={model.machineId}
        onChange={(v) => changeModel(v, "machineId")}
        label={t("pages.Devices.columns.mac")}
      />
    </div>
  );
});

DeviceManagement.displayName = "DeviceManagement";

DeviceManagement.defaultProps = {
  device: {},
};

DeviceManagement.propTypes = {
  device: PropTypes.object,
};

export default DeviceManagement;
