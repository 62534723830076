import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useCallback,
} from "react";
import Input from "@client.core.components/Input";
import { validateModel } from "@client.utils/form";
import BaseSystemLogModel from "@client.models/BaseSystemLogModel";
import ActionDropdown from "@client.components/ActionDropdown";
import LogTypeDropdown from "./components/LogTypeDropdown";
import "./SystemLogForm.scss";
import DeviceDropDown from "../../components/DeviceDropDown/DeviceDropDown";
import UserDropDown from "../../components/UserDropDown/UserDropDown";
import AdvertiserDropdown from "@client.components/AdvertiserDropdown";

const SystemLogForm = forwardRef((props, ref) => {
  const [model, setModel] = useState(
    new BaseSystemLogModel(
      {},
      {
        description: "Description is required",
        entity: "Entity is required",
        type: "Type is required",
        entityId: "Entity ID is required",
      }
    )
  );

  useImperativeHandle(ref, () => ({
    validate: () => {
      const isValid = validateModel(model, model.validationRules);
      return isValid;
    },
    logData: model,
  }));

  const handleChange = (value, field) => {
    setModel(
      new BaseSystemLogModel(
        { ...model, [field]: value },
        model.validationMsgs
      )
    );
  };

  const handleEntityChange = (value) => {
    setModel(
      new BaseSystemLogModel(
        { ...model, entity: value, entityId: "" }, // Reset entityId when entity changes
        model.validationMsgs
      )
    );
  };

  const handleEntityIdChange = (value) => {
    setModel(
      new BaseSystemLogModel(
        { ...model, entityId: value },
        model.validationMsgs
      )
    );
  };

  return (
    <div className="create-advertiser-modal" ref={ref}>
      <div className="dropdown-container">
        <div className="entity-drop-down">
          <label>Entity</label>
          <LogTypeDropdown
            value={model.entity}
            onSelect={handleEntityChange}
          />
        </div>
        <div className="options-drop-down">
          <label>Options</label>
          {model.entity === 0 && (
            <DeviceDropDown
              value={model.entityId}
              onSelect={handleEntityIdChange}
            />
          )}
          {model.entity === 1 && (
            <UserDropDown
              value={model.entityId}
              onSelect={handleEntityIdChange}
            />
          )}
          {model.entity === 2 && (
            <AdvertiserDropdown
              value={model.entityId}
              onSelect={handleEntityIdChange}
            />
          )}
        </div>
      </div>
      <label>Action Type</label>
      <ActionDropdown
        value={model.type}
        onSelect={(val) => handleChange(val, "type")}
      />
      <Input
        id="description"
        label="Description"
        value={model.description}
        onChange={(e) => handleChange(e, "description")}
      />
    </div>
  );
});

SystemLogForm.displayName = "SystemLogForm";

export default SystemLogForm;
















// import React, {
//   forwardRef,
//   useState,
//   useImperativeHandle,
//   useEffect,
//   useCallback
// } from "react";
// import Input from "@client.core.components/Input";
// import { validateModel } from "@client.utils/form";
// import BaseSystemLogModel from "@client.models/BaseSystemLogModel";
// import ActionDropdown from "@client.components/ActionDropdown";
// import LogTypeDropdown from "./components/LogTypeDropdown";
// import "./SystemLogForm.scss";
// import { makeDevices, getAllDevicesAsync } from "../../reducers/Device";
// import { getAllAccountsAsync } from "../Accounts/reducer";
// import { makeUsers, getAllUsersAsync } from "../Users/reducer";
// import { useDispatch, useSelector } from "react-redux";
// import AdvertiserDropdown from "@client.components/AdvertiserDropdown";
// import CampaignModel from "../../models/campaign";
// import UsersModel from "../../models/user";
// import DeviceDropDown from "../../components/DeviceDropDown/DeviceDropDown";
// import DeviceModel from "../../models/device";
// import UserDropDown from "../../components/UserDropDown/UserDropDown";

// const SystemLogForm = forwardRef((props, ref) => {
//   const dispatch = useDispatch();
//   const users = useSelector(makeUsers);
//   const devices = useSelector(makeDevices);
//   const accounts = useSelector((state) => state.account.accounts);

//   const [options, setOptions] = useState([]);

//   const [userModel, setUserModel] = useState(
//     new UsersModel(
//       {},
//       {
//         userName: "required",
//         Id: "required"
//       }
//     )
//   );

//   const [model, setModel] = useState(
//     new BaseSystemLogModel(
//       {},
//       {
//         description: "Description is required",
//         entity: "Entity is required",
//         type: "Type is required"
//       }
//     )
//   );
//   const [campaignModel, setCampaginModel] = useState(
//     new CampaignModel(
//       {},
//       {
//         campaignName: "required",
//         Id: "required"
//       }
//     )
//   );

//   const [deviceModel, setDeviceModel] = useState(
//     new DeviceModel(
//       {},
//       {
//         deviceName: "required",
//         Id: "required"
//       }
//     )
//   );

//   useImperativeHandle(ref, () => ({
//     validate: () => {
//       const isValid = validateModel(model, model.validationRules);
//       return isValid;
//     },
//     logData: model
//   }));

//   const handleOption = (entity) => {
//     switch (entity) {
//       case "Device":
//         dispatch(getAllDevicesAsync());
//         return devices;
//       case "User":
//         dispatch(getAllUsersAsync());
//         return users;
//       case "Accounts":
//         dispatch(getAllAccountsAsync());
//         return accounts;
//       default:
//         return [];
//     }
//   };

//   const changeModel = useCallback(
//     (value, field) => {
//       console.log("V,F", value, field);
//       setCampaginModel(
//         new CampaignModel(
//           { ...campaignModel, [field]: value },
//           campaignModel.validationMsgs || {}
//         )
//       );
//     },
//     [campaignModel]
//   );

//   const changeUserModel = useCallback(
//     (value, field) => {
//       console.log("V,F", value, field);
//       setUserModel(
//         new UsersModel(
//           { ...userModel, [field]: value },
//           userModel.validationMsgs || {}
//         )
//       );
//     },
//     [userModel]
//   );

//   const changeDeviceModel = useCallback(
//     (value, field) => {
//       console.log("V,F", value, field);
//       setDeviceModel(
//         new DeviceModel(
//           { ...deviceModel, [field]: value },
//           deviceModel.validationMsgs || {}
//         )
//       );
//     },
//     [deviceModel]
//   );

//   const handleOptionMemoized = useCallback(() => {
//     const newOptions = handleOption(model.entity);
//     setOptions(newOptions || []);
//     // console.log(newOptions);
//   }, [model.entity, devices, users, accounts]);

//   useEffect(() => {
//     handleOptionMemoized();
//   }, [handleOptionMemoized]);

//   const handleChange = (value, field) => {
//     console.log("V,Ffff", value, field);
//     setModel(
//       new BaseSystemLogModel(
//         { ...model, [field]: value },
//         model.validationMsgs
//       ),
//       new CampaignModel(
//         { ...campaignModel, ["Accounts"]: value },
//         campaignModel.validationMsgs
//       ),
//       new DeviceModel(
//         { ...deviceModel, ["Device"]: value },
//         deviceModel.validationMsgs
//       ),
//       new UsersModel(
//         { ...userModel, ["User"]: value },
//         userModel.validationMsgs
//       )
//     );
//   };

//   return (
//     <div className="create-advertiser-modal" ref={ref}>
//       <div className="dropdown-container">
//         <div className="entity-drop-down">
//           <label>Entity</label>
//           <LogTypeDropdown
//             value={model.entity}
//             onSelect={(val) => handleChange(val, "entity")}
//           />
//         </div>

//         <div className="options-drop-down">
//           <label>Options</label>
//           {model.entity === 0 && (
//             <DeviceDropDown
//               items={model.deviceId}
//               // valueKey="dropDownOptions"
//               labelKey="options"
//               onSelect={(option) => changeDeviceModel(option, "deviceId")}
//             />
//           )}
//           {model.entity === 1 && (
//             <UserDropDown
//               value={model.userId}
//               label="users"
//               onSelect={(option) => changeUserModel(option, "userId")}
//             />
//           )}
//           {model.entity === 2 && (
//             <AdvertiserDropdown
//               value={model.advertiserId}
//               label="Advertisers"
//               onSelect={(option) => changeModel(option, "advertiserId")}
//             />
//           )}
//         </div>
//       </div>

//       <label>Action Type</label>
//       <ActionDropdown
//         value={model.type}
//         onSelect={(val) => handleChange(val, "type")}
//       />

//       <Input
//         id="description"
//         label="Description"
//         value={model.description}
//         onChange={(e) => handleChange(e, "description")}
//       />
//     </div>
//   );
// });

// SystemLogForm.displayName = "SystemLogForm";

// export default SystemLogForm;
