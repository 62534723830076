import React, { useEffect, useState, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route } from "react-router-dom";
import LoginPage from "@client.pages/Login";
import ForgotPasswordPage from "@client.pages/ForgotPassword";
import ProfileGeneralEditUserPage from "@client.pages/ProfileGeneralEditUser";
import ProfileGeneralPage from "@client.pages/ProfileGeneral";
import ProfileBillingPage from "@client.pages/ProfileBilling";
import ProfileNotificationsPage from "@client.pages/ProfileNotifications";
import ProfileChangePasswordPage from "@client.pages/ProfileChangePassword";
import LogoutPage from "@client.pages/Logout";
import DevicesPage from "@client.pages/Devices";
import CampaignsPage from "@client.pages/Campaigns";
import MediaLibraryPage from "@client.pages/MediaLibrary/MediaLibrary";
import ZonesPage from "@client.pages/Zones";
import CreateCampaignPage from "@client.pages/CreateCampaign";
import DashboardPage from "@client.pages/Dashboard";
import AdvertiserPage from "@client.pages/Advertiser";
import AuthorizedLayout from "@client.components/AuthorizedLayout";
import UnauthorizedLayout from "@client.components/UnauthorizedLayout";
import RequireAuth from "@client.components/RequireAuth";
import Pages from "@client.enums/pages";
import CampaignPage from "@client.pages/Campaign/Campaign";
import FlickrDemoPage from "@client.pages/FlickrDemo";
import Loader from "@client.core.components/Loader";
import DevicePage from "@client.pages/Device";
import UsersPage from "@client.pages/Users";
import ProfilePaymentPage from "@client.pages/ProfilePayment/ProfilePayment";
import VpnServerPage from "@client.pages/VpnServer";
import DraftCampaignsPage from "@client.pages/DraftCampaigns/DraftCampaigns";
import ReportsPage from "@client.pages/ReportsPage";
import InviteUser from "@client.pages/InviteUser";
import RegisterUser from "@client.pages/RegisterUser";
import CampaignApprovals from "@client.pages/CampaignApprovals/CampaignApprovals";
import VpnManagement from "@client.pages/VpnManagement/VpnManagement";
import DataAnalyzer from "@client.pages/DataAnalyzer/DataAnalyzer";
import DataAnalyzerBlob from "@client.pages/DataAnalyzer_blob/DataAnalyzer";
import DataAnalyzerDemo from "@client.pages/DataAnalyzerDemo/DataAnalyzer";
import AccountsPage from "@client.pages/Accounts";
import PermissionsPage from "@client.pages/Permissions/PermissionsPage";
import UserLogs from "@client.pages/UserLogs/userLogs";
import SystemLogs from "@client.pages/SystemLogs/SystemLogs";
import Invoices from "@client.pages/Invoices/Invoices";
import Subscriptions  from "@client.pages/Subscriptions/Subscriptions";
const SoftwareVersions = lazy(() => import("@client.pages/SoftwareVersions"));
const Plans = lazy(() => import("@client.pages/Plans"));

import "./i18n";

const AppLoader = () => (
  <div className="app page-loading">
    <Loader loading={true} />
  </div>
);
const AppRoutes = () => {
  const { i18n } = useTranslation();
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  useEffect(() => {
    const defaultLanguge = "en";
    const existingLanguges = [defaultLanguge];
    let languageToUse = existingLanguges.find((l) => l === i18n.language);
    if (!languageToUse) {
      languageToUse = existingLanguges.find((l) => i18n.language.startsWith(l));
    }

    const language = languageToUse || defaultLanguge;
    import(`@client.i18n/${language}.json`)
      .then((translations) => {
        i18n.changeLanguage(language);
        i18n.addResourceBundle(
          language,
          "translation",
          translations.default,
          true,
          true
        );
        setTranslationsLoaded(true);
      })
      .catch((error) => {
        console.error("Error loading translations:", error);
      });
  }, [i18n]);
  // if this is needed /:advertiserId

  if (!translationsLoaded) {
    return <AppLoader />;
  }
  return (
    <div className="app">
      <Suspense fallback={<AppLoader />}>
        <Routes>
          <Route element={<UnauthorizedLayout />}>
            <Route path={Pages.Login} element={<LoginPage />} />
            <Route path={Pages.InviteUser} element={<InviteUser />} />
            <Route path={Pages.RegisterUser} element={<RegisterUser />} />
            <Route
              path={Pages.ForgotPassword}
              element={<ForgotPasswordPage />}
            />
            <Route path={Pages.FlickrDemo} element={<FlickrDemoPage />} />
            <Route path="*" element={<LoginPage />} />
          </Route>
          <Route element={<RequireAuth />}>
            <Route element={<AuthorizedLayout withNavBar={true} />}>
              <Route path={Pages.Dashboard} element={<DashboardPage />} />
              <Route path={Pages.Devices} element={<DevicesPage />} />
              <Route path={`${Pages.Devices}/:id`} element={<DevicePage />} />
              <Route path={Pages.Users} element={<UsersPage />} />
              <Route path={Pages.MediaLibrary} element={<MediaLibraryPage />} />
              <Route
                path={`${Pages.CreateCampaign}/:draftId?`}
                element={<CreateCampaignPage />}
              />
              <Route
                path={Pages.CreateCampaign}
                element={<CreateCampaignPage />}
              />
              <Route path={Pages.Campaigns} element={<CampaignsPage />} />
              <Route path={Pages.Reports} element={<ReportsPage />} />
              <Route path={Pages.VpnServer} element={<VpnServerPage />} />
              <Route
                path={`${Pages.ProfileGeneralEditUser}/:profileId`}
                element={<ProfileGeneralEditUserPage />}
              />
              <Route
                path={`${Pages.ProfileGeneralEditUser}`}
                element={<ProfileGeneralEditUserPage />}
              />
              <Route
                path={`${Pages.ProfileGeneral}/:profileId`}
                element={<ProfileGeneralPage />}
              />
              <Route
                path={Pages.ProfileGeneral}
                element={<ProfileGeneralPage />}
              />
              <Route
                path={Pages.ProfileChangePassword}
                element={<ProfileChangePasswordPage />}
              />
              <Route
                path={`${Pages.ProfileChangePassword}/:profileId`}
                element={<ProfileChangePasswordPage />}
              />

              <Route
                path={`${Pages.ProfileNotifications}/:profileId`}
                element={<ProfileNotificationsPage />}
              />
              <Route
                path={Pages.ProfileNotifications}
                element={<ProfileNotificationsPage />}
              />
              <Route
                path={`${Pages.ProfileBilling}/:profileId`}
                element={<ProfileBillingPage />}
              />
              <Route
                path={Pages.ProfileBilling}
                element={<ProfileBillingPage />}
              />

              <Route
                path={Pages.ProfilePayment}
                element={<ProfilePaymentPage />}
              />
              <Route
                path={`${Pages.ProfilePayment}/:profileId`}
                element={<ProfilePaymentPage />}
              />

              <Route path={Pages.Zones} element={<ZonesPage />} />
              <Route
                path={`${Pages.Advertiser}/:id`}
                element={<AdvertiserPage />}
              />
              <Route path={Pages.Accounts} element={<AccountsPage />} />
              <Route
                path={`${Pages.Campaign}/:id`}
                element={<CampaignPage />}
              />
              <Route path={Pages.Logout} element={<LogoutPage />} />
              <Route
                path={Pages.CampaignApprovals}
                element={<CampaignApprovals />}
              />
              <Route path={Pages.VpnManagement} element={<VpnManagement />} />
              <Route path={Pages.DataAnalyzer} element={<DataAnalyzer />} />
              <Route
                path={Pages.DataAnalyzerBlob}
                element={<DataAnalyzerBlob />}
              />
              <Route
                path={Pages.DataAnalyzerDemo}
                element={<DataAnalyzerDemo />}
              />
              <Route
                path={Pages.PermissionsPage}
                element={<PermissionsPage />}
              />
              <Route path={Pages.UserLogs} element={<UserLogs />} />
              <Route path={Pages.SystemLogs} element={<SystemLogs />} />
              <Route
                path={Pages.SoftwareVersions}
                element={<SoftwareVersions />}
              />
              <Route path={Pages.Invoices} element={<Invoices />} />
              <Route path={Pages.Plans} element={<Plans />} />
              <Route path={Pages.Subscriptions} element={<Subscriptions />} />
              <Route path={"*"} element={<DashboardPage />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
};

export default AppRoutes;
