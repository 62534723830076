import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@client.core.components/Button";
import "./CustomDateRangePicker_TwoFields.scss";

const CustomDateRangePicker_TwoFields = ({
  time,
  dateFrom,
  dateTo,
  onChange,
  label,
  label2,
  placeholder = "Select a range",
}) => {
  const [startDate, setStartDate] = useState(dateFrom);
  const [endDate, setEndDate] = useState(dateTo);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [leftMonth, setLeftMonth] = useState(new Date());
  const [rightMonth, setRightMonth] = useState(
    new Date(new Date().setMonth(new Date().getMonth() + 1))
  );

  useEffect(() => {
    setStartDate(dateFrom);
    setEndDate(dateTo);
  }, [dateFrom, dateTo]);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleDateSelection = (date) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate(null);
    } else if (startDate && !endDate && date > startDate) {
      setEndDate(date);
    } else {
      setStartDate(date);
    }
  };

  const navigateMonth = (direction, calendar) => {
    if (calendar === "left") {
      const newMonth = new Date(leftMonth);
      newMonth.setMonth(leftMonth.getMonth() + direction);
      setLeftMonth(newMonth);
    } else {
      const newMonth = new Date(rightMonth);
      newMonth.setMonth(rightMonth.getMonth() + direction);
      setRightMonth(newMonth);
    }
  };

  const clearRange = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const applyRange = () => {
    setIsPopupOpen(false);
    if (onChange) {
      onChange([startDate, endDate]);
    }
  };

  const renderCalendar = (viewMonth) => {
    const lastDayOfMonth = new Date(
      viewMonth.getFullYear(),
      viewMonth.getMonth() + 1,
      0
    );
    const days = [];
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      const currentDay = new Date(
        viewMonth.getFullYear(),
        viewMonth.getMonth(),
        i
      );
      const isSelected =
        (startDate && currentDay.getTime() === startDate.getTime()) ||
        (endDate && currentDay.getTime() === endDate.getTime()) ||
        (startDate &&
          endDate &&
          currentDay >= startDate &&
          currentDay <= endDate);

      days.push(
        <button
          key={i}
          className={`calendar-day ${isSelected ? "selected" : ""}`}
          onClick={() => handleDateSelection(currentDay)}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="calendar-grid">
        {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
          <div key={index} className="calendar-day-name">
            {day}
          </div>
        ))}
        {days}
      </div>
    );
  };

  return (
    <div className="date-range-picker">
      <div className="daterangepicker-container">
      {label && <span className="label">{label}</span>}
        <input
          type="text"
          placeholder={placeholder}
          readOnly
          value={`${startDate ? startDate.toLocaleDateString() : ""}`}
          onClick={togglePopup}
          className="daterangepicker-input2"
          />
        {isPopupOpen && (
          <div className="daterangepicker-popup">
            <div className="calendar-container">
              <div className="calendar">
                <div className="calendar-header">
                  <button
                    onClick={() => navigateMonth(-1, "left")}
                    className="calendar-nav"
                  >
                    {"<"}
                  </button>
                  <span className="calendar-title">
                    {leftMonth.toLocaleDateString("en-US", {
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                  <button
                    onClick={() => navigateMonth(1, "left")}
                    className="calendar-nav"
                  >
                    {">"}
                  </button>
                </div>
                {renderCalendar(leftMonth)}
              </div>
              <hr
                style={{ marginLeft: "20px", opacity: 0.3, marginTop: -15 }}
              />
              <div className="calendar">
                <div className="calendar-header">
                  <button
                    onClick={() => navigateMonth(-1, "right")}
                    className="calendar-nav"
                  >
                    {"<"}
                  </button>
                  <span className="calendar-title">
                    {rightMonth.toLocaleDateString("en-US", {
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                  <button
                    onClick={() => navigateMonth(1, "right")}
                    className="calendar-nav"
                  >
                    {">"}
                  </button>
                </div>
                {renderCalendar(rightMonth)}
              </div>
            </div>

            <div className="daterangepicker-footer">
              <Button
                text="Clear"
                onClick={clearRange}
                className="Button alternative"
              />
              <Button text="Apply" onClick={applyRange} />
            </div>
          </div>
        )}
      </div>



      <div className="daterangepicker-container">
          {label2 && <span className="label">{label2}</span>}
        <input
          type="text"
          placeholder={placeholder}
          readOnly
          value={` ${
            endDate ? endDate.toLocaleDateString() : ""
          }`}
          // onClick={togglePopup}
          className="daterangepicker-input2"
        />
      </div>
    </div>
  );
};

CustomDateRangePicker_TwoFields.defaultProps = {
  label: "",
  label2: "",
  time: false,
};

CustomDateRangePicker_TwoFields.propTypes = {
  time: PropTypes.bool,
  label: PropTypes.string,
  label2: PropTypes.string,
  dateFrom: PropTypes.instanceOf(Date),
  dateTo: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default React.memo(CustomDateRangePicker_TwoFields);
