import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SvgIcon from "@client.core.components/SvgIcon";
import SectionDescription from "@client.pages/CreateCampaign/SectionDescription";
import Button from "@client.core.components/Button";
import { useDispatch, useSelector } from "react-redux";
import { makeProfile } from "@client.pages/Login/reducer";
import Pages from "@client.enums/pages";
import {
  getCampaignDurationTimeSlotsAsync,
  getCampaignLocationsAsync,
  makeCampaignLoading,
  makeSelectedCampaign,
  saveCampaignAsync,
} from "../../../reducers/Campaign";

import classNames from "classnames";
import Loader from "@client.core.components/Loader";
import MediaFile from "@client.components/MediaFile";
import useToast from "@client.hooks/useToast";
import Dropdown from "@client.core.components/Dropdown";
import "./CampaignInfoSidebar.scss";
import moment from "moment";
import config from "@client.config";
import { updateAdvertiserCampaignStatusAsync } from "@client.pages/Advertiser/reducer";
import {
  getLocationByIdAsync,
  makeLocations,
} from "@client.pages/Zones/reducer";
import CampaignProgressBar from "./CampaignProgressBar";

import CampaignStatuses from "@client.enums/campaignStatuses";
import useCampaignStatus from "./useCampaignStatus";
import { renderEndDate } from "./utils";

const PaymentProps = Object.freeze({
  4654654654654654: "4654654654654654",
  8419887487352765: "8419887487352765",
});
const CREDIT_CARD_DATASOURCE = Object.keys(PaymentProps).map((k) => ({
  id: parseInt(k),
  name: PaymentProps[k],
}));

const CampaignInfo = ({ model, estimation, approve = false ,infoData=null}) => {
  const { t } = useTranslation();
  const { showToastAfterRequest } = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector(makeProfile);
  const selectedCampaign = useSelector(makeSelectedCampaign);
  const isCampaignLoading = useSelector(makeCampaignLoading);

  const [creditCard, setCreditCard] = useState(CREDIT_CARD_DATASOURCE[0].id);
  const [locationNames, setLocationNames] = useState([]);
  const [reason, setReason] = useState("");
  const { statusName, statusClass } = useCampaignStatus(model.status, t);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const source = estimation
    ? `${config.downloadUrl}/${model.mediaId}`
    : model.mediaId;

  useEffect(() => {
    const fetchLocationNames = async () => {
      if (Array.isArray(model.geofenceZoneIds)) {
        // Check if geofenceZoneIds is defined and is an array
        const names = await Promise.all(
          model.geofenceZoneIds.map(async (id) => {
            const result = await dispatch(getLocationByIdAsync(id));
            return result.payload?.data?.zone?.zoneName || "Unknown"; // Adjust based on actual response structure
          })
        );
        setLocationNames(names);
      } else {
        setLocationNames([]);
      }
    };

    fetchLocationNames();
  }, [dispatch, model.geofenceZoneIds]);

  // const price = estimation ? estimation.price : model.totalSpent;
  const price = estimation ? estimation.price : 0;
  const plays = estimation ? estimation.plays : model.totalViews;
  const pricePerView = price === 0 || plays === 0 ? 0 : price / plays;

  const renderPaymentSection = () => {
    if (isCampaignLoading) {
      return <Loader loading={true} />;
    }
    return (
      <>
        <SectionDescription title="pages.CreateCampaign.paymentMethod" />

        <Dropdown
          items={CREDIT_CARD_DATASOURCE}
          valueKey="id"
          payment={true}
          labelClassName="credit-card"
          defaultValue={creditCard}
          onPrepareValue={(v) => parseInt(v)}
          labelKey="name"
          placeholder="●●●● ●●●● ●●●● ●●●●"
          label={t("pages.CreateCampaign.creditCards")}
          onSelect={setCreditCard}
        />

        <Button
          text={t("pages.CreateCampaign.launchCampaign")}
          disabled={creditCard === null || !model.isValid()}
          onClick={async () => {
            // console.log("model.userId: ", model);
            if (!model.userId) {
              model.userId = profile.Id;
            }
            const resp = await dispatch(saveCampaignAsync(model));
            showToastAfterRequest(
              resp,
              "pages.CreateCampaign.successCampaignCreation"
            ).then(() => {
              // navigate(`${Pages.Advertiser}/${model.userId}`);
              navigate(Pages.Campaigns);
            });
          }}
        />
      </>
    );
  };

  const handleApprove = () => {
    const updatedCampaign = { ...model, status: CampaignStatuses.Queued };

    dispatch(
      updateAdvertiserCampaignStatusAsync({
        campaign: updatedCampaign,
        filters: { status: CampaignStatuses.PendingApproval },
      })
    )
      .then(() => {
        alert(t("pages.CampaignApproval.successApprove"));
        
      })
      .catch((err) => {
        // console.error("Approval failed: ", err);
        alert(t("pages.CampaignApproval.errorApprove"));
      });
  };

  const handleDeny = () => {
    // Define behavior for denying the campaign
    alert("Campaign denied.");
    // window.location.reload(); // Reload the page after denying
  };

  return (
    <div className="campaign-info">
      {!estimation && <div className="name">{model.campaignName}</div>}
      {!estimation && (
        <div
          className="d-flex justify-content-between"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* <span
            className={`status ${
              startDate ? "pending" : endDate ? "ended" : "live"
            }`}
          > */}
          <span className={`status ${statusClass}`}>
            {/* {startDate ? "Pending" : endDate ? "End" : "Live"} */}
            {statusName}
          </span>
          {renderEndDate(model.endDate, currentTime)}
        </div>
      )}
      <div className={classNames("bag", { "bag-with-name": !estimation })}>
        <div style={{ height: 256, width: 291, overflow: "hidden" }}>
          <div className="placeholder">
            <div className="thumb-img">
              <MediaFile
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover", // Ensures the image fills the div while maintaining aspect ratio
                }}
                key={model?.mediaId}
                // src={`${config.downloadUrl}/${model.mediaId}`}
                // src={`${model.mediaId}`}
                src={source}
                // src={model?.mediaId}
                className="thumb-img"
                fallbackElement={() => <SvgIcon name="file-not-found" />}
              />
            </div>
          </div>
        </div>
      </div>

      {estimation && (
        <SectionDescription title="pages.CreateCampaign.costEstimetor" />
      )}

      {model.status === CampaignStatuses.Active && (
        <div className="payment-details">
          {/* <div className="section-payment">
          <div className="price">{plays?.toLocaleString()}</div>
          <div className="description">
            {t("pages.CreateCampaign.totalPlays")}
          </div>
        </div> */}
          <div className="section-payment">
            <div className="price">
              {profile.currency} {infoData.peopleReached}
            </div>
            <div className="description">
              {t("pages.CreateCampaign.peopleReached")}
            </div>
          </div>
          <div className="section-payment">
            <div className="price">
              {profile.currency} {infoData.pricePerSlot}
            </div>
            <div className="description">
              {t("pages.CreateCampaign.pricePerSlot")}
            </div>
            {/* <div className="description">{t("pages.CreateCampaign.Spent")}</div> */}
          </div>
          <div className="section-payment no-border">
            <div className="price">
              {profile.currency} {infoData.budgetSpent}
            </div>
            {/* <div className="description">{t("pages.CreateCampaign.total")}</div> */}
            <div className="description">
              {t("pages.CreateCampaign.budgetSpent")}
            </div>
          </div>
        </div>
      )}

      {model.status === CampaignStatuses.PendingApproval && (
        <div className="payment-details">
          {/* <div className="section-payment">
          <div className="price">{plays?.toLocaleString()}</div>
          <div className="description">
            {t("pages.CreateCampaign.totalPlays")}
          </div>
        </div> */}
          <div className="section-payment">
            <div className="price">
              {profile.currency} {pricePerView?.toLocaleString()}
            </div>
            <div className="description">
              {t("pages.CreateCampaign.budget")}
            </div>
          </div>
          <div className="section-payment no-border">
            <div className="price">
              {profile.currency} {pricePerView?.toLocaleString()}
            </div>
            <div className="description">
              {t("pages.CreateCampaign.estimationPricePerSlot")}
            </div>
            {/* <div className="description">{t("pages.CreateCampaign.Spent")}</div> */}
          </div>
        </div>
      )}

      {model.status === CampaignStatuses.Ended && (
        <div className="payment-details">
          {/* <div className="section-payment">
          <div className="price">{plays?.toLocaleString()}</div>
          <div className="description">
            {t("pages.CreateCampaign.totalPlays")}
          </div>
        </div> */}
          <div className="section-payment">
            <div className="price">
              {profile.currency} {pricePerView?.toLocaleString()}
            </div>
            <div className="description">
              {t("pages.CreateCampaign.budget")}
            </div>
          </div>
          <div className="section-payment">
            <div className="price">
              {profile.currency} {pricePerView?.toLocaleString()}
            </div>
            <div className="description">
              {t("pages.CreateCampaign.pricePerPlay")}
            </div>
            {/* <div className="description">{t("pages.CreateCampaign.Spent")}</div> */}
          </div>
          <div className="section-payment no-border">
            <div className="price">
              {profile.currency} {price?.toLocaleString()}
            </div>
            {/* <div className="description">{t("pages.CreateCampaign.total")}</div> */}
            <div className="description">
              {t("pages.CreateCampaign.pricePerDay")}
            </div>
          </div>
        </div>
      )}

      {!estimation ? (
        <div className="area">
          <div>
            {t("pages.Campaign.Overview.duration")}
            <div className="d-flex flex-row justify-content-between">
              <span className="description">
                {moment(model.startDate).format(
                  config.dateTimeSecondsFormatReversed
                )}
              </span>
              <span className="description">Until</span>
              <span className="description">
                {moment(model.endDate).format(
                  config.dateTimeSecondsFormatReversed
                )}
              </span>
            </div>
          </div>

          {!approve && (
            <div>
              <CampaignProgressBar
                startDate={model.startDate}
                endDate={model.endDate}
              />
            </div>
          )}

          <div>
            {t("pages.Campaign.Overview.locations")}
            {locationNames.length > 0 ? (
              locationNames.map((name, index) => (
                <span key={index} className="description">
                  {name}
                </span>
              ))
            ) : (
              <span className="description">No Locations</span>
            )}
          </div>
        </div>
      ) : (
        renderPaymentSection()
      )}

      {approve && (
        <div className="approval-section">
          <textarea
            className="reason-textbox"
            placeholder={t("pages.CampaignApproval.enterReason")}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
          <div className="approval-buttons">
            <Button
              text={t("pages.CampaignApproval.deny")}
              className="small-button deny alternative"
              onClick={handleDeny}
            />
            <Button
              text={t("pages.CampaignApproval.approve")}
              className="small-button button"
              onClick={handleApprove}
            />
          </div>
        </div>
      )}

      {/* {estimation ? renderPaymentSection() : renderCampaignInfo()} */}
    </div>
  );
};

CampaignInfo.propTypes = {
  model: PropTypes.any,
  estimation: PropTypes.any,
  approve: PropTypes.any,
  infoData: PropTypes.any,
};

export default CampaignInfo;
