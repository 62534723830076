import UserService from "./userService";
import CampaignService from "./campaignService";
import AdvertiserService from "./advertiserService";
import StatisticService from "./statisticService";
import DeviceService from "./deviceService";
import ZoneService from "./zoneService";
import DashboardService from "./dashboardService";
import VpnService from "./vpnService";
import DataAnalyzerService from "./dataAnalyzer";
import UserLogService from "./userLogService";
import SystemLogService from "./systemLogService";
import SoftwareVersionService from "./softwareVersionService";
import LocalStorageService from "./localStorage";
import EventEmitterService from "./eventEmitter";
import AccountService from "./accountService";
import PlanService from "./planService";
import InvoiceService from "./invoiceService";
import SubscriptionService from "./subscriptionsService";

export default class ServiceProvider {
  static Account = new AccountService();
  static User = new UserService();
  static Dashboard = new DashboardService();
  static Campaign = new CampaignService();
  static EventEmitter = new EventEmitterService();
  static Advertiser = new AdvertiserService();
  static Statistic = new StatisticService();
  static Device = new DeviceService();
  static LocalStorage = new LocalStorageService();
  static Zone = new ZoneService();
  static Vpn = new VpnService();
  static DataAnalyzer = new DataAnalyzerService();
  static UserLogService = new UserLogService();
  static SystemLogService = new SystemLogService();
  static SoftwareVersion = new SoftwareVersionService();
  static Plan = new PlanService();
  static Invoice = new InvoiceService();
  static Subscription = new SubscriptionService();
}
