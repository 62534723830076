import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useStableParams from "@client.hooks/useStableParams";

import Loader from "@client.core.components/Loader";

import { useDispatch, useSelector } from "react-redux";
import { fetchCampaignInsightsAsync, makeCampaignInsights } from "../../../../reducers/Campaign";

import Summary from "./Summary";
import Statistics from "./Statistics";
import "./Insights.scss";

const Insights = () => {
  const { id: campaignId } = useStableParams();
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const campaignInsights = useSelector(makeCampaignInsights);

  console.log(campaignInsights)

  useEffect(() => {
    if (campaignId) {
      setLoading(true);
      dispatch(fetchCampaignInsightsAsync(campaignId)).then((response) => {
        console.log("Insights -> response", response);
        setLoading(false)
      }
      );
    }
  }, [campaignId, dispatch]);

  if (loading) {
    return <Loader loading={true} />;
  }

  return (
    <div className="insights-tab">
      <h3>Insights for Campaign: {campaignId}</h3>
      <Summary data={campaignInsights} />
      <Statistics insightsData={campaignInsights} />
    </div>
  );
};

Insights.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default Insights;


















// before removing captures

// import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
// import useStableParams from "@client.hooks/useStableParams";

// import Loader from "@client.core.components/Loader";
// import AgeChart from "./InsightsStatistics/AgeChart";
// import ViewsChart from "./InsightsStatistics/ViewsChart";
// import Statistic from "./InsightsStatistics/Statistic";
// import GoogleMap from "@client.components/GoogleMap";
// import ServiceProvider from "@client.services/provider";

// import { useDispatch, useSelector } from "react-redux";
// import { fetchCampaignInsightsAsync, makeCampaignInsights } from "./reducer";

// import "./Insights.scss";

// const Insights = () => {
//   const { id: campaignId } = useStableParams(); // Get campaign ID from URL params
//   const [data, setData] = useState({ results: [], count: 0 });
//   const [loading, setLoading] = useState(true);

//   const dispatch = useDispatch();
//   const campaignInsights = useSelector(makeCampaignInsights);

//   useEffect(() => {
//     if (campaignId) {
//       dispatch(fetchCampaignInsightsAsync(campaignId));
//     }
//   }, [campaignId, dispatch]);

//   // console.log("Insights -> campaignInsssssssssssssssaaaaaaaaaaaaaasightsaaaaaaaaaa", campaignInsights);
//   // console.log("Insights -> campaignInsssssssssssssssaaaaaaaaaaaaaasightsaaaaaaaaaa", campaignInsights.playLocations);

//   useEffect(() => {
//     if (campaignId) {
//       setLoading(true);
//       ServiceProvider.DataAnalyzer.getDeviceCaptures({ campaignId })
//         .then((response) => setData(response))
//         // .then(console.log("Data from Insights.js: ", data))
//         .finally(() => setLoading(false));
//     }
//   }, [campaignId]);

//   // const getPathCoordinates = () => {
//   //   const pathCoordinates = [];
//   //   data.results.forEach((frame) => {
//   //     frame.viewers.forEach((view) => {
//   //       pathCoordinates.push({ lat: view.lat, lng: view.lng });
//   //     });
//   //   });
//   //   return pathCoordinates;
//   // };

//   const getPathCoordinates = () => {
//     return campaignInsights.playLocations.map((location) => ({
//       lat: location.latitude,
//       lng: location.longitude,
//     }));
//   };

//   if (loading) {
//     return <Loader loading={true} />;
//   }

//   if (!data.results.length) {
//     return (
//       <div className="insights-tab">
//         <h3>No Insights Available</h3>
//         <p>Insights data could not be found for this campaign.</p>
//       </div>
//     );
//   }

//   return (
//     <div className="insights-tab">
//       <h3>Insights for Campaign: {campaignId}</h3>
//       {/* 
//       Pai Charts
//       */}
//       {/* {console.log("Insights -> data.results", data.results)} */}
//       <Statistic data={data.results} insightsData={campaignInsights} />
//       {/* 
//       Line Chart
//       */}
//       <ViewsChart data={data.results} insightsData={campaignInsights} />
//       <div className="data-analyzer-chart">
//         <div className="chart-title">Map Overview</div>
//         {/* {console.log("Insights -> getPathCoordinates()", getPathCoordinates())} */}
//         <GoogleMap className="map" pathCoordinates={getPathCoordinates()} />
//       </div>
//       <AgeChart data={data.results} insightsData={campaignInsights}/>
//     </div>
//   );
// };

// Insights.propTypes = {
//   campaign: PropTypes.shape({
//     id: PropTypes.string,
//   }),
// };

// export default Insights;


















// import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
// import useStableParams from "@client.hooks/useStableParams";

// import Loader from "@client.core.components/Loader";
// import AgeChart from "../DataAnalyzer/AgeChart";
// import ViewsChart from "../DataAnalyzer/ViewsChart";
// import Statistic from "../DataAnalyzer/Statistic";
// import GoogleMap from "@client.components/GoogleMap";
// import ServiceProvider from "@client.services/provider";

// import { useDispatch, useSelector } from "react-redux";
// import { fetchCampaignInsightsAsync, makeCampaignInsights } from "./reducer";

// import "./Insights.scss";

// const Insights = () => {
//   const { id: campaignId } = useStableParams(); // Get campaign ID from URL params
//   const [data, setData] = useState({ results: [], count: 0 });
//   const [loading, setLoading] = useState(true);

//   const dispatch = useDispatch();
//   const campaignInsights = useSelector(makeCampaignInsights);

//   useEffect(() => {
//     if (campaignId) {
//       dispatch(fetchCampaignInsightsAsync(campaignId));
//     }
//   }, [campaignId, dispatch]);

//   // console.log("Insights -> campaignInsssssssssssssssaaaaaaaaaaaaaasightsaaaaaaaaaa", campaignInsights);

//   useEffect(() => {
//     if (campaignId) {
//       setLoading(true);
//       ServiceProvider.DataAnalyzer.getDeviceCaptures({ campaignId })
//         .then((response) => setData(response))
//         .finally(() => setLoading(false));
//     }
//   }, [campaignId]);

//   const getPathCoordinates = () => {
//     const pathCoordinates = [];
//     data.results.forEach((frame) => {
//       frame.viewers.forEach((view) => {
//         pathCoordinates.push({ lat: view.lat, lng: view.lng });
//       });
//     });
//     return pathCoordinates;
//   };

//   if (loading) {
//     return <Loader loading={true} />;
//   }

//   if (!data.results.length) {
//     return (
//       <div className="insights-tab">
//         <h3>No Insights Available</h3>
//         <p>Insights data could not be found for this campaign.</p>
//       </div>
//     );
//   }

//   return (
//     <div className="insights-tab">
//       <h3>Insights for Campaign: {campaignId}</h3>
//       <Statistic data={data.results} />
//       <ViewsChart data={data.results} />
//       <div className="data-analyzer-chart">
//         <div className="chart-title">Map Overview</div>
//         <GoogleMap className="map" pathCoordinates={getPathCoordinates()} />
//       </div>
//       <AgeChart data={data.results} />
//     </div>
//   );
// };

// Insights.propTypes = {
//   campaign: PropTypes.shape({
//     id: PropTypes.string,
//   }),
// };

// export default Insights;
