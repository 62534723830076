import React, { useEffect, useState } from "react";
import GoogleMap from "@client.components/GoogleMap_Device";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceByIdAsync, makeDeviceInfo } from "../../reducers/Device";
import Loader from "@client.core.components/Loader";
import useStableParams from "@client.hooks/useStableParams";
import { getProfileAsync } from "@client.pages/Login/reducer";
import Title from "@client.components/Title";
import DeviceOverview from "./DeviceOverview";
import NoDataPlaceholder from "@client.core.components/NoDataPlaceholder";
import DeviceMapFilter from "./DeviceMapFilter";
import "./Device.scss";

let followIntervalId = null;

const Device = () => {
  const dispatch = useDispatch();

  const { id } = useStableParams();
  const [isFollow, setIsFollow] = useState(false);
  const overview = useSelector(makeDeviceInfo);
  const loading = !overview || !overview.device;

  // console.log("Device -> overview", overview.device);
  // console.log("Device -> overview lat", overview.device.latitude);
  // console.log("Device -> overview lon", overview.device.longitude);
  useEffect(() => {
    dispatch(getDeviceByIdAsync(id));
  }, [dispatch, id]);

   useEffect(() => {
    // Fetch device data every 50 seconds
    const intervalId = setInterval(() => {
      dispatch(getDeviceByIdAsync(id));
    }, 2000); // 2 seconds

    return () => {
      clearInterval(intervalId); // Clear interval on component unmount
    };
  }, [dispatch, id]);

  useEffect(() => {
    const clearFollowInterval = () => {
      if (followIntervalId) {
        clearInterval(followIntervalId);
      }
    };
    if (isFollow) {
      clearFollowInterval();
      followIntervalId = setInterval(
        () => dispatch(getDeviceByIdAsync(id)),
        1000
      );
    } else {
      clearFollowInterval();
    }

    return () => {
      clearFollowInterval();
    };
  }, [isFollow]);

  useEffect(() => {
    dispatch(getProfileAsync());
  }, []);

  if (loading) {
    return (
      <div>
        <Loader loading={true} />
      </div>
    );
  }

  if (!overview || !overview.device) {
    return <NoDataPlaceholder description="No device data available" />;
  }

  const interval = () => {};

  return (
    <div className="device-page page">
      <Title text={`Device: ${overview.device.deviceName}`} />
      {/* {console.log("Device -> overview", overview.device)} */}
      <div className="content">
        <DeviceOverview />
        <GoogleMap
          interval={interval}
          width="100%"
          height="76vh"
          options={{
            mapTypeControl: false,
          }}
          recenterMapTimestamp={Date.now()} // Trigger re-centering only when needed
          markers={[
            {
              lat: overview.device.latitude || 0,
              lng: overview.device.longitude || 0,
            },
          ]}
        >
          <DeviceMapFilter
            onFilterChange={(filter) => {
              setIsFollow(filter.isFollow);
            }}
          />
        </GoogleMap>
      </div>
    </div>
  );
};

export default Device;
