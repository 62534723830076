import BaseModel from "./base";
import { get } from "lodash";

class InvoiceModel extends BaseModel {
  constructor(invoice = {}, msg = {}) {
    super(msg);

    // Retain only necessary fields
    this.Id = get(invoice, "Id", "");
    this.subscriptionId = get(invoice, "subscriptionId", "");
    this.invoiceDate = get(invoice, "invoiceDate", ""); // Default to 0
    this.dueDate = get(invoice, "dueDate", "");
    this.amount = get(invoice, "amount", "");
    this.status = get(invoice, "status", "");

    // Define validation rules for the required fields
    this.validationRules = {
      subscriptionId: {
        presence: {
          allowEmpty: false,
          message: msg.name || "subscription is required"
        }
      },
      invoiceDate: {
        presence: {
          allowEmpty: false,
          message: msg.invoiceDate || "invoice date is required"
        }
      },
      dueDate: {
        presence: {
          allowEmpty: false,
          message: msg.dueDate || "due date is required"
        }
      },
      amount: {
        presence: {
          allowEmpty: false,
          message: msg.amount || "amount is required"
        }
      },
      status: {
        presence: {
          allowEmpty: false,
          message: msg.status || "status is required"
        }
      }
    };
  }

  // Define the fields to be used in mutations or forms
  get fields() {
    return {
      subscriptionId: this.subscriptionId,
      invoiceDate: this.invoiceDate,
      dueDate: this.dueDate,
      amount: this.amount,
      status: this.status,
      Id: this.Id || null // Add Id only if it exists
    };
  }
}

export default InvoiceModel;
