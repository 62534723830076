import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "@client.components/DataTable";
import Button from "@client.core.components/Button";
import { useTranslation } from "react-i18next";
import {
  fetchDeviceRegistrationsAsync,
  makeDeviceRegistrations,
  deviceRegistrationsJudgementAsync,
  fetchDeviceRegistrationFlagStatusAsync,
  modifyDeviceRegistrationFlagsAsync,
  makeDeviceRegistrationFlagStatus,
} from "../../reducers/Device";
import DeviceRegistrationsStatuses from "../../enums/deviceRegistrationsStatuses";
import "./DeviceApproval.scss";
import Input from "@client.core.components/Input";
import Switch from "react-switch";
import Icon from "@client.core.components/Icon"; // Import Icon Component

const ITEMS_PER_PAGE = 10;

const DeviceApproval = React.forwardRef(({ onClose }, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef(null); // Reference for the DataTable

  const deviceRegistrations = useSelector(makeDeviceRegistrations);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({
    query: "",
    startDate: "",
    endDate: "",
  });
  const [autoRegistration, setAutoRegistration] = useState(false); // Default value

  useEffect(() => {
    dispatch(fetchDeviceRegistrationFlagStatusAsync())
      .unwrap()
      .then((data) => {
        setAutoRegistration(data.deviceRegistrationFlagStatus); // Update local state with the fetched value
      })
      .catch((error) => {
        console.error("Error fetching flag status:", error);
      });
  }, [dispatch]);

  // const deviceFlagStatus = useSelector(makeDeviceRegistrationFlagStatus);
  // console.log("deviceFlagStatus", deviceFlagStatus);

  const handleToggleFlag = () => {
    const newValue = !autoRegistration; // Toggle the current value
    dispatch(modifyDeviceRegistrationFlagsAsync(newValue))
      .unwrap()
      .then(() => {
        setAutoRegistration(newValue); // Update the local state
        console.log("Auto Registration toggled to:", newValue);
      })
      .catch((error) => {
        console.error("Error toggling auto registration:", error);
      });
  };

  //  const filter = useSelector(makeDevicesFilter);
  // **NEW CHANGE: UseImperativeHandle** to expose the `selectedDevices` method
  React.useImperativeHandle(ref, () => ({
    selectedDevices: () => {
      if (gridRef.current) {
        return gridRef.current.api.getSelectedRows();
      }
      return [];
    },
  }));

  useEffect(() => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;
    dispatch(
      fetchDeviceRegistrationsAsync({
        limit: ITEMS_PER_PAGE,
        offset,
        search: "",
        searchOn: "",
        startDate: "",
        endDate: "",
      })
    );
  }, [dispatch, currentPage]);

  const handleApproval = useCallback(
    (id, status) => {
      dispatch(deviceRegistrationsJudgementAsync({ id, status }))
        .unwrap()
        .then(() => {
          console.log(
            `${status === 2 ? "Confirmed" : "Cancelled"} for device: ${id}`
          );
        })
        .catch((error) => {
          console.error(`Judgement Failed for ID: ${id}`, error);
        });
    },
    [dispatch]
  );

  const handleSearch = (query) => {
    console.log("searching for", query);
    setFilter((prev) => ({
      ...prev,
      query,
    }));

    dispatch(
      fetchDeviceRegistrationsAsync({
        limit: 10,
        offset: 0,
        search: query,
        searchOn: "machine_id", // Replace with appropriate field
        startDate: filter.startDate,
        endDate: filter.endDate,
      })
    );
  };

  return (
    <div className="device-approval">
      {/* <div className="modal-component-title">
  Device Approval
  <Icon
    name="close"
    className="modal-component-close-button"
    onClick={onClose} // Use the onClose handler for closing
  />
</div> */}
      <div className="search">
        <Input
          // label="Search"
          type="text"
          placeholder="Search by Machine ID"
          value={filter.query}
          onChange={(e) => {
            // console.log("searching for", e);
            handleSearch(e);
          }}
        />

        {/* <Button
          text={`Turn Auto Registration ${autoRegistration ? "Off" : "On"}`}
          onClick={handleToggleFlag}
        /> */}
         <div className="toggle-container">
          {/* <span>Auto Registration</span> */}
          <Switch
            onChange={handleToggleFlag}
            checked={autoRegistration}
            onColor="#ff007a"
            offColor="#ccc"
            uncheckedIcon={false}
            checkedIcon={false}
          />
        </div>
      </div>
      <DataTable
        ref={gridRef}
        columns={[
          {
            field: "index",
            headerName: "#",
            valueGetter: (params) => params.node.childIndex + 1,
            maxWidth: 50,
          },
          {
            field: "createdAt",
            headerName: "Received At",
            cellRenderer: (params) =>
              new Date(params.data.createdAt).toLocaleString(),
          },
          {
            field: "machineId",
            headerName: "Machine ID",
            minWidth: 290,
          },
          {
            field: "lastTelemetry.uptime",
            headerName: "Device Uptime",
            cellRenderer: (params) =>
              params.data?.lastTelemetry?.uptime || "N/A",
            maxWidth: 120,
          },
          {
            field: "status",
            headerName: "Status",
            cellRenderer: (params) =>
              t(DeviceRegistrationsStatuses[params.data.status] || "Unknown"),
          },

          {
            colId: "actions",
            headerName: "",
            cellRenderer: ({ data }) =>
              (autoRegistration&&data.status === 1) ? (
                <div className="action-buttons">
                  <button
                    className="confirm-button"
                    onClick={() => handleApproval(data.Id, 2)}
                  >
                    Approve
                  </button>
                  <button
                    className="cancel-button"
                    onClick={() => handleApproval(data.Id, 1)}
                  >
                    Reject
                  </button>
                </div>
              ) : null,
          },
        ]}
        data={deviceRegistrations.results}
        pagination={{
          total: deviceRegistrations.count,
          itemsPerPage: ITEMS_PER_PAGE,
          onChange: setCurrentPage,
        }}
        isLoading={deviceRegistrations.loading}
      />
    </div>
  );
});

DeviceApproval.propTypes = {
  onClose: PropTypes.func.isRequired,
};

DeviceApproval.displayName = "DeviceApproval";

export default DeviceApproval;
