import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Input from "@client.core.components/Input";
import SectionDescription from "../SectionDescription";
import {
  getCampaignTargetAsync,
  makeTargets,
} from "../../../reducers/Campaign";
import CampaignModel from "@client.models/campaign";
import TagList from "@client.components/TagList";
import Button from "@client.core.components/Button";
import { validateModel } from "@client.utils/form";
import AdvertiserDropdown from "@client.components/AdvertiserDropdown";
import {
  getAllAdvertisersAsync,
  makeAdvertisers,
} from "@client.pages/Advertiser/reducer";
import "./GeneralInformation.scss";
import {
  getProfileAsync,
  makeProfile,
  makeProfileAccount,
} from "@client.pages/Login/reducer";
import CustomerType from "@client.enums/customerType";
import DateTimeRangePicker from "@client.components/DateTimeRangePicker/DateTimeRangePicker";
import UserAssets from "../UserAssets";
import CustomDateRangePicker_TwoFields from "@client.components/CustomDateRangePicker_TwoFields";
import { setActiveStep, makeActiveStep, setOkPressed, makeOkPressed } from "../../../reducers/Campaign";

const GeneralInformation = ({ model, setModel, edit, onSave, onEdit }) => {
  // console.log("From GeneralInformation.js: model", model);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const targets = useSelector(makeTargets);
  const advertisers = useSelector(makeAdvertisers);
  // console.log("advertiserssss", advertisers[0]);
  const profile = useSelector(makeProfile);
  const account = useSelector(makeProfileAccount);
  const [isEdit, setIsEdit] = useState(true);
  // const [nextPressed, setNextPressed] = useState(false);

  // const [model, setModel] = useState(
  //   new CampaignModel({
  //     ...campaign,
  //     startDate: campaign.startDate ? moment(campaign.startDate).toDate() : new Date(),
  //     endDate: campaign.endDate ? moment(campaign.endDate).toDate() : new Date(),
  //   })
  // );

  useEffect(() => {
    dispatch(getAllAdvertisersAsync());
    dispatch(getProfileAsync());
  }, [dispatch]);

  useEffect(() => {
    setIsEdit(!!edit);
  }, [edit]);

  useEffect(() => {
    dispatch(getCampaignTargetAsync());
  }, [dispatch]);

  const changeModel = useCallback(
    (v, field) => {
      setModel(
        new CampaignModel({ ...model, [field]: v }, model.validationMsgs || {})
      );
    },
    [model]
  );

  const title = t("pages.CreateCampaign.section.generalInformation");

  if (!isEdit) {
    const target = targets.find((t) => t.id === model.target);

    return (
      <div className="general-information minimized">
        <div>
          <div className="step-title">{title}</div>
          <span className="step-description">
            {t("pages.CreateCampaign.minimizedInfoFormat", {
              name: model.campaignName,
              targetName: target?.name || "",
              dateFrom: moment(model.startDate).isValid()
                ? moment(model.startDate).format("YYYY-MM-DD HH:mm:ss.SSS")
                : "Invalid Date",
              dateTo: moment(model.endDate).isValid()
                ? moment(model.endDate).format("YYYY-MM-DD HH:mm:ss.SSS")
                : "Invalid Date",
            })}
          </span>
        </div>
        <Button
          icon="edit"
          text={t("pages.CreateCampaign.edit")}
          className="wizard"
          onClick={onEdit}
        />
      </div>
    );
  }

  const showAdvertisers = [
    CustomerType.Platform,
    CustomerType.Reseller,
  ].includes(account.entity);

  const getValidationRules = () => {
    const rules = {
      campaignName: {
        presence: {
          allowEmpty: false,
          message: t("pages.CreateCampaign.generalInfo.validation.name"),
        },
      },
      target: {
        presence: {
          allowEmpty: false,
          message: t(
            "pages.CreateCampaign.generalInfo.validation.campaignTarget"
          ),
        },
      },
      mediaId: {
        presence: {
          allowEmpty: false,
          message: t("pages.CreateCampaign.generalInfo.validation.mediaId"),
        },
      },
      startDate: {
        presence: {
          allowEmpty: false,
          message: t("pages.CreateCampaign.generalInfo.validation.startDate"),
        },
        datetime: {
          earliest: new Date(),
          message: t(
            "pages.CreateCampaign.generalInfo.validation.startDateFuture"
          ),
        },
      },
      endDate: {
        presence: {
          allowEmpty: false,
          message: t("pages.CreateCampaign.generalInfo.validation.endDate"),
        },
        datetime: {
          earliest: new Date(),
          message: t(
            "pages.CreateCampaign.generalInfo.validation.endDateFuture"
          ),
        },
      },
    };

    if (showAdvertisers) {
      rules.advertiserId = {
        presence: {
          allowEmpty: false,
          message: t("pages.CreateCampaign.generalInfo.validation.userId"),
        },
      };
    }
    return rules;
  };

  return (
    <div className="general-information">
      <div className="step-title">{title}</div>
      <div style={{ width: "95%" }}>
        <div className="campaign-section">
          <Input
            label={t("pages.CreateCampaign.campaignName")}
            value={model.campaignName}
            onChange={(v) => changeModel(v, "campaignName")}
          />
          {showAdvertisers && (
            <AdvertiserDropdown
              value={model.advertiserId}
              label="Advertisers"
              fromCreate={true}
              onSelect={(option) => {
                changeModel(option, "advertiserId");
                setModel(
                  new CampaignModel(
                    {
                      ...model,
                      advertiserId: option,
                    },
                    model.validationMsgs
                  )
                );
              }}
            />
          )}
        </div>

        <SectionDescription
          title="pages.CreateCampaign.campaignDates"
          description="pages.CreateCampaign.campaignDates.description"
        />
        {/* <DateTimeRangePicker
          showTimeSelect={false}
          dateRange={{
            start: moment(model.startDate).isValid()
              ? model.startDate
              : new Date(),
            end: moment(model.endDate).isValid()
              ? model.endDate
              : new Date(),
          }}
          captionStart={t("pages.CreateCampaign.startDate")}
          captionEnd={t("pages.CreateCampaign.endDate")}
          onChange={(startDate, endDate) => {
            setModel(
              new CampaignModel(
                {
                  ...model,
                  startDate: moment(startDate).isValid()
                    ? moment(startDate).format("YYYY-MM-DD HH:mm:ss.SSS")
                    : null,
                  endDate: moment(endDate).isValid()
                    ? moment(endDate).format("YYYY-MM-DD HH:mm:ss.SSS")
                    : null,
                },
                model.validationMsgs || {}
              )
            );
          }}
        /> */}
        <CustomDateRangePicker_TwoFields
          dateFrom={
            moment(model.startDate).isValid() ? new Date(model.startDate) : null
          }
          dateTo={
            moment(model.endDate).isValid() ? new Date(model.endDate) : null
          }
          label={t("pages.CreateCampaign.startDate")}
          label2={t("pages.CreateCampaign.endDate")}
          onChange={(selectedDates) => {
            const [startDate, endDate] = selectedDates;
            setModel(
              new CampaignModel(
                {
                  ...model,
                  startDate: moment(startDate).isValid()
                    ? moment(startDate).format("YYYY-MM-DD HH:mm:ss.SSS")
                    : null,
                  endDate: moment(endDate).isValid()
                    ? moment(endDate).format("YYYY-MM-DD HH:mm:ss.SSS")
                    : null,
                },
                model.validationMsgs || {}
              )
            );
          }}
        />
      </div>
      <SectionDescription
        title="pages.CreateCampaign.campaignTarget"
        description="pages.CreateCampaign.campaignTarget.description"
      />
      <TagList
        items={targets}
        selected={`${model.target}`}
        valueKey="id"
        nameKey="name"
        onSelect={(id) => changeModel(id, "target")}
      />
      <SectionDescription
        title="pages.CreateCampaign.uploadCampaignImage"
        description="pages.CreateCampaign.uploadCampaignImage.description"
      />
      {model.advertiserId && (
        <UserAssets
          userId={model.advertiserId || ""} // Pass the selected advertiser ID dynamically
          // selectedFileId={model.mediaId}
          currentFileId={model.mediaId}
          selectedFileId={model.fileId}
          onSelect={(data) => {
            setModel(
              new CampaignModel(
                {
                  ...model,
                  mediaId: data.external_id,
                },
                model.validationMsgs
              )
            );
          }}
        />
      )}
      <Button
        text={t("pages.CreateCampaign.next")}
        className="btn-next wizard"
        onClick={() => {
          const isValid = validateModel(model, getValidationRules());

          if (isValid) {
            onSave(model, true);
          }
        }}
      />
    
    
    {/* <Button
  text={t("pages.CreateCampaign.next")}
  className="btn-next wizard"
  onClick={() => {
    const isValid = validateModel(model, getValidationRules());
    dispatch(setOkPressed(isValid)); // Update Redux state for "Next" pressed

    if (isValid) {
      onSave(model, true); // Proceed to the next step
    }
  }}
/>

*/}


{/* <Button
  text={t("pages.CreateCampaign.next")}
  className="btn-next wizard"
  onClick={() => {
    const isValid = validateModel(model, getValidationRules());
    if (isValid) {
      dispatch(setOkPressed(true)); // Trigger "Next" in Redux
      onSave(model); // Save the step data
    }
  }}
/> */}



    </div>
  );
};

GeneralInformation.propTypes = {
  edit: PropTypes.bool,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  model: PropTypes.shape({
    campaignName: PropTypes.string,
    advertiserId: PropTypes.string,
    validationMsgs: PropTypes.string,
    fileId: PropTypes.string,
    mediaId: PropTypes.string,
    target: PropTypes.string,
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
  }),
  setModel: PropTypes.func,
};

export default GeneralInformation;
