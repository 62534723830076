import React from "react";
import PropTypes from "prop-types";
import Pagination from "rc-pagination";
import { useTranslation } from "react-i18next";
import "rc-pagination/assets/index.css";
import "./Pagination.scss";

const ITEMS_PER_PAGE = 10;

const DataTablePagination = ({
  total,
  onChange,
  itemsPerPage = ITEMS_PER_PAGE,
}) => {

  
  const { t } = useTranslation();
  return (
    total > itemsPerPage && (
      <Pagination
        showTitle={false}
        className="pagination"
        onChange={onChange}
        showTotal={(total, range) =>
          `${t("components.Pagination.TotalFormat", {
            from: range[0],
            to: range[1],
            total: total,
          })}`
        }
        pageSize={itemsPerPage}
        total={total}
      />
    )
  );
};

DataTablePagination.propTypes = {
  onChange: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number,
};

export default DataTablePagination;
