//trying clean

import React, { useCallback, forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedAssetsAsync,
  makeAdvertisersLoading,
  uploadUserAssetAsync,
} from "@client.pages/Advertiser/reducer";
import Loader from "@client.core.components/Loader";
import MediaFile from "@client.components/MediaFile";
import Uploader from "@client.components/Uploader";
import config from "@client.config";
import UserAsset from "@client.models/userAsset";
import ServiceProvider from "@client.services/provider";
import { MODAL_EVENT } from "@client.components/Modal";
import Button from "@client.core.components/Button";

import "./UserAssets.scss";

const UserAssets = forwardRef(
  ({ onSelect, userId, selectedFileId, currentFileId }, ref) => {
    // console.log("from UserAssets currentFileId:", currentFileId);
    // console.log("from UserAssets selectedFileId:", selectedFileId);
    const dispatch = useDispatch();
    const isUploading = useSelector(makeAdvertisersLoading);
    const [assets, setAssets] = useState([]);
    const [assetsMetaData, setAssetsMetaData] = useState({});
    const [isLoadingAssets, setIsLoadingAssets] = useState(true);

    // Clean the currentFileId
    const cleanedSelectedFileId = currentFileId?.startsWith(
      "https://core-ams-01.kaligon.com:60000/api/download/"
    )
      ? currentFileId.replace(
          "https://core-ams-01.kaligon.com:60000/api/download/",
          ""
        )
      : currentFileId;

    // console.log("from UserAssets cleanedSelectedFileId:", cleanedSelectedFileId);
    const [model, setModel] = useState(
      new UserAsset({ userLookupId: userId, lookupId: cleanedSelectedFileId })
    );

    // Update the model when selectedFileId changes
    useEffect(() => {
      const cleanedId = currentFileId?.startsWith(
        "https://core-ams-01.kaligon.com:60000/api/download/"
      )
        ? currentFileId.replace(
            "https://core-ams-01.kaligon.com:60000/api/download/",
            ""
          )
        : currentFileId;

      setModel(new UserAsset({ ...model, lookupId: cleanedId }));

      // console.log("from UserAssets cleanedId:", cleanedId);
      // console.log("from UserAssets model:", model );
    }, [selectedFileId]);

    useEffect(() => {
      setIsLoadingAssets(true);
      dispatch(getSelectedAssetsAsync(userId))
        .then((response) => {
          const fetchedAssets = response.payload?.data?.medias?.results || [];
          setAssets(fetchedAssets);
        })
        .finally(() => setIsLoadingAssets(false));
    }, [dispatch, userId]);

    const handleAssetClick = (asset) => {
      ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
        show: true,
        withBodyRef: true,
        title: "Confirm Action",
        component: (
          <div className="modal-content">
            <p>Do you want to use this photo?</p>
            <div className="modal-buttons">
              <Button onClick={() => handleYes(asset)} text="Yes" />
              <Button onClick={handleNo} text="No" />
            </div>
          </div>
        ),
      });
    };

    const handleYes = (asset) => {
      ServiceProvider.EventEmitter.emit(MODAL_EVENT, { show: false });
      onSelect({ id: asset.Id, external_id: asset.externalId });
      setModel(new UserAsset({ ...model, lookupId: asset.Id }));
    };

    const handleNo = () => {
      ServiceProvider.EventEmitter.emit(MODAL_EVENT, { show: false });
    };

    return (
      <div className="user-assets" ref={ref}>
        {/* {isUploading ? (
        <Loader loading={true} />
      ) : (
        <Uploader
          onChange={(file) => {
            model.file = file;
            model.userLookupId = userId;
            dispatch(uploadUserAssetAsync(model))
              .then((response) => {
                const data = response.payload || {};
                onSelect({ id: data.id, external_id: data.external_id });
                dispatch(getSelectedAssetsAsync(userId)).then((response) => {
                  setAssets(response.payload?.data?.medias?.results || []);
                });
              });
          }}
        />
      )} */}
        {isUploading ? (
          <Loader loading={true} />
        ) : (
          <Uploader
            onChange={(file) => {
              model.file = file;
              model.kgn_account_id = userId;
              dispatch(uploadUserAssetAsync(model))
                .then((resp) => {
                  const data = resp?.payload || {};
                  onSelect({ id: data.id, external_id: data.external_id });
                  dispatch(getSelectedAssetsAsync(userId)).then((response) => {
                    const updatedAssets =
                      response.payload?.data?.medias?.results || [];
                    setAssets(updatedAssets);
                  });
                })
                .catch(() => {});
            }}
          />
        )}
        <Loader loading={isLoadingAssets} />
        {!isLoadingAssets && assets.length > 0 ? (
          <div className="gallery">
            {assets.map((asset) =>
              cleanedSelectedFileId === "defaultMediaId" ? (
                <div
                  key={asset.Id}
                  className={classNames("gallery-item", {
                    selected: asset.Id === model.lookupId,
                  })}
                  onClick={() => handleAssetClick(asset)}
                >
                  <MediaFile
                    src={`${config.downloadUrl}/${asset.externalId}`}
                  />
                  {asset.Id === model.lookupId && (
                    <div className="selected-label">Selected</div>
                  )}
                </div>
              ) : (
                <div
                  key={asset.Id}
                  className={classNames("gallery-item", {
                    selected: cleanedSelectedFileId === asset.externalId,
                  })}
                  onClick={() => handleAssetClick(asset)}
                >
                  <MediaFile
                    src={`${config.downloadUrl}/${asset.externalId}`}
                  />
                  {cleanedSelectedFileId === asset.externalId && (
                    <div className="existing-label">Selected</div>
                  )}
                </div>
              )
            )}
          </div>
        ) : (
          !isLoadingAssets && <div>No assets found</div>
        )}
      </div>
    );
  }
);

UserAssets.displayName = "UserAssets";

UserAssets.propTypes = {
  onSelect: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  selectedFileId: PropTypes.string,
  currentFileId: PropTypes.string,
};

export default UserAssets;

//before clean

// import React, { useCallback, forwardRef, useEffect, useState } from "react";
// import PropTypes from "prop-types";
// import classNames from "classnames";
// import { useTranslation } from "react-i18next";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getSelectedAssetsAsync,
//   makeAdvertisersLoading,
//   uploadUserAssetAsync,
// } from "@client.pages/Advertiser/reducer";
// import Loader from "@client.core.components/Loader";
// import MediaFile from "@client.components/MediaFile";
// import Uploader from "@client.components/Uploader";
// import config from "@client.config";
// import UserAsset from "@client.models/userAsset";
// import ServiceProvider from "@client.services/provider";
// import { MODAL_EVENT } from "@client.components/Modal";
// import Button from "@client.core.components/Button";

// import "./UserAssets.scss";

// const UserAssets = forwardRef(({ onSelect, userId, selectedFileId }, ref) => {
//   const [assets, setAssets] = useState([]);
//   const isUploading = useSelector(makeAdvertisersLoading);
//   const [assetsMetaData, setAssetsMetaData] = useState({});
//   const [isLoadingAssets, setIsLoadingAssets] = useState(true);
//   const dispatch = useDispatch();
//   const { t } = useTranslation();

//   const [model, setModel] = useState(
//     new UserAsset({ userLookupId: userId, lookupId: selectedFileId })
//   );

//   React.useImperativeHandle(ref, () => ({
//     managementItem: model,
//     validate: () => validateModel(model, model.validationRules),
//   }));

//   useEffect(() => {
//     setIsLoadingAssets(true);
//     dispatch(getSelectedAssetsAsync(userId))
//       .then((response) => {
//         console.log("IDDDDDDD from UserAssets.js:", userId);
//         const fetchedAssets = response.payload?.data?.medias?.results || [];
//         setAssets(fetchedAssets);
//       })
//       .catch(() => {})
//       .finally(() => {
//         setIsLoadingAssets(false);
//       });
//   }, [dispatch, userId]);

//   const changeModel = useCallback(
//     (v, field) => {
//       setModel(new UserAsset({ ...model, [field]: v }, model.validationMsgs));
//     },
//     [model]
//   );

//   const handleAssetClick = (asset) => {
//     ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
//       show: true,
//       withBodyRef: true,
//       title: t("Confirm Action"),
//       component: (
//         <div className="modal-content">
//           <p className="modal-message">{t("Do you want to use this photo?")}</p>
//           <div className="modal-buttons">
//             <Button
//               className="Button"
//               onClick={() => handleYes(asset)}
//               text="Yes"
//             />
//             <Button
//               className="Button alternative"
//               onClick={() => handleNo()}
//               text="No"
//             />
//           </div>
//         </div>
//       ),
//     });
//   };

//   const handleYes = (asset) => {
//     ServiceProvider.EventEmitter.emit(MODAL_EVENT, { show: false });
//     onSelect({
//       id: asset.Id,
//       external_id: `${asset.externalId}`,
//     });
//     changeModel(asset.Id, "lookupId");
//   };

//   const handleNo = () => {
//     ServiceProvider.EventEmitter.emit(MODAL_EVENT, { show: false });
//   };

//   return (
//     <div className="user-assets" ref={ref}>
//       {isUploading ? (
//         <Loader loading={true} />
//       ) : (
//         <Uploader
//           onChange={(file) => {
//             model.file = file;
//             model.kgn_account_id = userId;
//             dispatch(uploadUserAssetAsync(model))
//               .then((resp) => {
//                 const data = resp?.payload || {};
//                 onSelect({ id: data.id, external_id: data.external_id });
//                 dispatch(getSelectedAssetsAsync(userId)).then((response) => {
//                   const updatedAssets =
//                     response.payload?.data?.medias?.results || [];
//                   setAssets(updatedAssets);
//                 });
//               })
//               .catch(() => {});
//           }}
//         />
//       )}

//       <Loader loading={isLoadingAssets} />

//       {!isLoadingAssets && assets.length > 0 ? (
//         <div className="gallery">
//           {assets.map((asset) => (
//             <div
//               key={asset.Id}
//               className={classNames("gallery-item", {
//                 selected: asset.Id === model.lookupId,
//               })}
//               onClick={() => handleAssetClick(asset)}
//             >
//               <MediaFile
//                 src={`${config.downloadUrl}/${asset.externalId}`}
//                 showThumbnail
//                 onLoad={({ width, height }) => {
//                   assetsMetaData[asset.Id] = { width, height };
//                   setAssetsMetaData({ ...assetsMetaData });
//                 }}
//               />
//               {asset.Id === model.lookupId && (
//                 <div className="selected-label">Selected</div>
//               )}
//             </div>
//           ))}
//         </div>
//       ) : (
//         !isLoadingAssets && <div>{t("components.UserAssets.noAssetsFound")}</div>
//       )}
//     </div>
//   );
// });

// UserAssets.displayName = "UserAssets";

// UserAssets.propTypes = {
//   onSelect: PropTypes.func.isRequired,
//   userId: PropTypes.string.isRequired,
//   selectedFileId: PropTypes.string,
// };

// export default UserAssets;

// //OG

// import React, { useCallback, forwardRef, useEffect, useState } from "react";
// import PropTypes from "prop-types";
// import classNames from "classnames";
// import { useTranslation } from "react-i18next";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getSelectedAssetsAsync,
//   makeAdvertisersLoading,
//   uploadUserAssetAsync,
// } from "@client.pages/Advertiser/reducer";
// import Loader from "@client.core.components/Loader";
// import MediaFile from "@client.components/MediaFile";
// import Uploader from "@client.components/Uploader";
// import config from "@client.config";
// import UserAsset from "@client.models/userAsset";
// import ServiceProvider from "@client.services/provider";
// import { MODAL_EVENT } from "@client.components/Modal";
// import Button from "@client.core.components/Button";

// import "./UserAssets.scss";

// const UserAssets = forwardRef(({ onSelect, userId, selectedFileId }, ref) => {
//   // console.log("from UserAssets.js userId:", userId);
//   const [assets, setAssets] = useState([]);
//   const isUploading = useSelector(makeAdvertisersLoading);
//   const [assetsMetaData, setAssetsMetaData] = useState({});
//   const [isLoadingAssets, setIsLoadingAssets] = useState(true);
//   const dispatch = useDispatch();
//   const { t } = useTranslation();

//   const [model, setModel] = useState(
//     new UserAsset({ userLookupId: userId, lookupId: selectedFileId })
//   );

//   React.useImperativeHandle(ref, () => ({
//     managementItem: model,
//     validate: () => validateModel(model, model.validationRules),
//   }));

//   useEffect(() => {
//     setIsLoadingAssets(true);
//     dispatch(getSelectedAssetsAsync(userId))
//       .then((response) => {
//         const fetchedAssets = response.payload?.data?.medias?.results || [];
//         setAssets(fetchedAssets);
//         // console.log("Assets fetched:", fetchedAssets);
//       })
//       .catch((error) => {
//         // console.error("Error fetching assets:", error);
//       })
//       .finally(() => {
//         setIsLoadingAssets(false);
//       });
//   }, [dispatch, userId]);

//   const changeModel = useCallback(
//     (v, field) => {
//       setModel(new UserAsset({ ...model, [field]: v }, model.validationMsgs));
//     },
//     [model]
//   );

//   const handleAssetClick = (asset) => {
//     // console.log("from UserAssets handleAssetClick:", asset);
//     ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
//       show: true,
//       withBodyRef: true,
//       title: t("Confirm Action"),
//       component: (
//         <div className="modal-content">
//           <p className="modal-message">{t("Do you want to use this photo?")}</p>
//           <div className="modal-buttons">
//             <Button className="Button " onClick={() => handleYes(asset)} text="Yes" />
//             <Button className="Button alternative" onClick={() => handleNo()} text="No" />
//           </div>
//         </div>
//       ),
//     });
//   };

//   const handleYes = (asset) => {
//     ServiceProvider.EventEmitter.emit(MODAL_EVENT, { show: false });
//     // console.log("from UserAssets.js11111111111111111111111 ", asset);
//     onSelect({
//       id: asset.Id,
//       external_id: `${asset.externalId}`,
//       // external_id: `${config.downloadUrl}/${asset.externalId}`,
//     });
//     changeModel(asset.Id, "lookupId");
//   };

//   const handleNo = () => {
//     ServiceProvider.EventEmitter.emit(MODAL_EVENT, { show: false });
//   };

//   return (
//     <div className="user-assets" ref={ref}>
//       {isUploading ? (
//         <Loader loading={true} />
//       ) : (
//         <Uploader
//           onChange={(file) => {
//             // console.log("from UserAssets file:", file);
//             model.file = file;
//             model.kgn_account_id = userId;
//             dispatch(uploadUserAssetAsync(model))
//               .then((resp) => {
//                 const data = resp?.payload || {};
//                 onSelect({ id: data.id, external_id: data.external_id });
//                 dispatch(getSelectedAssetsAsync(userId)).then((response) => {
//                   const updatedAssets = response.payload?.data?.medias?.results || [];
//                   // console.log("Assets updatedddddd in uploader:", updatedAssets);
//                   setAssets(updatedAssets);
//                 });
//               })
//               // .catch((error) => console.error("Error uploading asset:", error));
//           }}
//         />
//       )}

//       <Loader loading={isLoadingAssets} />

//       {!isLoadingAssets && assets.length > 0 ? (
//         <div className="gallery">
//           {/* {console.log("Assets fetcheddddd in gallery:", assets)} */}
//           {assets.map((asset) => (
//             // console.log("from UserAssets asset:", asset),
//             <div
//               key={asset.Id}
//               className={classNames("gallery-item", { selected: asset.Id === model.lookupId })}
//               onClick={() => handleAssetClick(asset)}
//             >
//               <MediaFile
//                 src={`${config.downloadUrl}/${asset.externalId}`}
//                 showThumbnail
//                 onLoad={({ width, height }) => {
//                   assetsMetaData[asset.Id] = { width, height };
//                   setAssetsMetaData({ ...assetsMetaData });
//                 }}
//               />
//             </div>
//           ))}
//         </div>
//       ) : (
//         !isLoadingAssets && <div>{t("components.UserAssets.noAssetsFound")}</div>
//       )}
//     </div>
//   );
// });

// UserAssets.displayName = "UserAssets";

// UserAssets.propTypes = {
//   onSelect: PropTypes.func.isRequired,
//   userId: PropTypes.string.isRequired,
//   selectedFileId: PropTypes.string,
// };

// export default UserAssets;
