import React from "react";
import PropTypes from "prop-types";
import Dropdown from "@client.core.components/Dropdown";
import SubscriptionsTypes from "@client.enums/subsType";
import { useTranslation } from "react-i18next";

const SubsTypeDropDown = ({ value, onSelect, label }) => {
  const { t } = useTranslation();
  // const profile = useSelector(makeProfile);// This is the profile of the logged in user.. the role of the logged in user is used to determine the roles that can be assigned to a user
  const actions = [
    {
      value: SubscriptionsTypes.Active,
      label: t("pages.subscription.columns.Active")
    },
    {
      value: SubscriptionsTypes.Cancelled,
      label: t("pages.subscription.columns.Cancelled")
    },
    {
      value: SubscriptionsTypes.PastDue,
      label: t("pages.subscription.columns.PastDue")
    },
    {
      value: SubscriptionsTypes.Expired,
      label: t("pages.subscription.columns.Expired")
    },
    {
      value: SubscriptionsTypes.Trial,
      label: t("pages.subscription.columns.Trial")
    }
  ];

  return (
    <Dropdown
      items={actions}
      defaultValue={value}
      label={label}
      valueKey="value"
      labelKey="label"
      onPrepareValue={(v) => parseInt(v)}
      onSelect={onSelect}
    />
  );
};

SubsTypeDropDown.defaultProps = {
  value: null,
  label: null
};

SubsTypeDropDown.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired
};

export default SubsTypeDropDown;