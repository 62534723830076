import ServiceProvider from "@client.services/provider";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { IDashboardFilter, IDashboardState } from "./type";
import DeviceTypes from "@client.enums/deviceTypes";

const DEFAULT_DASHBOARD = {
  activeCampaigns: null,
  dailyAdPlays: null,
  dailyAdViews: null,
  dailyBudgetSpent: null,
  deviceMap: [],
  deviceHeatmap: [],
  deviceLocations: [],
};

const initialState: IDashboardState = {
  platformDashboard: DEFAULT_DASHBOARD,
  loading: false,
  error: null,
  filter: {
    isUnknown: true,
    isDynamic: true,
    isStatic: true,
    isOnline: true,
    isOffline: true,
    enableHeatmap: false,
    // enableHeatmap: true,
    enableLocations: false,
    // enableLocations: true,
    id: "",
  },
};

export const fetchPlatformDashboardAsync = createAsyncThunk(
  "dashboard/fetchPlatformDashboard",
  async () => {
    return ServiceProvider.Dashboard.getDashboardBoxes();
  }
);

export const fetchMapAsync = createAsyncThunk(
  "dashboard/fetchMap",
  async (_, { getState }) => {
    const state = getState();
    const filter = makeDashboardFilter(state);
    console.log("filter", filter);
    const mapFilter: IDashboardFilter = {
      deviceStatus: filter.isOnline,
      deviceType: [],
      search: filter.id,
      showHeatmap: filter.enableHeatmap,
      showZones: filter.enableLocations,
    };

    if (filter.isUnknown) {
      mapFilter.deviceType.push(DeviceTypes.Unknown);
    }
    if (filter.isDynamic) {
      mapFilter.deviceType.push(DeviceTypes.Dynamic);
    }
    if (filter.isStatic) {
      mapFilter.deviceType.push(DeviceTypes.Static);
    }
    return ServiceProvider.Dashboard.getMap(mapFilter);
  }
);

// Create a slice for the reducer
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setDashboardFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlatformDashboardAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlatformDashboardAsync.fulfilled, (state, action) => {
        state.loading = false;
        const response = action.payload || [];
        state.platformDashboard = DEFAULT_DASHBOARD;
        state.platformDashboard = get(
          response,
          "data.dashboardBoxes",
          DEFAULT_DASHBOARD
        );
      })
      .addCase(fetchPlatformDashboardAsync.rejected, (state, action) => {
        state.loading = false;
        state.platformDashboard = DEFAULT_DASHBOARD;
        state.error = action.error.message || null;
      })
      .addCase(fetchMapAsync.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchMapAsync.fulfilled, (state, action) => {
        state.loading = false;
        const response = get(action, "payload.data.map", {
          devices: {},
          heatmap: {},
          zones: {},
        });
        state.platformDashboard.deviceMap = response.devices?.results || [];
        state.platformDashboard.deviceHeatmap = response.heatmap?.results || [];
        state.platformDashboard.deviceLocations = response.zones?.results || [];
      })

      // .addCase(fetchMapAsync.fulfilled, (state, action) => {
      //   state.loading = false;
      //   const response = get(action, "payload.data.map", {
      //     devices: {},
      //     heatmap: {},
      //     zones: {},
      //   });
      
      //   state.platformDashboard.deviceMap = response.devices?.results || [];
      //   state.platformDashboard.deviceHeatmap = response.heatmap?.results || [];
      //   state.platformDashboard.deviceLocations =
      //     response.zones?.results.map((zone) => ({
      //       ...zone,
      //       polygon: zone.polygon
      //         ? (Array.isArray(zone.polygon)
      //             ? zone.polygon
      //             : typeof zone.polygon === "string"
      //               ? JSON.parse(zone.polygon)
      //               : []
      //           ).map(([lng, lat]) => ({ lat, lng }))
      //         : [],
      //     })) || [];
      // })
      


      .addCase(fetchMapAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      });
  },
});

export const { setLoading, setError, setDashboardFilter } =
  dashboardSlice.actions;

export const makeDashboardFilter = (state) => state.dashboard.filter;
export const makePlatformDashboard = (state) =>
  state.dashboard.platformDashboard;
export const makeDashboardLoading = (state) => state.dashboard.loading;
export const makeDashboardError = (state) => state.dashboard.error;

export default dashboardSlice.reducer;
