import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "@client.components/DataTable";
import { getColumns } from "./consts";
import Title from "@client.components/Title";
import ServiceProvider from "@client.services/provider";
import "./CampaignApprovals.scss";
import CampaignStatuses from "@client.enums/campaignStatuses";
import {
  getAllAccountsAsync,
  makeAccountHierarchy,
  setSelectedAccount,
} from "../Accounts/reducer";
import { useDispatch, useSelector } from "react-redux";
import NoDataPlaceholder from "@client.core.components/NoDataPlaceholder";
import CampaignApprovalSummary from "./CampaignApprovalSummary";

const ITEMS_PER_PAGE = 15;

const CampaignApprovals = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  // Fetch accounts on mount
  useEffect(() => {
    dispatch(getAllAccountsAsync());
  }, [dispatch]);

  const accounts = useSelector(makeAccountHierarchy);
  // console.log("Accounts: ", accounts);
  // Helper function to map advertiserId to companyName
  const enrichCampaignsWithAccountName = (campaigns, accounts) => {
    // console.log("Campaigns: ", campaigns);
    const accountMap = accounts.hierarchy.reduce((acc, account) => {
      acc[account.Id] = account.companyName;
      return acc;
    }, {});

    return campaigns.map((campaign) => ({
      ...campaign,
      companyName: accountMap[campaign.advertiserId] || "Unknown Advertiser",
    }));
  };

  const fetchCampaignApprovals = () => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;
    setIsLoading(true);
    ServiceProvider.Campaign.getAllCampaigns({
      endDate: "",
      startDate: "",
      limit: ITEMS_PER_PAGE,
      offset: offset,
      status: CampaignStatuses.PendingApproval,
    })
      .then((res) => {
        const enrichedCampaigns = enrichCampaignsWithAccountName(
          res.data.campaigns.results,
          accounts
        );
        setData(enrichedCampaigns);
        setTotalItems(res.data.campaigns.count);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    fetchCampaignApprovals();
  }, [currentPage, accounts]);

  // Handle row selection in DataTable
  const onSelectionChanged = (d) => {
    setSelectedCampaign(d.data);
    const advertiserId = d.data.advertiserId;
    const selectedAcc = accounts.hierarchy.find((acc) => acc.Id === advertiserId);

    if (selectedAcc) {
      dispatch(setSelectedAccount(selectedAcc));
    }
  };

  return (
    <div className="campaign-approvals page">
      <div className="campaign-container">
       
        <div className="campaign-content">
          <Title text="components.NavigationBar.campaignApprovals" />
          <div style={{ height: '100%' }}>
          <DataTable
            columns={getColumns(t)}
            data={data}
            isLoading={isLoading}
            onSelectionChanged={onSelectionChanged}
            pagination={{
              total: totalItems,
              ITEMS_PER_PAGE: ITEMS_PER_PAGE,
              onChange: setCurrentPage,
            }}
          />
          </div>
        </div>
        <div className="campaign-info">
          {selectedCampaign ? (
            <CampaignApprovalSummary model={selectedCampaign} approve={true} fetchCampaignApprovals={fetchCampaignApprovals} />
          ) : (
            <div className="no-selection">
              <NoDataPlaceholder description={t("pages.Accounts.noData")} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignApprovals;












// import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
// import DataTable from "@client.components/DataTable";
// import { getColumns } from "./consts";
// import Title from "@client.components/Title";
// import ServiceProvider from "@client.services/provider";
// import { MODAL_EVENT } from "@client.components/Modal";
// import ApproveCampaign from "./components/ApproveCampaign";
// import "./CampaignApprovals.scss";
// import CampaignStatuses from "@client.enums/campaignStatuses";
// import {
//   getAllAccountsAsync,
//   makeAccountHierarchy,
//   setSelectedAccount,
// } from "../Accounts/reducer";
// import { useDispatch, useSelector } from "react-redux";
// import CampaignInfo from "@client.components/CampaignInfo";

// const ITEMS_PER_PAGE = 15;

// const CampaignApprovals = () => {
//   const { t } = useTranslation();
//   const dispatch = useDispatch();
//   const [data, setData] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalItems, setTotalItems] = useState(0);

//   // Fetch accounts on mount
//   useEffect(() => {
//     dispatch(getAllAccountsAsync());
//   }, [dispatch]);

//   const accounts = useSelector(makeAccountHierarchy);

//   // Helper function to map advertiserId to companyName
//   const enrichCampaignsWithAccountName = (campaigns, accounts) => {
//     const accountMap = accounts.hierarchy.reduce((acc, account) => {
//       acc[account.Id] = account.companyName;
//       return acc;
//     }, {});

//     return campaigns.map((campaign) => ({
//       ...campaign,
//       companyName: accountMap[campaign.advertiserId] || "Unknown Advertiser",
//     }));
//   };

//   // Fetch campaigns
//   useEffect(() => {
//     const offset = (currentPage - 1) * ITEMS_PER_PAGE;

//     setIsLoading(true);
//     ServiceProvider.Campaign.getAllCampaigns({
//       endDate: "",
//       startDate: "",
//       limit: ITEMS_PER_PAGE,
//       offset: offset,
//       status: CampaignStatuses.PendingApproval,
//     })
//       .then((res) => {
//         const enrichedCampaigns = enrichCampaignsWithAccountName(
//           res.data.campaigns.results,
//           accounts
//         );
//         // console.log("Enriched Campaigns: ", enrichedCampaigns);
//         setData(enrichedCampaigns);
//         setTotalItems(res.data.campaigns.count);
//       })
//       .finally(() => {
//         setIsLoading(false);
//       });
//   }, [currentPage, accounts]);

//   // Handle row selection in DataTable
//   const onSelectionChanged = (d) => {
//     const advertiserId = d.data.advertiserId;
//     const selectedAcc = accounts.hierarchy.find((acc) => acc.Id === advertiserId);

//     if (selectedAcc) {
//       dispatch(setSelectedAccount(selectedAcc));
//       // console.log("Selected Account Details: ", selectedAcc);
//     } else {
//       // console.log("Account not found for advertiserId: ", advertiserId);
//     }

//     ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
//       show: true,
//       className: "approve-campaign-modal",
//       title: t("pages.CampaignApprovals.approveCampaign"),
//       component: <ApproveCampaign campaign={d.data} />,
//     });
//   };

//   // console.log("Accounts: ", accounts);

//   return (
//     <div className="campaign-approvals page">
//       <div className="campaign-container">
//         <div className="campaign-info">
//           <CampaignInfo model={data} estimation={null} approve={true} />
//         </div>
//         <div className="campaign-content">
//         <Title text="components.NavigationBar.campaignApprovals" />
//       <DataTable
//         columns={getColumns(t)}
//         data={data}
//         isLoading={isLoading}
//         onSelectionChanged={onSelectionChanged}
//         pagination={{
//           total: totalItems,
//           ITEMS_PER_PAGE: ITEMS_PER_PAGE,
//           onChange: setCurrentPage,
//         }}
//       />
//         </div>
//       </div>
      
//     </div>
//   );
// };

// export default CampaignApprovals;





















// import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
// import DataTable from "@client.components/DataTable";
// import { getColumns } from "./consts";
// import Title from "@client.components/Title";
// import ServiceProvider from "@client.services/provider";
// import { MODAL_EVENT } from "@client.components/Modal";
// import ApproveCampaign from "./components/ApproveCampaign";
// import "./CampaignApprovals.scss";
// import CampaignStatuses from "@client.enums/campaignStatuses";

// const ITEMS_PER_PAGE = 15;

// const CampaignApprovals = () => {
//   const { t } = useTranslation();
//   const [data, setData] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalItems, setTotalItems] = useState(0);

//   useEffect(() => {
//     const offset = (currentPage - 1) * ITEMS_PER_PAGE;

//     setIsLoading(true);
//     ServiceProvider.Campaign.getAllCampaigns({
//       endDate: "",
//       startDate: "",
//       limit: ITEMS_PER_PAGE,
//       offset: offset,
//       status: CampaignStatuses.PendingApproval,
//     })
//       .then((res) => {
//         setTotalItems(res.data.campaigns.count);
//         setData(res.data.campaigns.results);
//       })
//       .finally(() => {
//         setIsLoading(false);
//       });
//   }, []);

//   const onSelectionChanged = (d) => {
//     ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
//       show: true,
//       className: "approve-campaign-modal",
//       title: t("pages.CampaignApprovals.approveCampaign"),
//       component: <ApproveCampaign campaign={d.data} />,
//     });
//   };

//   return (
//     <div className="campaign-approvals page">
//       <Title text="components.NavigationBar.campaignApprovals" />
//       <DataTable
//         columns={getColumns(t)}
//         data={data}
//         isLoading={isLoading}
//         onSelectionChanged={onSelectionChanged}
//         pagination={{
//           total: totalItems,
//           ITEMS_PER_PAGE: ITEMS_PER_PAGE,
//           onChange: setCurrentPage,
//         }}
//       />
//     </div>
//   );
// };

// export default CampaignApprovals;
