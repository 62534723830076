import { IDashboardFilter } from "@client.features/main/type";
import BaseService from "@client.services/baseService";

export default class DashboardService extends BaseService {
  async getSystemLogs() {
    return super.post("", {
      query: `query {
                fetchSystemLogs {
                  count
                  results {
                    createdAt
                    deletedAt
                    description
                    icon
                    isActive
                    isDeleted
                    lookupId
                    title
                    unitId
                    unitType
                    updatedAt
                  }
                }
      }`,
      variables: {},
    });
  }

  async getDashboardBoxes() {
    return super.post("", {
      query: `query {
        dashboardBoxes {
          activeCampaigns
          dailyAdPlays
          dailyAdViews
          dailyBudgetSpent
        }
      }`,
    });
  }

  async getMap2(filter: IDashboardFilter) {
    return super.post("", {
      query: `query($showZones: Boolean, $showHeatmap: Boolean, $deviceType: [Int], $deviceOnline: Boolean){
          map(showZones: $showZones, showHeatmap: $showHeatmap, deviceType: $deviceType, deviceOnline: $deviceOnline){
            heatmap {
              count
              results{
                name
                latitude
                longitude
                intensity
              }
            }
            devices {
              count
              results {
                Id
 createdAccountId
            assignedAccountId
            deviceReady
                latitude
                longitude
                createdAt
                deviceName
                deviceType
                hardwareVersion
                isActive
                isOnline
                lastMessageDate
                machineId
                softwareVersion
                timezone
                updatedAt
                vpnId
              }
            }
            zones {
              count
              results {
                Id
                category
                color
                createdAt
                isActive
                parentZoneId
                polygon
                ppvPrice
                primePrice
                updatedAt
                zoneName
              }
            }
          }
      }`,
      variables: {
        ...filter,
      },
    });
  }


  async getMap3(filter: IDashboardFilter) {
    // console.log("From DashboardService", filter);
    return super.post("", {
      query: `query($search:String, $showZones: Boolean, $showHeatmap: Boolean, $deviceType: [Int], $deviceOnline: Boolean){
          map(search:$search, showZones: $showZones, showHeatmap: $showHeatmap, deviceType: $deviceType, deviceOnline: $deviceOnline){
    zones {
      count
      results {
        category
        Id
        color
        createdAt
        isActive
        parentZoneId
        polygon
        primePrice
        ppvPrice
        updatedAt
        zoneName
      }
    }
    heatmap {
      count
      results {
        intensity
        latitude
        longitude
        name
        zoneId
      }
    }
    devices {
      count
      results {
        Id
        assignedAccountId
        createdAccountId
        createdAt
        deviceName
        deviceReady
        deviceType
        hardwareVersion
        isActive
        isOnline
        lastMessageDate
        lastTelemetry {
          campaignId
          machineId
          network {
            connectionTime
            localIp
            remoteIp
          }
          sensors {
            accelerometer {
              xValue
              yValue
              zValue
            }
            batteryA {
              current
              percentage
              temperature
              voltage
            }
            batteryB {
              current
              percentage
              temperature
              voltage
            }
            cpuTemperature
            fanA {
              current
              dutyCycle
              speed
            }
            fanB {
              current
              dutyCycle
              speed
            }
            gnss {
              latitude
              longitude
              satellites
            }
            gyroscope {
              xValue
              yValue
              zValue
            }
            intrusion
            light
            screen {
              brightness
              temperature
            }
          }
          services {
            serviceName {
              lastRestart
              status
            }
          }
          timestamp
          uptime
        }
        latitude
        longitude
        machineId
        softwareVersion
        timezone
        updatedAt
        vpnId
        vpnLocalIp
      }
    }
  }
}`,
      variables: {
        ...filter,
      },
    });
  }



  async getMap(filter: IDashboardFilter) {
    // console.log("From DashboardService", filter);
    return super.post("", {
      query: `query($search:String, $showZones: Boolean, $showHeatmap: Boolean, $deviceOnline: Boolean){
          map(search:$search, showZones: $showZones, showHeatmap: $showHeatmap, deviceOnline: $deviceOnline){
    zones {
      count
      results {
        Id
        category
        color
        createdAt
        isActive
        parentZoneId
        polygon
        ppvPrice
        primePrice
        updatedAt
        zoneName
      }
    }
    heatmap {
      count
      results {
        intensity
        latitude
        longitude
        name
        zoneId
      }
    }
    devices {
      count
      results {
        Id
        assignedAccountId
        createdAccountId
        createdAt
        deviceName
        deviceType
        hardwareVersion
        isActive
        isOnline
        lastMessageDate
        latitude
        longitude
        machineId
        updatedAt
        vpnId
        vpnLocalIp
        timezone
        softwareVersion
        lastTelemetry {
          campaignId
          machineId
          network {
            connectionTime
            localIp
            remoteIp
          }
          sensors {
            accelerometer {
              xValue
              yValue
              zValue
            }
            cpuTemperature
            gnss {
              latitude
              longitude
            }
            gyroscope {
              yValue
              xValue
              zValue
            }
            screen {
              brightness
              temperature
            }
            lightSensor
            battery {
              percentage
              temperatureA
              temperatureB
            }
            fan {
              dutyA
              dutyB
            }
            utilization {
              core
              disk
              memory
            }
          }
          uptime
          createdAt
        }
        status
      }
    }
  }
}`,
      variables: {
        ...filter,
      },
    });
  }



}
