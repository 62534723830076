import React from "react";
import PropTypes from "prop-types";
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer } from "recharts";
import variables from "../../Campaign.scss";

// eslint-disable-next-line no-unused-vars
const StatisticChart = ({ statistic, campaignId, dateFrom, dateTo }) => {
  return (
    <>
      {statistic &&
      statistic.data &&
      statistic.data.campaignMonitoring &&
      statistic.data.campaignMonitoring.graphData ? (

<>
{/* {console.log("From StatisticChart dddd statistic", statistic)} */}
{/* {console.log("Graph Data:", statistic.data.campaignMonitoring.graphData)} */}
          <div className="statistic-chart">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={300}
                data={
                  statistic && statistic.data.campaignMonitoring.graphData
                }
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="date" />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="plays"
                  stroke={variables.green}
                />
                <Line
                  type="monotone"
                  dataKey="views"
                  stroke={variables.orange}
                />
                <Line type="monotone" dataKey="spent" stroke={variables.blue} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

StatisticChart.propTypes = {
  campaignId: PropTypes.any,
  dateFrom: PropTypes.instanceOf(Date),
  dateTo: PropTypes.instanceOf(Date),
  statistic: PropTypes.any,
};

export default StatisticChart;
 //statistic: PropTypes.any,