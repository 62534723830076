
import React from 'react';
import PropTypes from 'prop-types';
import IconLibrary from './IconLibrary';


 const IconRenderer = ({ iconName, size = 22, color = "", style = {} , onClick,
  ...rest }) => {
  const IconComponent = IconLibrary[iconName];

  if (!IconComponent) {
    return <span>Icon not found: {iconName}</span>;
  }

  return <IconComponent size={size} color={color} style={style} onClick={onClick}
  {...rest}/>;
};

IconRenderer.propTypes = {
  iconName: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

export default IconRenderer;









// import React from 'react';
// import PropTypes from 'prop-types';
// import IconLibrary from './IconLibrary';


// const IconRenderer = ({ iconName, size = 24, color = ''  }) => {
//   const IconComponent = IconLibrary[iconName];

//   if (!IconComponent) {
//     return <span>Icon not found: {iconName}</span>; // Fallback for invalid icon names
//   }

//   return <IconComponent size={size} color={color} />;
// };
// IconRenderer.propTypes = {
//   iconName: PropTypes.string.isRequired,
//   size: PropTypes.number,
//   color: PropTypes.string,
// };

// export default IconRenderer;
