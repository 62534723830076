import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import loginReducer from "@client.pages/Login/reducer";
import advertiserReducer from "@client.pages/Advertiser/reducer";
import campaignReducer from "reducers/Campaign";
import deviceReducer from "reducers/Device";
import profileReducer from "reducers/Profile";
import profileUserEditReducer from "@client.pages/ProfileGeneralEditUser/reducer";
import locationReducer from "@client.pages/Zones/reducer";
import vpnManagementReducer from "@client.pages/VpnManagement/reducer";
import usersReducer from "@client.pages/Users/reducer";
import userLogsReducer from "@client.pages/UserLogs/reducer";
import systemLogsReducer from "@client.pages/SystemLogs/reducer";
import accountReducer from "@client.pages/Accounts/reducer";
import softwareVersionsReducer from "@client.features/analytics-tools/softwareVersions";
import plansReducer from "@client.features/billing/plans";
import dashboardReducer from "@client.features/main/dashboard";
import invoiceReducer from "@client.pages/Invoices/reducer";
import subscriptionReducer from "@client.pages/Subscriptions/reducer";

const rootReducer = {
  login: loginReducer,
  campaign: campaignReducer,
  advertiser: advertiserReducer,
  dashboard: dashboardReducer,
  device: deviceReducer,
  profile: profileReducer,
  profileUserEdit: profileUserEditReducer,
  location: locationReducer,
  vpnManagement: vpnManagementReducer,
  user: usersReducer,
  userLogs: userLogsReducer,
  systemLogs: systemLogsReducer,
  account: accountReducer,
  softwareVersions: softwareVersionsReducer,
  plans: plansReducer,
  invoice: invoiceReducer,
  subscriptions: subscriptionReducer,
};

export const getStore = (initialState = null) => {
  const optionalSettings: Record<string, unknown> = {};
  if (initialState) {
    optionalSettings.preloadedState = initialState;
  }
  const logger = createLogger({
    collapsed: true,
    diff: true
  });
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
    ...optionalSettings
  });
};

export type AppDispatch = ReturnType<typeof getStore>["dispatch"];
