import BaseService from "@client.services/baseService";
import config from "@client.config";

export default class CampaignService extends BaseService {
  async getAll(advertiserId) {
    return super.post("", {
      query: `query ($lookupId: String) {
        fetchUserCampaigns(id: $lookupId) {
          displayName
          totalViews
          totalSpent
          status
          lookupId
          totalPlays
          perPlayCalculation
          file {
            locationPath
          }
          endDate
          startDate
          isActive
        }
      }`,
      variables: {
        lookupId: advertiserId,
      },
    });
  }

  async updateCampaign(model) {
    // console.log("From service updateCampaigncccccccc updateCampaign", model);
    return super.post("", {
      query: `mutation updateCampaign($id: String, $fields: CampaignInputType){
      updateCampaign(id: $id, fields: $fields){
        message
        campaign{
          Id
          startDate
          endDate
        }
      }
    }`,
    variables: {
      id: model.Id,
      fields: {
        ...model,
        mediaId: model.mediaId
        .startsWith("https://core-ams-01.kaligon.com:60000/api/download/")==true
        ?model.mediaId
        :`${config.downloadUrl}/${model.mediaId}`,
        target:parseInt(model.target),
        budgetDaily:parseFloat(model.budgetDaily),
        budgetTotal:parseFloat(model.budgetTotal),
        advertiserId:model.advertiserId,
        geofenceZoneIds:model.geofenceZoneIds,
        paymentId:model.paymentId,
        // Ensure mediaId is explicitly passed
      },
    },


    // variables: {
    //   id: model.Id,
    //   fields: {
    //     ...model,
    //     mediaId:  `${config.downloadUrl}/${model.mediaId}`, // Ensure mediaId is explicitly passed
    //   },
    // },
    
      // variables: {
      //   id: model.Id,
      //   fields: model,
      // },
    });
  }

  async updateCampaignStatus(model) {
    // console.log("From service UpdateCampaignStatus", model);
    return super.post("", {
      query: `mutation updateCampaign($id: String, $fields: CampaignInputType){
      updateCampaign(id: $id, fields: $fields){
        message
        campaign{
          Id
          status
          startDate
          endDate
        }
      }
    }`,
      variables: {
        id: model.Id,
        fields: { 
          status: model.status  || "", // Default empty string if value is null/undefined
        },
      },
    });
  }
  

  async getAllCampaigns({
    status, advertiserId, limit, offset, search, searchOn, startDate, endDate,
  }) {
    // console.log("From service", startDate, endDate);
    return super.post("", {
      query: `
        query($pagination: PaginationInputType ,$status: Int,$advertiserId:String){
          campaigns(pagination: $pagination,status:$status,advertiserId:$advertiserId){
            count
            results{
              Id
              advertiserId
              budgetDaily
              budgetTotal
              campaignName
              createdAt
              creatorAccountId
              creatorUserId
              endDate
              geofenceZoneIds
              isActive
              mediaId
              paymentId
              startDate
              status
              target
              updatedAt
            }
          }
        }`,
      variables: {
        pagination: {
          limit,
          offset,
          search,
          searchOn,
          startDate: startDate ? new Date(startDate).toISOString().slice(0, 10) : null,
          endDate: endDate ? new Date(endDate).toISOString().slice(0, 10) : null,
        },
        status,
        advertiserId,
      },
    });
  }

  async getById(id) {
    return super.post("", {
      query: `query($id: String){
        campaign(id: $id){
        advertiserId
        budgetDaily
        budgetTotal
        campaignName
        createdAt
        creatorAccountId
        creatorUserId
        endDate
        geofenceZoneIds
        Id
        isActive
        mediaId
        paymentId
        startDate
        status
        target
        updatedAt
        }
      }`,
      variables: {
        id,
      },
    });
  }

  
  async getById2(id) {
    return super.post("", {
      query: `query($lookupId: String){
        fetchCampaign(id: $lookupId) {
          lookupId
          budgetAmount
          currentBalance
          displayName
          status
          totalSpent
          totalViews
          selectedDays
          endDate
          totalPlays
          startDate
          file {
            locationPath
          }
          locations {
            lookupId
            displayName
          }
        }
      }`,
      variables: {
        lookupId: id,
      },
    });
  }

  async getPerformance(id) {
    return super.post("", {
      query: `query ($lookupId: String) {
        fetchCampaignCaptures(lookupId: $lookupId) {
          createdAt
          latitude
          longitude
          views
        }
      }`,
      variables: {
        lookupId: id,
      },
    });
  }

  async getMonitoring(id) {
    return super.post("", {
      query: `query ($lookupId: String) {
        fetchCampaignMonitoring(lookupId: $lookupId) {
          campaign{
            lookupId
            displayName
          }
          totalPlays
          totalViews
          totalSpent
          graphData{
            date
            plays
            views
            spent
          }
        }
      }`,
      variables: {
        lookupId: id,
      },
    });
  }

  // eslint-disable-next-line no-unused-vars
  async getStatisticOverview(id, dateFrom, dateTo) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([
          {
            date: "11/15/2023",
            plays: 100,
            views: 230,
            spent: 20,
          },
          {
            date: "11/16/2023",
            plays: 111,
            views: 240,
            spent: 23,
          },
          {
            date: "11/17/2023",
            plays: 50,
            views: 100,
            spent: 10,
          },
          {
            date: "11/18/2023",
            plays: 500,
            views: 800,
            spent: 40,
          },
        ]);
      }, 500);
    });
  }

  // eslint-disable-next-line no-unused-vars
  async getEstimation(data) {
    return new Promise((resolve) => {
      resolve({
        plays: 1000,
        pricePerView: 0.3,
        price: 300,
      });
    });
  }

  async getTargets() {
    return Promise.resolve([
      {
        id: "1",
        name: "Google Search",
      },
      {
        id: "2",
        name: " Attract Customer to Location",
      },
      {
        id: "3",
        name: "Brand Awareness",
      },
      {
        id: "4",
        name: "Something Else",
      },
    ]);
  }

  async getTimeSlots() {
    return Promise.resolve([
      {
        id: "1",
        name: "All Day",
      },
      {
        id: "2",
        name: "Morning - 7am- 11am",
      },
      {
        id: "3",
        name: "Lunch - 11am- 2pm",
      },
      {
        id: "4",
        name: "Afternoon - 2pm - 5pm",
      },
      {
        id: "5",
        name: "Dinner - 5pm - 8pm",
      },
      {
        id: "6",
        name: "Evening - 8pm - 10pm",
      },
      {
        id: "7",
        name: "Night - 10pm - 7am",
      },
    ]);
  }



  async saveCampaign(campaign) {
    // console.log("From service", campaign);
    return super.post("", {
      query: `mutation (
        $campaignName: String,
        $advertiserId: String,
        $startDate: String,
        $endDate: String,
        $target: Int,
        $mediaId: String,
        $budgetType: Int,
        $budgetAmount: Float,
        $geofenceType: Int,
        $geofenceRadiusLatitude: Float,
        $geofenceRadiusLongitude: Float,
        $geofenceRadiusRange: Int,
        $geofenceZoneIds: [String],
        $paymentId: String,
        $status: Int
      ) {
        customCreateCampaign(
          campaignName: $campaignName,
          advertiserId: $advertiserId,
          startDate: $startDate,
          endDate: $endDate,
          target: $target,
          mediaId: $mediaId,
          budgetType: $budgetType,
          budgetAmount: $budgetAmount,
          geofenceType: $geofenceType,
          geofenceRadiusLatitude: $geofenceRadiusLatitude,
          geofenceRadiusLongitude: $geofenceRadiusLongitude,
          geofenceRadiusRange: $geofenceRadiusRange,
          geofenceZoneIds: $geofenceZoneIds,
          paymentId: $paymentId,
          status: $status
        ) {
          message
          campaign {
            Id
            campaignName
            startDate
            endDate
            advertiserId
            creatorAccountId
            creatorUserId
            target
            mediaId
            budgetTotal
            budgetDaily
            geofenceZoneIds
            paymentId
            status
          }
        }
      }`,
      variables: campaign.fields,
    });
  }



  async saveDraft(campaign) {
    console.log("From service Savecccccccc save", campaign);
    return super.post("", {
      query: `mutation (
        $campaignName: String,
        $advertiserId: String,
        $startDate: String,
        $endDate: String,
        $target: Int,
        $mediaId: String,
        $budgetType: Int,
        $budgetAmount: Float,
        $geofenceType: Int,
        $geofenceRadiusLatitude: Float,
        $geofenceRadiusLongitude: Float,
        $geofenceRadiusRange: Int,
        $geofenceZoneIds: [String],
        $paymentId: String,
        $status: Int
      ) {
        customCreateCampaign(
          campaignName: $campaignName,
          advertiserId: $advertiserId,
          startDate: $startDate,
          endDate: $endDate,
          target: $target,
          mediaId: $mediaId,
          budgetType: $budgetType,
          budgetAmount: $budgetAmount,
          geofenceType: $geofenceType,
          geofenceRadiusLatitude: $geofenceRadiusLatitude,
          geofenceRadiusLongitude: $geofenceRadiusLongitude,
          geofenceRadiusRange: $geofenceRadiusRange,
          geofenceZoneIds: $geofenceZoneIds,
          paymentId: $paymentId,
          status: $status
        ) {
          message
          campaign {
            Id
            campaignName
            startDate
            endDate
            advertiserId
            creatorAccountId
            creatorUserId
            target
            mediaId
            budgetTotal
            budgetDaily
            geofenceZoneIds
            paymentId
            status
          }
        }
      }`,
      variables: {
        ...campaign.fields,
        // isDraft: true,
        status: 0,
      }
    });
  }

 
  async saveDraft2(campaign) {
    return super.post("", {
      query: `mutation (
        $campaignName: String,
        $advertiserId: String,
        $startDate: String,
        $endDate: String,
        $target: Int,
        $mediaId: String,
        $budgetType: Int,
        $budgetAmount: Float,
        $geofenceType: Int,
        $geofenceRadiusLatitude: Float,
        $geofenceRadiusLongitude: Float,
        $geofenceRadiusRange: Int,
        $geofenceZoneIds: [String],
        $paymentId: String,
        $isDraft: Boolean
      ) {
        customCreateCampaign(
          campaignName: $campaignName,
          advertiserId: $advertiserId,
          startDate: $startDate,
          endDate: $endDate,
          target: $target,
          mediaId: $mediaId,
          budgetType: $budgetType,
          budgetAmount: $budgetAmount,
          geofenceType: $geofenceType,
          geofenceRadiusLatitude: $geofenceRadiusLatitude,
          geofenceRadiusLongitude: $geofenceRadiusLongitude,
          geofenceRadiusRange: $geofenceRadiusRange,
          geofenceZoneIds: $geofenceZoneIds,
          paymentId: $paymentId,
          isDraft: $isDraft
        ) {
          message
          campaign {
            Id
            campaignName
            startDate
            endDate
            advertiserId
            creatorAccountId
            creatorUserId
            target
            mediaId
            budgetTotal
            budgetDaily
            geofenceZoneIds
            paymentId
            isDraft
            status
          }
        }
      }`,
      variables: {
        ...campaign.fields,
        isDraft: true,
      }
    });
  }

  async getLocations() {
    return super.post("", {
      query: `query {
        fetchLocations{
          lookupId
          createdAt
          isActive
          polygon
          pricePerView
          displayName
          
        }
      }`,
    });
  }

  async getActiveLocations() {
    return super.post("", {
      query: `query {
        campaignLocations {
          lookupId
          polygon
        }
      }`,
    });
  }

  async getActiveHeatmaps() {
    return super.post("", {
      query: `query {
        fetchActiveCampaignHeatmap {
          lookupId
          device {
            allTelemetry {
              lat
              lng
            }
          }
        }
      }`,
    });
  }

  async getHeatmap_OLD(lookupId) {
    return super.post("", {
      query: `query($lookupId: String) {
        fetchCampaignHeatmap(id: $lookupId) {
          latitude
            longitude
        }
      }`,
      variables: {
        lookupId: lookupId,
      },
    });
  }



  async getCampaignDetails(id) {
    return this.post("", {
      query: `
        query($id: String) {
          campaignDetails(id: $id) {
            budgetDaily
            budgetSpent
            budgetTotal
            campaignName
            endDate
            estimationPricePerPlay
            peopleReached
            pricePerDay
            pricePerPlay
            pricePerSlot
            startDate
            status
            locations {
              zoneName
            }
          }
        }
      `,
      variables: { id },
    });
  }
  
  async getCampaignMonitoring({ id, startDate, endDate }) {
    return this.post("", {
      query: `
        query($id: String, $startDate: String, $endDate: String) {
          campaignMonitoring(id: $id, startDate: $startDate, endDate: $endDate) {
            graphData {
              date
              plays
              spent
              views
            }
            totalPlays
            totalSpent
            totalViews
          }
        }
      `,
      variables: { id, startDate, endDate },
    });
  }
  

///////////////////
async fetchCampaignPlaces(id, deviceId) {
  return super.post("", {
    query: `
      query($id: String, $deviceId: String) {
        campaignPlaces(id: $id, deviceId: $deviceId) {
          count
          results {
            deviceId
            deviceName
            latitude
            longitude
          }
        }
      }
    `,
    variables: { id, deviceId },
  });
}
///////////////////


async getHeatmap(id,zoneID) {
  return super.post("", {
    query: `query($id: String, $zoneId: String) {
      campaignHeatmap(id: $id, zoneId: $zoneId)  {
        count
        results{ 
          intensity
          latitude
          longitude
          name
          zoneId
        }
        }
    }`,
    variables: { id, zoneID },
  });
}

///////////////////
async CampaignInsights(id) {
  return super.post("", {
    query: `
      query($id: String){
        campaignInsights(id: $id) {
          ageGraph {
            age
            female
            male
          }
          ageRangeFemaleHigh
          ageRangeFemaleLow
          ageRangeMaleHigh
          ageRangeMaleLow
          impressions
          ivpGraph {
            datetime
            impressions
            peopleDetected
            views
          }
          peopleAgeCount {
            age
            quantity
          }
          peopleDetected
          peopleFemale
          peopleMale
          playLocations {
            latitude
            longitude
          }
          views
        }
      }
    `,
    variables: { id },
  });
}
///////////////////
}
