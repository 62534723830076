import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dropdown from "@client.core.components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsersAsync,
  makeUsers,
  setSelectedUserLoading
} from "@client.pages/Users/reducer";
import "./UserDropDown.scss";

const UserDropDown = ({
  value,
  onSelect,
  label,
  disabled,
  fromCreate = null
}) => {
  const dispatch = useDispatch();
  //const users = useSelector(makeUsers);
  const users = useSelector(makeUsers);
  const isLoading = useSelector(setSelectedUserLoading);
  // for some reason this is Null. need to check

  useEffect(() => {
    dispatch(getAllUsersAsync({}));
  }, [dispatch]);

  // Add "All" option to the advertiser list

  const UsersWithAll = [
    { Id: null, userName: "All" },
    ...users.results.map((user) => ({
      ...user,
      userName: `${user.firstName} ${user.lastName}` // Construct userName for dropdown
    }))
  ];
  //const UsersWithAll = [{ Id: null, userName: "All" }, ...users.results];

  const handleSelect = (selectedValue) => {
    // console.log("From AdvertiserDropdown", selectedValue);

    if (selectedValue === null) {
      // Explicitly set to null for "All"
      dispatch(getAllUsersAsync());
      onSelect(null);
    } else {
      onSelect(selectedValue);
    }
  };

  //debugger;

  return (
    <Dropdown
      items={fromCreate == true ? users : UsersWithAll}
      disabled={disabled}
      defaultValue={value}
      value={value}
      className="users-dropdown"
      labelClassName="users-background"
      label={label}
      valueKey="Id"
      labelKey="userName"
      onSelect={handleSelect}
    />
  );
};

UserDropDown.defaultProps = {
  value: null,
  label: null,
  disabled: false
};

UserDropDown.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  fromCreate: PropTypes.bool
};

export default UserDropDown;
