import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { OverviewBlock } from "./OverviewBlock";
import { makeProfile } from "@client.pages/Login/reducer";
import "./TopBoardOverview.scss";
import classNames from "classnames";

export const TopBoardOverview = ({
  budgetSpent,
  adPlays,
  adViews,
  activeCampaigns,
  className
}) => {
  const { t } = useTranslation();
  const profile = useSelector(makeProfile);

  return (
    <div className={classNames("top-board-overview", className)}>
      <OverviewBlock
        value={budgetSpent}
        // icon="budgetSpent"
        icon="FaWallet"
        text={t("components.TopBoardOverview.budgetSpent")}
        className="budget-spent"
        currency={profile.currency}
        info="daily budget spent info" // needs to include in I18N
        color="#7A0061"
      />
      <OverviewBlock
        value={adPlays}
        // icon="adPlays"
        icon="FaPlay"
        text={t("components.TopBoardOverview.adPlays")}
        className="ad-plays"
        info="Ads plays info" // needs to include in I18N
        color="#04297A"
      />
      <OverviewBlock
        value={adViews}
        // icon="adViews"
        icon="FaEye"
        text={t("components.TopBoardOverview.adViews")}
        className="ad-views"
        info="Ads view info" // needs to include in I18N
        color="#7A4F01"
      />
      <OverviewBlock
        value={activeCampaigns}
        // icon="activeCampaigns"
        icon="IoMdMegaphone"
        text={t("components.TopBoardOverview.activeCampaigns")}
        className="active-campaigns"
        info="Your active campagin info" // needs to include in I18N
        color="#08660D"
      />
    </div>
  );
};

TopBoardOverview.defaultProps = {
  budgetSpent: 0,
  adPlays: 0,
  adViews: 0,
  activeCampaigns: 0,
  loading: false,
  info: null
};

TopBoardOverview.propTypes = {
  budgetSpent: PropTypes.number,
  adPlays: PropTypes.number,
  adViews: PropTypes.number,
  activeCampaigns: PropTypes.number,
  loading: PropTypes.bool,
  className: PropTypes.string,
  info: PropTypes.string
};
