
import Button from "@client.core.components/Button";
import DateTimeRangePicker from "@client.components/DateTimeRangePicker/DateTimeRangePicker";
import Dropdown from "@client.core.components/Dropdown";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllDevicesAsync, makeDevices } from "../../reducers/Device";
import {
  getAdvertiserCampaignsAsync,
  makeSelectedCampaigns,
} from "@client.pages/Campaigns/reducer";

const FilterBar = ({ onFilterChange, dataAnalyzer = false }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [campaignId, setCampaignId] = useState("");

  const devices = useSelector(makeDevices);
  const campaigns = useSelector(makeSelectedCampaigns);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllDevicesAsync());
    dispatch(getAdvertiserCampaignsAsync());
  }, [dispatch]);

  return (
    <div className="search-bar">
      {!dataAnalyzer ? (
        <>
          <div className="search-inputs">
            <Dropdown
              items={devices.results}
              valueKey="lookupId"
              labelKey="deviceName"
              placeholder={t("pages.DataAnalyzer.selectDevice")}
              label={t("pages.DataAnalyzer.devices")}
              defaultValue={deviceId}
              onSelect={setDeviceId}
            />
            <Dropdown
              items={campaigns.campaigns}
              valueKey="lookupId"
              labelKey="displayName"
              placeholder={t("pages.DataAnalyzer.selectCampaign")}
              label={t("pages.DataAnalyzer.campaigns")}
              defaultValue={campaignId}
              onSelect={setCampaignId}
            />
            <DateTimeRangePicker
              onChange={(startDate, endDate) => {
                setStartDate(startDate || "");
                setEndDate(endDate || "");
              }}
            />
          </div>
          <Button
            text={t("pages.Devices.DeviceCaptures.submit")}
            className="search-submit"
           
            onClick={() => {
              onFilterChange({
                deviceId,
                campaignId,
                endDate,
                startDate,
              });
            }}
          />
        </>
      ) : (
        <div 
        style={{ width: "100%" }}>
        <Button
          text={"Load"}
          className="search-submit"
          onClick={() => {
            onFilterChange({
              deviceId,
              campaignId,
              endDate,
              startDate,
            });
          }}
        />
        </div>
      )}
    </div>
  );
};

FilterBar.propTypes = {
  onFilterChange: PropTypes.func,
  dataAnalyzer: PropTypes.bool,
};

export default FilterBar;




















// import Button from "@client.core.components/Button";
// import DateTimeRangePicker from "@client.components/DateTimeRangePicker/DateTimeRangePicker";
// import Dropdown from "@client.core.components/Dropdown";
// import PropTypes from "prop-types";
// import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
// import { useDispatch, useSelector } from "react-redux";
// import { getAllDevicesAsync, makeDevices } from "@client.pages/Devices/reducer";
// import {
//   getAdvertiserCampaignsAsync,
//   makeSelectedCampaigns,
// } from "@client.pages/Campaigns/reducer";

// const FilterBar = ({ onFilterChange}) => {
//   const [startDate, setStartDate] = useState("");
//   const [endDate, setEndDate] = useState("");
//   const [deviceId, setDeviceId] = useState("");
//   const [campaignId, setCampaignId] = useState("");

//   const devices = useSelector(makeDevices);
//   const campaigns = useSelector(makeSelectedCampaigns);

//   const { t } = useTranslation();
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getAllDevicesAsync());
//     dispatch(getAdvertiserCampaignsAsync());
//   }, [dispatch]);

//   return (
//     <div className="search-bar">
      
//       {<div className="search-inputs">
//         <Dropdown
//           items={devices.results}
//           valueKey="lookupId"
//           labelKey="deviceName"
//           placeholder={t("pages.DataAnalyzer.selectDevice")}
//           label={t("pages.DataAnalyzer.devices")}
//           defaultValue={deviceId}
//           onSelect={setDeviceId}
//         />
//         <Dropdown
//           items={campaigns.campaigns}
//           valueKey="lookupId"
//           labelKey="displayName"
//           placeholder={t("pages.DataAnalyzer.selectCampaign")}
//           label={t("pages.DataAnalyzer.campaigns")}
//           defaultValue={campaignId}
//           onSelect={setCampaignId}
//         />
//         <DateTimeRangePicker
//           onChange={(startDate, endDate) => {
//             setStartDate(startDate || "");
//             setEndDate(endDate || "");
//           }}
//         />
//       </div>}

//       <Button
//         text={t("pages.Devices.DeviceCaptures.submit")}
//         className="search-submit"
//         onClick={() => {
//           onFilterChange({
//             deviceId,
//             campaignId,
//             endDate,
//             startDate,
//           });
//         }}
//       />
//     </div>
//   );
// };

// FilterBar.propTypes = {
//   onFilterChange: PropTypes.func,
// };

// export default FilterBar;
