import BaseService from "@client.services/baseService";

export default class SubscriptionService extends BaseService {
  async createSubscription(fields) {
    return super.post("", {
      query: `
        mutation($fields: SubscriptionInputType) {
          createSubscription(fields: $fields) {
            message
            subscription {
              planId
              Id
              startDate
              endDate
              status
            }
          }
        }
      `,
      variables: {
        fields,
      },
    });
  }

  async getSubscription(id) {
    return super.post("", {
      query: `
        query($id: String) {
          subscription(id: $id) {
            Id
            planId
            startDate
            endDate
            status
          }
        }
      `,
      variables: {
        id,
      },
    });
  }

  async getAllSubscriptions() {
    return super.post("", {
      query: `
        query {
          subscriptions {
            count
            results {
              Id
              planId
              startDate
              endDate
              status
              userId
              updatedAt
              isActive
              createdAt
            }
          }
        }
      `,
    });
  }
  

  async updateSubscription(id, fields) {
    return super.post("", {
      query: `
        mutation($id: String, $fields: SubscriptionInputType) {
          updateSubscription(id: $id, fields: $fields) {
            message
            subscription {
                Id
                    planId
                    startDate
                    endDate
                    status
                    userId
                    updatedAt
                    isActive
                    createdAt
            }
          }
        }
      `,
      variables: {
        id,
        fields,
      },
    });
  }

  async deleteSubscription(id) {
    return super.post("", {
      query: `
        mutation($id: String) {
          deleteSubscription(id: $id) {
            message
          }
        }
      `,
      variables: {
        id,
      },
    });
  }
}
