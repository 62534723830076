import { IPlan } from "@client.features/billing/types";
import BaseService from "@client.services/baseService";
import { AllItemsResponse, ManagementResponse } from "types";

export default class PlanService extends BaseService {
  
  
  

  // async getAll(): Promise<AllItemsResponse<IPlan>> {
  //   return super.post("", {
  //     query: `query {
  //       plans {
  //         count
  //         results {
  //           Id
  //           planName
  //           pricePerMonth
  //           features
  //           isActive
  //           slotPrice
  //           totalSlots
  //         }
  //       }
  //     }`,
  //   });
  // }
  
  async getAll(): Promise<AllItemsResponse<IPlan>> {
    return super.post("", {
      query: `query {
        plans {
          count
          results {
            Id
            billingType
            createdAt
            featuresAllowed
            isActive
            planName
            pricePerMonth
            pricePerSlot
            totalSlotsPerMonth
            updatedAt
          }
        }
      }`,
    });
  }
  
  async getAll2(): Promise<AllItemsResponse<IPlan>> {
    return super.post("", {
      query: `query{
        plans{
          count
          results{
            Id
            planName
            pricePerMonth
            features {
              name
            }
            isActive
          }
        }
      }`,
    });
  }



  // async getPlanById(id: string): Promise<{ plan: IPlan }> {
  //   return super.post("", {
  //     query: `query($id: String){
  //       plan(id: $id){
  //         Id
  //         planName
  //         pricePerMonth
  //         features
  //         slotPrice
  //         totalSlots
  //       }
  //     }`,
  //     variables: {
  //       id,
  //     },
  //   });
  // }

  async getPlanById(id: string): Promise<{ plan: IPlan }> {
    return super.post("", {
      query: `query($id: String){
        plan(id: $id){
            Id
            billingType
            createdAt
            featuresAllowed
            isActive
            pricePerMonth
            planName
            pricePerSlot
            totalSlotsPerMonth
            updatedAt
        }
      }`,
      variables: {
        id,
      },
    });
  }

  
  async getPlanById2(id: string): Promise<{ plan: IPlan }> {
    return super.post("", {
      query: `query($id: String){
        plan(id: $id){
          Id
          planName
          pricePerMonth
          features {
            name
          }
        }
      }`,
      variables: {
        id,
      },
    });
  }

  async create(fields: any): Promise<any> {
    return super.post("", {
      query: `
        mutation($fields: PlanInputType) {
          createPlan(fields: $fields) {
            message
            plan {
              Id
              planName
              pricePerMonth
              features
              slotPrice
              totalSlots
            }
          }
        }
      `,
      variables: {
        fields,
      },
    });
  }
  
  async create2(version: IPlan): Promise<ManagementResponse> {
    return super.post("", {
      query: `mutation($fields: PlanInputType){
        createPlan(fields: $fields){
          message
          plan{
            Id
            planName
            pricePerMonth
            isActive
            features {
              name
            }
          }
        }
      }`,
      variables: {
        fields: version,
      },
    });
  }



  async update(plan: IPlan): Promise<any> {
    const { Id: id, ...fields } = plan; // Extract `Id` and other fields
    return super.post("", {
      query: `
        mutation($id: String, $fields: PlanInputType) {
          updatePlan(id: $id, fields: $fields) {
            message
            plan {
              Id
              planName
              pricePerMonth
              features
              slotPrice
              totalSlots
            }
          }
        }
      `,
      variables: { id, fields }, // Use `id` and `fields` as variables
    });
  }
  

  

  async update2(plan: IPlan): Promise<ManagementResponse> {
    const { Id: id, ...fields } = plan;
    return super.post("", {
      query: `mutation($id: String, $fields: PlanInputType){
        updatePlan(id: $id, fields: $fields){
          message
          plan{
            Id
            planName
            pricePerMonth
            features
          }
        }
      }`,
      variables: {
        id,
        fields,
      },
    });
  }


  async delete(id: string): Promise<ManagementResponse> {
    return super.post("", {
      query: `mutation($id: String){
        deletePlan(id: $id) {
          message
        }
      }`,
      variables: {
        id,
      },
    });
  }

  getAllFeatures(): string[] {
    return [
      "Geo Location Targeting",
      "Number of Impressions",
      "Dwelling Time",
      "Viewing Angles",
      "Foot Traffic Density",
      "Viewers Age Range",
      "Gender Demographics",
      "Time of Day Analysis",
      "Return Viewers",
      "Visitor Journeys",
      "Popular Areas",
      "Engagement Rate",
      "Controlling Campaign hours",
      "Gaze Tracking",
      "Economic Status",
      "Pedestrian Density",
      "Real-Time Traffic",
    ].sort();
  }
}
