import React, { useEffect,useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import SvgIcon from "@client.core.components/SvgIcon";
import CampaignModel from "@client.models/campaign";
import {
  setActiveStep,
  makeActiveStep,
  setOkPressed,
  makeOkPressed,
  makeIsCompleted,
  setIsCompleted,
  setSummaryTriggered,
  makeSummaryTriggered
} from "../../reducers/Campaign";
// import { resetSelectedDraft } from "@client.pages/Advertiser/reducer";
import "./WizardSteps.scss";

const WizardSteps = ({ steps, model, setModel }) => {
  const dispatch = useDispatch();
  const activeStep = useSelector(makeActiveStep);
  const okPressed = useSelector(makeOkPressed);
  const isCompleted = useSelector(makeIsCompleted);
  const summaryTriggered = useSelector(makeSummaryTriggered);

  // const summaryTriggered = useSelector(makeSummaryTriggered);
  // console.log("From WizardSteps.js: activeStep", activeStep);
  console.log("From WizardSteps.js: okPressed", okPressed);
  console.log("From WizardSteps.js: isCompleted", isCompleted);
  console.log("From WizardSteps.js: activeStep", activeStep);
  // Set initial active step based on the campaign model
  useEffect(() => {
    console.log("From WizardSteps.js: summaryTriggered", summaryTriggered);
    if (!summaryTriggered) {
    const campaign = new CampaignModel(model);
    // const step = campaign.getActiveStep();
    // console.log("From WizardSteps.js: step", step);
    const initialStep = campaign.getActiveStep();
    dispatch(setActiveStep(initialStep))
  }
  else{
    const initialStep = 0;
    dispatch(setActiveStep(initialStep))
    dispatch(setSummaryTriggered(false));
}

  }, []);


    // Handle transition when "Next" is pressed
    useEffect(() => {
      if (okPressed) {
        const newCampaign = new CampaignModel(model);
        if (newCampaign.isStageFilled(activeStep)) {
          const nextStep = activeStep + 1;
          dispatch(setActiveStep(nextStep)); // Move to the next step
          if (nextStep === 3 && okPressed) {
            dispatch(setIsCompleted(true)); // Set "isCompleted" state to true
            // dispatch(setOkPressed(true)); // Reset "Next" state
            console.log("From WizardSteps.js: inside if statement :isCompleted",isCompleted); //
            console.log("From WizardSteps.js: inside if statement :okPressed",okPressed); //
            console.log("From WizardSteps.js: inside if statement :nextStep",nextStep); //
        }
        else{
            dispatch(setIsCompleted(false)); // Set "isCompleted" state to false
          }
          dispatch(setOkPressed(false)); // Reset "Next" state
        }
      }
    }, [okPressed,isCompleted, activeStep, model, dispatch]);

  // // Handle transition when "Next" is pressed
  // useEffect(() => {
  //   if (okPressed) {
  //     const newCampaign = new CampaignModel(model);
  //     if (newCampaign.isStageFilled(activeStep)&&(activeStep===0||activeStep===1)) {
  //       const nextStep = activeStep + 1;
  //       dispatch(setActiveStep(nextStep)); // Move to the next step
  //       dispatch(setOkPressed(false)); // Reset "Next" state
  //     }
  //     else if (newCampaign.isStageFilled(activeStep)&&(activeStep===2)) {
  //       const nextStep = activeStep + 1;
  //       console.log("From WizardSteps.js: nextStep", nextStep);
  //       dispatch(setActiveStep(nextStep)); // Move to the next step
  //       dispatch(setIsCompleted(true)); // Set "isCompleted" state to true
  //       console.log("From WizardSteps.js: After Step 3 :okPressed", okPressed);
  //       console.log("From WizardSteps.js: After Step 3 :isCompleted", isCompleted);
  //       dispatch(setOkPressed(false)); // Reset "Next" state
  //     }
  //   }
  // }, [okPressed, activeStep, model, dispatch]);


  return (
    <div className="wizard-steps">
      {steps.map((step, index) => (
        <div className="step" key={index}>
          
           {/* 
             Pink circle with a checkmark if the stage is filled
            */}
           {model.isStageFilled(index) && (
             <div className="identification">
               <SvgIcon name={"step-marker-active"} />
               <div className="connection"></div>
             </div>
           )}

           {!model.isStageFilled(index) && (
             <div className="identification">
               <SvgIcon name={"step-marker"} />
               <div className="connection"></div>
             </div>
           )}
         
          <div className="content">
            {React.cloneElement(step, {
              campaign: model,
              edit: activeStep === index,
              onSave: (updatedModel) => {
                const newCampaign = new CampaignModel(updatedModel);
                setModel(newCampaign);
                dispatch(setOkPressed(true)); // Trigger Redux state for "Next"
              },
              onEdit: () => dispatch(setActiveStep(index)), // Move to the specific step
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

WizardSteps.propTypes = {
  steps: PropTypes.array.isRequired,
  model: PropTypes.object.isRequired,
  setModel: PropTypes.func.isRequired,
};

export default WizardSteps;














