import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import NavigationBar from "@client.components/NavigationBar";
import TopBar from "@client.components/TopBar";
import Toast from "@client.core.components/Toast";
import Modal from "@client.components/Modal";
import ErrorMessages from "@client.components/ErrorMessages";
import "./AuthorizedLayout.scss";

export const AuthorizedLayout = () => {
  const [showAdvertiser, setShowAdvertiser] = useState(false);
  const [navigationLink, setNavigationLink] = useState(null);

  return (
    <div className="authorized-layout">
      <Toast />
      <Modal />
      <ErrorMessages />
      <NavigationBar />
      <div className="main">
        <TopBar
          showAdvertiser={showAdvertiser}
          navigationLink={navigationLink}
        />
        <Outlet context={{ setShowAdvertiser, setNavigationLink }} />
      </div>
    </div>
  );
};
