import CampaignStatuses from "@client.enums/campaignStatuses";

const useCampaignStatus = (status, t) => {
    const getStatusName = () => {
        switch (status) {
          case CampaignStatuses.PendingApproval:
            return t("pages.Campaign.status.pending");
          case CampaignStatuses.Queued:
            return t("pages.Campaign.status.queued");
          case CampaignStatuses.Active:
            return t("pages.Campaign.status.active");
          case CampaignStatuses.Ended:
            return t("pages.Campaign.status.ended");
          case CampaignStatuses.Draft:
            return t("pages.Campaign.status.draft");
          case CampaignStatuses.Archive:
            return t("pages.Campaign.status.archived");
          case CampaignStatuses.Paused:
            return t("pages.Campaign.status.paused");
          default:
            return t("pages.Campaign.status.unknown");
        }
      };
    
      const getStatusClass = () => {
        switch (status) {
          case CampaignStatuses.PendingApproval:
            return "pending";
          case CampaignStatuses.Queued:
            return "queued";
          case CampaignStatuses.Active:
            return "live";
          case CampaignStatuses.Ended:
            return "ended";
          case CampaignStatuses.Draft:
            return "draft";
          case CampaignStatuses.Archive:
            return "archived";
          case CampaignStatuses.Paused:
            return "paused";
          default:
            return "unknown";
        }
      };

    const statusName = getStatusName();
    const statusClass = getStatusClass();

    return { statusName, statusClass }
}

export default useCampaignStatus;