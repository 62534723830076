import React, { useEffect, useState } from "react";
import TopBoardOverview from "@client.components/TopBoardOverview";
import { useDispatch, useSelector } from "react-redux";
import DevicesOverview from "../PlatformDashboard/DevicesOverview";
import {
  fetchMapAsync,
  fetchPlatformDashboardAsync,
  makeDashboardFilter,
  makePlatformDashboard,
  makeDashboardLoading,
  setLoading
} from "@client.features/main/dashboard";
import "./PlatformDashboard.scss";
import { AppDispatch } from "@client.store";
import Loader from "@client.core.components/Loader";



const PlatformDashboard = () => {
  const platformDashboard = useSelector(makePlatformDashboard);
  const dashboardFilter = useSelector(makeDashboardFilter);
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(true);
  
  const loading = useSelector(makeDashboardLoading);
  console.log("From PlatformDashboard", platformDashboard);
  
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchPlatformDashboardAsync());
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch]);


  // useEffect(() => {
  //   // Manually set loading to true before fetching data
  //   dispatch(setLoading(true));
  //   dispatch(fetchPlatformDashboardAsync()).finally(() => {
  //     dispatch(setLoading(false));
  //   });
  // }, [dispatch]);

   useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(setLoading(true));
      dispatch(fetchMapAsync()).finally(() => {
        dispatch(setLoading(false));
      });
    }, 300);// 300ms delay after trying spam the dashboard and its still returns platformDashboard.deviceMap

    return () => clearTimeout(timeout);
  }, [
    dispatch,
    dashboardFilter.enableLocations,
    dashboardFilter.enableHeatmap,
    dashboardFilter.id
  ]);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }




  // if (loading) {
  //   // Show the Loader component when loading is true
  //   return (
  //     <div className="dashboard-loader">
  //       <Loader loading={true} />
  //     </div>
  //   );
  // }
  
  return (
    <div className="page dashboard">
      
        {loading && (
          <div className="dashboard-loader">
            <Loader loading={true} />
          </div>
        )}
      <TopBoardOverview
        className="top-board-dashboard-overview"
        activeCampaigns={platformDashboard.activeCampaigns}
        adPlays={platformDashboard.dailyAdPlays}
        adViews={platformDashboard.dailyAdViews}
        budgetSpent={platformDashboard.dailyBudgetSpent}
      />
      <DevicesOverview
        deviceMap={platformDashboard.deviceMap}
        deviceHeatmap={platformDashboard.deviceHeatmap}
        deviceLocation={platformDashboard.deviceLocations}
      />
    </div>
  );
};

export default PlatformDashboard;
























// import React, { useEffect } from "react";
// import TopBoardOverview from "@client.components/TopBoardOverview";
// import { useDispatch, useSelector } from "react-redux";
// import DevicesOverview from "../PlatformDashboard/DevicesOverview";
// import {
//   fetchMapAsync,
//   fetchPlatformDashboardAsync,
//   makeDashboardFilter,
//   makePlatformDashboard
// } from "@client.features/main/dashboard";
// import "./PlatformDashboard.scss";
// import { AppDispatch } from "@client.store";

// const PlatformDashboard = () => {
//   const platformDashboard = useSelector(makePlatformDashboard);
//   console.log("platformDashboard", platformDashboard);
//   const dashboardFilter = useSelector(makeDashboardFilter);
//   //console.log("dashboardFilter", dashboardFilter);

//   const dispatch = useDispatch<AppDispatch>();

//   useEffect(() => {
//     dispatch(fetchPlatformDashboardAsync());
//   }, [dispatch]);

//   useEffect(() => {
//     dispatch(fetchMapAsync());
//   }, [
//     dispatch,
//     dashboardFilter.enableLocations,
//     dashboardFilter.enableHeatmap,
//     dashboardFilter.id
//   ]);
//   console.log("platformDashboard test", platformDashboard.deviceMap);

//   return (
//     <div className="page dashboard">
//       <TopBoardOverview
//         className="top-board-dashboard-overview"
//         activeCampaigns={platformDashboard.activeCampaigns}
//         adPlays={platformDashboard.dailyAdPlays}
//         adViews={platformDashboard.dailyAdViews}
//         budgetSpent={platformDashboard.dailyBudgetSpent}
//       />
//       <DevicesOverview
//         deviceMap={platformDashboard.deviceMap}
//         deviceHeatmap={platformDashboard.deviceHeatmap}
//         deviceLocation={platformDashboard.deviceLocations}
//       />
//     </div>
//   );
// };

// export default PlatformDashboard;