import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "@client.core.components/Loader";
import Icon from "@client.core.components/Icon";
import ServiceProvider from "@client.services/provider";
import { MODAL_EVENT } from "@client.components/Modal";
import DeleteSubConfirmation from "./DeleteSubConfirmation";
import { makeSelectedSubscription, getSubscriptionByIdAsync } from "./reducer";
import "./SubscriptionDetails.scss";

const SubscriptionDetails = ({ id, onView, onDelete }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedSub = useSelector(makeSelectedSubscription);
  //debugger;

  useEffect(() => {
    dispatch(getSubscriptionByIdAsync(id));
  }, [dispatch, id]);

  //debugger;

  if (selectedSub.loading) {
    return (
      <div className="subscription-details subscription-details-loading">
        <Loader loading={true} />
      </div>
    );
  }

  const renderInfo = (field, value) => {
    return (
      <div className="card">
        <h2 className="card-title">{t(field)}</h2>
        <p className="card-description">{value}</p>
      </div>
    );
  };

  const { subscription } = selectedSub;
  console.log("SubscriptionDetails -> subscription", subscription);
  //console.log("InvoiceDetails -> invoice", invoice);
  if (subscription === null) {
    return null;
  }

  return (
    <div className="subscription-details">
      <div className="subscription-details-title">
        {t("pages.subscription.subscriptionDetails")}
        <div className="management-butotns">
          <div className="btn" onClick={onView}>
            <Icon name="eye" />
          </div>
          <div className="btn">
            <Icon
              name="delete"
              onClick={() => {
                ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
                  show: true,
                  title: t("components.DeletesubscriptionConfirmation.title"),
                  component: (
                    <DeleteSubConfirmation
                      onDelete={onDelete}
                      onCancel={() =>
                        ServiceProvider.EventEmitter.emit(MODAL_EVENT, {})
                      }
                    />
                  )
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="subscription-info-grid">
        {renderInfo("pages.subscription.Details.id", subscription.Id)}
        {renderInfo("pages.subscription.Details.planId", subscription.planId)}
        {renderInfo(
          "pages.subscription.Details.startDate",
          subscription.startDate || "Date Was Not Found"
        )}
        {renderInfo(
          "pages.subscription.Details.endDate",
          subscription.endDate || "endDate Was Not Found"
        )}
        {renderInfo("pages.subscription.Details.status", subscription.status)}

        {renderInfo(
          "pages.subscription.Details.updatedAt",
          subscription.updatedAt
        )}
        {renderInfo(
          "pages.subscription.Details.isActive",
          subscription.isActive
        )}
        {renderInfo(
          "pages.subscription.Details.createdAt",
          subscription.createdAt
        )}
      </div>
    </div>
  );
};

SubscriptionDetails.defaultProps = {
  showMap: true
};

SubscriptionDetails.propTypes = {
  id: PropTypes.any,
  showMap: PropTypes.bool,
  onView: PropTypes.func,
  onDelete: PropTypes.func
};

export default SubscriptionDetails;













// import React, { useEffect } from "react";
// import PropTypes from "prop-types";
// import { useDispatch, useSelector } from "react-redux";
// import { getSubscriptionByIdAsync, makeSelectedSubscription } from "./reducer";
// import "./SubscriptionDetails.scss";

// const SubscriptionDetails = ({ subscriptionId }) => {
//   const dispatch = useDispatch();
//   const selectedSubscription = useSelector(makeSelectedSubscription);

//   useEffect(() => {
//     if (subscriptionId) {
//       dispatch(getSubscriptionByIdAsync(subscriptionId));
//     }
//   }, [dispatch, subscriptionId]);

//   if (!selectedSubscription) {
//     return <div className="no-selection">No subscription found</div>;
//   }

//   const { planId, startDate, endDate, status, userId } = selectedSubscription;

//   return (
//     <div className="subscription-details">
//       <div className="subscription-details-title">Subscription Details</div>
//       <div className="details-grid">
//         <div className="detail-item">
//           <strong>Plan ID:</strong> {planId}
//         </div>
//         <div className="detail-item">
//           <strong>Start Date:</strong> {startDate}
//         </div>
//         <div className="detail-item">
//           <strong>End Date:</strong> {endDate}
//         </div>
//         <div className="detail-item">
//           <strong>Status:</strong> {status}
//         </div>
//         <div className="detail-item">
//           <strong>User ID:</strong> {userId}
//         </div>
//       </div>
//     </div>
//   );
// };

// SubscriptionDetails.propTypes = {
//   subscriptionId: PropTypes.string.isRequired,
// };

// export default SubscriptionDetails;
