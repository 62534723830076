import React, { useCallback, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Title from "@client.components/Title";
import Input from "@client.core.components/Input";
import Button from "@client.core.components/Button";
import DataTable from "@client.components/DataTable";
import Dropdown from "@client.core.components/Dropdown";
import { MODAL_EVENT } from "@client.components/Modal";
import ServiceProvider from "@client.services/provider";
import CustomDateRangePicker from "@client.components/CustomDateRangePicker";
import { getColumns } from "./consts";
import InvoiceForm from "@client.components/InvoiceForm/InvoiceForm";
import {
  makeInvoices,
  getAllInvoicesAsync,
  deleteInvoiceAsync,
  updateInvoiceAsync,
  createInvoiceAsync,
  makeInvoiceFilter,
  setFilter
} from "./reducer";
import Pages from "@client.enums/pages";
import { useNavigate } from "react-router-dom";
import SvgIcon from "@client.core.components/SvgIcon";
import InvoiceDetails from "./InvoiceDetails";
import "./Invoices.scss";
import invoiceTypes from "@client.enums/invoiceTypes";
import useToast from "@client.hooks/useToast";

const ITEMS_PER_PAGE = 10;

const Invoices = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const gridRef = useRef(null);
  const { showToastAfterRequest } = useToast();
  const invoices = useSelector(makeInvoices);
  const updateTxt = t("pages.Invoices.updateInvoice"); // complete in i18n
  const createTxt = t("pages.Invoices.title.create");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState(null);
  const filter = useSelector(makeInvoiceFilter);

  const onSelectionChanged = (d) => {
    // console.log("onSelectionChanged", d);
    switch (d.colDef.field) {
      case "Name":
      case "index":
        navigate(`${Pages.Devices}/${d.data.Id}`);
        break;
      case undefined:
        break;
      default: {
        const row = gridRef.current.api.getSelectedRows()[0];
        setSelectedRow(row);
        break;
      }
    }
  }; // need to edit

  const statuses = [
    {
      value: invoiceTypes.Unpaid,
      label: t("pages.Invoices.columns.unpaid")
    },
    {
      value: invoiceTypes.Paid,
      label: t("pages.Invoices.columns.paid")
    },
    {
      value: invoiceTypes.Overdue,
      label: t("pages.Invoices.columns.overdue")
    }
  ];

  useEffect(() => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;
    dispatch(
      getAllInvoicesAsync({
        ...filter,
        offset,
        limit: ITEMS_PER_PAGE,
        search: filter.query,
        searchOn: "description,user_full_name",
        startDate: filter.startDate,
        endDate: filter.endDate
      })
    );
  }, [filter, currentPage, dispatch]);

  const handleFilterChange = (request) => {
    dispatch(
      setFilter({
        ...filter,
        ...request
      })
    );
  };

  const onInvoiceManagement = useCallback(
    (invoice) => {
      // console.log("invoices", invoice);
      ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
        show: true,
        withBodyRef: true,
        title: invoice.Id ? updateTxt : createTxt,
        component: <InvoiceForm invoice={invoice} />,
        buttonSettings: {
          text: t(
            invoice.Id ? "pages.Invoices.update" : "pages.Invoices.create"
          ), // complete in i18n
          onClick: async (component) => {
            const isValid = component.validate();
            // console.log("Invoice to create:", component.managementItem);

            return isValid
              ? dispatch(
                  component.managementItem.Id
                    ? updateInvoiceAsync(component.managementItem)
                    : createInvoiceAsync(component.managementItem)
                ).then((resp) => {
                  showToastAfterRequest(
                    resp,
                    "pages.Invoices.invoiceCreated"
                  ).then(() => {
                    ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
                      show: false
                    });
                  });
                })
              : Promise.resolve();
          }
        }
      });
    },
    [createTxt, dispatch, showToastAfterRequest, t]
  );

  const onEdit = useCallback(
    (rowData) => {
      onInvoiceManagement(rowData);
    },
    [onInvoiceManagement]
  );

  const onView = useCallback((id) => {
    navigate(`${Pages.Devices}/${id}`);
  }, []);

  const onDelete = useCallback(
    (invoiceId) => {
      dispatch(deleteInvoiceAsync(invoiceId)).then((resp) => {
        showToastAfterRequest(resp, "pages.Invoices.invoiceDeleted");
        setSelectedRow(null);
        ServiceProvider.EventEmitter.emit(MODAL_EVENT, {});
      });
    },
    [dispatch, showToastAfterRequest]
  );

  return (
    <div className="invoices page">
      <div className="content">
        <div className="content-grid">
          <Title text="components.NavigationBar.invoices">
            <div className="management-buttons">
              <Button
                icon="plus"
                text={createTxt}
                className="button-create"
                onClick={() => onInvoiceManagement({})}
              />
            </div>
          </Title>
          <div className="search">
            <Input
              label="Search"
              value={filter.query}
              onChange={(v) => {
                handleFilterChange({
                  query: v
                });
              }}
            />

            <CustomDateRangePicker
              dateFrom={
                filter.startDate === "" ? undefined : new Date(filter.startDate)
              }
              dateTo={
                filter.endDate === "" ? undefined : new Date(filter.endDate)
              }
              label={t("pages.Invoices.dateRange")}
              onChange={(v) => {
                handleFilterChange({
                  startDate: v[0] === null ? "" : v[0].toISOString(),
                  endDate: v[1] === null ? "" : v[1].toISOString()
                });
              }}
            />

            <Dropdown
              items={statuses}
              defaultValue={filter.isOnline}
              label={t("pages.Devices.status")}
              valueKey="value"
              labelKey="label"
              onSelect={(v) => {
                handleFilterChange({
                  isOnline: v
                });
              }}
            />
          </div>
          <DataTable
            ref={gridRef}
            columns={getColumns(t, onView, onEdit, onDelete)}
            data={invoices.results}
            isLoading={invoices.loading}
            onSelectionChanged={onSelectionChanged}
            pagination={{
              total: invoices.count,
              itemsPerPage: ITEMS_PER_PAGE,
              onChange: setCurrentPage
            }}
          />
        </div>
        {selectedRow ? (
          selectedRow.Id && (
            <InvoiceDetails
              id={selectedRow.Id}
              onView={() => onView(selectedRow.Id)}
              onDelete={() => onDelete(selectedRow.Id)}
            />
          )
        ) : (
          <div className="no-selection">
            <SvgIcon name="no-address" />
            <span>{t("pages.Invoices.noInvoiceSelected")}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Invoices;
