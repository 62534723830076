import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ServiceProvider from "@client.services/provider";
import Loader from "@client.core.components/Loader";
import Icon from "@client.core.components/Icon";
import NoDataPlaceholder from "@client.core.components/NoDataPlaceholder";
import { IDevice } from "@client.features/main/type";

const DevicesInfo = ({
  devices,
  onClose,
}: {
  devices: IDevice[];
  onClose: VoidFunction;
}) => {
  const { t } = useTranslation();
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [device, setDevice] = useState<IDevice>();

  useEffect(() => {
    if (devices.length > 0 && !devices.find((d) => d.Id !== selectedDeviceId)) {
      setSelectedDeviceId(devices[0].Id);
    }
  }, [devices]);

  useEffect(() => {
    if (selectedDeviceId !== undefined) {
      setLoading(true);
      ServiceProvider.Device.getById(selectedDeviceId)
        .then((resp) => {
          setDevice(resp?.data.device);
        })
        .finally(() => {
          setTimeout(() => setLoading(false), 200);
        });
    }
  }, [selectedDeviceId]);

  const copyToClipboard = (txt) => {
    navigator.clipboard.writeText(txt);
  };
  return (
    <div className="devices-info">
      <div className="info-header">
        <div className="info">
          <span>{t("components.Dashboard.MapFilter.Header")}</span>
          <div className="statuses">
            <div className="status-label">
              <span className="status-online"></span>
              {t("components.Dashboard.MapFilter.online", {
                val: devices.filter((d) => d.isOnline).length,
              })}
            </div>
            <div className="status-label">
              <span className="status-offline"></span>
              {t("components.Dashboard.MapFilter.offline", {
                val: devices.filter((d) => !d.isOnline).length,
              })}
            </div>
          </div>
        </div>
        {/* <Icon name="close" className="info-close" onClick={onClose} /> */}
      </div>
      <div className="devices-info-content">
        <ul className="devices">
          {devices.map((d) => (
            <li
              key={d.Id}
              className={d.Id === selectedDeviceId ? "active" : undefined}
              onClick={() => setSelectedDeviceId(d.Id)}
            >
            <div className="status-label">
              <span
                className={d.isOnline ? "status-online" : "status-offline"}
              ></span>
              </div>
              {d.deviceName}
            </li>
          ))}
        </ul>
        {loading && <Loader loading={true} />}
        <div className="device-info-holder">
          {!loading && !device && <NoDataPlaceholder />}
          {!loading &&
            device &&
            [
              { title: "Machine ID", value: device.machineId },
              { title: "Device Name", value: device.deviceName },
              { title: "Timezone", value: device.timezone },
              {
                title: "Hardware Version",
                value: device.hardwareVersion,
              },
              {
                title: "Software Version",
                value: device.softwareVersion,
              },
              // {
              //   title: "Assigned To",
              //   value: device.assignedTo.fullName,
              // },
              // {
              //   title: "VPN IP",
              //   value: device.vpn ? device.vpn.ipAddress : "N/A",
              // },
              // {
              //   title: "Gyroscope",
              //   valueToCopy: `X: ${
              //     device.lastTelemetry.gyroscopeX || "N/A"
              //   } Y: ${device.lastTelemetry.gyroscopeY || "N/A"} Z: ${
              //     device.lastTelemetry.gyroscopeZ || "N/A"
              //   }`,
              //   value: (
              //     <RenderXYZ
              //       x={device.lastTelemetry.gyroscopeX}
              //       y={device.lastTelemetry.gyroscopeY}
              //       z={device.lastTelemetry.gyroscopeZ}
              //     />
              //   ),
              // },
              // {
              //   title: "Acceleration",
              //   valueToCopy: `X: ${
              //     device.lastTelemetry.accelerometerX || "N/A"
              //   } Y: ${device.lastTelemetry.accelerometerY || "N/A"} Z: ${
              //     device.lastTelemetry.accelerometerZ || "N/A"
              //   }`,
              //   value: (
              //     <RenderXYZ
              //       x={device.lastTelemetry.accelerometerX}
              //       y={device.lastTelemetry.accelerometerY}
              //       z={device.lastTelemetry.accelerometerZ}
              //     />
              //   ),
              // },
            ].map((v) => (
              <React.Fragment key={v.title}>
                <div
                  className="device-info-cell"
                  title={v.value}
                  onClick={() => copyToClipboard(v.value || "N/A")}
                >
                  <div className="device-info-title">{v.title}</div>
                  <div className="device-info-value" title={v.value}>
                    {v.value || "N/A"}
                  </div>
                </div>
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};

DevicesInfo.propTypes = {
  onClose: PropTypes.func,
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string,
      lookupId: PropTypes.string,
      status: PropTypes.number,
    })
  ),
};

export default DevicesInfo;
