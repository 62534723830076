import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Loader from "@client.core.components/Loader";
import Button from "@client.core.components/Button";
import ServiceProvider from "@client.services/provider";
import { MODAL_EVENT } from "@client.components/Modal";
import ZoneManagement from "./ZoneManagement";
import { useDispatch } from "react-redux";
import useToast from "@client.hooks/useToast";
import {
  createLocationAsync,
  deleteLocationAsync,
  updateLocationAsync,
  getLocationByIdAsync,
} from "../../../pages/Zones/reducer";
// import {
//   makeLocations,
// } from "@client.pages/Zones/reducer";

import DeleteZoneConfirmation from "./DeleteZoneConfirmation";
import ZoneEntity from "./types";
import ZoneItem from "./ZoneItem";
import "./ZonesTree.scss";
import NoDataPlaceholder from "@client.core.components/NoDataPlaceholder";

const ZonesTree = ({
  zones,
  isLoading,
  isEditable,
  selectedZone,
  onZoneSelect,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showToastAfterRequest } = useToast();

  const onZoneManagement = useCallback(
    (zone) => {
      ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
        show: true,
        withBodyRef: true,
        title: t(
          zone.Id
            ? "pages.Locations.components.ZonesTree.modal.update.title"
            : "pages.Locations.components.ZonesTree.modal.create.title"
        ),
        component: <ZoneManagement zoneModel={zone} />,
        buttonSettings: {
          text: t("pages.Locations.components.ZonesTree.modal.save"),
          onClick: async (component) => {
            const isValid = component.validate();
            return isValid
              ? dispatch(
                  component.managementItem.Id
                    ? updateLocationAsync(component.managementItem)
                    : createLocationAsync(component.managementItem)
                ).then((resp) => {
                  showToastAfterRequest(
                    resp,
                    component.managementItem.Id
                      ? "pages.Locations.components.ZonesTree.modal.create.success"
                      : "pages.Locations.components.ZonesTree.modal.update.success"
                  ).then(() => {
                    ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
                      show: false,
                    });
                  });
                })
              : Promise.resolve();
          },
        },
      });
    },
    [dispatch, showToastAfterRequest, t]
  );

  const onZoneDelete = useCallback((zoneId) => {
    ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
      show: true,
      title: t("pages.Locations.components.ZonesTree.modal.title"),
      component: (
        <DeleteZoneConfirmation
          onDelete={() => {
            dispatch(deleteLocationAsync(zoneId)).then((resp) => {
              showToastAfterRequest(
                resp,
                "pages.Locations.components.ZonesTree.modal.remove.success"
              ).then(() => {
                ServiceProvider.EventEmitter.emit(MODAL_EVENT, {
                  show: false,
                });
              });
            });
          }}
          onCancel={() =>
            ServiceProvider.EventEmitter.emit(MODAL_EVENT, { show: false })
          }
        />
      ),
    });
  }, []);

  if (isLoading) {
    return (
      <div className="zones-loading">
        <Loader loading={true} />
      </div>
    );
  }

  return (
    <div className="zones">
      {isEditable && (
       <>
       {/* <Button
          icon="plus"
          text={t("pages.Locations.components.ZonesTree.ShowAll")}
          onClick={() => console.log("Show All")}
          className="button-create"
          /> */}
      
        <Button
          icon="plus"
          text={t("pages.Locations.components.ZonesTree.create")}
          onClick={onZoneManagement}
          className="button-create"
          />
          </>
      )}
      {zones.length > 0 ? (
        <ul className="zones-tree">
          {zones.map((item) => (
            <ZoneItem
              key={item.Id}
              zone={item}
              onZoneManagement={onZoneManagement}
              onDelete={onZoneDelete}
              isEditable={isEditable}
              selectedZone={selectedZone}
              onZoneSelect={onZoneSelect}
            />
          ))}
        </ul>
      ) : (
        <NoDataPlaceholder description="pages.Zones.noZonesFound" />
      )}
    </div>
  );
};

ZonesTree.propTypes = {
  zones: PropTypes.arrayOf(ZoneEntity),
  isLoading: PropTypes.bool,
  isEditable: PropTypes.bool,
  selectedZone: PropTypes.object,
  onZoneSelect: PropTypes.func,
};

export default ZonesTree;
