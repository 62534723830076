import DeviceStatuses from "@client.enums/deviceStatuses";
import BaseService from "@client.services/baseService";

export default class DeviceService extends BaseService {
  async getAll({
    limit,
    offset,
    startDate,
    endDate,
    search,
    searchOn,
    isOnline,
  }) {
    return super.post("", {
      query: `
        query($accountId: String, $pagination: PaginationInputType,$isOnline: Boolean){
	      devices(accountId: $accountId, pagination: $pagination,isOnline: $isOnline){
            count
            results{
              Id
              deviceName
              machineId
              deviceType
              vpnId
              isOnline
              createdAt
              updatedAt
            }
          }
        }`,
      variables: {
        accountId: "",
        pagination: {
          limit,
          offset,
          search,
          searchOn,
          startDate: startDate
            ? new Date(startDate).toISOString().slice(0, 10)
            : null,
          endDate: endDate
            ? new Date(endDate).toISOString().slice(0, 10)
            : null,
        },
        isOnline,
      },
    });
  }

  async getAll2({
    limit = 10,
    offset = 0,
    accountLookupId = "",
    startDate = "",
    endDate = "",
    filterType = "",
    isOnline = "",
  }) {
    const response = await super.post("", {
      query: `
        query (
          $account_lookup_id: String
          $start_date: String
          $end_date: String
          $filter_type: [Int]
          $is_online: Boolean
          $order_by: String
          $limit: Int
          $offset: Int
        ) {
          fetchDevices(
            accountLookupId: $account_lookup_id
            startDate: $start_date
            endDate: $end_date
            filterType: $filter_type
            isOnline: $is_online
            orderBy: $order_by
            limit: $limit
            offset: $offset
          ) {
            count
            results {
              lookupId
              deviceName
              machineId
              isOnline
              vpnConfiguration
              defaultMedia
              country
              softwareVersion
              hardwareVersion
              account {
                lookupId
              }
              lastTelemetry {
                createdAt
                accelerometerX
                accelerometerY
                accelerometerZ
                gyroscopeX
                gyroscopeY
                gyroscopeZ
              }
            }
          }
        }
      `,
      variables: {
        account_lookup_id: accountLookupId || "",
        start_date: startDate || "",
        end_date: endDate || "",
        filter_type: filterType,
        is_online:
          isOnline === DeviceStatuses.All
            ? null
            : isOnline === DeviceStatuses.Online,
        order_by: "-last_message_date",
        limit,
        offset,
      },
    });

    return response;
  }

  async getAllByAccountId(accountLookupId = "") {
    const response = await super.post("", {
      query: `
        query (
          $accountLookupId: String
        ) {
          devices(accountId: $accountLookupId) {
            results {
              deviceName
              deviceType
              hardwareVersion
              softwareVersion
              timezone
              vpnId
              isOnline
              isActive
              deviceReady
              lastMessageDate
              machineId
              updatedAt
              Id
              assignedAccountId
              createdAt
            }
          }
        }
      `,
      variables: {
        accountId: accountLookupId || "",
      },
    });

    return response;
  }
  async getApprovals() {
    const response = await super.post("", {
      query: `
        query{
          fetchPendingDevices{
            lookupId
            createdAt
            machineId
            verificationCode
            country
            timezoneCode
          }
        }
    `,
    });
    return response;
  }
  async approveDevice(lookupId) {
    return super.post("", {
      query: `mutation configureDeviceApproval($lookupId: String){
      configureDeviceApproval(lookupId: $lookupId){
      message
      }
    } `,
      variables: {
        lookupId: lookupId,
      },
    });
  }

  async getById(id) {
    return super.post("", {
      query: `query($id: String){
        device(id: $id){
    Id
    assignedAccountId
    createdAccountId
    createdAt
    deviceType
    deviceName
    hardwareVersion
    isActive
    isOnline
    lastTelemetry {
      campaignId
      createdAt
      machineId
      network {
        connectionTime
        localIp
        remoteIp
      }
      sensors {
        accelerometer {
          xValue
          yValue
          zValue
        }
        cpuTemperature
        fan {
          dutyA
          dutyB
        }
        gnss {
          latitude
          longitude
        }
        gyroscope {
          xValue
          yValue
          zValue
        }
        lightSensor
        screen {
          brightness
          temperature
        }
        utilization {
          core
          disk
          memory
        }
      }
      uptime
    }
    lastMessageDate
    latitude
    longitude
    machineId
    softwareVersion
    timezone
    status
    updatedAt
    vpnId
    vpnLocalIp
  }
}`,
              variables: {
                id: id,
              },
            });
          }

  async getById2(id) {
    return super.post("", {
      query: `query($lookupId: String){
        fetchDevice(id: $lookupId) {
          lookupId
          createdAt
          isActive
          updatedAt
          deviceName
          machineId
          defaultMedia
          verificationCode
          timezoneCode
          hardwareVersion
          softwareVersion
          country
          lastTelemetry {
            battery
            temperature
            coresUtilization
            memoryUtilization
            totalCores
            totalDisk
            uptime
            longitude
            latitude
            updatedAt
            luxLight
          }
          vpn{
            lookupId
            ipAddress
            vpnServer{
              serverName
            }
            vpnDeviceSingle{
              deviceName
            }
          }
          assignedTo{
            fullName
          }
        }
      }`,
      variables: {
        lookupId: id,
      },
    });
  }


  async getDeviceCaptures({ campaignId, deviceId, startDate, endDate }) {
    return super.post("", {
      query: `
        query (
          $campaignId: String
          $deviceId: String
          $startDate: String
          $endDate: String
        ) {
          captures(
            campaignId: $campaignId
            deviceId: $deviceId
            startDate: $startDate
            endDate: $endDate
          ) {
            count
            results {
              Id
              endDate
              afterMediaUrl
              startDate
              status
            }
          }
        }
      `,
      variables: {
        campaignId,
        deviceId,
        startDate,
        endDate,
      },
    });
  }

  async getDeviceCaptures2({ lookupId = "", endDate = "", startDate = "" }) {
    return super.post("", {
      query: `
        query (
          $lookupId: String
          $endDate: String
          $startDate: String
        ) {
          fetchDeviceSlots(endDate: $endDate, lookupId: $lookupId, startDate: $startDate) {
            count
            results {
              createdAt
              fileName
              id
              machineId
              opCode
              processed
              unprocessed
              uptime
            }
          }
        }
      `,
      variables: {
        lookupId,
        startDate,
        endDate,
      },
    });
  }


  async fetchDeviceTelemetry(id) {
    return super.post("", {
      query: `query($id: String) {
        telemetries(id: $id) {
    count
    results {
      Id
      campaignId
      createdAt
      isActive
      machineId
      network {
        connectionTime
        localIp
        remoteIp
      }
      sensors {
        accelerometer {
          xValue
          yValue
          zValue
        }
        cpuTemperature
        gnss {
          latitude
          longitude
        }
        gyroscope {
          xValue
          yValue
          zValue
        }
        screen {
          brightness
          temperature
        }
        lightSensor
        fan {
          dutyA
          dutyB
        }
        utilization {
          core
          disk
          memory
        }
        battery {
          percentage
          temperatureA
          temperatureB
        }
      }
      updatedAt
      uptime
    }
  }
}
      `,
      variables:{id}
    });
  }





  async fetchDeviceTelemetry2(limit, offset, lookupId) {
    return super.post("", {
      query: `query(
          $limit: Int
          $offset: Int
          $lookupId: String
          ) {
            fetchTelemetry(endDate: "", limit: $limit, lookupId: $lookupId, offset: $offset, startDate: "") {
              count
              results {
                battery
                coresUtilization
                createdAt
                diskUtilization
                fullSpectrumIrVisibleLight
                isActive
                infraredLight
                latitude
                longitude
                lookupId
                luxLight
                memoryUtilization
                signal
                temperature
                totalCores
                totalDisk
                totalMemory
                updatedAt
                uptime
                visibleLight
                accelerometerX
                accelerometerY
                accelerometerZ
                gyroscopeZ
                gyroscopeY
                gyroscopeX
              }
            }
          }`,
      variables: {
        limit,
        offset,
        lookupId,
      },
    });
  }

  async create(device) {
    return super.post("", {
      query: `mutation($fields: DeviceInputType){
        createDevice(fields: $fields){
          message
          device {
      Id
      assignedAccountId
      createdAccountId
      createdAt
      deviceName
      hardwareVersion
      deviceType
      isOnline
      isActive
      lastMessageDate
      latitude
      longitude
      machineId
      softwareVersion
      status
      updatedAt
      timezone
      vpnId
      vpnLocalIp
    }
        }
      }`,
      variables: {
        fields: {
          deviceName: device.deviceName,
          deviceType: device.deviceType,
          machineId: device.machineId,
          status: device.status,
        },
      },
    });
  }

  async update(model) {
    return super.post("", {
      query: `mutation($id: String, $fields: DeviceInputType) {
        updateDevice(id: $id, fields: $fields) {
          message
          device {
            Id
            deviceName
            machineId
            deviceType
          }
        }
      }`,
      variables: {
        id: model.Id, // Use model.Id directly as required by your new query
        fields: {
          // Ensure the correct field name
          deviceName: model.deviceName || "", // Default empty string if value is null/undefined
          deviceType: model.deviceType || "",
          machineId: model.machineId || "",
        },
      },
    });
  }

  async update2(model) {
    const { lookupId, ...deviceToUpdate } = model.fields;
    return super.post("", {
      query: `mutation updateDevice($lookupId: String, $fields: DeviceInputType) {
        updateDevice(fields: $fields, lookupId: $lookupId) {
          message
        }
      }`,
      variables: {
        fields: deviceToUpdate,
        lookupId: lookupId,
      },
    });
  }

  async updateDeviceMedia(lookupId, defaultMedia) {
    return super.post("", {
      query: `mutation updateDevice($id: String, $fields: DeviceInputType) {
        updateDevice(id: $id, fields: $fields) {
          message
        }
      }`,
      variables: {
        id: lookupId,
        fields: {
          defaultMedia: defaultMedia,
        },
      },
    });
  }

  async delete(Id) {
    return super.post("", {
      query: `mutation($id: String){
        deleteDevice(id: $id)
      }`,
      variables: {
        id: Id,
      },
    });
  }

  async delete2(deviceId) {
    return super.post("", {
      query: `mutation($id: String){
        deleteDevice(id: $id){
          message
        }
      } `,

      variables: {
        id: deviceId,
      },
    });
  }

  async flickrSignal(macAddress, signal) {
    return super.post("", {
      query: `mutation CloudFlicker($macAddress: String, $signal: String) {
        cloudFlicker(macAddress: $macAddress, signal: $signal) {
          message
        }
      }`,
      variables: {
        macAddress,
        signal,
      },
    });
  }





  async fetchDeviceRegistrations({ limit, offset, search, searchOn, startDate, endDate }) {
    return super.post("", {
      query: `
        query($pagination: PaginationInputType) {
          deviceRegistrations(pagination: $pagination) {
            count
            results {
              Id
              assignedAccountId
              createdAccountId
              createdAt
              deviceName
              deviceType
              hardwareVersion
              isActive
              isOnline
              lastMessageDate
              lastTelemetry {
                campaignId
                createdAt
                machineId
                network {
                  connectionTime
                  localIp
                  remoteIp
                }
                sensors {
                  accelerometer {
                    xValue
                    yValue
                    zValue
                  }
                  cpuTemperature
                  battery {
                    percentage
                    temperatureA
                    temperatureB
                  }
                  fan {
                    dutyA
                    dutyB
                  }
                  gnss {
                    latitude
                    longitude
                  }
                  lightSensor
                  gyroscope {
                    xValue
                    yValue
                    zValue
                  }
                  screen {
                    brightness
                    temperature
                  }
                  utilization {
                    core
                    disk
                    memory
                  }
                }
                uptime
              }
              latitude
              longitude
              machineId
              softwareVersion
              status
              timezone
              updatedAt
              vpnId
              vpnLocalIp
            }
          }
        }
      `,
      variables: {
        pagination: {
          limit,
          offset,
          search,
          searchOn,
          startDate,
          endDate,
        },
      },
    });
  }
  

  async deviceRegistrationsJudgement({ id, status }) {
    return this.post("", {
      query: `
        mutation ($id: String, $status: Int) {
          deviceRegistrationsJudgement(id: $id, status: $status)
        }
      `,
      variables: { id, status },
    });
  }


  async fetchDeviceRegistrationFlagStatus() {
    return super.post("", {
      query: `
        query {
          deviceRegistrationFlagStatus
        }
      `,
    });
  }
  
  async modifyDeviceRegistrationFlags({ autoRegistration }) {
    return super.post("", {
      query: `
        mutation ($autoRegistration: Boolean) {
          modifyDeviceRegistrationFlags(autoRegistration: $autoRegistration)
        }
      `,
      variables: { autoRegistration },
    });
  }
  
  
}
