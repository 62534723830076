import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CampaignModel from "@client.models/campaign";
import Slider from "@client.components/Slider/Slider";
import Button from "@client.core.components/Button";
import RadioGroup from "@client.components/RadioGroup";
import TagList from "@client.components/TagList";
import BudgetLimit from "@client.enums/budgetLimit";
import { makeProfile } from "@client.pages/Login/reducer";
import { validateModel } from "@client.utils/form";
import CampaignWizardStages from "@client.enums/campaignWizardStages";
import {
  setActiveStep,
  makeActiveStep,
  setOkPressed,
  makeOkPressed,
} from "../../../reducers/Campaign";
import "./Budget.scss";
const MIN_AMOUNT = 10;

const Budget = ({ model, setModel, edit, onSave, onEdit }) => {
  const { t } = useTranslation();
  const profile = useSelector(makeProfile);

  const [isEdit, setIsEdit] = useState(true);
  const [nextPressed, setNextPressed] = useState(false);
  const budgetLimits = [
    { value: BudgetLimit.Total, label: t("pages.CreateCampaign.budget.total") },
    { value: BudgetLimit.Daily, label: t("pages.CreateCampaign.budget.daily") },
  ];

  const amountTags = [
    { value: 50, label: `${profile.currency}50` },
    { value: 200, label: `${profile.currency}200` },
    { value: 300, label: `${profile.currency}300` },
    { value: 450, label: `${profile.currency}450` },
  ];

  useEffect(() => {
    setIsEdit(!!edit);
  }, [edit]);

  const changeModel = useCallback(
    (v, field) => {
      setModel(
        new CampaignModel({ ...model, [field]: v }, model.validationMsgs)
      );
    },
    [model]
  );

  if (!model) {
    return null;
  }
  
  useEffect(() => {
  if (model.isStageFilled(CampaignWizardStages.Budget)){
    setNextPressed(true)
  }
  else{setNextPressed(false)}
  }
  ,[model])
  // console.log("From Budget .js: model.isStageFilled(CampaignWizardStages.Budget)", model.isStageFilled(CampaignWizardStages.Budget));
  const title = t("pages.CreateCampaign.section.budget");
  if (!isEdit) {
    // console.log("From Budget.js: model", model);
    const isReadyToEdit = model.isStageFilled(CampaignWizardStages.Info);
    return (
      <div className="budget minimized">
        <div>
          <div className="step-title">{title}</div>
          {isReadyToEdit && (
            <span className="step-description">
              {t("pages.CreateCampaign.minimizedBudgetFormat", {
                type: budgetLimits.find((t) => t.value === model.budgetLimit)
                  .label,
                value: model.budgetTotal,
                currency: profile.currency,
              })}
            </span>
          )}
        </div>
        {/* {console.log("From Budget.js: nextPressed", nextPressed,"isReadyToEdit", isReadyToEdit)} */}
        {isReadyToEdit && nextPressed&&  (
          <Button
            icon="edit"
            text={t("pages.CreateCampaign.edit")}
            className="wizard"
            onClick={onEdit}
          />
        )}
      </div>
    );
  }

  return (
    <div className="budget">
      <div className="step-title">{title}</div>
      <RadioGroup
        groupName="budget-type"
        items={budgetLimits}
        value={model.budgetLimit}
        onChange={(v) => changeModel(v, "budgetLimit")}
      />
      <div className="daily-amount-title">
        {t("pages.CreateCampaign.budget.insertDailyAmount")}
      </div>
      <div className="budget-container">
        <div className="slider-container">
          <Slider
            min={MIN_AMOUNT}
            max={5000}
            currency={profile.currency}
            value={model.budgetTotal}
            onChange={(v) => changeModel(parseInt(v), "budgetTotal")}
          />
          <TagList
            items={amountTags}
            selected={model.budgetTotal}
            onSelect={(v) => changeModel(v, "budgetTotal")}
            nameKey="label"
            valueKey="value"
          />
        </div>
        <div className="minimum-amount-title">
          <span
            dangerouslySetInnerHTML={{
              __html: t("pages.CreateCampaign.budget.minAmountDescription", {
                currency: profile.currency,
                value: MIN_AMOUNT,
              }),
            }}
          />
          <span
            dangerouslySetInnerHTML={{
              __html: t("pages.CreateCampaign.budget.availableSlots", {
                val: Math.round(model.budgetTotal / 100) || 1,
              }),
            }}
          />
          <span
            dangerouslySetInnerHTML={{
              __html: t("pages.CreateCampaign.budget.noOfSeconds", {
                val: Math.round(model.budgetTotal / 100) * 10 || 1,
              }),
            }}
          />
        </div>
      </div>
     {/* <Button
        text={t("pages.CreateCampaign.next")}
        className="btn-next wizard"
        onClick={() => {
          const isValid = validateModel(model, {
            budgetLimit: {
              presence: {
                allowEmpty: false,
                message: t("pages.CreateCampaign.budget.validation.type"),
              },
            },
          })

          // console.log("From Budget.js: isValid", isValid);
          setNextPressed(isValid);
          if (isValid) onSave(model);
        }}
      /> */}
      <Button
  text={t("pages.CreateCampaign.next")}
  className="btn-next wizard"
  onClick={() => {
    const isValid = validateModel(model, {
      budgetLimit: {
        presence: {
          allowEmpty: false,
          message: t("pages.CreateCampaign.budget.validation.type"),
        },
      },
      budgetTotal: {
        presence: {
          allowEmpty: false,
          message: t("pages.CreateCampaign.budget.validation.total"),
        },
      },
    });
   
    if (isValid) {
      setNextPressed(isValid);
      onSave(model); // Trigger the save logic and advance the step
    }
  }}
/>
    </div>
  );
};

Budget.propTypes = {
  edit: PropTypes.bool,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  // okPressed: PropTypes.bool,

  model: PropTypes.shape({
    campaignName: PropTypes.string,
    advertiserId: PropTypes.string,
    validationMsgs: PropTypes.string,
    fileId: PropTypes.string,
    target: PropTypes.string,
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    isStageFilled: PropTypes.bool,
    budgetLimit: PropTypes.string,
    // budgetAmount: PropTypes.number,
    budgetTotal: PropTypes.string,
  }),
  setModel: PropTypes.func,
};

export default Budget;
