import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dropdown from "@client.core.components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDevicesAsync,
  makeDevices,
  makeDevicesLoading,
  makeDevicesFilter
} from "../../reducers/Device";
import "./DeviceDropDown.scss";

const ITEMS_PER_PAGE = 10;

const DeviceDropDown = ({
  value,
  onSelect,
  label,
  disabled,
  fromCreate = null
}) => {
  const dispatch = useDispatch();
  const devices = useSelector(makeDevices);
  const isLoading = useSelector(makeDevicesLoading);
  const filter = useSelector(makeDevicesFilter);
  const currentPage = 0;
  useEffect(() => {
    dispatch(
      getAllDevicesAsync({
        limit: ITEMS_PER_PAGE,
        offset: (currentPage - 1) * ITEMS_PER_PAGE,
        search: filter.query,
        searchOn: "device_name",
        startDate: filter.startDate,
        endDate: filter.endDate
      })
    );
  }, [dispatch, currentPage, filter]);

  // Add "All" option to the advertiser list

  const DevicesWithAll = [{ Id: null, deviceName: "All" }, ...devices.results];

  const handleSelect = (selectedValue) => {
    // console.log("From AdvertiserDropdown", selectedValue);

    if (selectedValue === null) {
      // Explicitly set to null for "All"
      dispatch(getAllDevicesAsync());
      onSelect(null);
    } else {
      onSelect(selectedValue);
    }
  };

  return (
    <Dropdown
      items={fromCreate == true ? devices : DevicesWithAll}
      disabled={isLoading || disabled}
      defaultValue={value}
      value={value} // Ensure the dropdown respects the current value
      className="devices-dropdown"
      labelClassName="devices-background"
      label={label}
      valueKey="Id"
      labelKey="deviceName"
      onSelect={handleSelect} // Use the custom handleSelect function
    />
  );
};

DeviceDropDown.defaultProps = {
  value: null,
  label: null,
  disabled: false
};

DeviceDropDown.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  fromCreate: PropTypes.bool
};

export default DeviceDropDown;
