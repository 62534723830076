import React from "react";
import PropTypes from "prop-types";
import "./ModalAccountsApprovalCustom.scss";
import Icon from "@client.core.components/Icon";

const ModalAccountsApprovalCustom = ({ title, children, Close, onSave }) => (
  <div className="modal-component-accounts">
    <div className="modal-component-accounts-content">
      <div className="modal-component-accounts-title">
        <span>{title}</span>
        <Icon
          name="close"
          className="modal-component-close-button"
          onClick={Close}
        />
      </div>
      <div className="modal-component-accounts-body">
        <div className="modal-section">
          {children}
        </div>
      </div>
      <div className="modal-component-accounts-footer">
        <button className="button button-cancel" onClick={Close}>
          Cancel
        </button>
        <button className="button button-confirm" onClick={onSave}>
          Save
        </button>
      </div>
    </div>
  </div>
);

ModalAccountsApprovalCustom.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  Close: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ModalAccountsApprovalCustom;













// import React from "react";
// import PropTypes from "prop-types";
// import "./ModalAccountsApprovalCustom.scss";
// import Icon from "@client.core.components/Icon";

// const ModalAccountsApprovalCustom = ({ title, personalInfo, businessInfo, Close, onSave }) => (
//   <div className="modal-component-accounts">
//     <div className="modal-component-accounts-content">
//       <div className="modal-component-accounts-title">
//         <span>{title}</span>
//         <Icon
//           name="close"
//           className="modal-component-close-button"
//           onClick={Close}
//         />
//       </div>
//       <div className="modal-component-accounts-body">
//         <div className="modal-section personal-info">
//           <h3>Personal Information</h3>
//           {personalInfo}
//         </div>
//         <div className="modal-section business-info">
//           <h3>Business Information</h3>
//           {businessInfo}
//         </div>
//       </div>
//       <div className="modal-component-accounts-footer">
//         <button className="button button-cancel" onClick={Close}>
//           Cancel
//         </button>
//         <button className="button button-confirm" onClick={onSave}>
//           Save
//         </button>
//       </div>
//     </div>
//   </div>
// );

// ModalAccountsApprovalCustom.propTypes = {
//   title: PropTypes.string.isRequired,
//   personalInfo: PropTypes.node.isRequired,
//   businessInfo: PropTypes.node.isRequired,
//   Close: PropTypes.func.isRequired,
//   onSave: PropTypes.func.isRequired,
// };

// export default ModalAccountsApprovalCustom;
















// import React from "react";
// import PropTypes from "prop-types";
// import "./ModalAccountsApprovalCustom.scss";
// import Icon from "@client.core.components/Icon";

// const ModalAccountsApprovalCustom = ({ title, personalInfo, businessInfo, Close, onSave }) => (
//   <div className="modal-component-accounts">
//     <div className="modal-component-accounts-content">
//       <div className="modal-component-accounts-title">
//         <span>{title}</span>
//         <Icon
//           name="close"
//           className="modal-component-close-button"
//           onClick={Close}
//         />
//       </div>
//       <div className="modal-component-accounts-body">
//         <div className="modal-section personal-info">
//           <h3>Personal Information</h3>
//           {personalInfo}
//         </div>
//         <div className="modal-section business-info">
//           <h3>Business Information</h3>
//           {businessInfo}
//         </div>
//       </div>
//       <div className="modal-component-accounts-footer">
//         <button className="button button-cancel" onClick={Close}>
//           Cancel
//         </button>
//         <button className="button button-confirm" onClick={onSave}>
//           Save
//         </button>
//       </div>
//     </div>
//   </div>
// );

// ModalAccountsApprovalCustom.propTypes = {
//   title: PropTypes.string.isRequired,
//   personalInfo: PropTypes.node.isRequired,
//   businessInfo: PropTypes.node.isRequired,
//   Close: PropTypes.func.isRequired,
//   onSave: PropTypes.func.isRequired,
// };

// export default ModalAccountsApprovalCustom;







