import React, { useState, useCallback, useEffect } from "react";
import { get } from "lodash";
import config from "@client.config";
import classNames from "classnames";
import Title from "@client.components/Title";
import ProfileTabs from "@client.components/ProfileTabs";
import Uploader from "@client.components/Uploader";
import Input from "@client.core.components/Input";
import ProfileModel from "@client.models/profile";
import { useTranslation } from "react-i18next";
import Button from "@client.core.components/Button";
import { useDispatch, useSelector } from "react-redux";
import { makeProfileLoading, saveUserProfileAsync } from "../../reducers/Profile";
import Loader from "@client.core.components/Loader";
import { validateModel } from "@client.utils/form";
import { getProfileAsync, makeProfile } from "@client.pages/Login/reducer";
import UserAsset from "@client.models/userAsset";
import {
  getAdvertiserByIdAsync,
  makeSelectedAdvertiser,
  uploadUserAssetAsync,
} from "@client.pages/Advertiser/reducer";
import useToast from "@client.hooks/useToast";
import "./ProfileGeneral.scss";
import useStableParams from "@client.hooks/useStableParams";
import moment from "moment";
import {
  getUserByIdAsync, 
} from "@client.pages/Users/reducer";

const ProfileGeneralPage = () => {
  const { t } = useTranslation();
  const { showToastAfterRequest } = useToast();
  const dispatch = useDispatch();
  const { profileId } = useStableParams();

  const selectedAdvertiser = useSelector(makeSelectedAdvertiser);
  const isProfileLoading = profileId && useSelector(makeProfileLoading);
  const profile = useSelector(makeProfile);

  const [model, setModel] = useState(() => {
    let profileModel = {};
    if (profileId) {
      if (selectedAdvertiser.advertiser)
        profileModel = selectedAdvertiser.advertiser;
    } else {
      profileModel = profile;
    }

    return new ProfileModel(
      { ...profileModel },
      {
        firstName: t("pages.ProfileGeneral.validation.firstName"),
        lastName: t("pages.ProfileGeneral.validation.lastName"),
        emailAddress: t("pages.ProfileGeneral.validation.emailAddress"),
        invalidEmail: t("pages.ProfileGeneral.validation.invalidEmail"),
        phoneNumber: t("pages.profileGeneral.validation.phoneNumber"),
      }
    );
  });

  useEffect(() => {
    if (profileId) {
      dispatch(getUserByIdAsync(profileId)).then((resp) => {
        console.log("From ProfileGeneralEditUser.js: ", resp);
      });
    }
  }, [dispatch, profileId]);
  
  useEffect(() => {
    if (profileId) {
      dispatch(getAdvertiserByIdAsync(profileId));
    }
  }, [dispatch, profileId]);

  useEffect(() => {
    if (profileId) {
      setModel(
        new ProfileModel(selectedAdvertiser.advertiser, model.validationMsgs)
      );
    }
  }, [dispatch, selectedAdvertiser.advertiser, profileId]);

  const changeAvatar = useCallback((file) => {
      const fileModel = new UserAsset({
        userLookupId: profile.Id,
        file: file,
      });

      dispatch(uploadUserAssetAsync(fileModel)).then((resp) => {
        const data = get(resp, "payload", {});
        console.log(data)
        setModel({
          ...model,
          avatarUrl: `${config.downloadUrl}/${data.external_id}`
        })
        saveUser("pages.ProfileGeneral.successPhotoUpload");
      });
    }, []);

  const changeModel = useCallback(
    (v, field) => {
      setModel(
        new ProfileModel({ ...model, [field]: v }, model.validationMsgs)
      );
    },
    [model]
  );

  const saveUser = async (msg) => {
    const {
      validationMsgs,
      validationRules,
      ...fields
    } = model
    const resp = await dispatch(saveUserProfileAsync({ id: profile.Id, fields }));

    showToastAfterRequest(resp, msg).then(() => {
      dispatch(getProfileAsync());
    });
  };

  const renderContent = () => (
    <div className="content">
      <div className="uploader-content">
        <Uploader
          placeholderUrl={model.avatarUrl}
          onChange={changeAvatar}
        />
        <div className="toggle-container">
          <label htmlFor="visibleProfie">
            {t("pages.ProfileGeneral.visible")}
          </label>
          <div className="toggle-switch">
            <input type="checkbox" id="visibleProfie" checked={false} />
            <label htmlFor="profileVisisble" className="toggle-label"></label>
          </div>
        </div>
      </div>
      <div className="user-settings-content">
        <Input
          value={model.firstName}
          onChange={(v) => changeModel(v, "firstName")}
          label={t("components.Advertisers.firstName")}
        />
        <Input
          value={model.lastName}
          onChange={(v) => changeModel(v, "lastName")}
          label={t("components.Advertisers.lastName")}
        />
        <Input
          value={model.emailAddress}
          onChange={(v) => changeModel(v, "emailAddress")}
          label={t("components.Advertisers.emailAddress")}
        />
        <Input
          value={model.phoneNumber}
          onChange={(v) => changeModel(v, "phoneNumber")}
          label={t("components.Advertisers.phoneNumber")}
        />
        {selectedAdvertiser.advertiser ? (
          <Input
            value={moment(selectedAdvertiser.advertiser.createdAt).format(
              config.dateTimeFormat
            )}
            label={t("components.Advertisers.created")}
            readOnly={true}
          />
        ) : (
          <Input
            value={moment(profile.createdAt).format(config.dateTimeFormat)}
            label={t("components.Advertisers.created")}
            readOnly={true}
          />
          
        )}
        {selectedAdvertiser.advertiser ? (
          <>
            <Input
              value={
                selectedAdvertiser &&
                selectedAdvertiser.advertiser &&
                selectedAdvertiser.advertiser.lastLoginTime !== null
                  ? moment(selectedAdvertiser.advertiser.lastLoginTime).format(
                      config.dateTimeFormat
                    )
                  : t("components.Advertisers.notLoggedIn")
              }
              label={t("components.Advertisers.lastLoggedIn")}
              readOnly={true}
            />
            <div></div>
          </>
        ) : (
          <Input
            value={moment(profile.lastLoginTime).format(config.dateTimeFormat)}
            label={t("components.Advertisers.lastLoggedIn")}
            readOnly={true}
          />
        )}
        <div>
          <Button
            text={t("pages.ProfileGeneral.save")}
            onClick={async () => {
              const isValid = validateModel(model);
              if (isValid) {
                await saveUser("pages.ProfileGeneral.successSave");
              }
            }}
          />
        </div>
      </div>
    </div>
  );

  const isLoading = isProfileLoading || selectedAdvertiser.loading;

  return (
    <div className="profile-general page">
      <Title text="pages.ProfileGeneral.myAccount" />
      <ProfileTabs profileId={profileId} />
      <div className={classNames({ "content-loading": isLoading })}>
        {isLoading ? <Loader loading={true} /> : renderContent()}
      </div>
    </div>
  );
};

export default ProfileGeneralPage;
