import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SvgIcon from "@client.core.components/SvgIcon";
import SectionDescription from "@client.pages/CreateCampaign/SectionDescription";
import Button from "@client.core.components/Button";
import { useDispatch, useSelector } from "react-redux";
import { makeProfile } from "@client.pages/Login/reducer";
import Pages from "@client.enums/pages";
import {
  makeCampaignLoading,
  saveCampaignDraftAsync,
  saveCampaignAsync,
  setSummaryTriggered,
  makeSummaryTriggered,
} from "../../reducers/Campaign";

import classNames from "classnames";
import Loader from "@client.core.components/Loader";
import MediaFile from "@client.components/MediaFile";
import useToast from "@client.hooks/useToast";
import Dropdown from "@client.core.components/Dropdown";
import "./CreateCampaignSummary.scss";
import moment from "moment";
import config from "@client.config";
import { updateAdvertiserCampaignAsync } from "@client.pages/Advertiser/reducer";
import {
  getLocationByIdAsync,
  makeLocations,
} from "@client.pages/Zones/reducer";

import CampaignStatuses from "@client.enums/campaignStatuses";
const PaymentProps = Object.freeze({
  4654654654654654: "4654654654654654",
  8419887487352765: "8419887487352765",
});
const CREDIT_CARD_DATASOURCE = Object.keys(PaymentProps).map((k) => ({
  id: parseInt(k),
  name: PaymentProps[k],
}));

const CreateCampaignSummary = ({ model, estimation,draftId=null}) => {
  // console.log("From CampaignInfo.js: ", model);
  const { t } = useTranslation();
  const { showToastAfterRequest, showToastWarning } = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector(makeProfile);
  const isCampaignLoading = useSelector(makeCampaignLoading);

  const [locationNames, setLocationNames] = useState([]);
  const [creditCard, setCreditCard] = useState(CREDIT_CARD_DATASOURCE[0].id);
  const summaryTriggered = useSelector(makeSummaryTriggered);
  
  // console.log("From CreateCampaignSummary.js: summaryTriggered", summaryTriggered);
  const currentDate = new Date();
  const source = model.mediaId
  .startsWith("https://core-ams-01.kaligon.com:60000/api/download/")==true
  ?model.mediaId
  :`${config.downloadUrl}/${model.mediaId}`
    // console.log("From CreateCampaignSummary.js: Source", source);
  // const source = estimation
  //   ? `${config.downloadUrl}/${model.mediaId}`
  //   : model.mediaId;

  useEffect(() => {
    const fetchLocationNames = async () => {
      if (Array.isArray(model.geofenceZoneIds)) {
        // Check if geofenceZoneIds is defined and is an array
        const names = await Promise.all(
          model.geofenceZoneIds.map(async (id) => {
            const result = await dispatch(getLocationByIdAsync(id));
            return result.payload?.data?.zone?.zoneName || "Unknown"; // Adjust based on actual response structure
          })
        );
        setLocationNames(names);
      } else {
        setLocationNames([]);
      }
    };

    fetchLocationNames();
  }, [dispatch, model.geofenceZoneIds]);

  useEffect(() => {
    if (!estimation) {
      const startDate = new Date(model.startDate);
      const endDate = new Date(model.endDate);
      const daysLeft = Math.floor(
        (endDate - currentDate) / (1000 * 60 * 60 * 24)
      );
      const daysAgo = Math.floor(
        (currentDate - endDate) / (1000 * 60 * 60 * 24)
      );

      // Check if the endDate is in the past relative to the current date
      const isStartDateStarted = startDate > currentDate;
      const isEndDatePast = endDate < currentDate;
      setDaysLeft(daysLeft);
      setDaysAgo(daysAgo);
      setEndDate(isEndDatePast);
      setStartDate(isStartDateStarted);
    }
  }, [currentDate, estimation]);

  const handleSaveDraft = async () => {
    if (!model.advertiserId) {
      showToastWarning("Advertiser Id is required");
      return;
    }
    if (!model.target) {
      showToastWarning("Target is required");
      return;
    }
    let resp;
    if (model.status === CampaignStatuses.Draft) {
      const { 
        validationMsgs,
        validationRules,
        budgetType,
        budgetLimit,
        budgetAmount,
        locationType,
        zone,
        ...filteredModel 
      } = model;
      resp = dispatch(updateAdvertiserCampaignAsync({ campaign: filteredModel }));
    } else {
      resp = dispatch(saveCampaignDraftAsync(model));
    }
    showToastAfterRequest(
      resp,
      "pages.CreateCampaign.successDraftCreation"
    ).then(() => {
      navigate(`${Pages.Campaigns}`);
    });
  }
  
  const handleLaunchCampaign = async () => {
    if (!model.userId) {
      model.userId = profile.Id;
    }
    let resp;
      if (model.status === CampaignStatuses.Draft) {
      const { 
        validationMsgs,
        validationRules,
        budgetType,
        budgetLimit,
        budgetAmount,
        locationType,
        zone,
        userId,
        ...filteredModel
      } = model;
      resp = dispatch(updateAdvertiserCampaignAsync({ campaign: {
        ...filteredModel,
        // isDraft: false,
        status: CampaignStatuses.PendingApproval,
      } }));
    } else {
      resp = dispatch(saveCampaignAsync(model));
    }
    showToastAfterRequest(
      resp,
      "pages.CreateCampaign.successCampaignCreation"
    ).then(() => {
      // navigate(`${Pages.Advertiser}/${model.userId}`);
      navigate(Pages.Campaigns);
    });
  }

  const renderPaymentSection = () => {
    if (isCampaignLoading) {
      return <Loader loading={true} />;
    }
    return (
      <>
        <Button
          text={t("pages.CreateCampaign.saveDraft")}
          // disabled={true}
          // onClick={() => {
          //   alert("Under construction");
          // }}
          disabled={summaryTriggered}
          onClick={handleSaveDraft}
        />

        <SectionDescription title="pages.CreateCampaign.paymentMethod" />

        <Dropdown
          items={CREDIT_CARD_DATASOURCE}
          valueKey="id"
          payment={true}
          labelClassName="credit-card"
          defaultValue={creditCard}
          onPrepareValue={(v) => parseInt(v)}
          labelKey="name"
          placeholder="●●●● ●●●● ●●●● ●●●●"
          label={t("pages.CreateCampaign.creditCards")}
          onSelect={setCreditCard}
        />

        <Button
          text={t("pages.CreateCampaign.launchCampaign")}
          disabled={creditCard === null || !model.isValid()|| !summaryTriggered}
          onClick={handleLaunchCampaign}
        />
      </>
    );
  };

  return (
    <div className="create-campaign-summary">
      <div className={classNames("bag", { "bag-with-name": !estimation })}>
        <div style={{ height: 256, width: 291, overflow: "hidden" }}>
          <div className="placeholder">
            <div className="thumb-img">
              <MediaFile
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover", // Ensures the image fills the div while maintaining aspect ratio
                }}
                key={model?.mediaId}
                // src={`${config.downloadUrl}/${model.mediaId}`}
                // src={`${model.mediaId}`}
                src={source}
                // src={model?.mediaId}
                className="thumb-img"
                fallbackElement={() => <SvgIcon name="file-not-found" />}
              />
            </div>
          </div>
        </div>
      </div>

      {estimation && (
        <SectionDescription title="pages.CreateCampaign.costEstimetor" />
      )}
      {renderPaymentSection()}
    </div>
  );
};

CreateCampaignSummary.propTypes = {
  model: PropTypes.any,
  estimation: PropTypes.any,
  draftId: PropTypes.string,
};

export default CreateCampaignSummary;
