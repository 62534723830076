import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import GoogleMap from "@client.components/GoogleMap";
import Input from "@client.core.components/Input";
import LocationModel from "@client.models/location";
import Button from "@client.core.components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  createLocationAsync,
  makeLocationsLoading,
  updateLocationAsync,
} from "../../../pages/Zones/reducer";
import Loader from "@client.core.components/Loader";
import { validateModel } from "@client.utils/form";
import ToggleButton from "@client.core.components/ToggleButton";
import useToast from "@client.hooks/useToast";
import ZoneType from "@client.enums/zoneType";
import ColorPicker from "@client.core.components/ColorPicker";

const ZoneManagement = ({
  selectedZone,
  onReset,
  allowDrawing,
  isPricePerViewEditable,
}) => {
  // console.log("ZoneManagement -> selectedZone", selectedZone);
  const { t } = useTranslation();
  const isLoading = useSelector(makeLocationsLoading);
  const dispatch = useDispatch();
  const { showToastAfterRequest } = useToast();
  const [polygonData, setPolygonData] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [model, setModel] = useState({});
  const [drawingManagerKey, setDrawingManagerKey] = useState(
    new Date().getTime().toString()
  );

  useEffect(() => {
    if (isDrawing) {
      setPolygonData(null);
    }
  }, [isDrawing]);

  useEffect(() => {
    setIsDrawing(false);
    setModel(
      new LocationModel(
        {
          pricePerView: isPricePerViewEditable ? undefined : 0,
        },
        {
          displayName: t(
            "pages.Locations.components.ZoneManagement.validation.zoneName"
          ),
          ppvPrice: t(
            "pages.Locations.components.ZoneManagement.validation.primeRate"
          ),
          polygon: t(
            "pages.Locations.components.ZoneManagement.validation.polygon"
          ),
          color: t(
            "pages.Locations.components.ZoneManagement.validation.color"
          ),
        }
      )
    );
    if (selectedZone && selectedZone.polygon) {
      setPolygonData({
        color: selectedZone.color,
        data: selectedZone.polygon.map((data) => ({
          lat: data[1],
          lng: data[0],
        })),
      });
    } else {
      setPolygonData(null);
    }
  }, [t, selectedZone, isPricePerViewEditable]);

  const changeModel = useCallback(
    (v, field) => {
      setModel(
        new LocationModel({ ...model, [field]: v }, model.validationMsgs)
      );
    },
    [model]
  );

  const setDrawingMode = useCallback(() => {
    const newIsDrawing = !isDrawing;
    setIsDrawing(newIsDrawing);
    if (!newIsDrawing) {
      setDrawingManagerKey(new Date().getTime().toString());
    }
  }, [isDrawing]);

  const saveLocation = useCallback(async () => {
    model.polygon = polygonData.data;
    const isValid = validateModel(model);
    if (isValid) {
      const isNewLocation = !model.Id;
      const fields = {
        Id: model.Id,
        zoneName: model.displayName,
        color: model.color,
        category: ZoneType.Custom,
        primePrice: 0,
        ppvPrice: parseFloat(model.pricePerView),
        polygon: JSON.stringify(model.polygon.map((d) => [d.lng, d.lat])),
      };
      const resp = await dispatch(
        isNewLocation
          ? createLocationAsync({ fields })
          : updateLocationAsync({ fields })
      );
      showToastAfterRequest(
        resp,
        isNewLocation
          ? "pages.Locations.components.ZonesTree.modal.create.success"
          : "pages.Locations.components.ZonesTree.modal.update.success"
      ).then(() => {
        setIsDrawing(false);
      });
    }
  }, [model, dispatch, polygonData, showToastAfterRequest]);

  if (isLoading) {
    return (
      <div className="location-management">
        <Loader loading={true} />
      </div>
    );
  }
  return (
    <div className="location-management">
      <GoogleMap
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
        }}
        width={"100%"}
        height={"380px"}
        polygons={polygonData ? [polygonData] : []}
        drawingManagerKey={drawingManagerKey}
        drawingManagerRef={
          isDrawing
            ? (v) => {
                if (v) {
                  v.setDrawingMode("polygon");
                }
              }
            : null
        }
        onDrawingManagerPolygonComplete={(coordinates) => {
          setPolygonData({
            color: model.color || "red",
            data: coordinates,
          });
        }}
      >
        {allowDrawing && (
          <div className="location-management-buttons">
            <ToggleButton
              text={t(
                isDrawing
                  ? "pages.Locations.stopDrawing"
                  : "pages.Locations.startDrawing"
              )}
              value={isDrawing}
              onChange={setDrawingMode}
            />
          </div>
        )}
      </GoogleMap>
      {allowDrawing && (
        <div className="management-controls">
          <Input
            value={model.displayName}
            onChange={(v) => changeModel(v, "displayName")}
            label={t("pages.Zones.zoneName")}
          />
          {isPricePerViewEditable && (
            <Input
              value={model.pricePerView}
              onChange={(v) => changeModel(v, "pricePerView")}
              label={t("pages.Locations.ppv")}
              type={"number"}
              optional={{
                min: 0,
                max: 10000,
                step: 0.1,
              }}
            />
          )}
          <ColorPicker
            hexColor={model.color}
            onChange={(v) => {
              changeModel(v, "color");
              setPolygonData((p) => {
                const newData = p ? p.data : [];
                return { data: newData, color: v };
              });
            }}
          />
          <Button
            text={t(
              model.Id ? "pages.Locations.update" : "pages.Locations.create"
            )}
            disabled={!model.displayName}
            onClick={saveLocation}
          />
          <Button
            text={t("pages.Locations.cancel")}
            disabled={!model.displayName}
            onClick={() => {
              setPolygonData(null);
              setIsDrawing(false);
              setModel(new LocationModel({}, model.validationMsgs));
              setDrawingManagerKey(new Date().getTime().toString());
              onReset();
            }}
          />
        </div>
      )}
    </div>
  );
};

ZoneManagement.propTypes = {
  selectedZone: PropTypes.shape({
    Id: PropTypes.string,
    zoneName: PropTypes.string,
    polygon: PropTypes.array,
    color: PropTypes.string,
  }),
  onReset: PropTypes.func.isRequired,
  allowDrawing: PropTypes.bool,
  isPricePerViewEditable: PropTypes.bool,
};

export default ZoneManagement;
