import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WizardSteps from "@client.pages/CreateCampaign/WizardSteps";
import GeneralInformation from "./Steps/GeneralInformation";
import Budget from "./Steps/Budget";
import Locations from "./Steps/Locations";
import CampaignModel from "@client.models/campaign";
import CampaignWizardStages from "@client.enums/campaignWizardStages";
import {
  getEstimationAsync,
  makeEstimation,
  getCampaignByIdAsync,
  makeSelectedCampaign,
  resetSelectedCampaign,
  resetActiveStep,
  makeIsCompleted,
  makeOkPressed
} from "../../reducers/Campaign";
import {
  setSelectedZone,
} from "@client.pages/Zones/reducer";

import useStableParams from "@client.hooks/useStableParams";
import Loader from "@client.core.components/Loader";
import { useTranslation } from "react-i18next";

import CreateCampaignSummary from "./CreateCampaignSummary";
import "./CreateCampaign.scss";
import FullCampaignSummery from "./FullCampaignSummery";

const CreateCampaignPage = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { draftId } = useStableParams();
  const estimation = useSelector(makeEstimation);
  const selectedDraftCampaign = useSelector(makeSelectedCampaign);
  const [model, setModel] = useState(null);
  const isCompleted = useSelector(makeIsCompleted);
  const okPressed = useSelector(makeOkPressed);
  // console.log("From CreateCampaign.js: isCompleted", isCompleted);


  useEffect(() => {
    dispatch(getEstimationAsync());
  }, [dispatch]);

  useEffect(() => {
    if (draftId) {
      dispatch(getCampaignByIdAsync(draftId)); 
    }
    else {
      setModel(new CampaignModel());
    }

    return () => {
      dispatch(resetSelectedCampaign());
      dispatch(setSelectedZone(null));
      dispatch(resetActiveStep());

    };
  }, [dispatch, draftId]);

  useEffect(() => {
     if (selectedDraftCampaign.campaign) {
      setModel(new CampaignModel(selectedDraftCampaign.campaign));
      dispatch(setSelectedZone(null));
    }
  }, [draftId, selectedDraftCampaign]);

  useEffect(() => {
    if (isCompleted&&okPressed) {
      console.log("From CreateCampaign.js: isCompleted&&okPressed", isCompleted&&okPressed);
    }
    else{
      console.log("From CreateCampaign.js: isCompleted&&okPressed", isCompleted&&okPressed);
    }
  }, [isCompleted,okPressed]);


  // useEffect(() => {
  //   if (!draftId) {
  //     setModel(new CampaignModel()); // Reset model for new campaign
  //     dispatch(setSelectedZone(null));
  //   } else if (selectedDraftCampaign.campaign) {
  //     setModel(new CampaignModel(selectedDraftCampaign.campaign));
  //     dispatch(setSelectedZone(null));
  //   }
  // }, [draftId, selectedDraftCampaign]);
  

  // useEffect(() => {
  //   setModel(new CampaignModel(selectedDraftCampaign.campaign));

  // }, [selectedDraftCampaign]);

  if (draftId && (selectedDraftCampaign.loading || !selectedDraftCampaign.campaign)) {
    return (
      <div className="create-campaign not-found">
        <Loader loading={selectedDraftCampaign.loading} />
        {!selectedDraftCampaign.loading &&
          !selectedDraftCampaign.campaign &&
          t("pages.CreateCampaign.draftNotFound")}
      </div>
    );
  }

  return (
    <div className="create-campaign">
      {model&&
      <>
      {!isCompleted&&<WizardSteps
        model={model}
        setModel={(m) => {setModel(m)}}
        steps={[
          <GeneralInformation model={model} setModel={setModel} key={CampaignWizardStages.Info} />,
          <Budget model={model} setModel={setModel} key={CampaignWizardStages.Budget} />,
          <Locations model={model} setModel={setModel} key={CampaignWizardStages.Location} />,
        ]}
        />}
        {isCompleted&&
        <FullCampaignSummery model={model} estimation={estimation} draftId={draftId}/>
        }
      <CreateCampaignSummary model={model} estimation={estimation} draftId={draftId}/>
        </>
        }
    </div>
  );
};

export default CreateCampaignPage;
