import React, { useEffect, useState } from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { makeSelectedAccount } from "../reducer";
import {
  getAccountOwnerAsync,
  makeSelectedOwner,
  makeSelectedDetails,
  getAccountDetailsAsync,
  makeSelectedPlanDetails,
  getPlanDetailsAsync,
} from "../../Advertiser/reducer";
import { useTranslation } from "react-i18next";
import Card from "./Card";
// import GoogleMap from "@client.components/GoogleMap";
import GoogleMap from "@client.components/GoogleMap_DashBoard";
import DashboardMapFilter from "../../Dashboard/PlatformDashboard/DashboardMapFilter";
import Loader from "@client.core.components/Loader";
import ServiceProvider from "@client.services/provider";
import "./Details.scss";
import moment from "moment";
import config from "@client.config";
import CustomerType from "@client.enums/customerType";

const DetailsTab = () => {
  const dispatch = useDispatch();
  const selectedAccount = useSelector(makeSelectedAccount);
  const selectedOwner = useSelector(makeSelectedOwner);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [zones, setZones] = useState([]);
  const [selectedClusterMarkers, setSelectedClusterMarkers] = useState([]);
  const selectedDetails = useSelector(makeSelectedDetails);
  console.log("From Accounts.js: selectedDetails)", selectedDetails);
  const selectedPlanDetails = useSelector(makeSelectedPlanDetails);
  console.log("From Accounts.js: selectedPlanDetails)", selectedPlanDetails);

  const getType = () => {
    switch (selectedDetails?.details?.account?.entity) {
      case CustomerType.Platform:
        return "Platform";
      case CustomerType.Reseller:
        return "Reseller";
      case CustomerType.Advertiser:
        return "Advertiser";
      case CustomerType.Partner:
        return "Partner";
    }
  };


  const typeStatistic = {
    dynamicQty: 0,
    staticQty: 0,
    unknownQty: 0
  };

  const statusStatistic = {
    onlineQty: 0,
    offlineQty: 0
  };

  
  // accounts details
  useEffect(() => {
    if (selectedAccount) {
      dispatch(getAccountDetailsAsync(selectedAccount.Id));
    }
  }, [dispatch, selectedAccount]);

  // plan details
  useEffect(() => {
    if (selectedAccount) {
      dispatch(getPlanDetailsAsync(selectedAccount.Id));
    }
  }, [dispatch, selectedAccount]);

  const dummyMarkers = [
    { lat: 37.7749, lng: -122.4194 }, // San Francisco
    { lat: 34.0522, lng: -118.2437 }, // Los Angeles
  ];
  // Fetch Zones
  useEffect(() => {
    setLoading(true);
    ServiceProvider.Zone.getAllByAccountId(selectedAccount?.Id)
      .then((resp) => {
        setZones(get(resp, "data.zones.results", []));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedAccount]);

  // Fetch Account Owner
  useEffect(() => {
    if (selectedAccount?.Id) {
      dispatch(getAccountOwnerAsync(selectedAccount.Id));
    }
  }, [selectedAccount, dispatch]);

  const renderKeyValue = (key, value) => (
    <div className="detail-item">
      <strong>{key}</strong>
      <br />
      <span>{value || "N/A"}</span>
    </div>
  );

  return (
    <div className="account-details-tab">
      <div className="feature-items">
        <div className="details-content">
          {/* Account Details Section */}
          <Card
            title={t("pages.Accounts.tabs.Details.accountDetails")}
            // details={true}
            className="account-details"
          >
            {renderKeyValue(
              // t("pages.Accounts.tabs.Details.accountName"),
              t("pages.Accounts.tabs.Details.companyName"),
              selectedDetails?.details?.account?.companyName
            )}
            {renderKeyValue(
              t("pages.Accounts.tabs.Details.companyNumber"),
              selectedDetails?.details?.account?.companyRegistrationNumber
            )}
            {renderKeyValue(
              t("pages.Accounts.tabs.Details.vatNumber"),
              selectedDetails?.details?.account?.companyVatNumber
            )}
            {renderKeyValue(
              t("pages.Accounts.tabs.Details.companyUrl"),
              selectedDetails?.details?.account?.companyUrl
            )}
            {/* {renderKeyValue(
            t("pages.Accounts.tabs.Details.description"),
            selectedAccount?.description
          )} */}
            {renderKeyValue(
              t("pages.Accounts.tabs.Details.fullName"),
              `${selectedDetails?.details?.user?.firstName || "N/A"} ${
                selectedDetails?.details?.user?.lastName || "N/A"
              }`
            )}
            {renderKeyValue(
              t("pages.Accounts.tabs.Details.emailAddress"),
              selectedDetails?.details?.user?.emailAddress
            )}
            {renderKeyValue(
              t("pages.Accounts.tabs.Details.phoneNumber"),
              selectedDetails?.details?.user?.phoneNumber
            )}
            {renderKeyValue(
              t("pages.Accounts.tabs.Details.jobTitle"),
              selectedDetails?.details?.user?.jobTitle
            )}
            {/* {renderKeyValue("Role", selectedOwner?.owner?.role)} */}
            {/* {renderKeyValue(
            "2FA Level 1",
            selectedOwner?.owner?.tfaLevelOne ? "Enabled" : "Disabled"
          )}
          {renderKeyValue(
            "2FA Level 2",
            selectedOwner?.owner?.tfaLevelTwo ? "Enabled" : "Disabled"
          )} */}
            {renderKeyValue(
              "Created At",
              moment(selectedDetails?.details?.account?.createdAt).format(
                config.dateTimeSecondsFormat
              )
            )}
            {renderKeyValue(
              "Last Login Time",
              moment(selectedDetails?.details?.user?.lastLoginTime).format(
                config.dateTimeSecondsFormat
              )
            )}
            {renderKeyValue(
              "Updated At",
              moment(selectedDetails?.details?.user?.updatedAt).format(
                config.dateTimeSecondsFormat
              )
            )}
            {renderKeyValue(
              t("pages.Accounts.tabs.Details.companyAddress"),
              `${
                selectedDetails?.details?.account?.companyAddress.lineOne || ""
              } ${
                selectedDetails?.details?.account?.companyAddress.city || ""
              } ${
                selectedDetails?.details?.account?.companyAddress.postalCode ||
                ""
              } ${
                selectedDetails?.details?.account?.companyAddress.country || ""
              }`
            )}
          </Card>

          {/* Plan Details Section */}
          <Card
            title={t("pages.Accounts.tabs.Details.planDetails")}
            // details={true}
            className="plan-details"
          >
            {renderKeyValue(
              "Purchase Date",
              moment(selectedPlanDetails.planDetails?.purchaseDate).format(
                config.dateTimeSecondsFormat
              )
            )}
            {renderKeyValue(
              "Activation Date",
              moment(selectedPlanDetails.planDetails?.activationDate).format(
                config.dateTimeSecondsFormat
              )
            )}
            {renderKeyValue(
              "Renewal Date",
              moment(selectedPlanDetails.planDetails?.renewalDate).format(
                config.dateTimeSecondsFormat
              )
            )}
            {renderKeyValue(
              "Reset Date",
              moment(selectedPlanDetails.planDetails?.resetDate).format(
                config.dateTimeSecondsFormat
              )
            )}
            {renderKeyValue(
              "Available Slots",
              selectedPlanDetails.planDetails?.availableSlots
            )}
            {renderKeyValue(
              "Active Campaigns",
              selectedPlanDetails.planDetails?.campaignsActive
            )}
            {renderKeyValue(
              "Pending Campaigns",
              selectedPlanDetails.planDetails?.campaignsPending
            )}
            {renderKeyValue(
              "Price Per Month",
              selectedPlanDetails.planDetails?.pricePerMonth || "₪ 0"
            )}
            {renderKeyValue(
              "Price Per Slot",
              selectedPlanDetails.planDetails?.pricePerSlot || "₪ 0"
            )}
            {renderKeyValue(
              "Used Slots",
              selectedPlanDetails.planDetails?.usedSlots
            )}
          </Card>
        </div>

        {/* Zones Map */}
        {loading ? (
          <div className="account-details-map">
            <Loader loading={true} />
          </div>
        ) : (
          // <GoogleMap
          //   options={{
          //     fullscreenControl: false,
          //     mapTypeControl: false,
          //   }}
          //   polygons={zones.map((z) => z.polygon)}
          //   className="account-details-map"
          // ></GoogleMap>
          <GoogleMap
            markers={dummyMarkers}
            options={{
              fullscreenControl: false,
              mapTypeControl: false,
            }}
            className="account-details-map"
          >
              <DashboardMapFilter
            onRecenterClick={() =>
              setRecenterMapTimestamp(new Date().getTime())
            }
            typeStatistic={typeStatistic}
            statusStatistic={statusStatistic}
            selectedDeviceInfos={selectedClusterMarkers}
          />
          </GoogleMap>
        )}
      </div>
    </div>
  );
};

DetailsTab.propTypes = {
  accountId: PropTypes.string,
};

export default DetailsTab;

//original code

// import React, { useEffect, useState } from "react";
// import { get } from "lodash";
// import PropTypes from "prop-types";
// import { useSelector,useDispatch } from "react-redux";
// import { makeSelectedAccount } from "../reducer";
// import {
//   getAccountOwnerAsync,
//   makeSelectedOwner,
// } from "../../Advertiser/reducer";
// import Input from "@client.core.components/Input";
// import { useTranslation } from "react-i18next";
// import Card from "./Card";
// import Button from "@client.core.components/Button";
// import GoogleMap from "@client.components/GoogleMap";
// import Loader from "@client.core.components/Loader";
// import ServiceProvider from "@client.services/provider";
// import "./Details.scss";

// const DetailsTab = () => {
//   const dispatch = useDispatch();
//   const selectedAccount = useSelector(makeSelectedAccount);
//   const selectedOwner = useSelector(makeSelectedOwner);
//   console.log("From Details.js: ", selectedOwner);
//   console.log("From Details.js: ", selectedAccount);
//   const { t } = useTranslation();
//   const [loading, setLoading] = useState(false);
//   const [zones, setZones] = useState([]);

//   useEffect(() => {
//     setLoading(true);
//     ServiceProvider.Zone.getAllByAccountId(selectedAccount.Id)
//       .then((resp) => {
//         setZones(get(resp, "data.zones.results", []));
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   }, [selectedAccount]);

//   useEffect(() => {
//     if (selectedAccount?.Id) {
//       dispatch(getAccountOwnerAsync(selectedAccount.Id));
//     }
//   }, [selectedAccount, dispatch]);
//   return (
//     <div className="account-details-tab">
//       <div className="details-content">
//         <Card
//           title={t("pages.Accounts.tabs.Details.accountDetails")}
//           className="account-details"
//         >
//           <Input
//             value={selectedAccount.companyName}
//             readOnly={true}
//             label={t("pages.Accounts.tabs.Details.accountName")}
//           />
//           <Input
//             value={selectedAccount.companyRegistrationNumber}
//             readOnly={true}
//             label={t("pages.Accounts.tabs.Details.companyNumber")}
//           />
//           <Input
//             value={selectedAccount.companyVatNumber || "N/A"}
//             readOnly={true}
//             label={t("pages.Accounts.tabs.Details.vatNumber")}
//           />
//           <Input
//             value={selectedAccount.description || "N/A"}
//             readOnly={true}
//             label={t("pages.Accounts.tabs.Details.description")}
//           />
//            <Input
//                 value={selectedOwner?.owner?.Id}
//                 readOnly={true}
//                 label={t("pages.Accounts.tabs.Details.id")}
//               />
//               <Input
//                 value={selectedOwner?.owner?.accountId}
//                 readOnly={true}
//                 label={t("pages.Accounts.tabs.Details.accountId")}
//               />
//               <Input
//                 value={selectedOwner?.owner?.firstName}
//                 readOnly={true}
//                 label={t("pages.Accounts.tabs.Details.firstName")}
//               />
//               <Input
//                 value={selectedOwner?.owner?.lastName}
//                 readOnly={true}
//                 label={t("pages.Accounts.tabs.Details.lastName")}
//               />
//               <Input
//                 value={selectedOwner?.owner?.emailAddress}
//                 readOnly={true}
//                 label={t("pages.Accounts.tabs.Details.emailAddress")}
//               />
//               <Input
//                 value={selectedOwner?.owner?.phoneNumber || "N/A"}
//                 readOnly={true}
//                 label={t("pages.Accounts.tabs.Details.phoneNumber")}
//               />
//               <Input
//                 value={selectedOwner?.owner?.role}
//                 readOnly={true}
//                 label={t("pages.Accounts.tabs.Details.role")}
//               />
//               <Input
//                 value={selectedOwner?.owner?.createdAt}
//                 readOnly={true}
//                 label={t("pages.Accounts.tabs.Details.createdAt")}
//               />
//               <Input
//                 value={selectedOwner?.owner?.updatedAt}
//                 readOnly={true}
//                 label={t("pages.Accounts.tabs.Details.updatedAt")}
//               />
//               <Input
//                 value={selectedOwner?.owner?.lastLoginTime || "N/A"}
//                 readOnly={true}
//                 label={t("pages.Accounts.tabs.Details.lastLoginTime")}
//               />
//               <Input
//                 value={selectedOwner?.owner?.isActive ? "Active" : "Inactive"}
//                 readOnly={true}
//                 label={t("pages.Accounts.tabs.Details.isActive")}
//               />
//               <Input
//                 value={selectedOwner?.owner?.tfaLevelOne ? "Enabled" : "Disabled"}
//                 readOnly={true}
//                 label={t("pages.Accounts.tabs.Details.tfaLevelOne")}
//               />
//               <Input
//                 value={selectedOwner?.owner?.tfaLevelTwo ? "Enabled" : "Disabled"}
//                 readOnly={true}
//                 label={t("pages.Accounts.tabs.Details.tfaLevelTwo")}
//               />
//         </Card>
//         <Card
//           title={t("pages.Accounts.tabs.Details.planDetails")}
//           className="plan-details"
//         >
//           <div className="plan-description">₪ 14,000 per month</div>
//           <Button
//             className="btn-upgrade"
//             text={t("pages.Accounts.tabs.Details.upgrade")}
//             onClick={() => {
//               alert("Under construction");
//             }}
//           />
//         </Card>
//       </div>
//       {loading ? (
//         <div className="account-details-map">
//           <Loader loading={true} />
//         </div>
//       ) : (
//         <GoogleMap
//           options={{
//             fullscreenControl: false,
//             mapTypeControl: false,
//           }}
//           polygons={zones.map((z) => z.polygon)}
//           className="account-details-map"
//         ></GoogleMap>
//       )}
//     </div>
//   );
// };

// DetailsTab.propTypes = {
//   accountId: PropTypes.string,
// };

// export default DetailsTab;

// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { makeSelectedAccount } from "../reducer";
// import { getAccountOwnerAsync,makeSelectedOwner } from "../../Advertiser/reducer";
// import Input from "@client.core.components/Input";
// import Card from "./Card";
// import Loader from "@client.core.components/Loader";
// import { useTranslation } from "react-i18next";
// import "./Details.scss";

// const DetailsTab = () => {
//   const dispatch = useDispatch();
//   const { t } = useTranslation();

//   const selectedAccount = useSelector(makeSelectedAccount);
//   const selectedOwner = useSelector(makeSelectedOwner);

//   useEffect(() => {
//     if (selectedAccount?.Id) {
//       dispatch(getAccountOwnerAsync(selectedAccount.Id));
//     }
//   }, [selectedAccount, dispatch]);

//   return (
//     <div className="account-details-tab">
//       <div className="details-content">
//         <Card
//           title={t("pages.Accounts.tabs.Details.accountDetails")}
//           className="account-details"
//         >
//           <Input
//             value={selectedAccount.companyName}
//             readOnly={true}
//             label={t("pages.Accounts.tabs.Details.accountName")}
//           />
//           <Input
//             value={selectedAccount.companyRegistrationNumber}
//             readOnly={true}
//             label={t("pages.Accounts.tabs.Details.companyNumber")}
//           />
//           <Input
//             value={selectedAccount.companyVatNumber || "N/A"}
//             readOnly={true}
//             label={t("pages.Accounts.tabs.Details.vatNumber")}
//           />
//         </Card>
//         <Card
//           title={t("pages.Accounts.tabs.Details.ownerDetails")}
//           className="owner-details"
//         >
//           {selectedOwner.loading ? (
//             <Loader loading={true} />
//           ) : (
//             <>
//               <Input
//                 value={selectedOwner.owner?.firstName || "N/A"}
//                 readOnly={true}
//                 label={t("pages.Accounts.tabs.Details.firstName")}
//               />
//               <Input
//                 value={selectedOwner.owner?.lastName || "N/A"}
//                 readOnly={true}
//                 label={t("pages.Accounts.tabs.Details.lastName")}
//               />
//               <Input
//                 value={selectedOwner.owner?.emailAddress || "N/A"}
//                 readOnly={true}
//                 label={t("pages.Accounts.tabs.Details.emailAddress")}
//               />
//             </>
//           )}
//         </Card>
//       </div>
//     </div>
//   );
// };

// export default DetailsTab;
