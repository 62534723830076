import React from "react";
import PropTypes from "prop-types";
import "./ModalDeviceApprovalCustom.scss";
import Icon from "@client.core.components/Icon"; // Import Icon Component

const ModalDeviceApprovalCustom = ({ title, children, Close,onReject, onConfirm,status  }) => (
  <div className="modal-component2">
    <div className="modal-component2-content">
      <div className="modal-component2-title">
        <span>{title}</span>
        <Icon
            name="close"
            className="modal-component-close-button"
            onClick={Close} // Use the Close handler for closing
          />
        {/* <button className="button button-cancel" onClick={Close}>
          Close
        </button> */}
      </div>
      <div className="modal-component2-body">{children}</div>
      {/* <div className="modal-component2-footer">
        <button className="button button-cancel" onClick={onReject}>
          Reject
        </button>
        <button className="button button-confirm" onClick={onConfirm}>
          Approve
        </button>
      </div> */}
    </div>
  </div>
);

ModalDeviceApprovalCustom.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  Close: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
};

export default ModalDeviceApprovalCustom;


