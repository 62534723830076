import BaseService from "@client.services/baseService";

export default class InvoiceService extends BaseService {
  async getAll() {
    return super.post("", {
      query: `
        query {
          invoices {
            count
            results {
              Id
              subscriptionId
              invoiceDate
              dueDate
              amount
              status
            }
          }
        }
      `
    });
  }

  async getByInvoiceId(id) {
    return super.post("", {
      query: `query($id: String){
      invoice(id: $id){
        Id
        subscriptionId
        invoiceDate
        dueDate
        amount
        status
        }
      }`,
      variables: {
        id: id
      }
    });
  }

  async delete(Id) {
    return super.post("", {
      query: `mutation($id: String){
        deleteInvoice(id: $id){
        message
        }
      }`,
      variables: {
        id: Id
      }
    });
  }

  async update(model) {
    return super.post("", {
      query: `mutation($id: String, $fields: InvoiceInputType) {
        updateInvoice(id: $id, fields: $fields) {
          message
          invoice {
            Id
            subscriptionId
            invoiceDate
            dueDate
            amount
            status
          }
        }
      }`,
      variables: {
        id: model.Id,
        fields: {
          subscriptionId: model.subscriptionId || "",
          invoiceDate: model.invoiceDate || "",
          dueDate: model.dueDate || "",
          amount: model.amount || "",
          status: model.status || ""
        }
      }
    });
  }

  async create(invoice) {
    return super.post("", {
      query: `mutation($fields: InvoiceInputType){
        createInvoice(fields: $fields){
          message
          invoice{
            Id
            subscriptionId
            invoiceDate
            dueDate
            amount
            status
          }
        }
      }`,
      variables: {
        fields: {
          subscriptionId: invoice.subscriptionId,
          invoiceDate: invoice.invoiceDate,
          dueDate: invoice.dueDate,
          amount: invoice.amount,
          status: invoice.status
        }
      }
    });
  }
}
