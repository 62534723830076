import React, { useEffect, useState, useCallback } from "react";
import DataTable from "@client.components/DataTable";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import config from "@client.config";
import LogActions from "@client.enums/logActions";
import LogType from "@client.enums/logType";
import Pages from "@client.enums/pages";
import {
  getAllSystemLogsAsync,
  makeSystemLogs
} from "../../pages/SystemLogs/reducer";

const ITEMS_PER_PAGE = 20;

const getColumns = (t, onRowClick) => [
  {
    field: "createdAt",
    headerName: t("Created At"),
    cellRenderer: (params) => {
      return params.value
        ? moment(params.value).format(config.dateTimeFormat)
        : "N/A";
    }
  },
  {
    field: "entityId",
    headerName: t("systemLogs.Related"),
    cellRenderer: (params) => {
      const entityPage = (() => {
        switch (params.data.entity) {
          case LogType.Device:
            return t("systemLogs.goToPage.Device");
          case LogType.User:
            return t("systemLogs.goToPage.User");
          case LogType.Accounts:
            return t("systemLogs.goToPage.Accounts");
          default:
            return t("N/A");
        }
      })();

      return (
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            onRowClick(entityPage, params.value);
          }}
        >
          {params.value || "N/A"}
        </a>
      );
    }
  },
  {
    field: "userFullName",
    headerName: t("Full Name")
  },
  {
    field: "type",
    headerName: t("Action"),
    valueGetter: (params) => {
      switch (params.data.type) {
        case LogActions.CREATE:
          return t("systemLogs.type.CREATE");
        case LogActions.UPDATE:
          return t("systemLogs.type.UPDATE");
        case LogActions.DELETE:
          return t("systemLogs.type.DELETE");
        case LogActions.LOGIN:
          return t("systemLogs.type.LOGIN");
        default:
          return t("N/A");
      }
    }
  },
  {
    field: "description",
    headerName: t("Description")
  },
  {
    field: "ipAddress",
    headerName: t("IP Address")
  }
];

const LogsAlertsDataTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const systemLogs = useSelector(makeSystemLogs);
  const [currentPage, setCurrentPage] = useState(1);

  const onRowClick = useCallback(
    (page, entityId) => {
      //console.log("Page.Device", `${Pages.Devices}/${entityId}`);
      switch (page) {
        case "Device":
          navigate(`${Pages.Devices}/${entityId}`);
          break;
        case "User":
          navigate(`${Pages.ProfileGeneralEditUser}/${entityId}`);
          break;
        case "Accounts":
          navigate(`${Pages.Advertiser}/${entityId}`);
          break;
        default:
          console.warn("Unknown entity type");
          break;
      }
    },
    [navigate]
  );

  useEffect(() => {
    const offset = (currentPage - 1) * ITEMS_PER_PAGE;
    dispatch(
      getAllSystemLogsAsync({
        offset,
        limit: ITEMS_PER_PAGE,
        search: "",
        searchOn: "",
        startDate: "",
        endDate: ""
      })
    );
  }, [currentPage, dispatch]);

  return (
    <div className="logs-alerts">
      <DataTable
        columns={getColumns(t, onRowClick)}
        data={systemLogs.results}
        isLoading={systemLogs.loading}
        // pagination={{
        //   total: systemLogs.count,
        //   ITEMS_PER_PAGE: ITEMS_PER_PAGE,
        //   currentPage,
        //   onChange: setCurrentPage
        // }}
      />
    </div>
  );
};

export default LogsAlertsDataTable;

// import React, { useEffect, useState } from "react";
// import DataTable from "@client.components/DataTable";
// import { useTranslation } from "react-i18next";
// import moment from "moment";
// import config from "@client.config";
// import { useDispatch, useSelector } from "react-redux";
// import { getAllSystemLogsAsync, makeSystemLogs } from "../../pages/SystemLogs/reducer";

// const ITEMS_PER_PAGE = 100;

// const getColumns = () => [
//   {
//     field: "entityId",
//     tooltipField: "entityId",
//     headerName: "Related",
//     callRenderer: (params) => params.data.entityId,
//   },
//   {
//     field: "createdAt",
//     headerName: "Created At",
//     tooltipValueGetter: (params) =>
//       moment(params.data.createdAt).format(config.dateTimeSecondsFormat),
//     cellRenderer: (params) =>
//       moment(params.data.createdAt).format(config.dateTimeSecondsFormat),
//   },
//   {
//     field: "title",
//     tooltipField: "title",
//     headerName: "Full Naame",
//     cellRenderer: (params) => params.data.userFullName,
//   },
//   {
//     field: "Action",
//     tooltipField: "unitType",
//     headerName: "Action",
//     cellRenderer: (params) => params.data.type,
//   },
//   {
//     field: "description",
//     tooltipField: "description",
//     headerName: "Description",
//   },
//   {
//     field: "IP",
//     tooltipField: "ip",
//     headerName: "IP",
//     cellRenderer: (params) => params.data.ipAddress,
//   },
// ];

// const LogsAlertsDataTable = () => {
//   const { t } = useTranslation();
//   const dispatch = useDispatch();
//   const systemLogs = useSelector(makeSystemLogs); // Redux state for system logs
//   const [currentPage, setCurrentPage] = useState(1);

//   useEffect(() => {
//     const offset = (currentPage - 1) * ITEMS_PER_PAGE;
//     dispatch(
//       getAllSystemLogsAsync({
//         offset,
//         limit: ITEMS_PER_PAGE,
//         search: "", // Adjust filters if needed
//         searchOn: "",
//         startDate: "",
//         endDate: "",
//       })
//     );
//   }, [currentPage, dispatch]);

//   return (
//     <div className="logs-alerts">
//       <DataTable
//         columns={getColumns(t)}
//         data={systemLogs.results} // Use Redux state
//         isLoading={systemLogs.loading} // Use loading from Redux
//         // pagination={{
//         //   total: systemLogs.count, // Use count from Redux
//         //   ITEMS_PER_PAGE: ITEMS_PER_PAGE,
//         //   onChange: setCurrentPage,
//         // }}
//       />
//     </div>
//   );
// };

// export default LogsAlertsDataTable;

// import React, { useEffect, useState } from "react";
// import DataTable from "@client.components/DataTable";
// import { useTranslation } from "react-i18next";
// import moment from "moment";
// import config from "@client.config";
// import ServiceProvider from "@client.services/provider";

// const ITEMS_PER_PAGE = 10;

// const getColumns = () => [
//   {
//     field: "id",
//     tooltipField: "id",
//     headerName: "ID",
//   },
//   {
//     field: "createdAt",
//     headerName: "Created At",
//     tooltipValueGetter: (params) =>
//       moment(params.data.createdAt).format(config.dateTimeSecondsFormat),
//     cellRenderer: (params) =>
//       moment(params.data.createdAt).format(config.dateTimeSecondsFormat),
//   },
//   {
//     field: "title",
//     tooltipField: "title",
//     headerName: "Title",
//   },
//   {
//     field: "unitType",
//     tooltipField: "unitType",
//     headerName: "Unit Type",
//   },
//   {
//     field: "unitId",
//     tooltipField: "unitId",
//     headerName: "Unit ID",
//   },
//   {
//     field: "icon",
//     tooltipField: "icon",
//     headerName: "Icon",
//   },
//   {
//     field: "description",
//     tooltipField: "description",
//     headerName: "Description",
//   },
// ];

// const LogsAlertsDataTable = () => {
//   const { t } = useTranslation();
//   const [data, setData] = useState({ count: 0, results: [] });
//   const [loading, setLoading] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);

//   useEffect(() => {
//     const offset = (currentPage - 1) * ITEMS_PER_PAGE;
//     setLoading(true);
//     ServiceProvider.Dashboard.getSystemLogs(ITEMS_PER_PAGE, offset)
//       .then((resp) => {
//         setData(resp.data.fetchSystemLogs);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//     setTimeout(() => setLoading(false), 1000);
//   }, [currentPage]);

//   return (
//     <div className="logs-alerts">
//       <DataTable
//         columns={getColumns(t)}
//         data={data.results}
//         isLoading={loading}
//         pagination={{
//           total: data.count,
//           ITEMS_PER_PAGE: ITEMS_PER_PAGE,
//           onChange: setCurrentPage,
//         }}
//       />
//     </div>
//   );
// };

// export default LogsAlertsDataTable;
