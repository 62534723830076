/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CampaignModel from "@client.models/campaign";
import Button from "@client.core.components/Button";
import { validateModel } from "@client.utils/form";
import RadioGroup from "@client.components/RadioGroup";
import LocationTypes from "@client.enums/locationTypes";

import {
  getAllLocationsAsync,
  makeSelectedZone,
  makeZones,
  setSelectedZone,
  getLocationByIdAsync
} from "@client.pages/Zones/reducer";
import "./Locations.scss";
import Zones from "@client.components/Zones";
import ZoneType from "@client.enums/zoneType";

const Locations = ({ model, setModel, edit, onSave, onEdit }) => {
  // console.log("From Locations.js: model", model);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(true);
  const [zonesToShow, setZonesToShow] = useState([]);
  const selectedZone = useSelector(makeSelectedZone);
  // console.log("From Locations.js: selectedZone", selectedZone);
  const zones = useSelector(makeZones);
  const [locationNames, setLocationNames] = useState([]);
  const [nextPressed, setNextPressed] = useState(false);
  // console.log("From Locations.js: nextPressed", nextPressed);
  // console.log("From Locations.js: locationNames", locationNames[0]);
  // const data = locationNames[0].polygon;

  // Convert the string to its original type
  // const coordinates = JSON.parse(data);

  // // console.log(coordinates); // Logs the array of arrays to the console


if (model.id!==null){
  useEffect(() => {
    const fetchLocationNames = async () => {
      if (Array.isArray(model.geofenceZoneIds)) {
        // Check if geofenceZoneIds is defined and is an array
        const names = await Promise.all(
          model.geofenceZoneIds.map(async (id) => {
            const result = await dispatch(getLocationByIdAsync(id));
            // console.log("From Locations.js: result", result.payload?.data?.zone);
            const revertedZone = {
              ...result.payload?.data?.zone,
              polygon: JSON.parse(result.payload?.data?.zone?.polygon),
            };
            // console.log("From Locations.js: revertedZone", revertedZone);
            return revertedZone || "Unknown"; // Adjust based on actual response structure
            // return result.payload?.data?.zone || "Unknown"; // Adjust based on actual response structure
            // return JSON.parse(result.payload?.data?.zone.polygon) || "Unknown"; // Adjust based on actual response structure
          })
        );
        setLocationNames(names);
      } else {
        setLocationNames([]);
      }
    };

    fetchLocationNames();
  }, [dispatch, model.geofenceZoneIds]);
}


  useEffect(() => {
    if (model) {
      if (model.locationType === LocationTypes.Zones) {
        setZonesToShow(
          zones.filter((zone) => zone.category !== ZoneType.Custom)
        );
      } else {
        setZonesToShow(
          zones.filter((zone) => zone.category === ZoneType.Custom)
        );
      }
    }
  }, [model, zones]);
  
  useEffect(() => {
    dispatch(getAllLocationsAsync());
  }, []);
  
  useEffect(() => {
    setIsEdit(!!edit);
  }, [edit]);
  
  const changeModel = useCallback(
    (v, field) => {
      // console.log("From Locations.js: v", v);
      // console.log("From Locations.js: field", field);
      // debugger
      setModel(
        // new CampaignModel({ ...model, [field]: v }, model.validationMsgs)
        new CampaignModel({ ...model, [field]: v, geofenceZoneIds : [v.Id]
          
        }, model.validationMsgs)
      );
      // console.log("From Locations.js: model", model);
    },
    [model]
  );
  
  useEffect(() => {}, [model]);
  
  const locationTypes = [
    {
      value: LocationTypes.Zones,
      label: t("pages.CreateCampaign.locationType.zones"),
    },
    {
      value: LocationTypes.Custom,
      label: t("pages.CreateCampaign.locationType.custom"),
    },
  ];
  
  if (!model) {
    return null;
  }
  const title = t("pages.CreateCampaign.section.locations");
  if (!isEdit) {
    // console.log("modelllll", model.zone);
    // const isReadyToEdit = model.zone.Id !== null;
    const isReadyToEdit = model.zone.Id !== null||(model.geofenceZoneIds.length > 0 &&nextPressed);

    return (
      <div className="locations minimized">
        <div>
          <div className="step-title">{title}</div>
          {/* {isReadyToEdit && (
            <span className="step-description">
              {t("pages.CreateCampaign.minimizedLocationFormat", {
                selectedArea: model.zone.zoneName,
              })}
            </span>
          )} */}


          
          {model.zone.Id !== null && (
            <span className="step-description">
              {t("pages.CreateCampaign.minimizedLocationFormat", {
                selectedArea: model.zone.zoneName,
              })}
            </span>
          )}
          {(model.geofenceZoneIds.length > 0)  && (
            <span className="step-description">
              {t("pages.CreateCampaign.minimizedLocationFormat", {
                selectedArea: locationNames[0]?.zoneName,
              })}
            </span>
          )}
        </div>
        {isReadyToEdit && (
          <Button
            icon="edit"
            text={t("pages.CreateCampaign.edit")}
            className="wizard"
            onClick={onEdit}
          />
        )}
      </div>
    );
  }

  return (
    <div className="locations">
      <div className="step-title">{title}</div>
      <div className="locations-body">
        <RadioGroup
          groupName="location-type"
          items={locationTypes}
          value={model.locationType}
          onChange={(v) => {
            dispatch(setSelectedZone(null));
            setModel(
              new CampaignModel(
                {
                  ...model,
                  locationType: v,
                  zone: null,
                },
                model.validationMsgs
              )
            );
          }}
        />
        <div className="content-area">
          <Zones
            selectedZone={selectedZone!==null?selectedZone:locationNames[0]}
            zones={zonesToShow}
            onZoneSelect={(zone) => {
              // console.log("selectedZone", zone);
              dispatch(setSelectedZone(zone));
              changeModel(zone, "zone");
            }}
            allowDrawing={model.locationType === LocationTypes.Custom}
            isPricePerViewEditable={false}
          />
        </div>
      </div>
      <Button
        text={t("pages.CreateCampaign.next")}
        className="btn-next wizard"
        onClick={() => {
          setNextPressed(!nextPressed);
          const validationRules = {
            locationType: {
              presence: {
                allowEmpty: false,
                message: t(
                  "pages.CreateCampaign.location.validation.locationType"
                ),
              },
            },
            zone: {
              presence: {
                allowEmpty: false,
                message: t("pages.CreateCampaign.location.validation.zone"),
              },
            },
          };

          const isValid = validateModel(model, validationRules);
          // console.log("From Locations.js: End of validation", model);
          if (isValid) {
            onSave(model)
          }
        }}
      />
    </div>
  );
};

Locations.propTypes = {
  edit: PropTypes.bool,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  model: PropTypes.shape({
    campaignName: PropTypes.string,
    advertiserId: PropTypes.string,
    validationMsgs: PropTypes.string,
    fileId: PropTypes.string,
    
    target: PropTypes.string,
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    locationType: PropTypes.string,
    
    zone: PropTypes.shape({
      Id: PropTypes.string,
      zoneName: PropTypes.string,
    })
  }),
  setModel: PropTypes.func,
};

export default Locations;
