import BaseModel from "./base";
import { get } from "lodash";

class SubscriptionModel extends BaseModel {
    constructor(subscription = {}, msg = {}) {
        super(msg);

        this.Id = get(subscription, "Id", null); // Included for tracking purposes
        this.planId = get(subscription, "planId", "");
        this.startDate = get(subscription, "startDate", "");
        this.endDate = get(subscription, "endDate", "");
        this.status = get(subscription, "status", 0);
        this.userId = get(subscription, "userId", "");
        this.updatedAt = get(subscription, "updatedAt", "");
        this.isActive = get(subscription, "isActive", false);
        this.createdAt = get(subscription, "createdAt", "");
        this.validationMsgs = msg;

        this.validationRules = {
            planId: {
                presence: { allowEmpty: false, message: msg.planId || "Plan ID is required" },
            },
            startDate: {
                presence: { allowEmpty: false, message: msg.startDate || "Start Date is required" },
            },
            endDate: {
                presence: { allowEmpty: false, message: msg.endDate || "End Date is required" },
            },
            status: {
                presence: { allowEmpty: false, message: msg.status || "Status is required" },
            },
        };
    }

    // Fields for operations like create or update
    get fields() {
        const baseFields = {
            planId: this.planId,
            startDate: this.startDate,
            endDate: this.endDate,
            status: this.status,
        };

        // Include `Id` only for updates or tracking purposes
        if (this.Id) {
            baseFields.Id = this.Id;
        }

        return baseFields;
    }
}

export default SubscriptionModel;
