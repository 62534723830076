import GridManagementButtons from "@client.components/GridManagementButtons";
import React from "react";

export const getColumns = (t, onView, onEdit, onDelete) => [
  {
    field: "index",
    tooltipField: "index",
    headerName: "#",
    valueGetter: (params) => params.node.childIndex + 1,
    cellRenderer: (params) => (
      <div className="subscription-name clickable-cell">{params.value}</div>
    ),
    maxWidth: 80
  },

  {
    field: "Plan ID",
    cellRenderer: (params) => (
      <div className="subscription-plan clickable-cell">
        {params.data.planId}
      </div>
    ),
    tooltipField: "PlanId",
    headerName: t("pages.subscription.columns.planId")
    // minWidth: 250,
  },
  {
    field: "Subscription startDate",
    cellRenderer: (params) => (
      <div className="subscription-date clickable-cell">
        {params.data.startDate}
      </div>
    ),
    tooltipField: "SubscriptionstartDate",
    headerName: t("pages.subscription.columns.startDate")
    // minWidth: 250,
  },
  {
    field: "Subscription endtDate",
    cellRenderer: (params) => (
      <div className="subscription-endDate clickable-cell">
        {params.data.endDate}
      </div>
    ),
    tooltipField: "SubscriptionendDate",
    headerName: t("pages.subscription.columns.endDate")
    // minWidth: 250,
  },
  {
    field: "status",
    headerName: t("pages.subscription.columns.subscriptionStatus"),
    cellClass: "data-table-cell cell-status",
    cellRenderer: (data) => {
      const statusMapping = {
        1: "status-1", // Active
        2: "status-2", // Cancelled
        3: "status-3", // PastDue
        4: "status-4", // Expired
        5: "status-5" // Trial
      };

      const statusClassName = statusMapping[data.data.status] || "status-1";

      const status = t(
        // `pages.subscription.status.${statusClassName}` ||
        `pages.subscription.status` ||
          "pages.subscription.status.Active"
      );

      return <span className={`status ${statusClassName}`}>{status}</span>;
    }
  },
  {
    field: "Updated At",
    cellRenderer: (params) => (
      <div className="subscription-updatedAt clickable-cell">
        {params.data.updatedAt}
      </div>
    ),
    tooltipField: "SubscriptionUpdatedAt",
    headerName: t("pages.subscription.columns.updatedAt")
    // minWidth: 250,
  },

  {
    field: "createdAt",
    cellRenderer: (params) => (
      <div className="subscription-createdAt clickable-cell">
        {params.data.createdAt}
      </div>
    ),
    tooltipField: "SubscriptionisActive",
    headerName: t("pages.subscription.columns.createdAt")
    // minWidth: 250,
  },

  {
    colId: "actions",
    headerName: "",
    cellRenderer: ({ data }) => (
      <>
        <GridManagementButtons
          item={data}
          onView={onView}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </>
    ),
    maxWidth: 70
  }
];












// export const SUBSCRIPTION_STATUSES = {
//     ACTIVE: 1,
//     INACTIVE: 0,
//     PENDING: 2,
//   };
  
//   export const SUBSCRIPTION_COLUMNS = [
//     { field: "planId", headerName: "Plan ID" },
//     { field: "startDate", headerName: "Start Date" },
//     { field: "endDate", headerName: "End Date" },
//     { field: "status", headerName: "Status" },
//     { field: "userId", headerName: "User ID" },
//     { field: "createdAt", headerName: "Created At" },
//     { field: "updatedAt", headerName: "Updated At" },
//   ];
  