import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "@client.services/provider";
import { getGraphqlResponseError, getResponseError } from "@client.utils/error";

const DEFAULT_ACCOUNTS = { data: [], loading: false, count: 0 };

const initialState = {
  accounts: DEFAULT_ACCOUNTS,
  selectedAccount: null,
};

export const getAllAccountsAsync = createAsyncThunk(
  "accounts/getAll",
  async () => {
    // const resp = await ServiceProvider.Account.getAll({
    const resp = await ServiceProvider.Advertiser.getAll({
      limit: 1000,
      offset: 0,
      search: "",
      searchOn: "",
      startDate: "",
      endDate: "",
    });
    return resp;
  }
);

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    setSelectedAccount: (state, action) => {
      state.selectedAccount = get(action, "payload", null);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAccountsAsync.pending, (state) => {
        state.accounts.loading = true;
      })
      .addCase(getAllAccountsAsync.fulfilled, (state, action) => {
        const response = get(
          action,
          "payload.data.accounts",
          DEFAULT_ACCOUNTS.data
        );
        state.accounts.data = response.results;
        state.accounts.loading = false;
        state.error = getGraphqlResponseError(action);
      })
      .addCase(getAllAccountsAsync.rejected, (state, action) => {
        state.devices = {
          ...DEFAULT_ACCOUNTS,
        };
        state.error = getResponseError(action);
      });
  },
});

export const { setSelectedAccount } = accountsSlice.actions;

export const makeSelectedAccount = (state) => state.account.selectedAccount;

export const makeAccountHierarchy = (state) => {
  const accountMap = {};
  const hierarchy = [];

  const accounts = state.account.accounts.data.map((d) => {
    accountMap[d.Id] = d;
    return { ...d, children: [] };
  });

  accounts.forEach((account) => {
    if (account.parentAccountId) {
      const parentAccount = accountMap[account.parentAccountId];
      if (parentAccount) {
        parentAccount.children.push(account);
      }
    } else {
      hierarchy.push(account);
    }
  });

  return { hierarchy, loading: state.account.accounts.loading };
};

export default accountsSlice.reducer;
